import createSelector from 'lib/web/createSelector'
import { isBedbankItem, isInstantBookingLEHotelItem } from 'lib/checkout/checkoutUtils'
import { checkoutAccommodationOfferView } from './view/accommodation'
import { getFlightItems } from './view/flights'
import { getVillaViews } from 'checkout/selectors/view/villa'
import { checkCanRedeemLuxPlusBenefits, isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { getTourV1Items } from './view/tourv1'

export const isFlightsPendingToAddToCart = createSelector(
  getFlightItems,
  (flightItems) : boolean => {
    return flightItems.length > 0 &&
    flightItems.some(item => {
      if (item.fareType === 'return') {
        return !item.flights[0] || !item.flights[1]
      } else if (item.fareType === 'oneWay') {
        return !item.flights[0]
      }
    })
  },
)

export const hasIncompleteTourItem = createSelector(
  getTourV1Items,
  item => item.some(item => !item.packageId || !item.startDate || !item.endDate),
)

export const hasInsuranceItem = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder?.insuranceItems,
  (items) => {
    if (!items?.length) {
      return false
    }

    return items.some(item => item.status !== 'cancelled')
  },
)

export const isExperiencesSupportedToAddToCart = createSelector(
  checkoutAccommodationOfferView,
  getVillaViews,
  (state: App.State) => state.checkout.cart.postPurchase,
  (accommodationViews, villaViews, postPurchase) : boolean => {
    if (!postPurchase || postPurchase === 'select-date') {
      if (accommodationViews.data.length > 0 || villaViews.data.length > 0) {
        return accommodationViews.data.every(view => view.itemViews.every(itemView => isInstantBookingLEHotelItem(itemView.item) || isBedbankItem(itemView.item))) ||
          villaViews.data.length > 0
      }
    }

    return false
  },
)

export const isLuxPlusAvailableToAddToCart = createSelector(
  isLuxPlusEnabled,
  checkCanRedeemLuxPlusBenefits,
  (state: App.State) => state.checkout.cart.isGift,
  (state: App.State) => state.checkout.cart.postPurchase,
  (state: App.State) => state.luxPlus.member.disableMembership,
  (enabled, canRedeemLuxPlusBenefits, isGift, isPostPurchase, disableMembership): boolean => {
    const shouldSeeUpsell = !canRedeemLuxPlusBenefits
    return !!(enabled && shouldSeeUpsell && !isGift && !isPostPurchase && !disableMembership)
  },
)
