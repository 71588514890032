import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineLuggageIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fillRule="evenodd" d="M14.7998 7.8928c.5523 0 1 .4477 1 1v9c0 .5523-.4477 1-1 1s-1-.4477-1-1v-9c0-.5523.4477-1 1-1ZM12 7.8928c.5523 0 1 .4477 1 1v9c0 .5523-.4477 1-1 1s-1-.4477-1-1v-9c0-.5523.4477-1 1-1ZM9.2002 7.8928c.5523 0 1 .4477 1 1v9c0 .5523-.4477 1-1 1s-1-.4477-1-1v-9c0-.5523.4477-1 1-1Z" clipRule="evenodd"/>
    <path fill="currentColor" fillRule="evenodd" d="M9 1c-.5523 0-1 .4477-1 1 0 .4644.3165.8548.7456.9674v1.6754H7.5c-1.6569 0-3 1.3432-3 3v11.5c0 1.4877 1.0829 2.7225 2.5034 2.9591.0524.7809.7024 1.3981 1.4966 1.3981.7803 0 1.4214-.5957 1.4933-1.3572h4.0134c.0719.7615.713 1.3572 1.4933 1.3572.7942 0 1.4442-.6172 1.4966-1.3981 1.4205-.2366 2.5034-1.4714 2.5034-2.9591v-11.5c0-1.6568-1.3431-3-3-3h-1.2456V2.9673C15.6835 2.8549 16 2.4644 16 2c0-.5523-.4477-1-1-1H9Zm4.2544 3.6428V3h-2.5088v1.6428h2.5088Zm-6.7544 3c0-.5523.4477-1 1-1h9c.5523 0 1 .4477 1 1v11.5c0 .5523-.4477 1-1 1h-9c-.5523 0-1-.4477-1-1v-11.5Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineLuggageIcon
