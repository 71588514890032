import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineTrashAltIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M10 18a1.0001 1.0001 0 0 0 1-1v-6a1.0001 1.0001 0 0 0-2 0v6a1 1 0 0 0 1 1ZM20 6h-4V5a3 3 0 0 0-3-3h-2a3 3 0 0 0-3 3v1H4a1 1 0 0 0 0 2h1v11a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8h1a1 1 0 1 0 0-2ZM10 5a1.0002 1.0002 0 0 1 1-1h2a1 1 0 0 1 1 1v1h-4V5Zm7 14a1.0001 1.0001 0 0 1-1 1H8a1.0002 1.0002 0 0 1-1-1V8h10v11Zm-3-1a1.0001 1.0001 0 0 0 1-1v-6a1.0001 1.0001 0 0 0-2 0v6a1.0001 1.0001 0 0 0 1 1Z"/>
  </SvgIcon>
}

export default LineTrashAltIcon
