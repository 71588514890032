import React from 'react'
import LoadingAnimation from '../../Common/Loading/LoadingAnimation'
import BodyText from '../../Luxkit/Typography/BodyText'
import Group from '../../utils/Group'

function AltDatesLoading() {
  return <Group direction="vertical" horizontalAlign="center" gap={12}>
    <LoadingAnimation />
    <div>
      <BodyText align="center" variant="small">Sold out for your exact dates.</BodyText>
      <BodyText align="center" variant="small">Searching for similar availability</BodyText>
    </div>
  </Group>
}

export default AltDatesLoading
