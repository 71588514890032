import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import moment from 'moment'
import { rem } from 'polished'
import styled from 'styled-components'
import useToggle from 'hooks/useToggle'
import ModalContext from 'contexts/ModalContext'
import { useAppDispatch } from 'hooks/reduxHooks'
import { dateDifference } from 'lib/datetime/dateUtils'
import { removeUserNotification } from 'actions/AuthActions'
import Group from 'components/utils/Group'
import Modal from 'components/Luxkit/Modal/Modal'
import ListItem from 'components/Luxkit/List/ListItem'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import IconButton from 'components/Luxkit/Button/IconButton'
import LineBellIcon from 'components/Luxkit/Icons/line/LineBellIcon'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import ListItemThumbnail from 'components/Luxkit/List/ListItemThumbnail'
import LineTrashAltIcon from 'components/Luxkit/Icons/line/LineTrashAltIcon'
import LineEllipsisVIcon from 'components/Luxkit/Icons/line/LineEllipsisVIcon'

const NewNotificationContainer = styled.div`
  width: ${rem(8)};
  height: ${rem(8)};
`

const NewDot = styled.div`
  width: ${rem(8)};
  height: ${rem(8)};
  border-radius: ${props => props.theme.borderRadius.round};
  background-color: ${props => props.theme.palette.highlight.secondary.normalForeground};
`

interface Props {
  notification: App.BrazeNotification;
}

function HeaderNotificationItem({ notification }: Props) {
  const { toggle, value: isMenuOpen } = useToggle()

  const dispatch = useAppDispatch()
  const showModal = useContext(ModalContext)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const subtitle = useMemo(() => {
    if (!notification.created) return
    const difference = dateDifference(new Date(), notification.created)

    if (difference.days > 1) return moment(notification.created).format('DD MMM YYYY')
    if (difference.days === 1) return 'Yesterday'
    if (difference.hours === 1) return `${difference.hours} hour ago`
    if (difference.hours > 1) return `${difference.hours} hours ago`
    if (difference.minutes === 1) return `${difference.minutes} minute ago`
    if (difference.minutes > 1) return `${difference.minutes} minutes ago`
    return 'Less than a minute ago'
  }, [notification])

  const onPropsClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    toggle()
  }, [toggle])

  const onClick = useCallback(() => {
    import(/* webpackChunkName: "braze-sdk" */ '@braze/web-sdk').then(({ logCardClick }) => {
      logCardClick(notification, true)
    })
  }, [notification])

  const onDismiss = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    import(/* webpackChunkName: "braze-sdk" */ '@braze/web-sdk').then(({ logCardDismissal }) => {
      e.preventDefault()
      logCardDismissal(notification)
      dispatch(removeUserNotification(notification))
      toggle()
    })
  }, [dispatch, notification, toggle])

  const onOpenMobilePropsModal = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    showModal(<Modal title="Manage notification">
      <ListItem
        size="medium"
        startIcon={<LineTrashAltIcon />}
        title="Delete"
        onClick={onDismiss}
      />
    </Modal>)
  }, [onDismiss, showModal])

  useEffect(() => {
    const logImpression = () => {
      import(/* webpackChunkName: "braze-sdk" */ '@braze/web-sdk').then(({ logCardImpressions }) => {
        logCardImpressions([notification], true)
      })
    }

    logImpression()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ListItem
      onClick={onClick}
      title={<><strong>{notification.title}</strong> {notification.description}</>}
      subtitle={subtitle}
      href={notification.url ?? undefined}
      target="_blank"
      startIcon={
        <Group direction="horizontal" gap={8} verticalAlign="center">
          <NewNotificationContainer>{!notification.viewed && <NewDot />}</NewNotificationContainer>
          <ListItemThumbnail kind="neutral"><LineBellIcon /></ListItemThumbnail>
        </Group>
      }
      endIcon={notification.dismissible &&
        <>
          <CSSBreakpoint max="mobile">
            <IconButton kind="tertiary" variant="dark" onClick={onOpenMobilePropsModal}>
              <LineEllipsisVIcon />
            </IconButton>
          </CSSBreakpoint>

          <CSSBreakpoint min="tablet">
            <IconButton
              kind="tertiary"
              variant="dark"
              onClick={onPropsClick}
              ref={buttonRef}
            >
              <LineEllipsisVIcon />
            </IconButton>
            <DropdownList
              size="S"
              open={isMenuOpen}
              placement="bottom-end"
              triggerRef={buttonRef}
              anchorRef={buttonRef}
              onClose={toggle}
            >
              <ListItem
                size="medium"
                startIcon={<LineTrashAltIcon />}
                title="Delete"
                onClick={onDismiss}
              />
            </DropdownList>
          </CSSBreakpoint>
        </>
      }
    />
  )
}

export default HeaderNotificationItem
