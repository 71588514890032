import CarouselCardSmall from 'components/OfferList/OfferCards/OfferCardSmall/CarouselCardSmall'
import { showUserReviewsRating } from 'lib/order/reviewUtils'
import React, { useContext, useMemo } from 'react'
import OfferLabels from 'components/Common/Labels/OfferLabels'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import OfferBadgeTag from 'components/Common/OfferBadgeTag'
import LEOfferPriceDetails from './LEOfferPriceDetails'
import ProductPaletteProvider from 'contexts/ProductPaletteContext'
import { isUltraLuxOffer } from 'lib/offer/offerUtils'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import LuxPlusHiddenOfferCarouselCard from 'luxPlus/components/HiddenOffer/LuxPlusHiddenOfferCarouselCard'
import { connect } from 'react-redux'
import { isLuxPlusHiddenOffer } from 'luxPlus/selectors/luxPlusHiddenOffer'
import CarouselCardMedium from './OfferCardMedium/CarouselCardMedium'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import getUrgencyLabels from 'lib/offer/getUrgencyLabels'
import GeoContext from 'contexts/geoContext'
import { getInclusionsViewableLuxPlusTier } from 'selectors/luxPlusSelectors'

interface MappedProps {
  isLuxPlusEnabled: boolean;
  isLuxPlusHiddenOffer: boolean;
  inclusionsViewableLuxPlusTier: App.MembershipSubscriptionTier;
}

interface Props extends MappedProps {
  offer: App.HotelOffer | App.HotelOfferSummary
  onClick: () => void
  className?: string;
  offerUrl: string;
  size: 'small' | 'medium';
  selectedDatesAndRoomsFilter?: App.OfferListFilters;
}

function LEOfferCard(props: Props) {
  const {
    offer,
    size,
    onClick,
    className,
    offerUrl,
    isLuxPlusHiddenOffer,
    isLuxPlusEnabled,
    selectedDatesAndRoomsFilter,
    inclusionsViewableLuxPlusTier,
  } = props
  const { lowestPricePackage } = offer

  const { currentRegionCode } = useContext(GeoContext)
  const reviews = useMemo(() => ({
    showReviews: showUserReviewsRating(offer.property.rating, offer.property.reviewsTotal),
    rating: offer.property.rating,
    reviewsTotal: offer.property.reviewsTotal,
    ratingSubtitle: offer.property.reviewsSource === 'google' ? 'From online reviews' : undefined,
  }), [offer.property])

  const isUltralux = isUltraLuxOffer(offer)

  const memberPrice = lowestPricePackage?.memberPrice ?? 0
  const showMemberPrice = isLuxPlusEnabled && memberPrice > 0
  const saveAmount = showMemberPrice ? (lowestPricePackage?.price ?? 0) - memberPrice : undefined
  const luxPlusTileInclusions = offer?.luxPlusTileInclusions
  const luxPlusInclusions = lowestPricePackage?.luxPlusInclusionsByTier?.[inclusionsViewableLuxPlusTier]

  const labels = useMemo(() => getUrgencyLabels(offer, currentRegionCode), [offer, currentRegionCode])
  const offerLabels = useMemo(() => labels.filter(label => label.type !== 'left'), [labels])
  const timeRemainingLabel = useMemo(() => labels.find(label => label.type === 'left'), [labels])

  return (
    <ProductPaletteProvider product={offer} >
      {isLuxPlusHiddenOffer && <LuxPlusHiddenOfferCarouselCard offer={offer} />}
      {!isLuxPlusHiddenOffer && <>
        {size === 'medium' && <CarouselCardMedium
          data-testid="offer-card"
          className={className}
          bookmarkButton={<BookmarkButton offer={offer} />}
          image={offer.image}
          location={[offer.locationHeading, offer.locationSubheading].filter((t) => t).join(', ')}
          providerName={offer.property.name}
          title={offer.name}
          priceDetails={<LEOfferPriceDetails variant="condensed" offer={offer} selectedDatesAndRoomsFilter={selectedDatesAndRoomsFilter}/>}
          to={offerUrl}
          reviews={reviews}
          timeRemainingLabel={timeRemainingLabel}
          urgencyLabels={<LabelGroup>
            {isUltralux && <ProductTypeLabel productType={offer.productType} />}
            <OfferLabels
              offer={offer}
              urgencyLabels={offerLabels}
              saveAmount={saveAmount}
              showMemberInclusionLabels
              luxPlusInclusions={luxPlusInclusions}
              luxPlusTileInclusions={luxPlusTileInclusions}
            />
            {offer.badge && <OfferBadgeTag badge={offer.badge} />}
          </LabelGroup>}
          onClick={onClick}
        />}
        {size === 'small' && <CarouselCardSmall
          data-testid="offer-card"
          className={className}
          bookmarkButton={<BookmarkButton offer={offer} />}
          description={offer.name}
          image={offer.image}
          location={[offer.locationHeading, offer.locationSubheading].filter((t) => t).join(', ')}
          title={offer.property.name}
          priceDetails={<LEOfferPriceDetails offer={offer} selectedDatesAndRoomsFilter={selectedDatesAndRoomsFilter}/>}
          to={offerUrl}
          productType={offer.productType}
          reviews={reviews}
          urgencyLabels={<LabelGroup>
            <OfferLabels
              offer={offer}
              saveAmount={saveAmount}
              showMemberInclusionLabels
              luxPlusTileInclusions={luxPlusTileInclusions}
              luxPlusInclusions={luxPlusInclusions}
            />
            {offer.badge && <OfferBadgeTag badge={offer.badge} />}
          </LabelGroup>}
          onClick={onClick}
        />}
      </>}
    </ProductPaletteProvider>
  )
}

const mapStateToProps = (state: App.State, ownProps: Partial<Props>): MappedProps => ({
  isLuxPlusEnabled: isLuxPlusEnabled(state),
  isLuxPlusHiddenOffer: isLuxPlusHiddenOffer(state, ownProps.offer),
  inclusionsViewableLuxPlusTier: getInclusionsViewableLuxPlusTier(state),
})

export default connect(mapStateToProps)(LEOfferCard)
