import { API_CALL } from './actionConstants'
import {
  FETCH_LE_SUBSCRIPTIONS,
  FETCH_LE_SUBSCRIPTIONS_V2,
  FETCH_RESUBSCRIPTION_DISMISSED_DATE,
  FIRE_RESUBSCRIBE_PROMO_CODE,
  UPDATE_LE_SUBSCRIPTIONS,
  UPDATE_LE_SUBSCRIPTIONS_V2,
  UPDATE_RESUBSCRIPTION_DISMISSED_DATE,
} from './apiActionConstants'
import * as leSubscriptionsService from 'api/LESubscriptions'

export function fetchLESubscriptions(userId: string) {
  return {
    type: API_CALL,
    api: FETCH_LE_SUBSCRIPTIONS,
    request: () => leSubscriptionsService.fetchLEUserSubscriptions(userId),
  }
}

export function fetchLESubscriptionsV2(userId: string) {
  return {
    type: API_CALL,
    api: FETCH_LE_SUBSCRIPTIONS_V2,
    request: () => leSubscriptionsService.fetchLEUserSubscriptionsV2(userId),
  }
}

export function updateLESubscriptions(
  data: any,
) {
  return {
    type: API_CALL,
    api: UPDATE_LE_SUBSCRIPTIONS,
    request: () => leSubscriptionsService.updateLEUserSubscriptions(data),
  }
}

export function updateLESubscriptionsV2(
  data: any,
) {
  return {
    type: API_CALL,
    api: UPDATE_LE_SUBSCRIPTIONS_V2,
    request: () => leSubscriptionsService.updateLEUserSubscriptionsV2(data),
  }
}

export function fetchResubscribeModalDismissedAt(userId: string) {
  return {
    type: API_CALL,
    api: FETCH_RESUBSCRIPTION_DISMISSED_DATE,
    request: () => leSubscriptionsService.fetchResubscribeModalDismissedDate(userId),
  }
}

export function updateResubscribeModalDismissedAt(userId: string) {
  return {
    type: API_CALL,
    api: UPDATE_RESUBSCRIPTION_DISMISSED_DATE,
    request: () => leSubscriptionsService.updateResubscribeModalDismissedDate(userId),
  }
}

export function fireEmailResubscribePromoCode(userId: string) {
  return {
    type: API_CALL,
    api: FIRE_RESUBSCRIBE_PROMO_CODE,
    request: () => leSubscriptionsService.sendEmailResubscribePromoCode(userId),
  }
}
