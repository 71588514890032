import React, { useCallback } from 'react'
import styled from 'styled-components'
import PromotionLabel from 'components/Luxkit/Label/PromotionLabel'
import { rem } from 'polished'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import FormatCurrency from 'components/Common/FormatCurrency'
import Caption from 'components/Luxkit/Typography/Caption'
import Divider from 'components/Luxkit/Divider'
import TextButton from 'components/Luxkit/Button/TextButton'
import Group from 'components/utils/Group'
import TextLink from 'components/Luxkit/TextLink'
import { generateBookingProtectionItem } from 'lib/checkout/bookingProtection/cart'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import LineCheckCircleIcon from 'components/Luxkit/Icons/line/LineCheckCircleIcon'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import { BookingProtectionContent } from './BookingProtectionComparison'
import { useAppDispatch } from 'hooks/reduxHooks'
import { addItem } from 'actions/CheckoutActions'
import PriceRowLoyaltyPrice from 'components/Luxkit/PricePoints/Loyalty/PriceRowLoyaltyPrice'

const Wrapper = styled.div`
  padding: ${rem(16)} ${rem(20)};
  position: relative;
  border: 1px solid ${(props) => props.theme.palette.highlight.secondary.normalForeground};
`

const StyledPromotionLabel = styled(PromotionLabel)`
  position: absolute;
  top: -${rem(12)};
  right: ${rem(16)};
`

const CardHeader = styled(Group)`
  height: ${rem(62)};
`

const EnhancedProtectionRow = styled(Group)`
  padding: ${rem(8)} ${rem(0)};
`

interface Props {
  bookingProtectionContent: Array<BookingProtectionContent>
  bookingProtectionQuotePricePerTraveller?: number;
  bookingProtectionSelected?: boolean;
  bookingProtectionQuote?: App.BookingProtectionQuote ;
  bookingProtectionQuotePrice: number;
  checkoutWithDiscountedBookingProtection: boolean;
  bookingProtectionQuoteLuxPlusPricePerTraveller: number;
  bookingProtectionQuoteLuxPlusPrice: number;
  isStandaloneExperience: boolean;
}

function BookingEnhancedProtectionCard(props: Props) {
  const {
    bookingProtectionContent,
    bookingProtectionQuotePricePerTraveller = 0,
    bookingProtectionSelected,
    bookingProtectionQuote,
    bookingProtectionQuotePrice,
    checkoutWithDiscountedBookingProtection,
    bookingProtectionQuoteLuxPlusPricePerTraveller,
    bookingProtectionQuoteLuxPlusPrice,
    isStandaloneExperience,
  } = props

  const modalContext = useModalElementContext()

  const dispatch = useAppDispatch()

  const handleAddBookingProtection = useCallback(() => {
    if (bookingProtectionSelected) return
    if (!bookingProtectionQuote) return
    dispatch(addItem(generateBookingProtectionItem(bookingProtectionQuote)))
    modalContext.resolve()
    showSnackbar('Cancellation Protection added.', 'success', { icon: <LineCheckCircleIcon /> })
  }, [dispatch, modalContext, bookingProtectionSelected, bookingProtectionQuote])

  return (
    <Wrapper>
      <StyledPromotionLabel variant="premium">
        Recommended
      </StyledPromotionLabel>

      <Group gap={12} direction="vertical">
        <CardHeader gap={4} direction="vertical">
          <BodyText colour="highlight-secondary"variant="medium" weight="bold">
            Cancellation Protection terms
          </BodyText>
          <Group direction="vertical">
            {checkoutWithDiscountedBookingProtection && (
              <>
                {!isStandaloneExperience && (
                  <PriceRowLoyaltyPrice
                    memberPrice={bookingProtectionQuoteLuxPlusPricePerTraveller}
                    saleUnit="person"
                    size="M"
                    priceFormat="dynamicDollar"
                  />
                )}

                {isStandaloneExperience && (
                  <PriceRowLoyaltyPrice
                    memberPrice={bookingProtectionQuoteLuxPlusPrice}
                    saleUnit="total"
                    size="M"
                    priceFormat="dynamicDollar"
                  />
                )}
              </>
            )}
            {!checkoutWithDiscountedBookingProtection && (
              <>
                {!isStandaloneExperience && (
                  <Heading variant="heading5">
                    <FormatCurrency value={bookingProtectionQuotePricePerTraveller} />
                    <Caption variant="large" as="span">{' '}/person</Caption>
                  </Heading>
                )}
                {isStandaloneExperience && (
                  <Heading variant="heading5">
                    <FormatCurrency value={bookingProtectionQuotePrice} />
                    <Caption variant="large" as="span">{' '}/total</Caption>
                  </Heading>
                )}
              </>
            )}
          </Group>
        </CardHeader>

        <Group direction="vertical">
          {bookingProtectionContent.map((item, index) => (
            <Group direction="vertical" key={index}>
              {index === 0 && <Divider kind="primary" />}
              <EnhancedProtectionRow direction="vertical" gap={8}>
                <Group direction="horizontal" verticalAlign="center" horizontalAlign="space-between">
                  <BodyText variant="small" weight="bold">{item.heading}</BodyText>
                  {item.enhancedProtectionIcon}
                </Group>
                <BodyText variant="small">{item.enhancedProtectionText}</BodyText>
              </EnhancedProtectionRow>
              {index !== bookingProtectionContent.length - 1 && <Divider kind="primary" />}
            </Group>
          ))}

          <TextButton kind="secondary" onClick={handleAddBookingProtection} isSelected={!!bookingProtectionSelected}>
            {bookingProtectionSelected ? 'Added' : 'Add protection'}
          </TextButton>
        </Group>

        <Caption variant="medium">
          View the full{' '}
          <TextLink weight="regular" href="https://www.refundable.me/extended/en/" target="_blank">
            Terms & Conditions.
          </TextLink>
        </Caption>
      </Group>
    </Wrapper>
  )
}

export default BookingEnhancedProtectionCard
