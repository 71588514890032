import {
  API_CALL,
  API_CALL_SUCCESS, CHECKOUT_ADD_ITEM,
  CHECKOUT_APPLY_PARTNERSHIP_STATUS,
  CHECKOUT_APPLY_PROMO_CODE,
  CHECKOUT_APPLY_PROMO_CODE_FAILED,
  CHECKOUT_APPLY_VELOCITY_BURN,
  CHECKOUT_CLEAR_CART,
  CHECKOUT_EDIT_FORM,
  CHECKOUT_FETCHING_PROMO_CODE,
  CHECKOUT_HIDE_PROMO_CORPORATE_BENEFITS,
  CHECKOUT_INITIALISE,
  CHECKOUT_MODIFY_FLIGHT_SEARCH_VIEW,
  CHECKOUT_PROCESS_CANCEL,
  CHECKOUT_PROCESS_FAILURE,
  CHECKOUT_PROCESS_START,
  CHECKOUT_PROCESS_SUCCESS,
  CHECKOUT_REMOVE_ITEM,
  CHECKOUT_RESET_PROMO_CODE,
  CHECKOUT_RESTORE_CART,
  CHECKOUT_RESTORE_FORM,
  CHECKOUT_RESTORE_PAYMENT,
  CHECKOUT_SET_FORM,
  CHECKOUT_SELECT_NO_INSURANCE,
  CHECKOUT_SET_CONNECT_ROOMS_SPECIAL_REQUEST,
  CHECKOUT_SET_RESTORE_CART_STATUS,
  CHECKOUT_TOGGLE_DEV_TOOLS,
  CHECKOUT_SET_INSURANCE_FETCH_PARAMETERS,
  CHECKOUT_SET_POST_PURCHASE,
  CHECKOUT_SET_ROOMS_TO_BE_CONNECTED,
  CHECKOUT_SET_SPECIAL_REQUEST,
  CHECKOUT_SET_VELOCITY_BURN_MODAL_STATUS,
  CHECKOUT_SET_VELOCITY_SSO,
  CHECKOUT_SHOW_PROMO_CORPORATE_BENEFITS,
  CHECKOUT_TOGGLE_CREDIT,
  CHECKOUT_UPDATE_ARRIVAL_FLIGHT_NUMBER,
  CHECKOUT_UPDATE_BUNDLE_ITEM_PRICING, CHECKOUT_UPDATE_CS_DEPOSIT_OVERRIDE,
  CHECKOUT_UPDATE_EXPERIENCE_ITEM, CHECKOUT_UPDATE_HOTEL_ITEM_PRICING,
  CHECKOUT_UPDATE_ITEM,
  CHECKOUT_UPDATE_PAYMENT_SELECTION,
  CHECKOUT_SHOW_PROMO_FRIENDS_AND_FAMILY,
  CHECKOUT_HIDE_PROMO_FRIENDS_AND_FAMILY,
  CHECKOUT_CLEAR_VELOCITY_BURN,
  CHECKOUT_SET_MULTI_CART_ITEM_MODE,
  CHECKOUT_REMOVE_TRAVELLER_SCHEMA,
  CHECKOUT_SET_MERCHANT_FEE_PAYMENT_TYPE,
  CHECKOUT_RESET_FORM,
  CHECKOUT_CLEAR_CART_RESTORE_PRICE,
  CHECKOUT_AGENT_BOOKING_DETAILS,
  CHECKOUT_SET_LUXPLUS_UPSELL_DATA,
  CHECKOUT_SET_STRIPE_PAYMENT_METHOD,
  CHECKOUT_BUSINESS_BOOKING_DETAILS,
  CHECKOUT_ADD_ARRIVAL_DETAILS,
  CHECKOUT_SET_ARRIVAL_DETAILS_TIME,
  CHECKOUT_SET_ARRIVAL_DETAILS_FLIGHT_NUMBER,
  ACTIVATE_REBOOKING,
  CHECKOUT_SET_PAYTO_BANK,
  CHECKOUT_SELECT_NO_TRAVEL_PROTECTION,
  CHECKOUT_SET_COMMS_RESUBSCRIBE,
  CHECKOUT_FETCHING_PAYMENT_LINK,
  CHECKOUT_SET_PAYMENT_LINK,
  CHECKOUT_FETCHING_PAYMENT_LINK_FAILED,
  CHECKOUT_SET_ORDER_ID,
} from 'actions/actionConstants'
import {
  CHECKOUT_FETCH_BEDBANK_EXISTING_ORDER,
  CHECKOUT_INIT_BEDBANK_CHANGE_DATES_SESSION,
  CHECKOUT_REQUEST_CORPORATE_BENEFITS_PROMO,
  CHECKOUT_REQUEST_FRIENDS_AND_FAMILY_PROMO,
  FETCH_BEDBANK_OFFER,
  FETCH_BEDBANK_OFFERS_RATES, FETCH_COMMMISSION_PROMO, FETCH_TRAVELLER_FORM_SCHEMA,
  FETCH_VELOCITY_MEMBER_DETAILS,
  LOG_PAYMENT_EVENT,
  UPDATE_BEDBANK_SESSION,
  UPDATE_CUSTOMER_DETAILS,
  CREATE_STRIPE_PAYMENT_METHOD,
  CHECKOUT_CREATE_CART_QUOTE,
  CHECKOUT_UPDATE_CART_QUOTE,
  FETCH_COMMISSION_PROMO_CODE,
} from 'actions/apiActionConstants'
import { enquiry } from 'api/bedbank'
import { getRatesForBedbankOffer, getRatesForBedbankOffers } from 'api/bedbankRates'
import { applyPromoWithV2ItemTotals, checkoutStateToDiscountOrder, stateToDiscountOrder } from 'api/mappers/promoMap'
import { getOfferById } from 'api/offer'
import {
  RestrictedPromoRequestData,
  getPromoV2,
  getReferralPromo,
  registerCorporateBenefits,
  registerFriendsAndFamily,
} from 'api/promo'
import { retrieveTravellerDetailsForm, updateCustomerDetails } from 'api/traveller'
import { getVelocityMemberProfile } from 'api/velocity'
import { checkoutTotalsView, getCreditPayableAmount, getPackageNamesFromCart } from 'checkout/selectors/payment/checkout'
import { getTravellerFormSchemaRequest } from 'checkout/selectors/request/travellerSchema'
import { checkoutAccommodationOfferView } from 'checkout/selectors/view/accommodation'
import { getExperienceItems, getExperienceItemsView } from 'checkout/selectors/view/experience'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import config from 'constants/config'
import { OFFER_TYPE_BED_BANK } from 'constants/offer'
import { VIRGIN_VELOCITY_BURN_MINIMUM } from 'constants/partnerships'
import {
  CREDIT_PAYMENT_TYPE,
  PAYMENT_OPTIONS,
  PROMO_PAYMENT_TYPE,
  STRIPE_PAYMENT_TYPE,
} from 'constants/payment'
import equal from 'fast-deep-equal'
import { deduceGenderFromTitle } from 'lib/tours/tourUtils'
import { arrayToMap, groupBy, sum } from 'lib/array/arrayUtils'
import { ExperienceDetails } from 'lib/checkout/experiences/cart'
import { isEmptyObject } from 'lib/object/objectUtils'
import { paymentMethodsToString } from 'lib/payment/paymentUtils'
import moment from 'moment/moment'
import { Dispatch } from 'redux'
import { getPrimaryTravellerForm } from 'selectors/checkoutSelectors'
import { ValueOf } from 'type-fest'
import { AppDispatch } from '../store'
import { getBedbankRateKey } from './BedbankOfferActions'
import { genericOrderErrorModalOpen, showSecurePaymentModal } from './UiActions'
import { saveCheckoutFormStateSnapshot } from 'storage/checkout'
import { getPaymentMethodAvailability } from 'checkout/selectors/payment/paymentType'
import { getCartFlightJourneys } from 'checkout/selectors/payment/flights'
import { sortTravellers } from 'checkout/lib/utils/form/sortTravellers'
import { SnowplowEvent } from 'analytics/snowplow/events'
import { createPaymentLink, CreatePaymentLinkPayload, postPaymentEventLog } from 'api/payment'
import { BUSINESS_TRAVELLER_ACTIONS } from 'reducers/businessTravellerActionReducer'
import { getSubscriptionJoinItems, isMemberOrHasSubscriptionInTheCart } from 'checkout/selectors/view/luxPlusSubscription'
import { Stripe, CreatePaymentMethodFromElements } from '@stripe/stripe-js/types/stripe-js'
import { getOrderWithPaymentsAndReservations } from 'api/order'
import { generateBedbankChangeDatesAccommodationCheckoutItems } from 'lib/checkout/accommodation/cart'
import { ISO_DATE_FORMAT } from '../constants/dateFormats'
import { createQuote, QuoteDedupeKeys, updateQuote } from 'api/cart'
import { getDepositBalance } from 'checkout/selectors/payment/deposit'
import { getSubscriptionOffer } from 'api/luxPlus'
import { generatePassengersFromOccupants } from 'checkout/lib/utils/flights/passenger'
import { CHECKOUT_ITEM_TYPE_BEDBANK, CHECKOUT_ITEM_TYPE_LE_HOTEL } from 'constants/checkout'
import { FlightViewTypes } from 'constants/flight'
import CartInitError from 'errors/CartInitError'
import { generateFlightCheckoutItem } from 'lib/checkout/flights/cart'
import { generateLuxPlusSubscriptionItems } from 'lib/checkout/luxPlusSubscription/cart'
import getPackageFromOffer from 'lib/offer/getPackageFromOffer'
import { countOccupantsForFlights } from 'lib/offer/occupancyUtils'
import uuidV4 from 'lib/string/uuidV4Utils'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { getAgentHubCommissionPromoCode } from 'agentHub/api/agentHubCalculateCartCommission'
import { stateToCommissionOrder } from 'agentHub/api/mappers/agentHubMap'
import { isPostPurchaseAncillaryPayment } from '../../checkout/selectors/view/hotels'
import { updateLESubscriptionsV2 } from './LESubscriptionsActions'
import * as Analytics from 'analytics/analytics'
import { Region } from 'constants/geo'

type Actions = Utils.FullActionMap<{
  [CHECKOUT_INITIALISE]: { items: Array<App.Checkout.AnyItem>, params: CartInitialisationParams },
  [CHECKOUT_RESTORE_CART]: { cartState: Partial<App.CheckoutCartState> },
  [CHECKOUT_RESTORE_FORM]: { formState: Partial<App.Checkout.FormState> },
  [CHECKOUT_RESET_FORM]: {},
  [CHECKOUT_RESTORE_PAYMENT]: { state: Partial<App.Checkout.PaymentState> },
  [CHECKOUT_UPDATE_ITEM]: { item: App.Checkout.AnyItem, resetFormSchema: boolean },
  [CHECKOUT_ADD_ITEM]: { item: App.Checkout.AnyItem, resetFormSchema: boolean },
  [CHECKOUT_REMOVE_ITEM]: { itemId: string, resetFormSchema: boolean },
  [CHECKOUT_SET_FORM]: { form: App.Checkout.FormState },
  [CHECKOUT_EDIT_FORM]: { id: string, editIdx: number },
  [CHECKOUT_SET_SPECIAL_REQUEST]: { itemId: string, request: string },
  [CHECKOUT_SET_CONNECT_ROOMS_SPECIAL_REQUEST]: { contactName: string },
  [CHECKOUT_SET_ROOMS_TO_BE_CONNECTED]: { roomsToBeConnected: Array<Array<string>> }
  [CHECKOUT_PROCESS_START]: {},
  [CHECKOUT_PROCESS_SUCCESS]: {},
  [CHECKOUT_PROCESS_CANCEL]: {},
  [CHECKOUT_PROCESS_FAILURE]: { data: { name: string; status?: number; errorMsg?: string; } },
  [CHECKOUT_APPLY_VELOCITY_BURN]: { points: number },
  [CHECKOUT_APPLY_PARTNERSHIP_STATUS]: { partnership: 'velocity', status: boolean },
  [CHECKOUT_SET_VELOCITY_BURN_MODAL_STATUS]: { data: { isActive: boolean, isClean: boolean } },
  [CHECKOUT_SET_VELOCITY_SSO]: { data: Partial<App.Checkout.PaymentState['sso']['velocity']> },
  [CHECKOUT_UPDATE_PAYMENT_SELECTION]: { paymentSelected: PAYMENT_OPTIONS },
  [CHECKOUT_UPDATE_EXPERIENCE_ITEM]: { id: string, data: { details?: ExperienceDetails, tickets?: Array<App.Checkout.ExperienceItemTicket> } },
  [CHECKOUT_SET_INSURANCE_FETCH_PARAMETERS]: { params: App.Checkout.InsuranceFetchParameters },
  [CHECKOUT_APPLY_PROMO_CODE]: { promotion: App.Promotion },
  [CHECKOUT_RESET_PROMO_CODE]: {},
  [CHECKOUT_FETCHING_PROMO_CODE]: {},
  [CHECKOUT_APPLY_PROMO_CODE_FAILED]: { error: string },
  [CHECKOUT_SET_RESTORE_CART_STATUS]: { data: Partial<App.CheckoutState['restoreCart']> },
  [CHECKOUT_MODIFY_FLIGHT_SEARCH_VIEW]: { modifyFlightView: App.CheckoutState['modifyFlightView'] }
  [CHECKOUT_TOGGLE_DEV_TOOLS]: { },
  [CHECKOUT_UPDATE_HOTEL_ITEM_PRICING]: { itemId: string, newPrice: number, surcharge?: { newSurcharge?: number, newExtraGuestSurcharge?: number } },
  [CHECKOUT_UPDATE_BUNDLE_ITEM_PRICING]: { itemId: string, offerId: string, newPrice: number, surcharge?: { newSurcharge?: number, newExtraGuestSurcharge?: number } },
  [CHECKOUT_SET_POST_PURCHASE]: { order: App.Order, postPurchase: App.PostPurchase },
  [CHECKOUT_CLEAR_CART]: {},
  [CHECKOUT_UPDATE_CS_DEPOSIT_OVERRIDE]: { depositOverride: boolean },
  [CHECKOUT_SET_MERCHANT_FEE_PAYMENT_TYPE]: { paymentType: App.MerchantFeePaymentType | null },
  [CHECKOUT_CLEAR_CART_RESTORE_PRICE]: {},
  [CHECKOUT_SET_LUXPLUS_UPSELL_DATA]: { eventData: SnowplowEvent },
  [CREATE_STRIPE_PAYMENT_METHOD]: { stripe: Stripe, createPaymentMethod: CreatePaymentMethodFromElements },
  [CHECKOUT_SET_STRIPE_PAYMENT_METHOD]: { paymentMethod: App.StripePaymentMethod },
  [CHECKOUT_ADD_ARRIVAL_DETAILS]: { itemId: string, arrivalDetails: App.ArrivalDetails },
  [CHECKOUT_SET_PAYTO_BANK]: { selectedPayToBank: App.PayToSupportedBank },
  [CHECKOUT_SET_COMMS_RESUBSCRIBE]: { commsResubscribe: boolean },
}>

export type CheckoutAction = ValueOf<Actions>

export interface CartInitialisationParams {
  regionCode: string;
  currencyCode: string;
  isGift: boolean;
  restoredCartOriginalTotal: number | null;
  restoredCartOriginalMemberTotal: number | null;
  isRestoreCheckout: boolean;
  postPurchase?: App.PostPurchase;
  order?: App.Order;
}

export function initialiseCheckout(items: Array<App.Checkout.AnyItem>, params: Partial<CartInitialisationParams> = {}) {
  return function(dispatch: AppDispatch, getState: () => App.State) {
    const state = getState()
    const transformedParams = {
      regionCode: params.regionCode ?? state.geo.currentRegionCode,
      currencyCode: params.currencyCode ?? state.geo.currentCurrency,
      isGift: params.isGift ?? false,
      restoredCartOriginalTotal: params.restoredCartOriginalTotal ?? null,
      restoredCartOriginalMemberTotal: params.restoredCartOriginalMemberTotal ?? null,
      postPurchase: params.postPurchase,
      order: params.order,
    }

    // Clear LE Business approval request
    if (!params.isRestoreCheckout && config.businessTraveller.currentAccountMode === 'business' && config.BUSINESS_TRAVELLER_ACCOMMODATION_APPROVAL_ENABLED) {
      dispatch({
        type: BUSINESS_TRAVELLER_ACTIONS.CLEAR_CART_RESTORE_REQUEST_ID,
      })
      dispatch({
        type: BUSINESS_TRAVELLER_ACTIONS.CLEAR_APPROVAL_REQUEST,
      })
    }

    dispatch({
      type: CHECKOUT_INITIALISE,
      items,
      params: transformedParams,
    })
  }
}

export function clearRestoreCartPrice(): Actions['CHECKOUT_CLEAR_CART_RESTORE_PRICE'] {
  return {
    type: CHECKOUT_CLEAR_CART_RESTORE_PRICE,
  }
}

export function clearCheckoutCart(): Actions['CHECKOUT_CLEAR_CART'] {
  return {
    type: CHECKOUT_CLEAR_CART,
  }
}

export function restoreCheckoutCart(cartState: Partial<App.CheckoutCartState>): Actions['CHECKOUT_RESTORE_CART'] {
  return {
    type: CHECKOUT_RESTORE_CART,
    cartState,
  }
}

export function restoreCheckoutForm(formState: Partial<App.Checkout.FormState>): Actions['CHECKOUT_RESTORE_FORM'] {
  return {
    type: CHECKOUT_RESTORE_FORM,
    formState,
  }
}

export function resetCheckoutForm(): Actions['CHECKOUT_RESET_FORM'] {
  return {
    type: CHECKOUT_RESET_FORM,
  }
}

export function restoreCheckoutPayment(state: Partial<App.Checkout.PaymentState>): Actions['CHECKOUT_RESTORE_PAYMENT'] {
  return {
    type: CHECKOUT_RESTORE_PAYMENT,
    state,
  }
}

export function updateCheckoutItem(item: App.Checkout.AnyItem, resetFormSchema = false): Actions['CHECKOUT_UPDATE_ITEM'] {
  return {
    type: CHECKOUT_UPDATE_ITEM,
    item,
    resetFormSchema,
  }
}

export function removeItem(itemId: string, resetFormSchema = true) {
  return (dispatch: AppDispatch, getState: () => App.State) => {
    const state = getState()

    dispatch({
      type: CHECKOUT_REMOVE_ITEM,
      itemId,
      resetFormSchema,
    })

    const item = state.checkout.cart.items.find(item => item.itemId === itemId)

    if (item?.itemType === 'bedbankHotel') {
      dispatch(updateBedbankSession())
    }
  }
}

export function removeItems(items: Array<string>, resetFormSchema = true) {
  return (dispatch) => {
    items.forEach((item) => {
      dispatch(removeItem(item, resetFormSchema))
    })
  }
}

export function addItem(item: App.Checkout.AnyItem, resetFormSchema = true): Actions['CHECKOUT_ADD_ITEM'] {
  return {
    type: CHECKOUT_ADD_ITEM,
    item,
    resetFormSchema,
  }
}

export function addItems(items: Array<App.Checkout.AnyItem>, resetFormSchema = true) {
  return (dispatch) => {
    items.forEach((item) => {
      dispatch(addItem(item, resetFormSchema))
    })
  }
}

export function addToCheckoutCart(items: Array<App.Checkout.AnyItem> | App.Checkout.AnyItem, initParams?: Partial<CartInitialisationParams>) {
  return (dispatch: AppDispatch, getState: () => App.State) => {
    const state = getState()
    const arrayItems = Array.isArray(items) ? items : [items]
    if ((!state.checkout.cart.isMultiItemMode || state.checkout.cart.cartId === null) || !!initParams) {
      dispatch(initialiseCheckout(arrayItems, initParams))
    } else {
      arrayItems.forEach(item => dispatch(addItem(item)))
    }
  }
}

export function toggleMultiCartItemMode() {
  return (dispatch: AppDispatch, getState: () => App.State) => {
    const state = getState()
    const isMultiItemMode = state.checkout.cart.isMultiItemMode
    dispatch(clearCheckoutCart())
    dispatch({
      type: CHECKOUT_SET_MULTI_CART_ITEM_MODE,
      isMultiItemMode: !isMultiItemMode,
    })
  }
}

export function setSpecialRequest(itemId: string, request: string): Actions['CHECKOUT_SET_SPECIAL_REQUEST'] {
  return {
    type: CHECKOUT_SET_SPECIAL_REQUEST,
    itemId,
    request,
  }
}

export function setConnectRoomsSpecialRequest() {
  return (dispatch, getState) => {
    const state = getState() as App.State
    let contactName = ''

    if (state.checkout.form.travellerForms.length) {
      const contact = state.checkout.form.travellerForms[0]
      contactName = `${contact.firstName} ${contact.lastName}`
    } else {
      contactName = state.auth.account.givenName
      if (state.auth.account.surname) contactName += ` ${state.auth.account.surname}`
    }

    dispatch({
      type: CHECKOUT_SET_CONNECT_ROOMS_SPECIAL_REQUEST,
      contactName,
    })
  }
}

export function setRoomsToBeConnected(roomsToBeConnected: Array<Array<string>>) {
  return {
    type: CHECKOUT_SET_ROOMS_TO_BE_CONNECTED,
    roomsToBeConnected,
  }
}

export function processingStart(): Actions['CHECKOUT_PROCESS_START'] {
  return {
    type: CHECKOUT_PROCESS_START,
  }
}

export function processingSuccess() {
  return {
    type: CHECKOUT_PROCESS_SUCCESS,
  }
}

export function processingCancel(): Actions['CHECKOUT_PROCESS_CANCEL'] {
  return {
    type: CHECKOUT_PROCESS_CANCEL,
  }
}

export function processingFailure(name: string, status?: number, errorMsg?: string): Actions['CHECKOUT_PROCESS_FAILURE'] {
  return {
    type: CHECKOUT_PROCESS_FAILURE,
    data: {
      name,
      status,
      errorMsg,
    },
  }
}

function resetPaymentSelectionBeforeCreditApplied(dispatch: AppDispatch, state: App.State) {
  const creditPayableAmount = getCreditPayableAmount(state)
  const depositBalance = getDepositBalance(state)

  // before credit is applied check if selected payment option is deposit and whether credit amount is greater than deposit balance
  if (!state.checkout.payment.useCredit && state.checkout.payment.paymentSelected === PAYMENT_OPTIONS.DEPOSIT &&
    creditPayableAmount >= depositBalance) {
    dispatch(updatePaymentSelection(PAYMENT_OPTIONS.FULL))
  }
}

export function toggleCredit() {
  return (dispatch: AppDispatch, getState: () => App.State) => {
    const state = getState()

    resetPaymentSelectionBeforeCreditApplied(dispatch, state)

    dispatch({
      type: CHECKOUT_TOGGLE_CREDIT,
    })
  }
}

export function setUsingCredit(useCredit: boolean) {
  return (dispatch: AppDispatch, getState: () => App.State) => {
    const state = getState()

    resetPaymentSelectionBeforeCreditApplied(dispatch, state)

    dispatch({
      type: CHECKOUT_TOGGLE_CREDIT,
      useCredit,
    })
  }
}

export function applyVelocityBurn(points: number): Actions['CHECKOUT_APPLY_VELOCITY_BURN'] {
  return {
    type: CHECKOUT_APPLY_VELOCITY_BURN,
    points,
  }
}

export function setPartnershipStatus(
  partnership: 'velocity',
  status: boolean,
): Actions['CHECKOUT_APPLY_PARTNERSHIP_STATUS'] {
  return {
    type: CHECKOUT_APPLY_PARTNERSHIP_STATUS,
    partnership,
    status,
  }
}

export function setVelocityBurnModalStatus(isActive: boolean, isClean = false): Actions['CHECKOUT_SET_VELOCITY_BURN_MODAL_STATUS'] {
  const data = { isActive, isClean }
  return {
    type: CHECKOUT_SET_VELOCITY_BURN_MODAL_STATUS,
    data,
  }
}

export function getVelocityMemberDetails(token: string) {
  return {
    type: API_CALL,
    api: FETCH_VELOCITY_MEMBER_DETAILS,
    request: async() => {
      const memberProfile = await getVelocityMemberProfile(token)
      if (memberProfile.pointBalance < VIRGIN_VELOCITY_BURN_MINIMUM) {
        showSnackbar(
          'Sorry! Your Velocity point balance is insufficient for payment',
          'critical',
        )
      }
      return memberProfile
    },
  }
}

export function saveVelocitySSO(data: Partial<App.Checkout.VelocitySSO>): Actions['CHECKOUT_SET_VELOCITY_SSO'] {
  return {
    type: CHECKOUT_SET_VELOCITY_SSO,
    data,
  }
}

export function getTravellerFormSchema() {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const requestPayload = getTravellerFormSchemaRequest(state)
    const isNewRequest = !equal(requestPayload, state.checkout.schemaRequestData)

    if (!state.checkout.schemaData || isNewRequest) {
      if (requestPayload.items.length > 0) {
        dispatch({
          type: API_CALL,
          api: FETCH_TRAVELLER_FORM_SCHEMA,
          schemaRequestData: requestPayload,
          request: () => retrieveTravellerDetailsForm(requestPayload),
        })
      } else {
        dispatch({
          type: API_CALL,
          api: FETCH_TRAVELLER_FORM_SCHEMA,
          schemaRequestData: requestPayload,
          request: () => Promise.resolve({}),
        })
      }
    }
  }
}

export function removeTravellerSchema() {
  return { type: CHECKOUT_REMOVE_TRAVELLER_SCHEMA }
}

export function setTravellerForm(form: App.Checkout.FormState): Actions['CHECKOUT_SET_FORM'] {
  return {
    type: CHECKOUT_SET_FORM,
    form,
  }
}

export function saveTravellerForm(item: App.Checkout.TravellerForm) {
  return function(dispatch, getState) {
    const state = getState() as App.State

    const schema = state.checkout.schemaData?.accommodationFlightSchema
    if (!schema) { return }

    const { form } = state.checkout

    // Update the active forms accordingly and
    // decide which traveller form should be set to edit mode
    let newActiveHeadIdx = form.activeHeadIdx + 1

    let newEditIdx: number | null = form.editIdx !== null ? form.editIdx + 1 : 0

    const numForms = Object.keys(schema.properties).length

    if (numForms === 1) {
      newEditIdx = 1
      newActiveHeadIdx = 1
    } else {
      if (newEditIdx > numForms) newEditIdx = numForms
      if (newActiveHeadIdx > numForms) newActiveHeadIdx = numForms
    }

    // Create a new form, or otherwise overwrite fields if the form (id) already exists
    const newForm = { ...form.travellerForms.find(form => form.id === item.id), ...item }
    const newTravellerFormList = sortTravellers([...form.travellerForms.filter(x => x.id !== item.id), newForm])

    const newFormState = { ...form, editIdx: newEditIdx, activeHeadIdx: newActiveHeadIdx, travellerForms: newTravellerFormList }

    saveCheckoutFormStateSnapshot(newFormState)

    if (!equal(form, newFormState)) {
      dispatch(setTravellerForm(newFormState))
    }
  }
}

export function editTravellerForm(id: string, editIdx: number): Actions['CHECKOUT_EDIT_FORM'] {
  return {
    type: CHECKOUT_EDIT_FORM,
    id,
    editIdx,
  }
}

export function respondShadowBanUserError(paymentType: string) {
  return function(dispatch, getState) {
    const state = getState() as App.State

    if (!state.checkout.processing) {
      dispatch(processingStart())
    }

    let responseMsg: string
    switch (paymentType) {
      case CREDIT_PAYMENT_TYPE:
      case PROMO_PAYMENT_TYPE:
        responseMsg = 'An error occurred while processing your card'
        break
      case STRIPE_PAYMENT_TYPE:
        responseMsg = 'Your card was declined. Decline Code: [1]'
        break
      default:
        responseMsg = 'Default Error Message'
    }

    setTimeout(() => {
      dispatch(processingFailure(responseMsg, 400))
      dispatch(genericOrderErrorModalOpen([responseMsg]))
    }, 3000)
  }
}

export function updateUserTravellerDetails() {
  return function(dispatch, getState) {
    const state = getState() as App.State
    const details = getPrimaryTravellerForm(state) as App.Checkout.TravellerForm

    const addressDetails = {
      address: details.address,
      city: details.city,
      state: details.state,
      usStateOfResidence: details.usStateOfResidence,
      countryOfResidence: details.countryOfResidence,
      postcode: details.postcode,
    }

    const payload = {
      title: details.title as App.Title,
      gender: deduceGenderFromTitle(details.title as App.Title),
      middleName: details.middleName,
      addressDetails,
    }

    if (!isEmptyObject(payload)) {
      dispatch({
        type: API_CALL,
        api: UPDATE_CUSTOMER_DETAILS,
        request: () => updateCustomerDetails({
          customerId: state.auth.account.memberId,
          ...payload,
        }),
      })
    }
  }
}

export function updatePaymentSelection(paymentSelected: PAYMENT_OPTIONS): Actions['CHECKOUT_UPDATE_PAYMENT_SELECTION'] {
  return {
    type: CHECKOUT_UPDATE_PAYMENT_SELECTION,
    paymentSelected,
  }
}

export function updateExperienceItem(
  experienceId: string,
  data: { details?: ExperienceDetails, tickets?: Array<App.Checkout.ExperienceItemTicket> }) {
  return {
    type: CHECKOUT_UPDATE_EXPERIENCE_ITEM,
    id: experienceId,
    data,
  }
}

export function removeUnpurchasableItems() {
  return function(dispatch, getState) {
    const state = getState() as App.State

    // Remove unpurchasable experience tickets
    const experienceItems = getExperienceItems(state)
    const expItemViewsById = arrayToMap(getExperienceItemsView(state).data.filter(Boolean), v => v.itemId)
    experienceItems.forEach(item => {
      const itemView = expItemViewsById.get(item.itemId)
      if (!itemView) { return }
      const unavailableTicketIds = new Set(itemView.ticketViews.filter(v => v.unavailable).map(v => v.id))

      const availableTickets = item.tickets.filter(t => !unavailableTicketIds.has(t.ticketId))

      if (availableTickets.length === 0) {
        dispatch(removeItem(item.itemId))
      }
      else if (availableTickets.length < item.tickets.length) {
        dispatch(updateExperienceItem(item.experienceId, { tickets: availableTickets }))
      }
    })
  }
}

export function setInsuranceParameters(
  params: App.Checkout.InsuranceFetchParameters,
): Actions['CHECKOUT_SET_INSURANCE_FETCH_PARAMETERS'] {
  return {
    type: CHECKOUT_SET_INSURANCE_FETCH_PARAMETERS,
    params,
  }
}

export function setLuxPlusUpsellData(
  params: SnowplowEvent | undefined,
): Actions['CHECKOUT_SET_LUXPLUS_UPSELL_DATA'] {
  return {
    type: CHECKOUT_SET_LUXPLUS_UPSELL_DATA,
    eventData: params,
  }
}

/**
 * @deprecated Set these values in the initial `intialiseCheckout` instead
 */
export function setPostPurchaseOrder(order: App.Order, postPurchase: App.PostPurchase): Actions['CHECKOUT_SET_POST_PURCHASE'] {
  return {
    type: CHECKOUT_SET_POST_PURCHASE,
    order,
    postPurchase,
  }
}

export function showCorporateBenefitsForm(promoCode: string, corporateName: string, allowedEmailDomains: Array<string>) {
  return {
    type: CHECKOUT_SHOW_PROMO_CORPORATE_BENEFITS,
    promoCode,
    corporateName,
    allowedEmailDomains,
  }
}

export function hideCorporateBenefitsForm() {
  return {
    type: CHECKOUT_HIDE_PROMO_CORPORATE_BENEFITS,
  }
}

export function showFriendsAndFamilyForm(promoCode: string, category: string) {
  return {
    type: CHECKOUT_SHOW_PROMO_FRIENDS_AND_FAMILY,
    promoCode,
    category,
  }
}

export function hideFriendsAndFamilyForm() {
  return {
    type: CHECKOUT_HIDE_PROMO_FRIENDS_AND_FAMILY,
  }
}

export function requestCorporateBenefitsPromo(corporateEmail: string) {
  return async function(dispatch, getState) {
    const state = getState() as App.State

    dispatch({
      type: API_CALL,
      api: CHECKOUT_REQUEST_CORPORATE_BENEFITS_PROMO,
      submittedEmail: corporateEmail,
      request: () => registerCorporateBenefits(
        state.checkout.corporatePromoForm.sourceCodeName as string,
        corporateEmail,
      ),
    })
  }
}

export function requestFriendsAndFamilyPromo(data: Omit<RestrictedPromoRequestData, 'code' | 'category'>) {
  return async function(dispatch, getState) {
    const state = getState() as App.State

    dispatch({
      type: API_CALL,
      api: CHECKOUT_REQUEST_FRIENDS_AND_FAMILY_PROMO,
      submittedEmail: data.email,
      request: () => registerFriendsAndFamily({
        ...data,
        code: state.checkout.friendsAndFamilyPromoForm.sourceCodeName!,
        category: state.checkout.friendsAndFamilyPromoForm.category!,
      }),
    })
  }
}

export function applyCommissionPromoCode(code: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const applyWitItemTotalsV2 = applyPromoWithV2ItemTotals(state)
    const discountRequestOrder = checkoutStateToDiscountOrder(state, !applyWitItemTotalsV2)
    const discountRequestOrderV2 = stateToDiscountOrder(state)

    dispatch({
      type: API_CALL,
      api: FETCH_COMMMISSION_PROMO,
      request: () => getPromoV2({
        codeName: code,
        order: applyWitItemTotalsV2 ? discountRequestOrderV2 : discountRequestOrder,
        preCheckoutOrder: applyWitItemTotalsV2 ? discountRequestOrder : discountRequestOrderV2,
        userId: state.auth.account.memberId as string,
        requestSource: 'global-checkout',
      }),
    })
  }
}

/**
 * MARKETING_SERVER_SIDE_PROMO_CHECKOUT_CALC_ENABLED:
 * 0 - the promo calculation occurs below (legacy) or within svc-promo
 * 1 - the promo calculation occurs within svc-promo and is returned via promo.discountTotal
 *
 */
export function addPromoCode(codeName: string) {
  return async function(dispatch, getState) {
    try {
      dispatch({
        type: CHECKOUT_FETCHING_PROMO_CODE,
      })

      const state = getState() as App.State
      let referralResult

      if (couldBeReferralCode(codeName)) {
        referralResult = await getReferralPromotion(state, codeName)
        if (!('code' in referralResult)) {
          dispatch({
            type: CHECKOUT_APPLY_PROMO_CODE_FAILED,
            error: referralResult.message ?? 'Failed to apply referral code',
          })
          return
        }
      }
      const usePromoItemTotalsV2 = applyPromoWithV2ItemTotals(state)
      const discountRequestOrder = checkoutStateToDiscountOrder(state, !usePromoItemTotalsV2)
      const discountRequestOrderV2 = stateToDiscountOrder(state)

      const promotion = await getPromoV2({
        codeName: referralResult?.code ?? codeName,
        order: usePromoItemTotalsV2 ? discountRequestOrderV2 : discountRequestOrder,
        preCheckoutOrder: usePromoItemTotalsV2 ? discountRequestOrder : discountRequestOrderV2,
        userId: state.auth.account.memberId as string,
        requestSource: 'global-checkout',
      })

      if (promotion) {
        verifyPromotion(promotion as App.Promotion, state)
        applyPromotion(promotion as App.Promotion, dispatch)
      }
    } catch (e) {
      if (e.status === 422) {
        const messageCode = e.result?.message

        if (messageCode === 'corporateEmailRequired') {
          dispatch(showCorporateBenefitsForm(codeName, e.metadata?.corporateName, e.metadata?.allowedEmailDomains))
        }
        else if (messageCode === 'restrictedCategory') {
          // todo: rename it to more appropriate name
          // when more categories is added
          dispatch(showFriendsAndFamilyForm(codeName, e.metadata?.category))
        }
      } else {
        dispatch({
          type: CHECKOUT_APPLY_PROMO_CODE_FAILED,
          error: e.result?.errors.length > 0 ? e.result.errors[0].message : e.message,
        })
      }
    }
  }
}

async function getReferralPromotion(state: App.State, codeName: string): Promise<App.Promotion | App.ReferralError | null> {
  const regionCode = state.geo.currentRegionCode
  const { data } = checkoutTotalsView(state)
  const itemTypes = state.checkout.cart.items.map((i) => i.itemType)

  if (couldBeReferralCode(codeName)) {
    return getReferralPromo({
      codeName,
      regionCode,
      brand: config.BRAND,
      cartValue: data.subTotal,
      itemTypes,
    })
  }
  return null
}

function couldBeReferralCode(codeName: string): boolean {
  return codeName.slice(0, 6).includes('INVITE')
}

/**
 * @deprecated - These checks are moving into svc-promo via the /api/promo/discount endpoint
 */
function verifyPromotion(
  promotion: App.Promotion,
  state: App.State,
) {
  verifyPromoPayMethods(promotion, state)
  verifyPromoCurrency(promotion, state)
  verifyPromoOnAirlineCarriers(promotion, state)
}

function verifyPromoPayMethods(promotion: App.Promotion, state: App.State) {
  const paymentMethodAvailability = getPaymentMethodAvailability(state)
  if (
    promotion.allowedPaymentMethods.length > 0 &&
    promotion.allowedPaymentMethods.every(paymentMethod => !paymentMethodAvailability[paymentMethod])
  ) {
    throw new Error('Invalid promo code for this deal')
  }
}

function verifyPromoCurrency(promotion: App.Promotion, state: App.State) {
  const cartCurrency = state.checkout.cart.currencyCode
  if (promotion.currency !== null && promotion.currency !== cartCurrency) {
    throw new Error(`This promo code has a specified currency - ${promotion.currency}`)
  }
}

export function verifyPromoOnAirlineCarriers(promotion: App.Promotion, state: App.State) {
  if (!promotion.allowedAirlineCarriers?.length) {
    return
  }
  if (promotion.type !== 'fixed_amount') {
    throw new Error('Promo code cannot be applied')
  }
  const journeys = getCartFlightJourneys(state)
  const allowedAirlineCarriers = new Set(promotion.allowedAirlineCarriers)
  const anyAirlineCarrierMatches = journeys.some(journey => {
    return journey.departing.flights.some((flight) => allowedAirlineCarriers.has(flight.carrier)) ||
      journey.returning?.flights.some((flight) => allowedAirlineCarriers.has(flight.carrier))
  })
  if (!anyAirlineCarrierMatches) {
    throw new Error('Promo code cannot be applied to selected airline')
  }
}

function applyPromotion(promotion: App.Promotion, dispatch: Dispatch) {
  dispatch({
    type: CHECKOUT_APPLY_PROMO_CODE,
    promotion,
  })
  dispatch({
    type: CHECKOUT_CLEAR_VELOCITY_BURN,
  })
  if (promotion.allowedPaymentMethods.length) {
    showSnackbar(
      paymentMethodsToString(promotion.allowedPaymentMethods),
      'success',
      { heading: 'Promo code applied' },
    )
  }
}

export function resetPromoCode(): Actions['CHECKOUT_RESET_PROMO_CODE'] {
  return {
    type: CHECKOUT_RESET_PROMO_CODE,
  }
}

export function setRestoreCartStatus(
  status: App.CheckoutState['restoreCart']['status'],
  errorMsg?: string,
  context?: App.RestoreCartErrorModalContext,
): Actions['CHECKOUT_SET_RESTORE_CART_STATUS'] {
  return {
    type: CHECKOUT_SET_RESTORE_CART_STATUS,
    data: {
      status,
      errorMsg,
      context,
    },
  }
}

export function setCheckoutModifyFlightSearchView(view: App.CheckoutState['modifyFlightView']) {
  return {
    type: CHECKOUT_MODIFY_FLIGHT_SEARCH_VIEW,
    modifyFlightView: view,
  }
}

export function updateBedbankSession() {
  return async function(dispatch, getState) {
    const state = getState() as App.State
    const viewsWithStatus = checkoutAccommodationOfferView(state)
    if (!viewsWithStatus.hasRequiredData) { return null }

    // Assumption: Only a single accommodation per cart
    const targetView = viewsWithStatus.data[0]

    if (targetView.offerType !== OFFER_TYPE_BED_BANK) {
      return null
    }

    const groupedBySessionId = groupBy(targetView.itemViews, itemView => itemView.item.sessionId)

    for (const itemViews of Array.from(groupedBySessionId.values())) {
      const targetItem = itemViews[0].item
      const accomTotals = sum(itemViews.map((itemView) => itemView.price + sum(Object.values(itemView.otherFees))))

      const enquiryParams = {
        propertyId: targetView.offerId,
        isFlightBundle: targetItem.isFlightBundle,
        rooms: targetView.occupancy,
        roomTypeId: targetItem.roomId,
        roomRateId: targetItem.roomRateId,
        bedGroupId: targetItem.bedGroupId,
        rateGroupId: targetItem.rateGroupId,
        region: state.geo.currentRegionCode,
        checkIn: targetView.startDate,
        checkOut: targetView.endDate,
        price: accomTotals,
        csBooking: state.auth.account.isSpoofed,
      }

      dispatch({
        type: API_CALL,
        api: UPDATE_BEDBANK_SESSION,
        request: async() => enquiry(enquiryParams)
          .then(response => ({ sessionId: response.sessionId, oldSessionId: targetItem.sessionId })),
      })
    }
  }
}

export function updateHotelItemPricing(itemId: string | undefined, newPrice: number, surcharge?: { newSurcharge?: number, newExtraGuestSurcharge?: number }): Actions['CHECKOUT_UPDATE_HOTEL_ITEM_PRICING'] {
  return {
    type: CHECKOUT_UPDATE_HOTEL_ITEM_PRICING,
    itemId,
    newPrice,
    surcharge,
  }
}

export function updateBundleItemPricing(itemId: string, offerId: string, newPrice: number, surcharge?: { newSurcharge?: number, newExtraGuestSurcharge?: number }): Actions['CHECKOUT_UPDATE_BUNDLE_ITEM_PRICING'] {
  return {
    type: CHECKOUT_UPDATE_BUNDLE_ITEM_PRICING,
    itemId,
    offerId,
    newPrice,
    surcharge,
  }
}

export function toggleCheckoutDevMode() {
  return {
    type: CHECKOUT_TOGGLE_DEV_TOOLS,
  }
}

export function checkoutFetchAndInitialiseBedbankChangeDates(orderId: string, orderItemId: string) {
  return (dispatch) => {
    dispatch({
      type: API_CALL,
      api: CHECKOUT_FETCH_BEDBANK_EXISTING_ORDER,
      request: async() => {
        const order = await getOrderWithPaymentsAndReservations(orderId)
        const orderItem = order.bedbankItems.find(i => i.id === orderItemId)

        if (orderItem) {
          const offerId = orderItem.offer.id
          const offer = await getOfferById(offerId, {
            region: order.regionCode,
          }) as App.BedbankOffer

          dispatch({
            type: API_CALL_SUCCESS,
            api: FETCH_BEDBANK_OFFER,
            data: offer,
            offerId,
          })

          const bedGroupId = orderItem.rooms[0]?.bedGroup.id
          const isFlightBundle = orderItem.rooms[0]?.isFlightBundle
          const occupancies = orderItem.rooms?.filter(room => room.status === 'booked').map(room => room.occupancy)
          const dates = {
            checkIn: moment(orderItem.checkIn).format(ISO_DATE_FORMAT),
            checkOut: moment(orderItem.checkOut).format(ISO_DATE_FORMAT),
            duration: orderItem.duration,
          }
          const items = generateBedbankChangeDatesAccommodationCheckoutItems({
            orderItemId: orderItem.id,
            offerId: orderItem.offer.id,
            roomId: orderItem.roomTypeId,
            roomRateId: orderItem.roomRateId,
            isFlightBundle,
            bedGroupId,
            dates,
            occupancies,
          })
          dispatch(addToCheckoutCart([...items], {
            regionCode: order.regionCode,
            currencyCode: order.currencyCode,
            order,
            postPurchase: 'change-dates',
          }))
        }
      },
    })
  }
}

export function initCheckoutBedbankChangeDatesSession(region: string, items: Array<App.Checkout.BedbankHotelItem>, isSpoofed: boolean) {
  return (dispatch) => {
    dispatch({
      type: API_CALL,
      api: CHECKOUT_INIT_BEDBANK_CHANGE_DATES_SESSION,
      request: async() => {
        const offerId = items[0].offerId
        const offer = await getOfferById(offerId, {
          region,
        }) as App.BedbankOffer

        const occupancy = items.map(item => item.occupancy)

        if (offer) {
          const updatedRatesRequest = {
            checkIn: items[0].checkIn,
            checkOut: items[0].checkOut,
            rooms: occupancy,
            region,
            isSpoofed,
          }

          const newRates = await getRatesForBedbankOffers([offer.id], updatedRatesRequest)
          const rate = newRates[0].rates.find(rate => rate.id === items[0].roomRateId)

          if (rate) {
            dispatch({
              type: API_CALL_SUCCESS,
              api: FETCH_BEDBANK_OFFERS_RATES,
              data: newRates,
              offerIds: [offer.id],
              filterKey: getBedbankRateKey(occupancy, items[0].checkIn, items[0].checkOut),
            })

            try {
              const session = await enquiry({
                propertyId: offer.id,
                roomTypeId: items[0].roomId,
                roomRateId: rate.id,
                rateGroupId: rate.groupId,
                isFlightBundle: items[0].isFlightBundle,
                region,
                rooms: occupancy,
                checkIn: items[0].checkIn,
                checkOut: items[0].checkOut,
                price: rate.totals.inclusive,
                csBooking: isSpoofed,
              })
              for (const item of items) {
                dispatch(updateCheckoutItem({
                  ...item,
                  sessionId: session.sessionId,
                  bedGroupId: rate.bedGroups[0].id,
                }))
              }
              return {
                sessionId: session.sessionId,
                defaultBedGroupId: rate.bedGroups[0].id,
              }
            } catch (error) {
              if (error.status === 422) {
                const priceChange = error.errors?.find(e => e.code === 'price_change')
                if (priceChange) {
                  throw new Error('Price is changed')
                }
              }
              throw error
            }
          } else {
            throw new Error('Room is sold out')
          }
        }
      },
    })
  }
}

export async function initCheckoutBedbankSession(dispatch, region: string, item: App.Checkout.BedbankHotelItem, isSpoofed: boolean): Promise<{
  sessionId: string,
  defaultBedGroupId: string,
}> {
  const offerId = item.offerId

  const offer = await getOfferById(offerId, {
    region,
  }) as App.BedbankOffer

  dispatch({
    type: API_CALL_SUCCESS,
    api: FETCH_BEDBANK_OFFER,
    data: offer,
    offerId,
  })

  if (offer) {
    const updatedRatesRequest = {
      checkIn: item.checkIn,
      checkOut: item.checkOut,
      rooms: [item.occupancy],
      region,
      isSpoofed,
    }

    const newRates = await getRatesForBedbankOffers([offer.id], updatedRatesRequest)
    const rate = newRates[0].rates.find(rate => rate.id === item.roomRateId)

    if (rate) {
      dispatch({
        type: API_CALL_SUCCESS,
        api: FETCH_BEDBANK_OFFERS_RATES,
        data: newRates,
        offerIds: [offer.id],
        filterKey: getBedbankRateKey([item.occupancy], item.checkIn, item.checkOut),
      })

      try {
        const session = await enquiry({
          propertyId: offer.id,
          roomTypeId: item.roomId,
          roomRateId: rate.id,
          rateGroupId: rate.groupId,
          isFlightBundle: rate.isFlightBundle,
          region,
          rooms: [item.occupancy],
          checkIn: item.checkIn,
          checkOut: item.checkOut,
          price: rate.totals.inclusive,
          csBooking: isSpoofed,
        })

        return {
          sessionId: session.sessionId,
          defaultBedGroupId: rate.bedGroups[0].id,
        }
      } catch (error) {
        if (error.status === 422) {
          const priceChange = error.errors?.find(e => e.code === 'price_change')
          if (priceChange) {
            throw new Error('Price is changed')
          }
        }
        throw error
      }
    } else {
      throw new Error('Room is sold out')
    }
  } else {
    throw new Error('Offer not found')
  }
}

export function updateArrivalFlightNumber(flightNumber: string) {
  return {
    type: CHECKOUT_UPDATE_ARRIVAL_FLIGHT_NUMBER,
    data: flightNumber,
  }
}

export function updateAgentInformation(agent: App.AgentDetails) {
  return {
    type: CHECKOUT_AGENT_BOOKING_DETAILS,
    data: agent,
  }
}

export function updateBusinessInformation(businessData: Record<string, unknown>) {
  return {
    type: CHECKOUT_BUSINESS_BOOKING_DETAILS,
    data: businessData,
  }
}

export function removeInsurance() {
  return {
    type: CHECKOUT_SELECT_NO_INSURANCE,
  }
}

export function removeTravelProtection() {
  return {
    type: CHECKOUT_SELECT_NO_TRAVEL_PROTECTION,
  }
}

export function updateCsDepositPercentageOverride(depositOverride: boolean): Actions['CHECKOUT_UPDATE_CS_DEPOSIT_OVERRIDE'] {
  return {
    type: CHECKOUT_UPDATE_CS_DEPOSIT_OVERRIDE,
    depositOverride,
  }
}

export function setMerchantFeePaymentType(paymentType: App.MerchantFeePaymentType | null): Actions['CHECKOUT_SET_MERCHANT_FEE_PAYMENT_TYPE'] {
  return {
    type: CHECKOUT_SET_MERCHANT_FEE_PAYMENT_TYPE,
    paymentType,
  }
}

export function logPaymentEvent(event: App.PaymentEventLog) {
  return {
    type: API_CALL,
    api: LOG_PAYMENT_EVENT,
    request: () => postPaymentEventLog(event),
  }
}

/**
 * Removes the given subscription item (lux plus) from the cart
 *
 * Also removes the associated join fee item if it exists
 * @param itemId
 */
export function removeSubscriptionItem(itemId: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const joinFeeItem = getSubscriptionJoinItems(state).find(item => item.subscriptionItemId === itemId)
    if (joinFeeItem) {
      dispatch(removeItem(joinFeeItem.itemId, false))
    }
    dispatch(removeItem(itemId, false))
  }
}

export function createStripePaymentMethod(stripe: Stripe, options: CreatePaymentMethodFromElements) {
  return {
    type: API_CALL,
    api: CREATE_STRIPE_PAYMENT_METHOD,
    request: async() => {
      const paymentMethodResponse = await stripe.createPaymentMethod(options)

      if (!paymentMethodResponse?.paymentMethod) {
        showSnackbar('Invalid card. Please check your card details and try again.', 'critical')
      }

      return paymentMethodResponse
    },
  }
}

export function setStripePaymentMethod(paymentMethod: App.StripePaymentMethod) {
  return {
    type: CHECKOUT_SET_STRIPE_PAYMENT_METHOD,
    paymentMethod,
  }
}

export function createCartQuote(cartId: string, callbackDate?: string) {
  return (dispatch: AppDispatch, getState: () => App.State) => {
    const state = getState()
    const quoteStatus = state.checkout.cart.csCartQuoteStatus
    if (quoteStatus === 'created' || quoteStatus === 'loading') return
    dispatch({
      type: API_CALL,
      api: CHECKOUT_CREATE_CART_QUOTE,
      request: () => createQuote(cartId, callbackDate),
    })
  }
}

export function updateCartQuote(keys: QuoteDedupeKeys, callbackDate?: string) {
  return (dispatch: AppDispatch, getState: () => App.State) => {
    const state = getState()
    const { csCartQuoteStatus, csCartQuoteId } = state.checkout.cart
    if (csCartQuoteStatus !== 'created' || !csCartQuoteId) return
    dispatch({
      type: API_CALL,
      api: CHECKOUT_UPDATE_CART_QUOTE,
      request: () => updateQuote(csCartQuoteId, keys, callbackDate),
    })
  }
}

export function addArrivalDetails(itemId: string, arrivalDetails: App.ArrivalDetails) {
  return {
    type: CHECKOUT_ADD_ARRIVAL_DETAILS,
    itemId,
    arrivalDetails,
  }
}

export function setArrivalDetailsTime(itemId: string, arrivalTime: string) {
  return {
    type: CHECKOUT_SET_ARRIVAL_DETAILS_TIME,
    itemId,
    arrivalTime,
  }
}

export function setArrivalDetailsFlightNumber(itemId: string, arrivalFlightNumber: string) {
  return {
    type: CHECKOUT_SET_ARRIVAL_DETAILS_FLIGHT_NUMBER,
    itemId,
    arrivalFlightNumber,
  }
}

interface InitialiseMobileCheckoutParams {
  originAirportCode: string;
  offerId: string;
  checkIn: string;
  packageId: string;
  numberOfNights: number;
  occupancy: Array<App.Occupants>;
  roomRateId: string;
  destinationAirportCode: string;
  flightQuotedPrice?: number;
  luxPlusOfferId?: string;
}

export function initialiseMobileFlightCheckout(data: InitialiseMobileCheckoutParams) {
  const {
    offerId,
    originAirportCode,
    checkIn,
    numberOfNights,
    packageId,
    roomRateId,
    occupancy,
    destinationAirportCode,
    flightQuotedPrice,
    luxPlusOfferId,
  } = data

  return async(dispatch, getState) => {
    const state = getState() as App.State
    const { geo, auth } = state
    const { currentRegionCode } = geo

    const offer = (await getOfferById(offerId, {
      region: currentRegionCode,
      flightOrigin: originAirportCode,
    })) as App.Offer | App.BedbankOffer

    if (!offer) {
      throw new CartInitError({
        type: 'unrecoverable_offer_error',
        code: 'offer_not_found',
        message: 'Offer not found',
      })
    }
    const checkOut = moment(checkIn).add(numberOfNights, 'days').format(ISO_DATE_FORMAT)

    const luxPlusEnabled = isLuxPlusEnabled(state)
    let luxPlusSubscriptionItems: Array<App.Checkout.LuxPlusSubscriptionItem | App.Checkout.SubscriptionJoinItem> = []
    if (luxPlusEnabled && luxPlusOfferId) {
      try {
        const luxPlusOffers = await getSubscriptionOffer(currentRegionCode)
        const luxPlusOffer = luxPlusOffers?.find(offer => offer.id === luxPlusOfferId)

        if (!luxPlusOffer) {
          throw new CartInitError({
            type: 'unrecoverable_offer_error',
            code: 'offer_not_found',
            message: 'LuxPlus offer not found',
          })
        }

        luxPlusSubscriptionItems = generateLuxPlusSubscriptionItems(luxPlusOffer)
      } catch (error) {
        throw new CartInitError({
          type: 'unrecoverable_offer_error',
          code: 'offer_not_found',
          message: `Error occurred while fetching LuxPlus offer ${error.message}`,
        })
      }
    }

    if (offer.type === 'bedbank_hotel') {
      const authState: App.AuthState = state.auth

      const updatedRatesRequest = {
        checkIn,
        checkOut,
        rooms: occupancy,
        region: currentRegionCode,
        id: offerId,
        timezone: offer.property.timezone,
        isSpoofed: authState.account.isSpoofed,
      }

      const newRates = await getRatesForBedbankOffer(updatedRatesRequest)
      const rate = packageId ? newRates.find(rate => rate.roomId === packageId) : newRates[0]

      if (!rate) {
        throw new Error('Rate not found')
      }

      const session = await enquiry({
        propertyId: offer.id,
        roomTypeId: rate.roomId,
        roomRateId: rate.id,
        rateGroupId: rate.groupId,
        isFlightBundle: rate.isFlightBundle,
        region: currentRegionCode,
        rooms: occupancy,
        checkIn,
        checkOut,
        price: rate.totals.inclusive,
        csBooking: auth.account.isSpoofed,
      })

      const accommodationItems: Array<App.Checkout.BedbankHotelItem> =
        occupancy.map((occupancy) => {
          const accomodation: App.Checkout.BedbankHotelItem = {
            itemId: uuidV4(),
            offerId: offer.id,
            transactionKey: uuidV4(),
            itemType: CHECKOUT_ITEM_TYPE_BEDBANK,
            roomRateId,
            duration: numberOfNights,
            occupancy,
            checkIn,
            checkOut,
            bedGroupId: rate.bedGroups[0].id,
            isFlightBundle: rate.isFlightBundle,
            roomId: packageId,
            sessionId: session.sessionId,
          }

          return accomodation
        })

      const rooms = accommodationItems.map((item) => item.occupancy)
      const flightOccupants = countOccupantsForFlights(rooms)

      const flightItem = generateFlightCheckoutItem({
        originAirportCode,
        destinationAirportCode,
        bundledItemIds: accommodationItems.map((item) => item.itemId),
        occupants: flightOccupants,
        fareType: 'return',
        viewType: FlightViewTypes.TWO_ONE_WAYS,
        passengers: generatePassengersFromOccupants(flightOccupants),
        quotedFare: flightQuotedPrice,
      })

      dispatch(addToCheckoutCart([...accommodationItems, flightItem]))
    } else {
      const pkg = getPackageFromOffer(offer, packageId, numberOfNights)

      if (!pkg) {
        throw new Error('No Package found')
      }

      const accommodationItems: Array<App.Checkout.InstantBookingLEHotelItem> =
        occupancy.map((occupancy) => ({
          itemId: uuidV4(),
          offerId: offer.id,
          transactionKey: uuidV4(),
          itemType: CHECKOUT_ITEM_TYPE_LE_HOTEL,
          reservationType: 'instant_booking',
          packageId,
          roomRateId,
          duration: numberOfNights,
          occupancy,
          checkIn,
          checkOut,
        }))

      const rooms = accommodationItems.map((item) => item.occupancy)
      const flightOccupants = countOccupantsForFlights(rooms)

      const flightItem = generateFlightCheckoutItem({
        originAirportCode,
        destinationAirportCode,
        bundledItemIds: accommodationItems.map((item) => item.itemId),
        occupants: flightOccupants,
        fareType: 'return',
        viewType: FlightViewTypes.TWO_ONE_WAYS,
        passengers: generatePassengersFromOccupants(flightOccupants),
        quotedFare: flightQuotedPrice,
      })

      dispatch(addToCheckoutCart([...accommodationItems, flightItem, ...luxPlusSubscriptionItems]))
    }
  }
}

export function activateRebooking(rebookingID: string) {
  return {
    type: ACTIVATE_REBOOKING,
    data: { rebookingID },
  }
}

export function fetchCommissionPromoCode() {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const regionCode = state.geo.currentRegionCode

    const commissionCartItems = stateToCommissionOrder(state)
    const isAncillaryPayment = isPostPurchaseAncillaryPayment(state)

    if (isAncillaryPayment) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_COMMISSION_PROMO_CODE,
      request: () => getAgentHubCommissionPromoCode(regionCode, commissionCartItems),
    })
  }
}

export function setPayToBankSelected(selectedPayToBank: App.PayToSupportedBank) {
  return {
    type: CHECKOUT_SET_PAYTO_BANK,
    selectedPayToBank,
  }
}

export function setCommsResubscribe(commsResubscribe: boolean) {
  return {
    type: CHECKOUT_SET_COMMS_RESUBSCRIBE,
    commsResubscribe,
  }
}

export function resubscribeUserForComms() {
  return (dispatch, getState) => {
    const state = getState()
    if (!state.checkout.form.commsResubscribe) return
    const isLuxPlus = isMemberOrHasSubscriptionInTheCart(state)
    dispatch(updateLESubscriptionsV2({
      userId: state.auth.account.memberId,
      subscriptions: {
        sms_subscribed: true,
        email_subscribed: true,
        app_push_subscribed: true,
        my_journey_subscribed: true,
        todays_escapes_subscribed: true,
        curated_collection_subscribed: true,
        todays_escapes_cadence: 'WEEKLY',
      },
    }))
    Analytics.trackClientEvent({
      subject: isLuxPlus ? 'personalised-weekly-luxplus' : 'personalised-weekly',
      action: 'checkout',
      type: 'interaction',
      category: 'resubscribe',
    })
  }
}

export function generatePaymentLink() {
  return (dispatch: AppDispatch, getState: () => App.State) => {
    const state = getState()

    dispatch({
      type: CHECKOUT_FETCHING_PAYMENT_LINK,
    })

    const itemNames = getPackageNamesFromCart(state)

    if (!itemNames || itemNames.length === 0 || !state.checkout.orderId) {
      // adding timeout to show the error modal after the processing modal
      setTimeout(() => {
        dispatch({
          type: CHECKOUT_FETCHING_PAYMENT_LINK_FAILED,
        })
        dispatch(processingCancel())
        dispatch(genericOrderErrorModalOpen(['Unable to generate payment link']))
      }, 3000)
      return
    }

    const payload = {
      orderId: state.checkout.orderId,
      region: state.geo.currentRegionCode as Region,
      metadata: {
        itemNames,
      },
    }

    // brand is added by the request preprocessor
    const payloadWithoutBrand = payload as CreatePaymentLinkPayload

    createPaymentLink(payloadWithoutBrand)
      .then((paymentLink) => {
        dispatch({
          type: CHECKOUT_SET_PAYMENT_LINK,
          data: paymentLink,
        })
        dispatch(showSecurePaymentModal())
      })
      .catch(() => {
        dispatch({
          type: CHECKOUT_FETCHING_PAYMENT_LINK_FAILED,
        })
        dispatch(processingCancel())
        dispatch(genericOrderErrorModalOpen(['Unable to generate payment link']))
      })
  }
}

export function setOrderId(orderId: string) {
  return {
    type: CHECKOUT_SET_ORDER_ID,
    orderId,
  }
}
