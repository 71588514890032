import { arrayToObject } from 'lib/array/arrayUtils'
import { reducerSwitch, createReducer } from 'lib/redux/reducerUtils'

import {
  FETCH_CRUISE_LINES,
  FETCH_CRUISE_PROMO_BANNERS, FETCH_CRUISES_LIST_RECOMMENDATION,
  FETCH_CRUISE_OFFER,
  FETCH_CRUISE_SEARCH_FACETS,
  FETCH_CRUISE_SEARCH_COUNT,
  FETCH_CRUISE_SAILINGS,
  FETCH_CRUISE_SHIP_DECK,
  FETCH_CRUISE_BOOKING_CABIN_RELEASE,
  FETCH_CRUISE_BOOKING_RATE_LIST,
  FETCH_CRUISE_BOOKING_CABIN_LIST,
  FETCH_CRUISE_BOOKING_CABIN_DETAILS_LIST,
  FETCH_CRUISE_BOOKING_CABIN_SELECTION,
  FETCH_CRUISE_BOOKING_CABIN_PRICING,
  FETCH_CRUISE_BOOKING_RATE_DETAILS_LIST,
  FETCH_CRUISE_BOOKING_BY_ID,
  FETCH_CRUISE_DEPARTURE_BY_ID,
  FETCH_CRUISE_LOWEST_PRICE_BY_MONTH,
  RESET_SESSION_ID,
  FETCH_CRUISE_SHIP,
  FETCH_CRUISE_CONSOLIDATED_PAYMENT_SCHEDULE,
} from 'actions/apiActionConstants'

import {
  API_CALL_REQUEST,
  API_CALL_FAILURE,
  API_CALL_SUCCESS,
  SAVE_CABIN_OPTION,
  RESET_REVELEX_CALLS,
  RESET_CABIN_AVAILABILITY,
  CRUISE_IN_FLOW_CHECKOUT_STEP,
  CRUISE_SET_SEARCH_FACETS,
  CRUISE_SET_CRUISE_LINES,
  RESET_BOOKING_CABIN_SELECTION,
  SELECT_BOOKING_CRUISE_ITEM,
  SET_BOOKING_INTEREST_IN_CONNECTED_CABINS,
} from 'actions/actionConstants'

export const initialCruiseState: App.CruiseState = {
  endOfPagination: false,
  fetchingCruises: true,
  cruiseIdListRecommendation: [],
  cruiseSailingList: {},
  fetchingCruiseSailingList: {},

  // PROMO BANNERS
  cruisePromoBanners: [],
  fetchingCruisePromoBanners: false,

  // SEARCH FACETS/FILTERS
  cruiseFacets: {},
  cruiseSearchCount: {},
  cruiseLowestPricesByMonth: {},

  // OFFERS
  cruiseOffers: {},
  cruiseOffersLoading: {},
  cruiseOffersErrors: {},

  // OFFER SHIP DATA
  deckSelections: {},
  ships: {},

  // CRUISE LINES
  cruiseLines: [],
  fetchingCruiseLines: false,

  // DEPARTURES
  cruiseDeparture: {},
  cruiseDepartureError: {},
  cruiseDepartureLoading: {},

  // BOOKING INFO
  cruiseBookingInfo: {},
  cruiseBookingInfoError: {},
  cruiseBookingInfoLoading: {},

  // LEGACY BOOKING FLOW - TODO: move to multiBooking object
  inFlowCheckoutStep: undefined,
  sessionIDExpired: false,

  // BOOKING FLOW
  multiBooking: {
    selectedCruiseItemId: undefined,
    interestInConnectedCabins: false,
    consolidatedPaymentSchedule: {
      error: false,
      loading: false,
      schedule: undefined,
    },
    /*
     * Data stored by cabin passenger hash ex: "adults_2_children_3"
     * this means that cabins with the same passenger configuration can share the same data
     * without needing to request the API for each cabin
    */
    rateList: {},
    cabinList: {},
    cabinDetails: {},
    rateDetails: {},

    /*
     * Data stored by cart item ID
     * Even if all selected cabins have the same passenger configuration,
     * each cabin will have individual data as session, bookingId, and pricing
    */
    session: {},
    cabinSelection: {},
    cabinPricing: {},
    cabinRelease: {},
  },
}

const apiRequests = reducerSwitch<App.CruiseState>({
  [FETCH_CRUISE_LINES]: () => ({
    fetchingCruiseLines: true,
  }),
  [FETCH_CRUISE_SEARCH_FACETS]: (state, action) => ({
    cruiseFacets: {
      ...state.cruiseFacets,
      [action.key]: {
        fetching: true,
        facets: [],
      },
    },
  }),
  [FETCH_CRUISE_SEARCH_COUNT]: (state, action) => ({
    cruiseSearchCount: {
      ...state.cruiseSearchCount,
      [action.key]: {
        count: 0,
        fetching: true,
      },
    },
  }),
  [FETCH_CRUISE_PROMO_BANNERS]: () => ({
    fetchingCruisePromoBanners: true,
  }),
  [FETCH_CRUISE_LOWEST_PRICE_BY_MONTH]: (state, action) => ({
    cruiseLowestPricesByMonth: {
      ...state.cruiseLowestPricesByMonth,
      [action.key]: {
        fetching: true,
        lowestPrices: [],
      },
    },
  }),
  [FETCH_CRUISE_SAILINGS]: (state, action) => ({
    fetchingCruiseSailingList: {
      ...state.fetchingCruiseSailingList,
      ...arrayToObject(
        action.ids as Array<string>,
        (id) => id,
        () => true,
      ),
    },
  }),
  [FETCH_CRUISE_OFFER]: (state, action) => ({
    ...state,
    cruiseOffersLoading: {
      ...state.cruiseOffersLoading,
      [action.offerId]: true,
    },
  }),
  [FETCH_CRUISE_BOOKING_BY_ID]: (state, action) => ({
    ...state,
    cruiseBookingInfoError: {
      ...state.cruiseBookingInfoError,
      [action.id]: false,
    },
    cruiseBookingInfoLoading: {
      ...state.cruiseBookingInfoLoading,
      [action.id]: true,
    },
  }),
  [FETCH_CRUISE_DEPARTURE_BY_ID]: (state, action) => ({
    ...state,
    cruiseDepartureError: {
      ...state.cruiseDepartureError,
      [action.departureId]: false,
    },
    cruiseDepartureLoading: {
      ...state.cruiseDepartureLoading,
      [action.departureId]: true,
    },
  }),
  [FETCH_CRUISE_SHIP_DECK]: (state) => ({
    ...state,
    // TODO loading
  }),
  [FETCH_CRUISE_BOOKING_RATE_LIST]: (state, action) => ({
    ...state,
    multiBooking: {
      ...state.multiBooking,
      rateList: {
        ...state.multiBooking.rateList,
        [action.cartItemId]: {
          rates: [],
          loading: true,
          error: false,
        },
      },
    },
  }),
  [FETCH_CRUISE_BOOKING_CABIN_LIST]: (state, action) => ({
    ...state,
    multiBooking: {
      ...state.multiBooking,
      cabinList: {
        ...state.multiBooking.cabinList,
        [action.travelerKey]: {
          cabins: [],
          cabinByNumber: {},
          loading: true,
          error: false,
        },
      },
    },
  }),
  [FETCH_CRUISE_BOOKING_CABIN_DETAILS_LIST]: (state, action) => {
    const detailsData = state.multiBooking.cabinDetails[action.travelerKey] || {
      detailsByCabin: {},
      errorByCabin: {},
    }

    return {
      ...state,
      multiBooking: {
        ...state.multiBooking,
        cabinDetails: {
          ...state.multiBooking.cabinDetails,
          [action.travelerKey]: {
            ...detailsData,
            loading: true,
          },
        },
      },
    }
  },
  [FETCH_CRUISE_BOOKING_CABIN_SELECTION]: (state, action) => ({
    ...state,
    multiBooking: {
      ...state.multiBooking,
      cabinSelection: {
        ...state.multiBooking.cabinSelection,
        [action.cruiseItemId]: {
          cabinSelection: null,
          loading: true,
          error: false,
        },
      },
      consolidatedPaymentSchedule: {
        error: false,
        loading: false,
        schedule: undefined,
      },
    },
  }),
  [FETCH_CRUISE_BOOKING_CABIN_PRICING]: (state, action) => ({
    ...state,
    multiBooking: {
      ...state.multiBooking,
      cabinPricing: {
        ...state.multiBooking.cabinPricing,
        [action.cruiseItemId]: {
          cabinPricing: null,
          loading: true,
          error: false,
          errorToRender: null,
        },
      },
      consolidatedPaymentSchedule: {
        error: false,
        loading: false,
        schedule: undefined,
      },
    },
  }),
  [FETCH_CRUISE_BOOKING_RATE_DETAILS_LIST]: (state, action) => {
    const detailsData = state.multiBooking.rateDetails[action.cartItemId] || {
      detailsByRate: {},
      errorByRate: {},
      loadingByRate: {},
    }

    return {
      ...state,
      multiBooking: {
        ...state.multiBooking,
        rateDetails: {
          ...state.multiBooking.rateDetails,
          [action.cartItemId]: {
            ...detailsData,
            loadingByRate: {
              ...detailsData.loadingByRate,
              [action.componentId]: true,
            },
          },
        },
      },
    }
  },
  [FETCH_CRUISE_BOOKING_CABIN_RELEASE]: (state, action) => ({
    ...state,
    cabinPricing: null,
    cabinPricingError: false,
    cabinPricingLoading: false,
    multiBooking: {
      ...state.multiBooking,
      cabinSelection: {
        ...state.multiBooking.cabinSelection,
        [action.cruiseItemId]: undefined,
      },
      cabinPricing: {
        ...state.multiBooking.cabinPricing,
        [action.cruiseItemId]: undefined,
      },
      cabinRelease: {
        ...state.multiBooking.cabinRelease,
        [action.cruiseItemId]: {
          released: false,
          loading: true,
          error: false,
        },
      },
      consolidatedPaymentSchedule: {
        error: false,
        loading: false,
        schedule: undefined,
      },
    },
  }),
  [FETCH_CRUISE_CONSOLIDATED_PAYMENT_SCHEDULE]: (state) => ({
    ...state,
    multiBooking: {
      ...state.multiBooking,
      consolidatedPaymentSchedule: {
        error: false,
        loading: true,
        schedule: undefined,
      },
    },
  }),
})

const apiSuccesses = reducerSwitch<App.CruiseState>({
  [FETCH_CRUISES_LIST_RECOMMENDATION]: (state, action) => ({
    cruiseIdListRecommendation: action.data,
  }),
  [FETCH_CRUISE_LINES]: (state, action) => ({
    cruiseLines: action.data,
    fetchingCruiseLines: false,
  }),
  [FETCH_CRUISE_SEARCH_FACETS]: (state, action) => ({
    cruiseFacets: {
      ...state.cruiseFacets,
      [action.key]: {
        fetching: false,
        facets: action.data,
      },
    },
  }),
  [FETCH_CRUISE_SEARCH_COUNT]: (state, action) => ({
    cruiseSearchCount: {
      ...state.cruiseSearchCount,
      [action.key]: {
        count: action.data,
        fetching: false,
      },
    },
  }),
  [FETCH_CRUISE_PROMO_BANNERS]: (state, action) => ({
    cruisePromoBanners: action.data,
    fetchingCruisePromoBanners: false,
  }),
  [FETCH_CRUISE_LOWEST_PRICE_BY_MONTH]: (state, action) => ({
    cruiseLowestPricesByMonth: {
      ...state.cruiseLowestPricesByMonth,
      [action.key]: {
        fetching: false,
        lowestPrices: action.data,
      },
    },
  }),
  [FETCH_CRUISE_SAILINGS]: (state, action) => ({
    cruiseSailingList: {
      ...state.cruiseSailingList,
      ...arrayToObject(
        action.data as Array<App.CruiseOffer>,
        (sailing) => sailing.id,
      ),
    },
    fetchingCruiseSailingList: {
      ...state.fetchingCruiseSailingList,
      ...arrayToObject(
        action.ids as Array<string>,
        (id) => id,
        () => false,
      ),
    },
  }),
  [FETCH_CRUISE_OFFER]: (state, action) => ({
    ...state,
    cruiseOffersLoading: {
      ...state.cruiseOffersLoading,
      [action.offerId]: false,
    },
    cruiseOffers: {
      ...state.cruiseOffers,
      [action.offerId]: action.data,
    },
  }),
  [FETCH_CRUISE_BOOKING_BY_ID]: (state, action) => ({
    ...state,
    cruiseBookingInfoError: {
      ...state.cruiseBookingInfoError,
      [action.id]: false,
    },
    cruiseBookingInfoLoading: {
      ...state.cruiseBookingInfoLoading,
      [action.id]: false,
    },
    cruiseBookingInfo: {
      ...state.cruiseBookingInfo,
      [action.id]: action.data,
    },
  }),
  [FETCH_CRUISE_DEPARTURE_BY_ID]: (state, action) => ({
    ...state,
    cruiseDepartureError: {
      ...state.cruiseDepartureError,
      [action.departureId]: false,
    },
    cruiseDepartureLoading: {
      ...state.cruiseDepartureLoading,
      [action.departureId]: false,
    },
    cruiseDeparture: {
      ...state.cruiseDeparture,
      [action.departureId]: action.data,
    },
  }),
  [FETCH_CRUISE_SHIP_DECK]: (state, action) => ({
    ...state,
    deckSelections: {
      ...state.deckSelections,
      [action.deckId]: action.data,
    },
  }),
  [FETCH_CRUISE_SHIP]: (state, action) => ({
    ...state,
    ships: {
      ...state.ships,
      [action.shipId]: action.data,
    },
  }),
  [FETCH_CRUISE_BOOKING_RATE_LIST]: (state, action) => {
    return {
      ...state,
      multiBooking: {
        ...state.multiBooking,
        rateList: {
          ...state.multiBooking.rateList,
          [action.cartItemId]: {
            rates: action.data.cabinRates,
            loading: false,
            error: false,
          },
        },
        session: {
          ...state.multiBooking.session,
          [action.cartItemId]: {
            sessionId: action.data.sessionId,
            bookingId: action.data.bookingId,
            cabinHoldTimeout: action.data.cabinHoldTimeout,
            personTitles: action.data.personTitles,
          },
        },
      },
    }
  },
  [FETCH_CRUISE_BOOKING_CABIN_LIST]: (state, action) => {
    return {
      ...state,
      multiBooking: {
        ...state.multiBooking,
        cabinList: {
          ...state.multiBooking.cabinList,
          [action.travelerKey]: {
            cabins: action.data.cabins,
            cabinByNumber: action.data.cabinByNumber,
            loading: false,
            error: false,
          },
        },
      },
    }
  },
  [FETCH_CRUISE_BOOKING_CABIN_DETAILS_LIST]: (state, action) => {
    const detailsData = state.multiBooking.cabinDetails[action.travelerKey]
    return {
      ...state,
      multiBooking: {
        ...state.multiBooking,
        cabinDetails: {
          ...state.multiBooking.cabinDetails,
          [action.travelerKey]: {
            ...detailsData,
            detailsByCabin: {
              ...detailsData.detailsByCabin,
              ...action.data,
            },
            loading: false,
          },
        },
      },
    }
  },
  [FETCH_CRUISE_BOOKING_CABIN_SELECTION]: (state, action) => {
    return {
      ...state,
      multiBooking: {
        ...state.multiBooking,
        cabinSelection: {
          ...state.multiBooking.cabinSelection,
          [action.cruiseItemId]: {
            cabinSelection: action.data,
            loading: false,
            error: false,
          },
        },
        // Reset cabinPricing when cabin selection is updated
        cabinPricing: {
          ...state.multiBooking.cabinPricing,
          [action.cruiseItemId]: {
            cabinPricing: null,
            loading: false,
            error: false,
            errorToRender: null,
            dirtyPreferences: false,
          },
        },
      },
    }
  },
  [FETCH_CRUISE_BOOKING_CABIN_PRICING]: (state, action) => ({
    ...state,
    multiBooking: {
      ...state.multiBooking,
      cabinPricing: {
        ...state.multiBooking.cabinPricing,
        [action.cruiseItemId]: {
          cabinPricing: action.data,
          loading: false,
          error: false,
          errorToRender: null,
          dirtyPreferences: false,
        },
      },
    },
  }),
  [FETCH_CRUISE_BOOKING_RATE_DETAILS_LIST]: (state, action) => {
    const detailsData = state.multiBooking.rateDetails[action.cartItemId]

    return {
      ...state,
      multiBooking: {
        ...state.multiBooking,
        rateDetails: {
          ...state.multiBooking.rateDetails,
          [action.cartItemId]: {
            ...detailsData,
            loadingByRate: {
              ...detailsData.loadingByRate,
              [action.componentId]: false,
            },
            detailsByRate: {
              ...detailsData.detailsByRate,
              ...action.data,
            },
          },
        },
      },
    }
  },
  [FETCH_CRUISE_BOOKING_CABIN_RELEASE]: (state, action) => ({
    ...state,
    multiBooking: {
      ...state.multiBooking,
      cabinRelease: {
        ...state.multiBooking.cabinRelease,
        [action.cruiseItemId]: {
          released: true,
          loading: false,
          error: false,
        },
      },
    },
  }),
  [FETCH_CRUISE_CONSOLIDATED_PAYMENT_SCHEDULE]: (state, action) => ({
    ...state,
    multiBooking: {
      ...state.multiBooking,
      consolidatedPaymentSchedule: {
        error: false,
        loading: false,
        schedule: action.data,
      },
    },
  }),
})

const apiFailures = reducerSwitch<App.CruiseState>({
  [FETCH_CRUISE_SEARCH_FACETS]: (state, action) => ({
    cruiseFacets: {
      ...state.cruiseFacets,
      [action.key]: {
        fetching: false,
        facets: [],
        error: action.error,
      },
    },
  }),
  [FETCH_CRUISE_SEARCH_COUNT]: (state, action) => ({
    cruiseSearchCount: {
      ...state.cruiseSearchCount,
      [action.key]: {
        count: 0,
        fetching: false,
      },
    },
  }),
  [FETCH_CRUISE_PROMO_BANNERS]: () => ({
    fetchingCruisePromoBanners: false,
  }),
  [FETCH_CRUISE_LOWEST_PRICE_BY_MONTH]: (state, action) => ({
    cruiseLowestPricesByMonth: {
      ...state.cruiseLowestPricesByMonth,
      [action.key]: {
        fetching: false,
        lowestPrices: [],
        error: action.error,
      },
    },
  }),
  [FETCH_CRUISE_SAILINGS]: (state, action) => ({
    fetchingCruiseSailingList: {
      ...state.fetchingCruiseSailingList,
      ...arrayToObject(
        action.ids as Array<string>,
        (id) => id,
        () => false,
      ),
    },
  }),
  [FETCH_CRUISE_OFFER]: (state, action) => ({
    ...state,
    cruiseOffersLoading: {
      ...state.cruiseOffersLoading,
      [action.offerId]: false,
    },
    cruiseOffersErrors: {
      ...state.cruiseOffersErrors,
      [action.offerId]: action.error,
    },
  }),
  [FETCH_CRUISE_BOOKING_BY_ID]: (state, action) => ({
    ...state,
    cruiseBookingInfoError: {
      ...state.cruiseBookingInfoError,
      [action.id]: true,
    },
    cruiseBookingInfoLoading: {
      ...state.cruiseBookingInfoLoading,
      [action.id]: false,
    },
  }),
  [FETCH_CRUISE_DEPARTURE_BY_ID]: (state, action) => ({
    ...state,
    cruiseDepartureError: {
      ...state.cruiseDepartureError,
      [action.departureId]: true,
    },
    cruiseDepartureLoading: {
      ...state.cruiseDepartureLoading,
      [action.departureId]: false,
    },
  }),
  [FETCH_CRUISE_SHIP_DECK]: (state, action) => ({
    ...state,
    deckSelections: {
      ...state.deckSelections,
      [action.deckId]: null,
    },
  }),
  [FETCH_CRUISE_BOOKING_RATE_LIST]: (state, action) => ({
    ...state,
    multiBooking: {
      ...state.multiBooking,
      rateList: {
        ...state.multiBooking.rateList,
        [action.cartItemId]: {
          rates: [],
          loading: false,
          error: true,
          errors: action.error?.errors,
          errorMessage: action.error?.message,
          errorToRender: action.error?.errorToRender,
        },
      },
      session: {
        ...state.multiBooking.session,
        [action.cartItemId]: null,
      },
    },
  }),
  [FETCH_CRUISE_BOOKING_CABIN_LIST]: (state, action) => ({
    ...state,
    multiBooking: {
      ...state.multiBooking,
      cabinList: {
        ...state.multiBooking.cabinList,
        [action.travelerKey]: {
          cabins: [],
          cabinByNumber: {},
          loading: false,
          error: true,
          errors: action.error?.errors,
          errorMessage: action.error?.message,
          errorToRender: action.error?.errorToRender,
        },
      },
    },
  }),
  [FETCH_CRUISE_BOOKING_CABIN_DETAILS_LIST]: (state, { cabinNumbers, travelerKey }) => {
    const detailsData = state.multiBooking.cabinDetails[travelerKey]

    // set error for each cabinNumber
    const errorByCabin = {
      ...detailsData.errorByCabin,
      ...cabinNumbers.reduce((acc, cabinNumber) => ({ ...acc, [cabinNumber]: true }), {}),
    }

    return {
      ...state,
      multiBooking: {
        ...state.multiBooking,
        cabinDetails: {
          ...state.multiBooking.cabinDetails,
          [travelerKey]: {
            ...detailsData,
            loading: false,
            errorByCabin,
          },
        },
      },
    }
  },
  [FETCH_CRUISE_BOOKING_CABIN_SELECTION]: (state, action) => ({
    ...state,
    multiBooking: {
      ...state.multiBooking,
      cabinSelection: {
        ...state.multiBooking.cabinSelection,
        [action.cruiseItemId]: {
          cabinSelection: null,
          loading: false,
          error: true,
          errors: action.error?.errors,
          errorMessage: action.error?.message,
          errorToRender: action.error?.errorToRender,
        },
      },
      cabinRelease: {
        ...state.multiBooking.cabinRelease,
        [action.cruiseItemId]: {
          released: false,
          loading: false,
          error: false,
        },
      },
    },
  }),
  [FETCH_CRUISE_BOOKING_CABIN_PRICING]: (state, action) => ({
    ...state,
    multiBooking: {
      ...state.multiBooking,
      cabinPricing: {
        ...state.multiBooking.cabinPricing,
        [action.cruiseItemId]: {
          cabinPricing: null,
          loading: false,
          error: true,
          errors: action.error?.errors,
          errorMessage: action.error?.message,
          errorToRender: action.error?.errorToRender,
          dirtyPreferences: false,
        },
      },
    },
  }),
  [FETCH_CRUISE_BOOKING_RATE_DETAILS_LIST]: (state, action) => {
    const detailsData = state.multiBooking.rateDetails[action.cartItemId]

    return {
      ...state,
      multiBooking: {
        ...state.multiBooking,
        rateDetails: {
          ...state.multiBooking.rateDetails,
          [action.cartItemId]: {
            ...detailsData,
            loadingByRate: {
              ...detailsData.loadingByRate,
              [action.componentId]: false,
            },
            errorByRate: {
              ...detailsData.errorByRate,
              [action.componentId]: true,
            },
          },
        },
      },
    }
  },
  [FETCH_CRUISE_BOOKING_CABIN_RELEASE]: (state, action) => ({
    ...state,
    multiBooking: {
      ...state.multiBooking,
      cabinRelease: {
        ...state.multiBooking.cabinRelease,
        [action.cruiseItemId]: {
          released: false,
          loading: false,
          error: true,
        },
      },
    },
  }),
  [FETCH_CRUISE_CONSOLIDATED_PAYMENT_SCHEDULE]: (state) => ({
    ...state,
    multiBooking: {
      ...state.multiBooking,
      consolidatedPaymentSchedule: {
        error: true,
        loading: false,
        schedule: undefined,
      },
    },
  }),
})

const bookingRequests = new Set([
  FETCH_CRUISE_BOOKING_RATE_LIST,
  FETCH_CRUISE_BOOKING_CABIN_LIST,
  FETCH_CRUISE_BOOKING_CABIN_SELECTION,
  FETCH_CRUISE_BOOKING_CABIN_PRICING,
  FETCH_CRUISE_BOOKING_RATE_DETAILS_LIST,
])

export default createReducer<App.CruiseState>(initialCruiseState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => {
    const isBookingRequest = bookingRequests.has(action.api)

    // Reset state if session has expired, so that the cabin rate request can be restarted
    if (isBookingRequest && action.error.message.includes('session has expired')) {
      return apiFailures(action.api)({
        ...state,
        sessionIDExpired: true,
      }, action)
    }

    return apiFailures(action.api)(state, action)
  },
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [SAVE_CABIN_OPTION]: (state, action) => {
    return {
      ...state,
      multiBooking: {
        ...state.multiBooking,
        cabinPricing: {
          ...state.multiBooking.cabinPricing,
          [action.cruiseItemId]: {
            cabinPricing: null,
            loading: false,
            error: false,
            errorToRender: null,
            dirtyPreferences: true,
          },
        },
      },
    }
  },
  [RESET_SESSION_ID]: (state) => ({ ...state, revelexSessionId: null }),
  [RESET_REVELEX_CALLS]: (state) => {
    return {
      ...state,
      sessionIDExpired: false,
      // BOOKING V2
      multiBooking: {
        ...state.multiBooking,
        selectedCruiseItemId: undefined,

        rateList: {},
        cabinList: {},
        cabinDetails: {},
        rateDetails: {},

        session: {},
        cabinRelease: {},
        cabinSelection: {},
        cabinPricing: {},
      },
    }
  },
  [RESET_CABIN_AVAILABILITY]: (state) => ({
    ...state,
    cabinAvailability: null,
    cabinAvailabilityError: false,
    cabinAvailabilityLoading: false,
  }),
  [RESET_BOOKING_CABIN_SELECTION]: (state) => ({
    ...state,
    multiBooking: {
      ...state.multiBooking,
      cabinSelection: {},
      cabinPricing: {},
    },
  }),
  [SELECT_BOOKING_CRUISE_ITEM]: (state, action) => ({
    ...state,
    multiBooking: {
      ...state.multiBooking,
      selectedCruiseItemId: action.cruiseItemId,
    },
  }),
  [CRUISE_SET_SEARCH_FACETS]: (state, action) => ({
    cruiseFacets: {
      ...state.cruiseFacets,
      [action.key]: {
        fetching: false,
        facets: action.data,
      },
    },
  }),
  [CRUISE_SET_CRUISE_LINES]: (state, action) => ({
    cruiseLines: action.data,
  }),
  [CRUISE_IN_FLOW_CHECKOUT_STEP]: (state, action) => ({ ...state, inFlowCheckoutStep: action.data }),
  [SET_BOOKING_INTEREST_IN_CONNECTED_CABINS]: (state, action) => ({
    ...state,
    multiBooking: {
      ...state.multiBooking,
      interestInConnectedCabins: action.data,
    },
  }),
})
