import AdditionalGuestsPopup from 'components/Common/AdditionalGuestsPopup/AdditionalGuestsPopup'
import { getSumExtraGuestsSurcharge, hasIncludedGuestsExceeded } from 'lib/checkout/cartReservationUtils'
import { formatOccupantsShort } from 'lib/offer/occupancyUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import useDefaultAirport from 'hooks/useDefaultAirport'
import PriceRowTaxesAndFees from 'components/Luxkit/PricePoints/PriceRowTaxesAndFees'
import TextButton from 'components/Luxkit/Button/TextButton'
import BusinessTravellerOfferCreditsTextLink from 'businessTraveller/components/offer-credits/BusinessTravellerOfferCreditsTextLink'
import config from 'constants/config'
import getValueOffPercent from 'lib/offer/getValueOffPercent'
import { MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE } from 'constants/content'
import { rem } from 'polished'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import LuxPlusPriceStack from 'luxPlus/components/LuxPlusPriceStack'
import { useOfferPriceWithMember } from 'hooks/Offers/useOfferPrice'
import { checkCanViewLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import { connect } from 'react-redux'
import { unique } from 'lib/array/arrayUtils'
import OfferPriceDetailsRow from 'components/Common/PriceDetails/OfferPriceDetailsRow'

const Pricing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  &.newTile {
    align-items: flex-start;
  }
`

const StyledTextButton = styled(TextButton)`
  margin-top: ${rem(16)};
`

interface MappedProps {
  canViewLuxPlusBenefits: boolean;
}

interface Props extends MappedProps {
  offer: App.Offer | App.OfferSummary;
  className?: string;
  rooms?: Array<App.Occupants>;
  pkg: App.Package;
  pricing?: App.OfferAvailableRate;
  hasDates?: boolean;
  offerUrl: string;
}

function SearchTileFlightOnlyPricing(props: Props) {
  const {
    offer,
    className,
    pkg,
    pricing,
    hasDates,
    rooms,
    offerUrl,
    canViewLuxPlusBenefits,
  } = props

  const defaultAirport = useDefaultAirport()

  const priceMultiplier = rooms?.length || 1
  const extraGuestSurcharges = useMemo(() => getSumExtraGuestsSurcharge(rooms || [], offer, pkg), [pkg, rooms, offer])

  const {
    propertyFees = 0,
    price,
    memberPrice,
    taxesAndFees,
  } = pricing ?? pkg

  const showPriceDetails = taxesAndFees > 0 || propertyFees > 0 || config.agentHub.isEnabled
  const flightPrice = (offer.flightPrices[defaultAirport?.code ?? ''] || 0)
  const hotelPrice = price * priceMultiplier + extraGuestSurcharges + flightPrice
  const showMemberPrice = memberPrice > 0
  const rateOrPkgMemberPrice = pricing?.memberPriceWithSurcharge ?? pkg.memberPrice
  const hotelMemberPrice = showMemberPrice ? rateOrPkgMemberPrice * priceMultiplier + extraGuestSurcharges + flightPrice : 0
  const totalPrice = hotelPrice + propertyFees
  const totalMemberPrice = showMemberPrice ? hotelMemberPrice + propertyFees : 0
  const pkgValueOrMemberValue = canViewLuxPlusBenefits ? pkg.memberValue : pkg.value
  const totalValue = pkgValueOrMemberValue * priceMultiplier + extraGuestSurcharges + flightPrice
  const shouldShowValue = pkg.shouldDisplayValue && pkgValueOrMemberValue > 0 && totalValue >= (showMemberPrice ? totalMemberPrice : totalPrice)

  const valueOffPercent = getValueOffPercent(totalValue, showMemberPrice ? hotelMemberPrice : hotelPrice)

  // https://aussiecommerce.atlassian.net/browse/CONLT-863
  // Only show discount pill for forced bundles
  const shouldShowRate = !offer.forceBundleId && offer.bundledWithFlightsOnly && valueOffPercent >= MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE

  const showIncludedGuestsExceeded = useMemo(() => {
    if (!pkg.roomType || (pkg.roomRate?.extraGuestSurcharges?.length || 0) > 0) {
      return false
    }

    return rooms?.some(room => hasIncludedGuestsExceeded(room, pkg, offer))
  }, [pkg, rooms, offer])

  const {
    total: displayPrice,
    memberTotal: displayMemberPrice,
    saleUnit: displaySaleUnit,
  } = useOfferPriceWithMember({
    value: undefined,
    saleUnit: 'total',
    duration: pkg.duration,
    total: totalPrice,
    memberTotal: totalMemberPrice,
  })

  const offerLocations = useMemo(() => unique(offer.locations.concat(offer.location)),
    [offer.locations, offer.location])
  const vendorName = offer.vendorName

  return (<Pricing className={className}>
    <PriceRowPriceCaption>
      {hasDates && <>
        {pluralizeToString('night', pkg.duration)} + flights
        {!!rooms?.length && `, ${formatOccupantsShort(rooms)}`}
        {' '}from
      </>}
      {!hasDates && offer.tileDurationLabel && `${offer.tileDurationLabel.toLocaleLowerCase()} + flights from`}
      {!hasDates && !offer.tileDurationLabel && 'Hotel + flights from'}
    </PriceRowPriceCaption>
    <LuxPlusPriceStack
      price={displayPrice}
      memberPrice={displayMemberPrice}
      size="L"
      saleUnit={displaySaleUnit}
      testid="search-price"
      offerConfig={offer.luxPlus}
    />
    <PriceRowTaxesAndFees />
    {showIncludedGuestsExceeded && (
      <AdditionalGuestsPopup
        complex={false}
        description="+ Extra guest surcharge"
        modalContent={pkg.roomPolicyDescription}
      />
    )}
    {shouldShowValue && <PriceRowValueDiscountWithCaption
      data-testid="value-box"
      size="M"
      originalValue={totalValue}
      discountPercentage={shouldShowRate ? valueOffPercent : undefined}
    />}
    {config.businessTraveller.currentAccountMode === 'business' && <BusinessTravellerOfferCreditsTextLink
      type="estimate"
      offer={offer}
      totalCost={totalPrice}
      numberOfNights={pkg.duration}
    />}
    <PriceRowAgentHubCommission
      size="L"
      productType={offer.productType}
      offerId={offer.id}
      vendorName={vendorName}
      offerLocations={offerLocations}
    />
    {showPriceDetails && <OfferPriceDetailsRow
      trigger="price-row"
      triggerSize="M"
      offer={offer}
      duration={pkg.duration}
      propertyFees={propertyFees}
      hotelPrice={hotelPrice}
      rooms={rooms}
      extraGuestMessage={showIncludedGuestsExceeded ? pkg.roomPolicyDescription : ''}
      dueAtPropertyMessage={offer.property?.taxesAndFeesContent ?? ''}
      hotelMemberPrice={hotelMemberPrice}
      showMemberPrice={showMemberPrice}
      context="search"
      cta={
        <TextButton kind="primary" fit="flex" to={offerUrl}>
          View Offer
        </TextButton>
      }
    />}
    <StyledTextButton fit="full-width" kind="primary">View offer</StyledTextButton>
  </Pricing>

  )
}

SearchTileFlightOnlyPricing.defaultProps = {
  rooms: [],
}

const mapStateToProps = (state: App.State): MappedProps => ({
  canViewLuxPlusBenefits: checkCanViewLuxPlusBenefits(state),
})

export default connect(mapStateToProps)(SearchTileFlightOnlyPricing)
