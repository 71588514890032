import { PublicOfferV2 } from '@luxuryescapes/contract-public-offer'
import { buildCancellationPolicies, buildPartiallyRefundableCancellationPolicies } from '@luxuryescapes/lib-refunds'

function bedGroupsMap(bedGroup: PublicOfferV2.BedGroup): App.RoomBedGroups {
  return {
    id: bedGroup.id,
    description: bedGroup.description,
    configuration: bedGroup.configuration.map(bedGroupsConfigurationMap),
  }
}

function bedGroupsConfigurationMap(configuration: PublicOfferV2.Configuration): App.BedGroupsConfiguration {
  return {
    type: configuration.type,
    size: configuration.size,
    quantity: configuration.quantity,
  }
}

export default function bedbankRateMap({
  checkIn,
  checkOut,
  timezone,
  rate,
  room,
} : {
  checkIn: string;
  checkOut: string;
  timezone: string;
  rate: PublicOfferV2.BedbankRate;
  room: App.BedbankPackage;
}): App.BedbankRate {
  return {
    bedGroups: rate.bedGroups.map(bedGroupsMap),
    id: rate.id,
    optionId: rate.optionId,
    roomId: room.roomId,
    room,
    refundable: rate.refundable,
    partiallyRefundable: rate.partiallyRefundable,
    isFlightBundle: rate.isFlightBundle,
    mobilePromotion: rate.mobilePromotion,
    groupId: rate.groupId,
    corporate: rate.corporate,
    occupancyPricing: rate.occupancyPricing,
    totals: rate.totals,
    currencyCode: rate.currencyCode,
    regionCode: rate.regionCode,
    facilities: rate.facilities,
    promotions: rate.promotions,
    nights: rate.duration,
    cancellationPolicies: rate.cancellationPolicies,
    nonRefundableDateRanges: rate.nonRefundableDateRanges,
    value: rate.value,
    discount: rate.discount,
    cancellationPolicy: rate.partiallyRefundable ? buildPartiallyRefundableCancellationPolicies({ checkIn, checkOut, policies: rate.cancellationPolicies, nonRefundableDates: rate.nonRefundableDateRanges }, { timezone } as any) : buildCancellationPolicies(rate.cancellationPolicies, { timezone } as any),
    margin: rate.margin,
    marginAud: rate.marginAud,
    supplier: rate.supplier,
  }
}
