import createSelector from 'lib/web/createSelector'
import config from 'constants/config'
import { PAYMENT_OPTIONS } from 'constants/payment'
import { RefundProtectQuoteRequest, RefundProtectQuotePatchRequest } from 'api/insurance'
import { getTotalsWithoutInsurance, getMerchantFeeAmount, checkoutTotalsView } from 'checkout/selectors/payment/checkout'
import { checkoutAccommodationGroupingKey, checkoutAccommodationOfferView, getAccommodationItems } from 'checkout/selectors/view/accommodation'
import moment from 'moment'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { areOccupantsEqual, mergeOccupants, sumUpOccupancies } from 'lib/offer/occupancyUtils'
import { getBookingProtectionEnabledCheckoutItems, getBookingProtectionItems, isBookingProtectionSelected } from '../view/bookingProtection'
import { groupBy, partitionBy, sum, unique, last, take, nonNullable } from 'lib/array/arrayUtils'
import { excludeNullOrUndefined } from 'checkout/utils'
import { getBookingProtectionQuoteKey, isBookingProtectionAvailableForAccommodationOffer } from 'lib/checkout/bookingProtection/utils'
import { getTourV2IdForCheckoutItem, isBedbankItem, isBookingProtectionItem, isFlightItem, isInstantBookingLEHotelItem } from 'lib/checkout/checkoutUtils'
import { adjustForDecimal } from 'lib/maths/mathUtils'
import { getDepositAmount, getServiceFee } from '../payment/deposit'
import { getFlightItems, getFlightItemsView } from '../view/flights'
import { BOOKING_PROTECTION_DEFAULT_MAX_ORDER_VALUE, BOOKING_PROTECTION_MAX_ORDER_CURRENCY_MAP, BOOKING_PROTECTION_RISK_TYPE_MAP, NO_BOOKING_PROTECTION_QUOTE_ID } from 'constants/bookingProtection'
import { getFullOffers } from 'selectors/offerSelectors'
import {
  CHECKOUT_ITEM_TYPE_BEDBANK,
  CHECKOUT_ITEM_TYPE_CRUISE,
  CHECKOUT_ITEM_TYPE_FLIGHT,
  CHECKOUT_ITEM_TYPE_LE_HOTEL,
  CHECKOUT_ITEM_TYPE_TOUR_V1,
  CHECKOUT_ITEM_TYPE_TOUR_V2,
  CHECKOUT_ITEM_TYPE_VILLA,
} from 'constants/checkout'
import { getItineraryCountries } from '../../lib/utils/cruises/itinerary'
import { isValidCountry, isValidRegion } from '../../lib/utils/insurance/payload'
import { findByTwoLetterCode } from 'lib/insurance/insuranceCountries'
import { isSpoofed } from 'selectors/featuresSelectors'
import { getOptimizelyExperimentVariation } from 'lib/optimizely/optimizelyUtils'
import { OptimizelyExperiments, OptimizelyFeatureFlags } from 'constants/optimizely'
import { isEnabledForFeature } from 'lib/config/featureFlagUtils'
import { getExperienceItemsView } from '../view/experience'
import { checkoutWithDiscountedBookingProtection } from '../view/luxPlusSubscription'
import { isStandaloneExperience } from 'selectors/checkoutSelectors'
import { isAndroidAppUserAgent, isIOSAppUserAgent } from 'lib/web/deviceUtils'

const getBookingProtectionOccupants = createSelector(
  getBookingProtectionEnabledCheckoutItems,
  (items): Array<App.Occupants> | undefined => {
    if (items.length > 0) {
      const [accommodationItems, flightItems] = partitionBy(items, (item) => (isInstantBookingLEHotelItem(item) || isBedbankItem(item)))
      const itemMap = groupBy(accommodationItems, checkoutAccommodationGroupingKey)

      const bookingProtectionOccupantsByAccommodationGroup = Array.from(itemMap.values()).map((itemList) => {
        const occupants = itemList
          .map(item => 'occupancy' in item ? item.occupancy : null)
          .filter(excludeNullOrUndefined)
        return mergeOccupants(occupants)
      })

      const bookingProtectionOccupantsByFlight = flightItems.map(item => 'occupants' in item ? item.occupants : null).filter(excludeNullOrUndefined)

      return [
        ...bookingProtectionOccupantsByAccommodationGroup,
        ...bookingProtectionOccupantsByFlight,
      ].flat()
    }
  },
)

const getBookingProtectionExperienceTickets = createSelector(
  getBookingProtectionEnabledCheckoutItems,
  (items): number => {
    const totalCountOfTickets = sum(
      items,
      (item) => {
        if ('tickets' in item) {
          return sum(item.tickets, (ticket) => ticket.count)
        }
        return 0
      },
    )

    return totalCountOfTickets
  },
)

export const areBookingProtectionOccupantsConsistent = createSelector(
  getBookingProtectionOccupants,
  (occupants): boolean => {
    return occupants ? areOccupantsEqual(...occupants) : true // if no occupants, that's consistent
  },
)

export const getBookingProtectionOccupantsFromCartItems = createSelector(
  getBookingProtectionOccupants,
  areBookingProtectionOccupantsConsistent,
  (occupants, isConsistent) => {
    if (isConsistent && occupants) {
      return occupants[0]
    }
  },
)

interface FlightMetadata {
  airline: string,
  departureAirportCode: string,
  departureCountry: string,
  arrivalAirportCode: string,
  eventTravelClass: string,
  flightType: string,
  hasReturnFlight: boolean,
}

export const getBookingProtectionStartDateFromCart = createSelector(
  checkoutAccommodationOfferView,
  getFlightItemsView,
  getExperienceItemsView,
  (accommodationViews, flightViews, experienceViews) => {
    if (!accommodationViews.hasRequiredData && !flightViews.hasRequiredData && !experienceViews.hasRequiredData) { return null }

    const accommodationDates = accommodationViews.data.map(item => item.startDate)

    const flightDates = flightViews.data.map(item => item.flights[0]?.journeyFlight.flights[0].departingDate)

    const experienceDates = experienceViews.data.map(item => item.bookingDate)

    const dates = nonNullable([...accommodationDates, ...flightDates, ...experienceDates])

    if (dates.length) {
      return moment.min(dates.map(date => moment(date, ISO_DATE_FORMAT)))
    }
  },
)

export const getFlightBookingMetadata = createSelector(
  getFlightItemsView,
  (flightViews): FlightMetadata | null => {
    if (!flightViews.hasRequiredData || !flightViews.data?.length) {
      return null
    }

    try {
      const departing = flightViews.data[0].flights[0]
      const departingFlight = departing?.journeyFlight.flights[0]
      const returningFlight = last(flightViews.data[0].flights[1]?.journeyFlight?.flights ?? [])

      const hasReturnFlight = !!returningFlight
      const totalFlightDuration = hasReturnFlight ? moment(returningFlight.arrivalDate).diff(moment(departingFlight?.departingDate), 'days') : null

      if (departingFlight) {
        return {
          airline: departingFlight.carrierName,
          departureAirportCode: departingFlight.departingAirport,
          departureCountry: departingFlight.departingCountry,
          arrivalAirportCode: departingFlight.arrivalAirport,
          eventTravelClass: departing?.fareFamily?.cabin ?? departingFlight.fareClass,
          hasReturnFlight,
          flightType: hasReturnFlight ? `${totalFlightDuration} Days` : 'OneWay',
        }
      }
    } catch {
      return null
    }

    return null
  },
)

const getBookingProtectionCoverAmountFromCart = createSelector(
  getTotalsWithoutInsurance,
  (totals): number | undefined => {
    if (totals.hasRequiredData) {
      const {
        paidPrice = 0,
        price = 0,
        surcharge = 0,
        otherFees = {},
      } = totals.data
      return paidPrice + price + surcharge + sum(Object.values(otherFees))
    }
  },
)

const getBookingProtectionCoverUpdateAmountFromCart = createSelector(
  getTotalsWithoutInsurance,
  (totals): number | undefined => {
    if (totals.hasRequiredData) {
      const {
        price = 0,
        surcharge = 0,
        otherFees = {},
      } = totals.data
      return price + surcharge + sum(Object.values(otherFees))
    }
  },
)

export const mapCheckoutItemTypesToRiskType = createSelector(
  getBookingProtectionEnabledCheckoutItems,
  (items): BOOKING_PROTECTION_RISK_TYPE_MAP | undefined => {
    if (items.length === 0) return undefined
    if (items.length > 1) {
      if (items.every(isInstantBookingLEHotelItem)) {
        return BOOKING_PROTECTION_RISK_TYPE_MAP.HOTEL
      }
      return BOOKING_PROTECTION_RISK_TYPE_MAP.BUNDLED
    }

    const item = items[0]
    if (isFlightItem(item)) {
      return BOOKING_PROTECTION_RISK_TYPE_MAP.FLIGHT
    }
    if (isInstantBookingLEHotelItem(item)) {
      return BOOKING_PROTECTION_RISK_TYPE_MAP.HOTEL
    }
    return undefined
  },
)

export const getDestCountriesFromCart = createSelector(
  getFullOffers,
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => state.geo.airports,
  (offers, items, airports) => {
    return unique(items.map(item => {
      if (item.itemType === CHECKOUT_ITEM_TYPE_BEDBANK) {
        const offer = offers[item.offerId] as App.BedbankOffer
        return offer ? [offer.property.address.countryName] : []
      } else if (item.itemType === CHECKOUT_ITEM_TYPE_LE_HOTEL) {
        const offer = offers[item.offerId] as App.Offer
        return offer ? (offer.insuranceCountries?.length ? offer.insuranceCountries : [offer.property?.geoData.country]) : []
      } else if (item.itemType === CHECKOUT_ITEM_TYPE_VILLA) {
        const offer = offers[item.offerId] as App.VillaOffer
        return offer ? (offer.insuranceCountries?.length ? offer.insuranceCountries : [offer.property?.geoData.country]) : []
      } else if (item.itemType === CHECKOUT_ITEM_TYPE_TOUR_V2) {
        const offer = offers[getTourV2IdForCheckoutItem(item)] as Tours.TourV2Offer
        const departure = offer?.departures[item.purchasableOption.fkDepartureId]
        return departure?.isGuaranteed ? offer.variations[departure.fkVariationId].countriesVisited : []
      } else if (item.itemType === CHECKOUT_ITEM_TYPE_TOUR_V1) {
        const offer = offers[item.offerId] as App.TourOffer
        if (offer) {
          return offer.locations.length > 0 ? offer.locations : [offer.locationHeading]
        }
      } else if (item.itemType === CHECKOUT_ITEM_TYPE_CRUISE) {
        const offer = offers[item.offerId] as App.CruiseOffer
        if (offer) {
          return getItineraryCountries(offer)
        }
      } else if (item.itemType === CHECKOUT_ITEM_TYPE_FLIGHT) {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        const flightItem = item as App.Checkout.FlightItem
        const airport = airports.find(airport => airport.code === flightItem.destinationAirportCode)
        return airport?.countryCode ? [findByTwoLetterCode(airport?.countryCode)] : []
      }
    }).flat().filter(excludeNullOrUndefined).filter(isValidCountry))
  },
)

export const getBookingProtectionQuoteRequest = createSelector(
  getBookingProtectionOccupantsFromCartItems,
  getBookingProtectionCoverAmountFromCart,
  getBookingProtectionStartDateFromCart,
  mapCheckoutItemTypesToRiskType,
  getDestCountriesFromCart,
  getFlightBookingMetadata,
  (state: App.State) => state.checkout.cart.currencyCode,
  isSpoofed,
  (state: App.State) => isStandaloneExperience(state),
  getBookingProtectionExperienceTickets,
  (state: App.State) => getExperienceItemsView(state).data,
  (state: App.State) => isIOSAppUserAgent(state.config.userAgent.rawUserAgentString) || isAndroidAppUserAgent(state.config.userAgent.rawUserAgentString),
  (
    occupants,
    coverAmount,
    startDate,
    riskType,
    getDestinationCountries,
    flightMetadata,
    currencyCode,
    isSpoofing,
    isStandaloneExperience,
    numberOfExperienceTickets,
    experienceView,
    isMobileApp,
  ): RefundProtectQuoteRequest | null => {
    let numberOfTickets
    let venue

    if ((!occupants && !numberOfExperienceTickets) || !coverAmount || !startDate) return null
    if (isStandaloneExperience) {
      numberOfTickets = numberOfExperienceTickets
      venue = experienceView[0]?.redemptionLocation?.name || ''
    } else {
      numberOfTickets = occupants?.adults ?? 0 + (occupants?.children ?? 0) + (occupants?.infants ?? 0)
      venue = take(getDestinationCountries, 10).join(',')
    }

    return {
      brand: 'luxuryescapes',
      currencyCode,
      eventTravelDateTime: startDate.format('YYYY-MM-DDTHH:mm:ss.SSSSSSZZ'),
      totalValue: coverAmount,
      numberOfTickets,
      // limiting the list countries to 10 to avoid sending large payloads to an external API
      venue,
      source: isSpoofing ? 'Mobile' : 'Web',
      riskType,
      ...(flightMetadata && {
        airline: flightMetadata.airline,
        departureAirportCode: flightMetadata.departureAirportCode,
        departureCountry: flightMetadata.departureCountry,
        arrivalAirportCode: flightMetadata.arrivalAirportCode,
        eventTravelClass: flightMetadata.eventTravelClass,
        flightType: flightMetadata.flightType,
      }),
      mobile_only_price: isMobileApp,
    }
  },
)

export const getBookingProtectionQuoteRequestKey = createSelector(
  getBookingProtectionQuoteRequest,
  (request) => getBookingProtectionQuoteKey(request),
)

export const getBookingProtectionQuoteUpdateRequest = createSelector(
  getBookingProtectionCoverUpdateAmountFromCart,
  getBookingProtectionStartDateFromCart,
  mapCheckoutItemTypesToRiskType,
  getDestCountriesFromCart,
  (state: App.State) => state.checkout.cart.currencyCode,
  (state: App.State) => state.checkout.cart.existingOrder?.bookingProtectionItems,
  isSpoofed,
  (coverAmount, startDate, riskType, getDestinationCountries, currencyCode, bookingProtectionItems, isSpoofing): RefundProtectQuotePatchRequest | null => {
    const item = bookingProtectionItems?.find(item => item.status === 'completed')
    if (!coverAmount || !startDate || !item) return null

    return {
      brand: 'luxuryescapes',
      currencyCode,
      eventTravelDateTime: startDate.format('YYYY-MM-DDTHH:mm:ss.SSSSSSZZ'),
      totalValue: coverAmount + item.coverAmount,
      quoteId: item.quoteId,
      numberOfTickets: item.numberOfTickets,
      venue: take(getDestinationCountries, 10).join(','),
      source: isSpoofing ? 'phone' : 'web',
      riskType,
    }
  },
)

export const getBookingProtectionQuoteUpdateRequestKey = createSelector(
  getBookingProtectionQuoteUpdateRequest,
  (request) => getBookingProtectionQuoteKey(request),
)

export const getUpdatedQuoteFromBookingProtectionQuotes = createSelector(
  (state: App.State) => state.bookingProtection.quotes,
  getBookingProtectionQuoteUpdateRequestKey,
  (quotes, quoteKey): App.BookingProtectionQuote | undefined => {
    if (!quoteKey || !quotes) return undefined
    return quotes[quoteKey]
  },
)

export const getQuoteFromBookingProtectionQuotes = createSelector(
  (state: App.State) => state.bookingProtection.quotes,
  getBookingProtectionQuoteRequestKey,
  (quotes, quoteKey): App.BookingProtectionQuote | undefined => {
    if (!quoteKey || !quotes) return undefined
    return quotes[quoteKey]
  },
)

export const getBookingProtectionQuoteMobileAppOnlyPrice = createSelector(
  getQuoteFromBookingProtectionQuotes,
  (quote): number => {
    if (!quote) return 0
    return quote.mobileAppOnlyPrice
  },
)

export const getBookingProtectionQuotePrice = createSelector(
  getQuoteFromBookingProtectionQuotes,
  (quote): number => {
    if (!quote) return 0
    return quote.price
  },
)

export const getBookingProtectionQuoteLuxPlusPrice = createSelector(
  getQuoteFromBookingProtectionQuotes,
  (quote): number => {
    if (!quote) return 0
    return quote.luxPlusPrice || 0
  },
)

export const getExistingBookingProtection = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder?.bookingProtectionItems,
  (items) => {
    return items?.find(item => item.status === 'completed')
  },
)

export const getUpdatedBookingProtectionQuotePriceDifference = createSelector(
  getUpdatedQuoteFromBookingProtectionQuotes,
  checkoutWithDiscountedBookingProtection,
  (state: App.State) => state.checkout.cart.existingOrder?.bookingProtectionItems,
  (quote, checkoutWithDiscountedBookingProtection, existingBookingProtectItems): number => {
    if (!quote) return 0
    const oldQuotePrice = existingBookingProtectItems?.find(bookingProtectItem => bookingProtectItem.status === 'completed')?.total || 0
    const newQuotePrice = checkoutWithDiscountedBookingProtection ? (quote.luxPlusPrice ?? 0) : quote.price
    const quotePriceDiff = Math.round((newQuotePrice - oldQuotePrice + Number.EPSILON) * 100) / 100
    // For now, we don't want to refund when negative difference
    return quotePriceDiff < 0 ? 0 : quotePriceDiff
  },
)

export const getUpdatedBookingProtectionQuotePrice = createSelector(
  getUpdatedQuoteFromBookingProtectionQuotes,
  (quote): number => {
    if (!quote) return 0
    return quote.price
  },
)

export const getUpdatedBookingProtectionQuoteLuxPlusPrice = createSelector(
  getUpdatedQuoteFromBookingProtectionQuotes,
  (quote): number => {
    if (!quote) return 0
    return quote.luxPlusPrice || 0
  },
)

export const getBookingProtectionQuotePricePerTraveller = createSelector(
  getBookingProtectionQuotePrice,
  getBookingProtectionOccupantsFromCartItems,
  (price, occupants): number => {
    if (!price || !occupants) return 0
    const numTravellers = sumUpOccupancies([occupants])
    return adjustForDecimal(price / numTravellers)
  },
)

export const getBookingProtectionQuoteLuxPlusPricePerTraveller = createSelector(
  getBookingProtectionQuoteLuxPlusPrice,
  getBookingProtectionOccupantsFromCartItems,
  (luxPlusPrice, occupants): number => {
    if (!luxPlusPrice || !occupants) return 0
    const numTravellers = sumUpOccupancies([occupants])
    return adjustForDecimal(luxPlusPrice / numTravellers)
  },
)

export const getBookingProtectionQuoteMobileOnlyPricePerTraveller = createSelector(
  getBookingProtectionQuoteMobileAppOnlyPrice,
  getBookingProtectionOccupantsFromCartItems,
  (mobileOnlyPrice, occupants): number => {
    if (!mobileOnlyPrice || !occupants) return 0
    const numTravellers = sumUpOccupancies([occupants])
    return adjustForDecimal(mobileOnlyPrice / numTravellers)
  },
)

export const getBookingProtectionDetails = createSelector(
  (state: App.State) => state.checkout.payment.paymentSelected,
  (state: App.State) => state.checkout.cart.currencyCode,
  (state: App.State) => state.checkout.form.travellerForms,
  getBookingProtectionQuotePrice,
  getBookingProtectionQuoteMobileAppOnlyPrice,
  getBookingProtectionQuoteLuxPlusPrice,
  getBookingProtectionCoverAmountFromCart,
  getBookingProtectionStartDateFromCart,
  getBookingProtectionOccupantsFromCartItems,
  getQuoteFromBookingProtectionQuotes,
  getDepositAmount,
  getServiceFee,
  (state: App.State) => getMerchantFeeAmount(state, state.checkout.payment.paymentSelected),
  (state: App.State) => state.checkout.orderFailure,
  checkoutWithDiscountedBookingProtection,
  (state: App.State) => isStandaloneExperience(state),
  getBookingProtectionExperienceTickets,
  (
    paymentSelected,
    currencyCode,
    travellers,
    quotePrice,
    quoteMobilePrice,
    quoteLuxPlusPrice,
    coverAmount,
    startDate,
    occupants,
    quote,
    depositAmount,
    serviceFee,
    merchantFee,
    orderFailure,
    checkoutWithDiscountedBookingProtection,
    isStandaloneExperience,
    numberOfExperienceTickets,
  ): App.Checkout.BookingProtectionDetails | null => {
    const eventTravelDateTime = startDate?.format('YYYY-MM-DDTHH:mm:ss.SSSSSSZZ')
    let numberOfTickets
    if (!quote || !quotePrice || !coverAmount || !eventTravelDateTime) return null

    const { quoteId } = quote

    const defaultOrMobilePrice = quoteMobilePrice > 0 ? quoteMobilePrice : quotePrice
    const totalValue = checkoutWithDiscountedBookingProtection ? quoteLuxPlusPrice : defaultOrMobilePrice

    if (isStandaloneExperience) {
      numberOfTickets = numberOfExperienceTickets
    } else {
      numberOfTickets = occupants?.adults ?? 0 + (occupants?.children ?? 0) + (occupants?.infants ?? 0)
    }

    return {
      quote_id: quoteId,
      total_value: totalValue,
      total_value_lux_plus: quoteLuxPlusPrice,
      total_value_public: quotePrice,
      total_value_mobile: quoteMobilePrice,
      order_value: coverAmount,
      brand: config.BRAND,
      is_paid_in_full: paymentSelected === PAYMENT_OPTIONS.FULL,
      initial_payment_value: paymentSelected === PAYMENT_OPTIONS.DEPOSIT ? adjustForDecimal(depositAmount - totalValue - serviceFee - merchantFee) : undefined,
      currency_code: currencyCode,
      number_of_tickets: numberOfTickets,
      event_travel_date_time: eventTravelDateTime,
      traveller_details: travellers.map(
        traveller => ({
          first_name: traveller.firstName,
          last_name: traveller.lastName,
        })),
      quote: {
        products: quote.products,
      },
      previousAttemptFailure: orderFailure,
    }
  },
)

export const getUpdatedBookingProtectionDetails = createSelector(
  getUpdatedBookingProtectionQuotePrice,
  getUpdatedBookingProtectionQuoteLuxPlusPrice,
  getUpdatedBookingProtectionQuotePriceDifference,
  getBookingProtectionCoverAmountFromCart,
  getUpdatedQuoteFromBookingProtectionQuotes,
  checkoutWithDiscountedBookingProtection,
  getBookingProtectionItems,
  (
    quotePrice,
    quoteLuxPlusPrice,
    quotePriceDifference,
    coverAmount,
    quote,
    checkoutWithDiscountedBookingProtection,
    bookingProtectionItems,
  ): App.Checkout.UpdatedBookingProtectionDetails | null => {
    const noneSelected = bookingProtectionItems.every(item => item.quoteId === NO_BOOKING_PROTECTION_QUOTE_ID)
    if (noneSelected || !quote || !quotePrice || !coverAmount) return null

    const { quoteId, products } = quote

    return {
      quoteId,
      total: checkoutWithDiscountedBookingProtection ? quoteLuxPlusPrice : quotePrice,
      totalLuxPlus: quoteLuxPlusPrice,
      publicTotal: quotePrice,
      orderValue: coverAmount,
      brand: config.BRAND,
      quotePriceDiff: quotePriceDifference,
      quote: {
        products,
      },
    }
  },
)

export const getTravelDatesFromCart = createSelector(
  checkoutAccommodationOfferView,
  getFlightItemsView,
  getExperienceItemsView,
  (accommodationViews, flightViews, experienceViews) => {
    if ((!accommodationViews.hasRequiredData || !flightViews.hasRequiredData) && !experienceViews.hasRequiredData) { return null }

    const accommodationDates = accommodationViews.data.map(item => ({
      startDate: item.startDate,
      endDate: item.endDate,
    }))

    const flights = flightViews.data.map(flightView => [
      ...flightView.flights[0]?.journeyFlight.flights || [],
      ...flightView.flights[1]?.journeyFlight.flights || [],
    ]).flat()

    const flightDates = flights.map(flight => ({
      startDate: flight.departingDate,
      endDate: flight.arrivalDate,
    }))

    const experienceDates = experienceViews.data.map(experienceView => ({
      startDate: experienceView.bookingDate,
      endDate: experienceView.bookingDate,
    }))

    const dates = [...accommodationDates, ...flightDates, ...experienceDates]

    if (dates.length) {
      return {
        startDate: moment.min(dates.map(date => moment(date.startDate, ISO_DATE_FORMAT))),
        endDate: moment.max(dates.map(date => moment(date.endDate, ISO_DATE_FORMAT))),
      }
    }
  },
)

const isDomesticTrip = createSelector(
  (state: App.State) => state.geo.currentRegionName,
  getDestCountriesFromCart,
  (regionName, destCountriesFromCart): boolean => {
    return destCountriesFromCart.every(countryName => countryName === regionName)
  },
)

const cartOnlyHasFlightsOrBookingProtection = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): boolean => items.length > 0 && items.every(item => isFlightItem(item) || isBookingProtectionItem(item)),
)

const isBookingProtectionAvailableForCartItems = createSelector(
  getAccommodationItems,
  getFlightItems,
  (state: App.State) => state.offer.offers,
  isStandaloneExperience,
  (state: App.State) => state.optimizely.optimizelyFeatureFlags[OptimizelyFeatureFlags.rpForExperienceEnabled],
  (accommodationItems, flightItems, offers, isStandaloneExperience, refundProtectForExperienceEnabled) => {
    const items = [...accommodationItems, ...flightItems]
    for (const item of items) {
      if (!isEnabledForFeature(config.BOOKING_PROTECTION_ENABLED_LIST, item.itemType)) {
        return false
      }

      if (item.itemType === CHECKOUT_ITEM_TYPE_LE_HOTEL) {
        const offer = offers[item.offerId]
        if (offer && !isBookingProtectionAvailableForAccommodationOffer(offer)) {
          return false
        }
      }
    }

    if (isStandaloneExperience && !refundProtectForExperienceEnabled) {
      return false
    }

    return true
  },
)

export const isBookingProtectionAvailable = createSelector(
  getTravelDatesFromCart,
  checkoutTotalsView,
  (state: App.State) => isBookingProtectionSelected(state),
  (state: App.State) => state.checkout.cart.postPurchase,
  (state: App.State) => state.geo.currentCurrency,
  isSpoofed,
  isBookingProtectionAvailableForCartItems,
  (travelDates, checkoutTotalsView, bookingProtectionSelected, postPurchase, currency, isSpoofed, isBookingProtectionAvailableForCartItems) => {
    // If booking protection is already selected, return true
    if (bookingProtectionSelected) return true
    if (postPurchase) return false
    if (!travelDates?.startDate && !isSpoofed) return false
    if (checkoutTotalsView.data.subTotal > (BOOKING_PROTECTION_MAX_ORDER_CURRENCY_MAP[currency] || BOOKING_PROTECTION_DEFAULT_MAX_ORDER_VALUE)) return false
    // This applies to Flash, LPC, LPP, Experiences, Flights and Bundled
    if (!isBookingProtectionAvailableForCartItems) return false
    return true
  },
)

export const isBookingProtectionEnabled = createSelector(
  isBookingProtectionAvailable,
  isDomesticTrip,
  cartOnlyHasFlightsOrBookingProtection,
  (state: App.State) => state.geo.currentRegionCode,
  isSpoofed,
  (state: App.State) => !!getOptimizelyExperimentVariation(state, OptimizelyExperiments.refundProtectForInternationalEnabled),
  (state: App.State) => state.checkout.cart.postPurchase,
  (isBookingProtectionAvailable, isADomesticTrip, cartOnlyHasFlightsOrBookingProtection, currentRegionCode, isSpoofed, bookingProtectionInternationABTest, postPurchase): boolean => {
    if (postPurchase === 'insurance') return false
    if (!config.BOOKING_PROTECTION_ENABLED) return false
    if (!isBookingProtectionAvailable) return false
    // Booking Protection is available on all standalone flights
    if (cartOnlyHasFlightsOrBookingProtection) return true
    // Booking protection is not available for international trips from AU region when not spoofed and not in ON variant of AB test
    if (!isADomesticTrip && !isSpoofed && currentRegionCode === 'AU' && !bookingProtectionInternationABTest) return false
    // Booking protection is not available for all trips within regions where Insurance is enabled except AU
    if (isValidRegion(currentRegionCode) && currentRegionCode !== 'AU') return false
    return true
  },
)
