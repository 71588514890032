import React, { forwardRef, HTMLAttributes, PropsWithChildren, useMemo } from 'react'
import cn from 'clsx'
import styled from 'styled-components'
import { mediaQueryDown, mediaQueryOnly, mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GROUP_GAPS = [0, 2, 4, 8, 12, 16, 20, 24, 32, 40, 48, 56, 72, 80] as const
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GROUP_V_ALIGNS = ['start', 'center', 'stretch', 'end', 'space-between', 'space-evenly', 'baseline'] as const
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GROUP_H_ALIGNS = ['start', 'center', 'stretch', 'end', 'space-between', 'space-evenly'] as const

const V_GAP_CSS_VAR = '--group-v-gap'
const H_GAP_CSS_VAR = '--group-h-gap'
const V_GAP_TABLET_CSS_VAR = '--group-v-gap-tablet'
const H_GAP_TABLET_CSS_VAR = '--group-h-gap-tablet'
const V_GAP_DESKTOP_CSS_VAR = '--group-v-gap-desktop'
const H_GAP_DESKTOP_CSS_VAR = '--group-h-gap-desktop'

const V_ALIGN_CSS_VAR = '--group-v-align'
const H_ALIGN_CSS_VAR = '--group-h-align'
const V_ALIGN_TABLET_CSS_VAR = '--group-v-align-tablet'
const H_ALIGN_TABLET_CSS_VAR = '--group-h-align-tablet'
const V_ALIGN_DESKTOP_CSS_VAR = '--group-v-align-desktop'
const H_ALIGN_DESKTOP_CSS_VAR = '--group-h-align-desktop'

const GroupContainer = styled.div`
  display: flex;
  row-gap: var(${V_GAP_CSS_VAR});
  column-gap: var(${H_GAP_CSS_VAR});

  ${mediaQueryUp.tablet} {
    row-gap: var(${V_GAP_TABLET_CSS_VAR});
    column-gap: var(${H_GAP_TABLET_CSS_VAR});
  }

  ${mediaQueryUp.desktop} {
    row-gap: var(${V_GAP_DESKTOP_CSS_VAR});
    column-gap: var(${H_GAP_DESKTOP_CSS_VAR});
  }

  &.dir-horizontal {
    flex-direction: row;
  }
  &.dir-horizontal-reverse {
    flex-direction: row-reverse;
  }
  &.dir-vertical {
    flex-direction: column;
  }
  &.dir-vertical-reverse {
    flex-direction: column-reverse;
  }
  &.dir-horizontal, &.dir-horizontal-reverse {
    align-items: var(${V_ALIGN_CSS_VAR});
    justify-content: var(${H_ALIGN_CSS_VAR});
  }
  &.dir-vertical, &.dir-vertical-reverse {
    align-items: var(${H_ALIGN_CSS_VAR});
    justify-content: var(${V_ALIGN_CSS_VAR});
  }
  ${mediaQueryUp.tablet} {
    &.dir-tablet-horizontal {
      flex-direction: row;
    }
    &.dir-tablet-horizontal-reverse {
      flex-direction: row-reverse;
    }
    &.dir-tablet-vertical {
      flex-direction: column;
    }
    &.dir-tablet-vertical-reverse {
      flex-direction: column-reverse;
    }
    &.dir-tablet-horizontal, &.dir-tablet-horizontal-reverse {
      align-items: var(${V_ALIGN_TABLET_CSS_VAR});
      justify-content: var(${H_ALIGN_TABLET_CSS_VAR});
    }
    &.dir-tablet-vertical, &.dir-tablet-vertical-reverse {
      align-items: var(${H_ALIGN_TABLET_CSS_VAR});
      justify-content: var(${V_ALIGN_TABLET_CSS_VAR});
    }
  }
  ${mediaQueryUp.desktop} {
    &.dir-desktop-horizontal {
      flex-direction: row;
    }
    &.dir-desktop-horizontal-reverse {
      flex-direction: row-reverse;
    }
    &.dir-desktop-vertical {
      flex-direction: column;
    }
    &.dir-desktop-vertical-reverse {
      flex-direction: column-reverse;
    }
    &.dir-desktop-horizontal, &.dir-desktop-horizontal-reverse {
      align-items: var(${V_ALIGN_DESKTOP_CSS_VAR});
      justify-content: var(${H_ALIGN_DESKTOP_CSS_VAR});
    }
    &.dir-desktop-vertical, &.dir-desktop-vertical-reverse {
      align-items: var(${H_ALIGN_DESKTOP_CSS_VAR});
      justify-content: var(${V_ALIGN_DESKTOP_CSS_VAR});
    }
  }

  &.wrap {
    &-nowrap { flex-wrap: nowrap; }
    &-wrap { flex-wrap: wrap; }
    &-wrap-reverse { flex-wrap: wrap-reverse; }
  }
  &.wrap-tablet {
    ${mediaQueryUp.tablet} {
      &-nowrap { flex-wrap: nowrap; }
      &-wrap { flex-wrap: wrap; }
      &-wrap-reverse { flex-wrap: wrap-reverse; }
    }
  }
  &.wrap-desktop {
    ${mediaQueryUp.desktop} {
      &-nowrap { flex-wrap: nowrap; }
      &-wrap { flex-wrap: wrap; }
      &-wrap-reverse { flex-wrap: wrap-reverse; }
    }
  }

  &.full-width {
    width: 100%;
  }

  &.full-height {
    height: 100%;
  }

  &.dir-horizontal.stretch-children-mobile,
  &.dir-horizontal-reverse.stretch-children-mobile {
    ${mediaQueryDown.mobile} {
      > * {
        flex: 1;
      }
    }
  }
  &.dir-tablet-horizontal-reverse.stretch-children-tablet,
  &.dir-tablet-horizontal.stretch-children-tablet {
    ${mediaQueryOnly.tablet} {
      > * {
        flex: 1;
      }
    }
  }
  &&.dir-desktop-horizontal.stretch-children-desktop,
  &&.dir-desktop-horizontal-reverse.stretch-children-desktop {
    ${mediaQueryUp.desktop} {
      > * {
        flex: 1;
      }
    }
  }

  &.no-display-when-empty:empty {
    display: none;
  }
`

type SingleGap = typeof GROUP_GAPS[number]
type GroupGap = SingleGap | `${SingleGap} ${SingleGap}`

function parseGroupGaps(gap: GroupGap | undefined): [
  parsedVGap: number | undefined,
  parsedHGap: number | undefined,
] {
  if (gap === undefined) {
    return [undefined, undefined]
  }
  if (typeof gap === 'string') {
    const [extractedVGap, extractedHGap] = gap.split(' ')

    return [
      Number(extractedVGap),
      Number(extractedHGap),
    ]
  }

  return [
    gap,
    gap,
  ]
}

function generateResponsiveClassNames(
  baseClassName: string,
  values: [baseValue: string, tabletValue: string | undefined, desktopValue: string | undefined],
) {
  const [baseValue, tabletValue, desktopValue] = values
  const tablet = tabletValue ?? baseValue
  const desktop = desktopValue ?? tablet

  return [
    `${baseClassName}-${baseValue}`,
    tablet ? `${baseClassName}-tablet-${tablet}` : undefined,
    desktop ? `${baseClassName}-desktop-${desktop}` : undefined,
  ].filter(Boolean)
}

type GroupWrap = 'nowrap' | 'wrap' | 'wrap-reverse'

interface Props<Element extends HTMLElement> extends HTMLAttributes<Element> {
  className?: string
  direction: 'horizontal' | 'vertical' | 'horizontal-reverse' | 'vertical-reverse'
  /**
   * apply spacing to children
   * @example
   * as a number `gap={8}` sets both vertical and horizontal spacing to 8
   * as a string ("vertical horizontal") `gap="8 16"` sets vertical spacing to 8 and horizontal spacing to 16
   * */
  gap?: GroupGap
  tabletDirection?: 'horizontal' | 'vertical' | 'horizontal-reverse' | 'vertical-reverse'
  desktopDirection?: 'horizontal' | 'vertical' | 'horizontal-reverse' | 'vertical-reverse'
  /** similar to `gap`, applies spacing to children on breakpoint `tablet` and up */
  tabletGap?: GroupGap
  /** similar to `gap`, applies spacing to children on breakpoint `desktop` and up */
  desktopGap?: GroupGap
  horizontalAlign?: typeof GROUP_H_ALIGNS[number]
  tabletHorizontalAlign?: typeof GROUP_H_ALIGNS[number]
  desktopHorizontalAlign?: typeof GROUP_H_ALIGNS[number]
  verticalAlign?: typeof GROUP_V_ALIGNS[number]
  tabletVerticalAlign?: typeof GROUP_V_ALIGNS[number]
  desktopVerticalAlign?: typeof GROUP_V_ALIGNS[number]
  wrap?: GroupWrap
  tabletWrap?: GroupWrap
  desktopWrap?: GroupWrap
  fullWidth?: boolean
  fullHeight?: boolean
  /** set display to none when no child is present */
  noDisplayWhenEmpty?: boolean
  as?: React.ElementType | keyof JSX.IntrinsicElements;
}

const Group = forwardRef<HTMLDivElement, PropsWithChildren<Props<HTMLDivElement>>>((props, ref) => {
  const {
    children,
    className,
    direction,
    tabletDirection,
    desktopDirection,
    gap,
    tabletGap,
    desktopGap,
    horizontalAlign,
    tabletHorizontalAlign,
    desktopHorizontalAlign,
    verticalAlign,
    tabletVerticalAlign,
    desktopVerticalAlign,
    wrap,
    tabletWrap,
    desktopWrap,
    fullWidth,
    fullHeight,
    noDisplayWhenEmpty,
    as,
    style,
    ...rest
  } = props

  const {
    parsedHGap,
    parsedTabletHGap,
    parsedDesktopHGap,
    parsedVGap,
    parsedTabletVGap,
    parsedDesktopVGap,
  } = useMemo(() => {
    const [parsedVGap, parsedHGap] = parseGroupGaps(gap)
    const [parsedTabletVGap, parsedTabletHGap] = parseGroupGaps(tabletGap)
    const [parsedDesktopVGap, parsedDesktopHGap] = parseGroupGaps(desktopGap)

    return {
      parsedHGap: rem(parsedHGap ?? 0),
      parsedTabletHGap: rem(parsedTabletHGap ?? parsedHGap ?? 0),
      parsedDesktopHGap: rem(parsedDesktopHGap ?? parsedTabletHGap ?? parsedHGap ?? 0),
      parsedVGap: rem(parsedVGap ?? 0),
      parsedTabletVGap: rem(parsedTabletVGap ?? parsedVGap ?? 0),
      parsedDesktopVGap: rem(parsedDesktopVGap ?? parsedTabletVGap ?? parsedVGap ?? 0),
    }
  }, [gap, tabletGap, desktopGap])
  const directionClassNames = useMemo(() => generateResponsiveClassNames('dir', [direction, tabletDirection, desktopDirection]), [desktopDirection, direction, tabletDirection])
  const stretchChildrenClassNames = useMemo<Array<string> | undefined>(() => {
    const classNames = new Set<string>()

    if (horizontalAlign === 'stretch') {
      classNames.add('stretch-children-mobile')
    }

    if (tabletHorizontalAlign === 'stretch' || (!tabletHorizontalAlign && classNames.has('stretch-children-mobile'))) {
      classNames.add('stretch-children-tablet')
    }

    if (desktopHorizontalAlign === 'stretch' || (!desktopHorizontalAlign && classNames.has('stretch-children-tablet'))) {
      classNames.add('stretch-children-desktop')
    }

    return Array.from(classNames)
  }, [horizontalAlign, tabletHorizontalAlign, desktopHorizontalAlign])

  return <GroupContainer
    {...rest}
    ref={ref}
    as={as}
    className={cn(
      className,
      directionClassNames,
      stretchChildrenClassNames,
      wrap ? `wrap-${wrap}` : undefined,
      tabletWrap ? `wrap-tablet-${tabletWrap}` : undefined,
      desktopWrap ? `wrap-desktop-${desktopWrap}` : undefined,
      {
        'full-width': fullWidth,
        'full-height': fullHeight,
        'no-display-when-empty': noDisplayWhenEmpty,
      },
    )}
    style={{
      ...style,
      [V_GAP_CSS_VAR]: parsedVGap,
      [H_GAP_CSS_VAR]: parsedHGap,
      [V_GAP_TABLET_CSS_VAR]: parsedTabletVGap,
      [H_GAP_TABLET_CSS_VAR]: parsedTabletHGap,
      [V_GAP_DESKTOP_CSS_VAR]: parsedDesktopVGap,
      [H_GAP_DESKTOP_CSS_VAR]: parsedDesktopHGap,
      [V_ALIGN_CSS_VAR]: verticalAlign ?? 'normal',
      [H_ALIGN_CSS_VAR]: horizontalAlign ?? 'normal',
      [V_ALIGN_TABLET_CSS_VAR]: tabletVerticalAlign ?? verticalAlign ?? 'normal',
      [H_ALIGN_TABLET_CSS_VAR]: tabletHorizontalAlign ?? horizontalAlign ?? 'normal',
      [V_ALIGN_DESKTOP_CSS_VAR]: desktopVerticalAlign ?? tabletVerticalAlign ?? verticalAlign ?? 'normal',
      [H_ALIGN_DESKTOP_CSS_VAR]: desktopHorizontalAlign ?? tabletHorizontalAlign ?? horizontalAlign ?? 'normal',
    }}
  >
    {children}
  </GroupContainer>
})

Group.displayName = 'Group'

export default Group
