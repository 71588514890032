import { sortBy, uniqueBy } from '../array/arrayUtils'

// Score: 3 - Amazing to show
// Score: 2 - Nice to Have - should be shown
// Score: 1 - Meh, default
const amenityScores: Map<string, number> = new Map([
  ['Private Pool', 4],
  ['Fully equipped kitchen', 3],
  ['Air Conditioning', 3],
  ['Wifi', 3],
  ['Garden View', 3],
  ['Pool View', 2],
  ['Breakfast Bar', 2],
  ['Valet Parking Service', 3],
  ['Free Onsite Private Parking', 3],
  ['Free Onsite Public Parking', 2],
  ['Golf Course View', 2],
  ['Heat', 1],
  ['Heated Floors', 1],
])

// map similar named amenities to a score
function getAmenityNameNormalised(amenity: App.PackageRoomAmenity): string {
  if (amenity.name.toLowerCase().includes('pool')) {
    return 'Private Pool'
  }

  if (amenity.name.toLowerCase().includes('parking')) {
    return 'Free Onsite Public Parking'
  }

  if (amenity.name.toLowerCase().includes('heat')) {
    return 'Heat'
  }

  if (amenity.name.toLowerCase().includes('wi-fi')) {
    return 'Wifi'
  }

  return amenity.name
}

function getNormalisedGroup(amenity: App.PackageRoomAmenity) {
  if (amenity.group.toLowerCase().includes('pool')) {
    return 'pool'
  }

  if (
    amenity.group.toLowerCase().includes('parking') ||
    amenity.name.toLowerCase().includes('parking')
  ) {
    return 'parking'
  }

  if (
    amenity.group.toLowerCase().includes('heated') ||
    amenity.name.toLowerCase().includes('heat')
  ) {
    return 'heat'
  }

  if (amenity.group.toLowerCase().includes('general')) {
    return amenity.name
  }

  return amenity.group.toLowerCase()
}

function getOrderedAmenities(
  amenities: Array<App.PackageRoomAmenity>,
): Array<App.PackageRoomAmenity> {
  const sortedAmenities = sortBy(amenities, (amenity) => amenityScores.get(getAmenityNameNormalised(amenity)) ?? 1, 'desc')

  return uniqueBy(sortedAmenities, amenity => getNormalisedGroup(amenity))
}

export function getOrderedAmenitiesFromPackage(villaPackage: App.Package | undefined) :
  { amenities: Array<App.PackageRoomAmenity>, orderedAmenities: Array<App.PackageRoomAmenity> }
{
  const amenities = villaPackage?.roomType?.amenities ?? []
  const orderedAmenities = getOrderedAmenities(amenities)
  return {
    amenities,
    orderedAmenities,
  }
}

export function mapHotelPackageToTileInclusions(roomPackages: Array<App.HotelPackage>): Array<App.TileInclusion> {
  const allAmenities = new Set<App.PackageRoomAmenity>()

  for (const roomPackage of roomPackages) {
    const { amenities } = roomPackage.roomType
    for (const amenity of amenities) {
      allAmenities.add(amenity)
    }
  }

  const orderedAmenities = getOrderedAmenities(Array.from(allAmenities))

  return orderedAmenities.map(amenity => ({
    text: amenity.name,
    isHighlighted: false,
    icon: amenity.name,
  }))
}
