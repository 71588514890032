import { shortDateFormat } from 'lib/datetime/dateUtils'

export function splitStringToArray(separator: string, str?: string): Array<string> {
  return (str ?? '').split(separator).map((item) => item.trim()).filter(Boolean)
}

function splitHighLevelDescription(locations: Array<string>) {
  return locations
    .flatMap((name) => splitStringToArray(', ', name?.replace(/[^a-zA-Z0-9\s]/g, ', ')))
}

interface Props {
  offers: Array<App.Offer>,
  limit?: number,
  cruiseLineNames: Array<string>,
  cabinTypes: Array<string>,
  cruiseShips: Array<string>,
  departureName: string,
  destinationName: string,
  departureMonths: Array<string>,
  destinationNames: Array<string>,
  departureNames: Array<string>,
  minPrice?: number,
  maxPrice?: number,
  durationMin: number,
  durationMax: number,
  durationRange: Array<{ min: number, max: number }>,
  cruisePriceByNight: boolean,
}

export function cruiseFilterFlashOffers(props: Props) {
  const {
    offers,
    limit,
    cruiseLineNames,
    cabinTypes,
    cruiseShips,
    departureName,
    destinationName,
    departureMonths,
    destinationNames,
    departureNames,
    minPrice,
    maxPrice,
    durationMin,
    durationMax,
    durationRange,
    cruisePriceByNight,
  } = props

  const filteredOffers = offers.filter((offer) => {
    const offerCabinTypes = splitStringToArray(',', offer.cabinTypes)
    const lowestPrice = offer.lowestPricePackage?.price || 0
    const vendorNames = splitStringToArray('/', offer.vendorName)
    const minDuration = offer.minDuration || 0

    // Concat all locations with locationsVisited and keep only a array of string with all of them
    let locationsVisited: Array<string> = []
    if (offer.locationsVisited) {
      locationsVisited = offer.locationsVisited.map(location => Object.values(location)).flat()
    }
    const listAllLocations = [...new Set([...offer.locations, ...locationsVisited])]

    let isValid = true

    if (cruiseLineNames.length) {
      isValid = isValid && cruiseLineNames.some((name) => vendorNames.includes(name))
    }

    if (cabinTypes.length) {
      isValid = isValid && cabinTypes.every((type) => offerCabinTypes.includes(type))
    }

    if (cruiseShips.length) {
      isValid = isValid && cruiseShips.includes(offer.vendorVehicle || '')
    }

    if (departureName && departureName !== 'Anywhere') {
      isValid = isValid && offer.startLocation === departureName
    }

    if (destinationName && destinationName !== 'Anywhere') {
      isValid = isValid && offer.endLocation === destinationName
    }

    if (departureMonths.length) {
      isValid = isValid && !!offer.travelFromDate && departureMonths.includes(shortDateFormat(new Date(offer.travelFromDate)))
    }

    const startLocation = offer.startLocation?.split(', ')[0]
    const endLocation = offer.endLocation?.split(', ')[0]
    if (destinationNames.length) {
      // Remove all locations that are equals to startLocation
      // To keep only the last locations visited
      const lastLocationsVisited = listAllLocations.filter(location => !location.includes(startLocation!))
      const mappedDestinationNames = splitHighLevelDescription(destinationNames)
      isValid = isValid && !!endLocation &&
        (mappedDestinationNames.includes(endLocation) ||
          mappedDestinationNames.some((name) => lastLocationsVisited.includes(name)))
    }

    if (departureNames.length) {
      // Remove all locations that are equals to endLocation
      // To keep only the first locations visited
      const firstLocationsVisited = listAllLocations.filter(location => !location.includes(endLocation!))
      const mappedDepartureNames = splitHighLevelDescription(departureNames)
      isValid = isValid && !!startLocation &&
        (mappedDepartureNames.includes(startLocation) ||
          mappedDepartureNames?.some((name) => firstLocationsVisited.includes(name)))
    }

    if (minPrice && !cruisePriceByNight) {
      isValid = isValid && lowestPrice >= minPrice
    }

    if (maxPrice && !cruisePriceByNight) {
      isValid = isValid && lowestPrice <= maxPrice
    }

    if (minPrice && cruisePriceByNight && minDuration) {
      isValid = isValid && Math.round(lowestPrice / (minDuration - 1)) >= minPrice
    }

    if (maxPrice && cruisePriceByNight && minDuration) {
      isValid = isValid && Math.round(lowestPrice / (minDuration - 1)) <= maxPrice
    }

    // We decrease the minDuration by 1 because the duration is in days, and we showed in the UI as nights
    if (minDuration) {
      const newMinDuration = minDuration - 1
      if (durationMin && minDuration) {
        isValid = isValid && newMinDuration >= durationMin
      }

      if (durationMax && minDuration) {
        isValid = isValid && newMinDuration <= durationMax
      }

      if (durationRange && minDuration) {
        durationRange?.forEach(({ min, max }) => {
          if (min && max) {
            isValid = isValid && newMinDuration >= min && newMinDuration <= max
          } else if (min && !max) {
            isValid = isValid && newMinDuration >= min
          }
        })
      }
    }

    return isValid
  })

  if (limit) {
    return filteredOffers.slice(0, limit)
  }

  return filteredOffers
}
