import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LinePlusCircleIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 2a10 10 0 1 0 0 20.0001A10 10 0 0 0 12 2Zm0 18a8.0005 8.0005 0 0 1-7.391-4.9385A8 8 0 1 1 12 20Zm4-9h-3V8a1.0002 1.0002 0 0 0-1.7071-.7071A1.0002 1.0002 0 0 0 11 8v3H8a1.0001 1.0001 0 0 0-.7071 1.7071C7.4804 12.8946 7.7348 13 8 13h3v3a1.0001 1.0001 0 0 0 2 0v-3h3a1.0001 1.0001 0 0 0 0-2Z"/>
  </SvgIcon>
}

export default LinePlusCircleIcon
