import { GetSecurePaymentInfoResponse, MerchantFeeConfigResponse, MerchantFeeDetailsResponse, PayToSupportedBanksResponse, SecurePaymentInfo, StripeSavedCardsV2Response } from 'api/payment'

export function instalmentDetailsMap(instalmentDetails): App.InstalmentDetails {
  return instalmentDetails ? {
    instalmentPaymentDetails: instalmentDetails.instalmentPaymentDetails.map(i => instalmentPaymentDetailsMap(i)),
    instalment_status: instalmentDetails.instalment_status,
    total_paid_amount: parseFloat(instalmentDetails.total_paid_amount),
    balance_amount: parseFloat(instalmentDetails.balance_amount),
    currency: instalmentDetails.currency,
    total_amount: parseFloat(instalmentDetails.total_amount),
    fk_orders: instalmentDetails.fk_orders,
    instalment_frequency: instalmentDetails.instalment_frequency,
    total_instalments_count: instalmentDetails.total_instalments_count,
    instalment_frequency_days: instalmentDetails.instalment_frequency_days,
    per_instalment_amount: parseFloat(instalmentDetails.per_instalment_amount),
    initial_payment_date: instalmentDetails.initial_payment_date,
    initial_payment_amount: parseFloat(instalmentDetails.initial_payment_amount),
    scheduled_instalment_dates: instalmentDetails.scheduled_instalment_dates,
    total_due_balance_auto_debit_failed_count: instalmentDetails.total_due_balance_auto_debit_failed_count,
    paid_instalments_count: instalmentDetails.paid_instalments_count,
    items: instalmentDetails.items,
    is_active: instalmentDetails.is_active,
    metadata: {
      external_ref: instalmentDetails.metadata.external_ref,
    },
    last_instalment_amount: parseFloat(instalmentDetails.last_instalment_amount),
    fk_customer: instalmentDetails.fk_customer,
  } : null
}

function instalmentPaymentDetailsMap(instalmentPaymentDetails): App.Instalment {
  return {
    id: instalmentPaymentDetails.id,
    instalments_number: instalmentPaymentDetails.instalments_number,
    instalment_amount: parseFloat(instalmentPaymentDetails.instalment_amount),
    instalment_due_date: instalmentPaymentDetails.instalment_due_date,
    instalment_paid_date: instalmentPaymentDetails.instalment_paid_date,
    currency: instalmentPaymentDetails.currency,
    instalment_status: instalmentPaymentDetails.instalment_status,
    due_balance_auto_debit_failed_count: instalmentPaymentDetails.due_balance_auto_debit_failed_count,
    created_at: instalmentPaymentDetails.created_at,
    metadata: {
      is_credit: instalmentPaymentDetails.metadata.is_credit,
      is_promo: instalmentPaymentDetails.metadata.is_promo,
    },
  }
}

export function merchantFeeConfigMap(result: MerchantFeeConfigResponse['result']): Array<App.MerchantFeeConfig> {
  return result.merchantFeeConfigs.map(merchantFeeConfig => ({
    id: merchantFeeConfig.id,
    name: merchantFeeConfig.name,
    percentage: merchantFeeConfig.percentage,
  }))
}

export function merchantFeeDetailsMap(result: MerchantFeeDetailsResponse['result']): Array<App.MerchantFeeDetails> {
  return result.merchantFeeDetails.filter(item => item.intent === 'sale').map(merchantFeeDetail => ({
    fkItem: merchantFeeDetail.fk_item,
    amount: merchantFeeDetail.amount,
    metadata: {
      data: {
        shouldAutoRefund: merchantFeeDetail.metadata.data.shouldAutoRefund,
      },
    },
  }))
}

export function payToSupportedBanksMap(result: PayToSupportedBanksResponse['result']): Array<App.PayToSupportedBank> {
  return result.map(payToSupportedBank => ({
    bankName: payToSupportedBank.bankName,
    bankCode: payToSupportedBank.bankCode,
    bsbPrefixes: payToSupportedBank.bsbPrefixes,
    bankLogo: payToSupportedBank.bankLogo,
    bankAppName: payToSupportedBank.bankAppName,
    bankOnlineBankingName: payToSupportedBank.bankOnlineBankingName,
    faqLink: payToSupportedBank.faqLink,
  }))
}

export function savedStripeCardsMap(result: StripeSavedCardsV2Response['result']): Array<App.StripePaymentCardV2> {
  return result.map(savedCard => ({
    last4: savedCard.last4,
    maskedPan: savedCard.maskedPan,
    expMonth: savedCard.expMonth,
    expYear: savedCard.expYear,
    expiry: savedCard.expiry,
    brand: savedCard.brand,
    logo: savedCard.logo,
    country: savedCard.country,
    paymentMethodId: savedCard.paymentMethodId,
    fingerprint: savedCard.fingerprint,
    funding: savedCard.funding,
  }))
}

export function securePaymentInfoMap(result: GetSecurePaymentInfoResponse['result']): SecurePaymentInfo {
  return {
    amount: result.amount,
    currency: result.currency,
    orderId: result.orderId,
    isDeposit: result.depositInfo.depositPaymentType === 'initial',
    isDepositBalance: result.depositInfo.depositPaymentType === 'final',
    isPaid: result.isPaid,
    isExpired: result.isExpired,
    hasLuxPlus: result.hasLuxPlus,
    items: result.paymentPlanItems.map(paymentPlanItem => ({
      total: paymentPlanItem.totalAmount,
      type: paymentPlanItem.type,
    })),
  }
}
