import {
  API_CALL_REQUEST,
  API_CALL_FAILURE,
  API_CALL_SUCCESS,
  ACCOUNT_INIT_COMPLETE,
  ACCOUNT_ACCESS_SHOW_LOGIN,
  ACCOUNT_ACCESS_SHOW_EMAIL_JOIN,
  ACCOUNT_ACCESS_SHOW_REGISTER,
  ACCOUNT_ACCESS_SHOW_FORGOT_PASSWORD,
  SET_RECENTLY_USED_AIRPORT_CODE,
  SAVE_CUSTOMER_SIGNATURE,
  DELETE_CUSTOMER_SIGNATURE,
  SYNC_USER_DETAILS,
  GSI_SCRIPT_LOADED,
  SET_CUSTOMER_VALUE,
  CLEAR_AUTH_ERROR,
  SET_USER_NOTIFICATIONS,
  REMOVE_USER_NOTIFICATIONS,
  VIEW_USER_NOTIFICATIONS,
} from 'actions/actionConstants'
import { reducerSwitch, createReducer } from 'lib/redux/reducerUtils'
import {
  FETCH_PAYMENT_CARDS,
  FETCH_STRIPE_PAYMENT_CARDS,
  FETCH_AVAILABLE_CREDIT,
  USER_LOGIN,
  USER_LOGOUT,
  USER_REGISTRATION,
  UPDATE_USER_DETAILS,
  UPDATE_ACCOUNT_PARTNERSHIP,
  USER_FORGOT_PASSWORD,
  LINK_PARTNERSHIP_ACCOUNT,
  CHECK_LEGACY_ORDERS_FOR_CURRENT_USER,
  CHECK_USER_EXISTS,
  USER_RESET_PASSWORD,
  FETCH_CUSTOMER_DETAILS,
  DELETE_CUSTOMER_DETAILS,
  UPDATE_CUSTOMER_DETAILS,
  SEND_VERIFICATION_CODE,
} from 'actions/apiActionConstants'
import config from 'constants/config'

import { extractTokenFields } from 'lib/jwtToken/extractTokenFields'

export const initialAuthState: App.AuthState = {
  account: {
    memberId: undefined,
    creditsByCurrency: {},
    balance: 0,
    balanceFetched: false,
    balanceError: false,
    paymentCards: [],
    paymentCardsFetched: false,
    paymentCardsError: {},
    stripePaymentCards: [],
    stripePaymentCardsFetched: false,
    stripePaymentCardsError: {},
    referralProgramEnabled: true,
    isSpoofed: false,
    givenName: undefined,
    surname: undefined,
    fullName: undefined,
    title: undefined,
    email: undefined,
    dob: undefined,
    legacyId: undefined,
    legacyPlatform: undefined,
    status: undefined,
    roles: [],
    vendors: [],
    countryCode: undefined,
    postcode: undefined,
    phonePrefix: undefined,
    phone: undefined,
    signupDomain: undefined,
    partnerships: {},
    personContactId: undefined,
    emailVerified: true,
    toggles: {},
    lastLogin: {},
    creditsLedger: [],
    numberOfPurchases: 0,
    customerSupportCode: undefined,
    recentlyUsedAirportCode: undefined,
    subscriberTier: null,
    notifications: [],
  },
  users: {},
  accessToken: undefined,
  error: undefined,
  processing: false,
  source: null,
  addressDetails: null,
  gender: null,
  title: null,
  middleName: null,
  travellerDetailsProcessing: false,
  travellerDetailsFetched: false,
  travellerDetailsError: false,
  accountProcessing: false,
  partnershipProcessing: false,
  partnershipError: undefined,
  ledLegacyOrders: {
    hasAny: false,
    legacyCutoffDate: undefined,
    fetchLegacyOrdersFailed: false,
    hasFetched: false,
  },
  initComplete: false,
  signature: null,
  gsiLoaded: false,
  completeAccountBannerDismissed: false,
}

const authSuccess = (state: App.AuthState, action) => {
  const accessToken = action.data.accessToken ?? state.accessToken
  const { agentId } = extractTokenFields(accessToken)

  const accountData: Partial<App.AuthState> = {
    accessToken,
    account: {
      ...state.account,
      ...action.data.account,
      agentId,
    },
    users: {
      ...state.users,
      // they just logged in...we know the account exists!
      [action.data.account.email]: {
        ...state.users[action.data.account.email],
        exists: true,
      },
    },
    processing: false,
    initComplete: true,
  }
  // WL-Mobile login flow
  if (config.MOBILE_APP_CUSTOMISATIONS) {
    const persistentAccountData = {
      account: {
        ...accountData.account,
        accessToken,
      },
    }

    // Persist account data in iphone/capacitor "local storage"
    try {
      import(/* webpackChunkName: "CapacitorPreferences" */ '@capacitor/preferences')
        .then(capacitorPreferencesModule => {
          const { Preferences } = capacitorPreferencesModule
          Preferences.set({
            key: 'account',
            value: JSON.stringify(persistentAccountData),
          })
        })
    } catch {
      console.error('Failed to persist account data')
    }
  }

  return ({
    ...accountData,
  })
}

const authClearError = () => ({
  error: undefined,
})

const authStart = (state, action) => ({
  ...authClearError(),
  processing: true,
  source: action.source,
})

const authProcessing = () => ({
  ...authClearError(),
  processing: true,
})

const authError = (state, action) => ({
  error: action.error ?? action.data,
  processing: false,
  accountProcessing: false,
})

const apiRequests = reducerSwitch<App.AuthState>({
  [CHECK_USER_EXISTS]: authProcessing,
  [USER_LOGIN]: authStart,
  [USER_REGISTRATION]: authStart,
  [USER_RESET_PASSWORD]: authProcessing,
  [USER_FORGOT_PASSWORD]: authProcessing,
  [LINK_PARTNERSHIP_ACCOUNT]: authProcessing,
  [SEND_VERIFICATION_CODE]: authProcessing,
  [UPDATE_USER_DETAILS]: () => ({
    accountProcessing: true,
  }),
  [UPDATE_ACCOUNT_PARTNERSHIP]: () => ({
    partnershipProcessing: true,
  }),
  [FETCH_AVAILABLE_CREDIT]: (state, action) => ({
    account: {
      ...state.account,
      creditsByCurrency: {
        ...state.account.creditsByCurrency,
        [action.currencyCode]: {
          balance: 0,
          nextExpiringCredit: null,
          loading: true,
          error: null,
        },
      },
    },
  }),
  [FETCH_CUSTOMER_DETAILS]: () => ({
    travellerDetailsProcessing: true,
    travellerDetailsFetched: false,
    travellerDetailsError: false,
  }),
  [DELETE_CUSTOMER_DETAILS]: () => ({
    travellerDetailsProcessing: true,
    travellerDetailsFetched: false,
  }),
  [UPDATE_CUSTOMER_DETAILS]: () => ({
    travellerDetailsProcessing: true,
  }),
})

const apiSuccesses = reducerSwitch<App.AuthState>({
  [CHECK_USER_EXISTS]: (state, action) => ({
    processing: false,
    users: {
      ...state.users,
      [action.key]: {
        id: action.key,
        exists: action.data.isExist,
        maskedPhone: action.data.maskedPhone,
        email: action.email,
        phoneNumber: action.phoneNumber,
      },
    },
  }),
  [USER_LOGIN]: authSuccess,
  [USER_REGISTRATION]: authSuccess,
  [SEND_VERIFICATION_CODE]: () => ({
    processing: false,
  }),
  [USER_RESET_PASSWORD]: () => ({
    processing: false,
  }),
  [USER_FORGOT_PASSWORD]: () => ({
    processing: false,
  }),
  [CHECK_LEGACY_ORDERS_FOR_CURRENT_USER]: (state, action) => {
    return ({
      ledLegacyOrders: {
        ...state.ledLegacyOrders,
        hasAny: action.data.orders.length > 0,
        legacyCutoffDate: action.data.legacyCutoffDate,
        hasFetched: true,
        fetchLegacyOrdersFailed: false,
      },
    })
  },
  [FETCH_PAYMENT_CARDS]: (state, action) => ({
    account: {
      ...state.account,
      paymentCards: action.data,
      paymentCardsFetched: true,
      paymentCardsError: {},
    },
  }),
  [FETCH_STRIPE_PAYMENT_CARDS]: (state, action) => ({
    account: {
      ...state.account,
      stripePaymentCards: action.data,
      stripePaymentCardsFetched: true,
      stripePaymentCardsError: {},
    },
  }),
  [FETCH_AVAILABLE_CREDIT]: (state, action) => ({
    account: {
      ...state.account,
      balance: action.data.balance ?? 0,
      balanceFetched: true,
      balanceError: false,
      creditsLedger: action.data.credits,
      creditsByCurrency: {
        ...state.account.creditsByCurrency,
        [action.currencyCode]: {
          balance: action.data.balance,
          nextExpiringCredit: action.data.nextExpiringCredit,
          loading: false,
          error: null,
        },
      },
    },
  }),
  [UPDATE_USER_DETAILS]: (state, action) => ({
    ...authClearError(),
    processing: false,
    accountProcessing: false,
    account: {
      ...state.account,
      ...action.data,
    },
  }),
  [USER_LOGOUT]: (state) => {
    // WL-Mobile: remove Persist account data from iphone/capacitor "local storage"
    if (config.MOBILE_APP_CUSTOMISATIONS) {
      try {
        import(/* webpackChunkName: "Capacitor" */ '@capacitor/preferences')
          .then(capacitorPreferencesModule => {
            const { Preferences } = capacitorPreferencesModule
            Preferences.remove({ key: 'account' })
          })
      } catch {
        console.error('Failed to delete account data')
      }
    }
    return ({ ...initialAuthState, gsiLoaded: state.gsiLoaded, initComplete: true })
  },
  [UPDATE_ACCOUNT_PARTNERSHIP]: (state, action) => ({
    partnershipProcessing: false,
    account: {
      ...state.account,
      partnerships: {
        ...state.account.partnerships,
        [action.partnershipPrefix]: {
          ...state.account.partnerships[action.partnershipPrefix],
          ...action.data,
        },
      },
    },
  }),
  [LINK_PARTNERSHIP_ACCOUNT]: (state, action) => ({
    processing: false,
    account: {
      ...state.account,
      partnerships: {
        ...state.account.partnerships,
        [action.prefix]: action.details,
      },
    },
  }),
  [SET_RECENTLY_USED_AIRPORT_CODE]: (state, action) => ({
    account: {
      ...state.account,
      recentlyUsedAirportCode: action.airportCode,
    },
  }),
  [FETCH_CUSTOMER_DETAILS]: (state, action) => ({
    addressDetails: action.data.addressDetails,
    gender: action.data.gender,
    title: action.data.title,
    middleName: action.data.middleName,
    travellerDetailsProcessing: false,
    travellerDetailsFetched: true,
    travellerDetailsError: false,
  }),
  [DELETE_CUSTOMER_DETAILS]: () => ({
    addressDetails: null,
    gender: null,
    title: null,
    middleName: null,
    travellerDetailsProcessing: false,
    travellerDetailsFetched: false,
  }),
  [UPDATE_CUSTOMER_DETAILS]: (state, action) => ({
    addressDetails: action.data.addressDetails,
    gender: action.data.gender,
    title: action.data.title,
    middleName: action.data.middleName,
    travellerDetailsProcessing: false,
  }),
})

const apiFailures = reducerSwitch<App.AuthState>({
  [CHECK_USER_EXISTS]: authError,
  [USER_LOGIN]: authError,
  [USER_REGISTRATION]: authError,
  [UPDATE_USER_DETAILS]: authError,
  [USER_RESET_PASSWORD]: authError,
  [USER_FORGOT_PASSWORD]: authError,
  [LINK_PARTNERSHIP_ACCOUNT]: authError,
  [SEND_VERIFICATION_CODE]: authError,
  [FETCH_PAYMENT_CARDS]: (state, action) => ({
    account: {
      ...state.account,
      paymentCardsError: action.error,
    },
  }),
  [FETCH_STRIPE_PAYMENT_CARDS]: (state, action) => ({
    account: {
      ...state.account,
      stripePaymentCardsError: action.error,
    },
  }),
  [CHECK_LEGACY_ORDERS_FOR_CURRENT_USER]: (state) => ({
    ledLegacyOrders: {
      ...state.ledLegacyOrders,
      fetchLegacyOrdersFailed: true,
      hasFetched: false,
    },
  }),
  [FETCH_AVAILABLE_CREDIT]: (state, action) => ({
    account: {
      ...state.account,
      balance: 0,
      balanceFetched: false,
      balanceError: true,
    },
    creditsByCurrency: {
      ...state.account.creditsByCurrency,
      [action.currencyCode]: {
        balance: 0,
        nextExpiringCredit: null,
        loading: false,
        error: true,
      },
    },
  }),
  [UPDATE_ACCOUNT_PARTNERSHIP]: (state, action) => ({
    partnershipProcessing: false,
    partnershipError: action.error,
  }),
  [FETCH_CUSTOMER_DETAILS]: () => ({
    travellerDetailsProcessing: false,
    travellerDetailsFetched: false,
    travellerDetailsError: true,
  }),
  [DELETE_CUSTOMER_DETAILS]: () => ({
    travellerDetailsProcessing: false,
  }),
})

export default createReducer<App.AuthState>(initialAuthState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [ACCOUNT_INIT_COMPLETE]: () => ({ initComplete: true }),
  [ACCOUNT_ACCESS_SHOW_LOGIN]: authClearError,
  [ACCOUNT_ACCESS_SHOW_EMAIL_JOIN]: authClearError,
  [ACCOUNT_ACCESS_SHOW_REGISTER]: authClearError,
  [ACCOUNT_ACCESS_SHOW_FORGOT_PASSWORD]: authClearError,
  [CLEAR_AUTH_ERROR]: authClearError,
  [SAVE_CUSTOMER_SIGNATURE]: (state, action) => ({
    signature: action.signature,
  }),
  [DELETE_CUSTOMER_SIGNATURE]: () => ({
    signature: null,
  }),
  [SYNC_USER_DETAILS]: (state, action) => ({
    account: {
      ...state.account,
      ...action.userDetails,
    },
  }),
  [GSI_SCRIPT_LOADED]: () => ({
    gsiLoaded: true,
  }),
  [SET_CUSTOMER_VALUE]: (state, action) => ({
    customerValue: action.value,
  }),
  [SET_USER_NOTIFICATIONS]: (state, action) => ({
    account: {
      ...state.account,
      notifications: action.notifications,
    },
  }),
  [REMOVE_USER_NOTIFICATIONS]: (state, action) => ({
    account: {
      ...state.account,
      notifications: state.account.notifications.filter(item => item.id !== action.notification.id),
    },
  }),
  [VIEW_USER_NOTIFICATIONS]: (state) => ({
    account: {
      ...state.account,
      notifications: state.account.notifications.map(item => ({ ...item, viewed: true })),
    },
  }),
})
