import { useAppSelector } from '../reduxHooks'
import { min } from '../../lib/array/arrayUtils'
import getOfferListKey from '../../lib/offer/offerListKey'

/**
 * retrieves cheapest alternative date for an offer given the checkIn and checkOut dates
 */
function useOfferAlternativeDate(offerId: string, kind: 'bedbank' | 'le', filters: App.OfferListFilters) {
  const listKey = getOfferListKey(filters)
  const availabilities = useAppSelector(state => {
    const altDates = state.offer.alternativeDates[listKey]
    return altDates ? altDates[offerId] : undefined
  })
  const fetching = !!useAppSelector(state => state.offer.alternativeDatesLoading[offerId])

  let alternativeDate: undefined | App.AlternativeDatesWithRates = undefined

  if (availabilities?.length) {
    alternativeDate = kind === 'le' ?
      min(availabilities, (a: App.AlternativeDatesWithRates) => (a.rate as App.OfferAvailableRate).price) :
      min(availabilities, (a: App.AlternativeDatesWithRates) => (a.rate as App.BedbankRate).totals.inclusive)
  }

  return { alternativeDate, fetching }
}

export default useOfferAlternativeDate
