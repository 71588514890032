import { createReducer, reducerSwitch } from 'lib/redux/reducerUtils'
import {
  ADD_ORDERS,
  ADD_REFUND_REQUEST_TO_ORDER,
  API_CALL_FAILURE,
  API_CALL_REQUEST,
  API_CALL_SUCCESS,
  TOUR_TRAVELLER_DETAILS_UPDATE_SUBMITTED,
  UPDATE_ORDER,
} from 'actions/actionConstants'
import {
  FETCH_CANCELLATION_MODAL_SETTINGS,
  FETCH_FLIGHT_ORDER_PNRS,
  FETCH_ORDER,
  FETCH_ORDERS_FOR_CURRENT_USER,
  FETCH_ORDER_BY_SUBSCRIPTION,
  FETCH_ORDER_ITEM_FLIGHT_DETAILS,
  FETCH_ROOM_REFUND_INFO,
  FETCH_TOUR_ORDER_TRAVELLER_DETAILS,
  REFUND_ORDER_ITEM,
  REFUND_ORDER_ITEMS,
  UPDATE_ORDER_PARTNERSHIP,
  USER_LOGIN,
  USER_LOGOUT,
  FETCH_ORDERS_DATES_REQUESTS,
  CREATE_DATES_REQUEST,
} from 'actions/apiActionConstants'
import { arrayToObject } from 'lib/array/arrayUtils'
import { AnyAction } from 'redux'

export const initialOrdersState: App.OrdersState = {
  orders: {},
  orderErrors: {},
  ordersFetching: {},
  tourOrdersTravellerDetails: {},
  error: undefined,
  fetching: {
    all: false,
    upcoming: false,
    upsellable: false,
  },
  ordersFetched: {
    all: false,
    upcoming: false,
    upsellable: false,
  },
  ordersBySubscriptionId: {},
  orderItemsProcessing: {},
  orderItemRefundErrors: {},
  flightDetails: {},
  cancellationSettings: undefined,
  fetchingCancellationSettings: false,
  cancellationSettingsError: undefined,
  itemRefundInfo: {},
  datesRequests: {},
  fetchingDatesRequests: {},
}

const apiRequests = reducerSwitch<App.OrdersState>({
  [FETCH_ROOM_REFUND_INFO]: (state, action) => ({
    itemRefundInfo: {
      ...state.itemRefundInfo,
      [action.key]: {
        fetching: true,
        error: undefined,
      },
    },
  }),
  [FETCH_ORDER]: (state, action) => ({
    ordersFetching: {
      ...state.ordersFetching,
      [action.orderId]: true,
    },
    orderErrors: {
      ...state.orderErrors,
      [action.orderId]: undefined,
    },
  }),
  [FETCH_ORDER_BY_SUBSCRIPTION]: (state, action) => ({
    ordersFetching: {
      ...state.ordersFetching,
      [action.subscriptionId]: true,
    },
  }),
  [FETCH_ORDERS_FOR_CURRENT_USER]: (state, action) => ({
    error: undefined,
    fetching: {
      ...state.fetching,
      [action.key]: true,
    },
  }),
  [FETCH_TOUR_ORDER_TRAVELLER_DETAILS]: (state, action) => ({
    tourOrdersTravellerDetails: {
      ...state.tourOrdersTravellerDetails,
      [action.tourOrderId]: { status: 'pending' },
    },
  }),
  [FETCH_ORDER_ITEM_FLIGHT_DETAILS]: (state, action) => ({
    flightDetails: {
      ...state.flightDetails,
      [action.key]: {
        fetching: true,
      },
    },
  }),
  [REFUND_ORDER_ITEMS]: (state, action) => {
    const nextState: Partial<App.OrdersState> = {
      orderItemsProcessing: {
        ...state.orderItemsProcessing,
        ...arrayToObject(action.itemIds, (id: string) => id, () => true),
      },
    }

    if (action.itemIds.some(id => state.orderItemRefundErrors[id])) {
      nextState.orderItemRefundErrors = {
        ...state.orderItemRefundErrors,
        ...arrayToObject(action.itemIds, (id: string) => id, () => undefined),
      }
    }

    return nextState
  },
  [REFUND_ORDER_ITEM]: (state, action) => ({
    orderItemsProcessing: {
      ...state.orderItemsProcessing,
      [action.itemId]: true,
    },
    orderItemRefundErrors: {
      ...state.orderItemRefundErrors,
      [action.itemId]: undefined,
    },
  }),
  [FETCH_CANCELLATION_MODAL_SETTINGS]: () => {
    return {
      fetchingCancellationSettings: true,
      cancellationSettingsError: undefined,
    }
  },
  [FETCH_ORDERS_DATES_REQUESTS]: (state, action) => ({
    fetchingDatesRequests: {
      ...state.fetchingDatesRequests,
      [action.orderId]: true,
    },
  }),
})

const apiSuccesses = reducerSwitch<App.OrdersState>({
  [FETCH_ROOM_REFUND_INFO]: (state, action) => ({
    itemRefundInfo: {
      ...state.itemRefundInfo,
      [action.key]: {
        fetching: false,
        info: action.data,
      },
    },
  }),
  [FETCH_ORDERS_FOR_CURRENT_USER]: (state, action) => ({
    fetching: {
      ...state.fetching,
      [action.key]: false,
    },
    ordersFetched: {
      ...state.ordersFetched,
      [action.key]: true,
    },
    orders: {
      ...state.orders,
      ...arrayToObject<App.Order>(action.data, order => order.id),
    },
  }),
  [FETCH_ORDER]: (state, action) => ({
    orders: {
      ...state.orders,
      [action.orderId]: action.data,
    },
    ordersFetching: {
      ...state.ordersFetching,
      [action.orderId]: false,
    },
  }),
  [FETCH_ORDER_BY_SUBSCRIPTION]: (state, action) => ({
    orders: {
      ...state.orders,
      [action.data.id]: action.data,
    },
    ordersBySubscriptionId: {
      ...state.ordersBySubscriptionId,
      [action.subscriptionId]: action.data.id,
    },
    ordersFetching: {
      ...state.ordersFetching,
      [action.subscriptionId]: false,
    },
  }),
  [FETCH_TOUR_ORDER_TRAVELLER_DETAILS]: (state, action) => ({
    tourOrdersTravellerDetails: {
      ...state.tourOrdersTravellerDetails,
      [action.tourOrderId]: {
        status: 'fetched',
        loadedAtTimeStamp: new Date().getTime(),
        travellerDetails: action.data,
      },
    },
  }),
  [USER_LOGIN]: () => initialOrdersState,
  [USER_LOGOUT]: () => initialOrdersState,
  [FETCH_ORDER_ITEM_FLIGHT_DETAILS]: (state, action) => ({
    flightDetails: {
      ...state.flightDetails,
      [action.key]: {
        flight: action.data,
        fetching: false,
      },
    },
  }),
  [FETCH_FLIGHT_ORDER_PNRS]: (state, action) => ({
    orders: {
      ...state.orders,
      [action.data.id]: {
        ...state.orders[action.data.id],
        flightItems: action.data.flightItems,
      },
    },
  }),
  [REFUND_ORDER_ITEMS]: (state, action) => {
    return {
      orders: {
        ...state.orders,
        [action.orderId]: action.data,
      },
      orderItemsProcessing: {
        ...state.orderItemsProcessing,
        ...arrayToObject(action.itemIds, (id: string) => id, () => false),
      },
    }
  },
  [REFUND_ORDER_ITEM]: (state, action) => ({
    orders: {
      ...state.orders,
      [action.orderId]: action.data,
    },
    orderItemsProcessing: {
      ...state.orderItemsProcessing,
      [action.itemId]: false,
    },
  }),
  [UPDATE_ORDER_PARTNERSHIP]: (state, action: AnyAction & { data: App.Order}) => ({
    orders: {
      ...state.orders,
      [action.data.id]: {
        ...state.orders[action.data.id],
        partnerships: action.data.partnerships,
      },
    },
  }),
  [FETCH_CANCELLATION_MODAL_SETTINGS]: (state, action) => {
    return {
      fetchingCancellationSettings: false,
      cancellationSettings: action.data,
    }
  },
  [CREATE_DATES_REQUEST]: (state: App.OrdersState, action) => ({
    datesRequests: {
      ...state.datesRequests,
      [action.orderId]: state.datesRequests[action.orderId] || [],
    },
  }),
  [FETCH_ORDERS_DATES_REQUESTS]: (state: App.OrdersState, action) => ({
    fetchingDatesRequests: {
      ...state.fetchingDatesRequests,
      [action.orderId]: false,
    },
    datesRequests: {
      ...state.datesRequests,
      [action.orderId]: action.data,
    },
  }),
})

const apiFailures = reducerSwitch<App.OrdersState>({
  [FETCH_ROOM_REFUND_INFO]: (state, action) => ({
    itemRefundInfo: {
      ...state.itemRefundInfo,
      [action.key]: {
        fetching: false,
        error: action.error,
      },
    },
  }),
  [FETCH_ORDERS_FOR_CURRENT_USER]: (state, action) => ({
    error: action.error,
    fetching: {
      ...state.fetching,
      [action.key]: false,
    },
  }),
  [FETCH_ORDER_ITEM_FLIGHT_DETAILS]: (state, action) => ({
    flightDetails: {
      ...state.flightDetails,
      [action.key]: {
        flight: undefined,
        fetching: false,
        error: action.error,
      },
    },
  }),
  [FETCH_ORDER]: (state, action) => ({
    ordersFetching: {
      ...state.ordersFetching,
      [action.orderId]: false,
    },
    orderErrors: {
      ...state.orderErrors,
      [action.orderId]: action.error,
    },
  }),
  [FETCH_ORDER_BY_SUBSCRIPTION]: (state, action) => ({
    ordersFetching: {
      ...state.ordersFetching,
      [action.subscriptionId]: false,
    },
  }),
  [REFUND_ORDER_ITEMS]: (state, action) => {
    return {
      orderItemsProcessing: {
        ...state.orderItemsProcessing,
        ...arrayToObject(action.itemIds, (id: string) => id, () => false),
      },
      orderItemRefundErrors: {
        ...state.orderItemRefundErrors,
        ...arrayToObject(action.itemIds, (id: string) => id, () => action.error),
      },
    }
  },
  [REFUND_ORDER_ITEM]: (state, action) => ({
    orderItemsProcessing: {
      ...state.orderItemsProcessing,
      [action.itemId]: false,
    },
    orderItemRefundErrors: {
      ...state.orderItemRefundErrors,
      [action.itemId]: action.error,
    },
  }),
  [FETCH_CANCELLATION_MODAL_SETTINGS]: (state, action) => {
    return {
      fetchingCancellationSettings: false,
      cancellationSettingsError: action.error,
    }
  },
  [FETCH_ORDERS_DATES_REQUESTS]: (state, action) => ({
    fetchingDatesRequests: {
      ...state.fetchingDatesRequests,
      [action.orderId]: false,
    },
  }),
})

export default createReducer<App.OrdersState>(initialOrdersState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [UPDATE_ORDER]: (state, action) => ({
    orders: {
      ...state.orders,
      [action.order.id]: action.order,
    },
  }),
  [ADD_ORDERS]: (state, action) => ({
    orders: {
      ...state.orders,
      ...arrayToObject<App.Order>(action.orders, order => order.id),
    },
  }),
  [FETCH_TOUR_ORDER_TRAVELLER_DETAILS]: (state, action) => ({
    tourOrdersTravellerDetails: {
      ...state.tourOrdersTravellerDetails,
      [action.tourOrderId]: { status: 'failed', error: action.data },
    },
  }),
  [TOUR_TRAVELLER_DETAILS_UPDATE_SUBMITTED]: (state, action) => {
    return ({
      tourOrdersTravellerDetails: {
        ...state.tourOrdersTravellerDetails,
        [action.tourOrderId]: {
          ...state.tourOrdersTravellerDetails[action.tourOrderId],
          travellerDetails: action.travellerDetails,
        },
      },
    })
  },
  [ADD_REFUND_REQUEST_TO_ORDER]: (state, action) => {
    const order = state.orders[action.orderId]

    if (!order) {
      return state
    }

    return {
      orders: {
        ...state.orders,
        [action.orderId]: {
          ...order,
          refundRequests: {
            ...order.refundRequests,
            [action.orderItemId]: action.refundRequest,
          },
        },
      },
    }
  },
})
