import InclusionItem from 'components/Common/InclusionItem'
import LargeContentToggle from 'components/Common/LargeContentToggle'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import MarkdownRender from 'components/Luxkit/MarkdownRender'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { EmptyArray } from 'lib/array/arrayUtils'
import React from 'react'

interface Props {
  inclusions?: Array<App.Inclusion>;
  maxHeight: number;
  content?: string;
  showMoreText?: string;
  showLessText?: string;
  heading?: string;
}

function BedbankOfferInclusions(props: Props) {
  const {
    maxHeight = 208,
    showLessText = 'View less inclusions',
    showMoreText = 'View more inclusions',
    inclusions = EmptyArray,
    content,
    heading,
  } = props

  return <VerticalSpacer gap={4}>
    {heading && inclusions.length > 0 && <BodyText variant="medium" weight="bold" colour="neutral-two">{heading}</BodyText>}
    <LargeContentToggle
      maxHeight={maxHeight}
      minimumContentHeight={160}
      showLessText={showLessText}
      showMoreText={showMoreText}
    >
      {content && inclusions.length === 0 && <MarkdownRender fontSize="medium" type="compact" content={content} />}
      {inclusions.map((inclusion) => <InclusionItem key={inclusion.text} inclusion={inclusion} />)}
    </LargeContentToggle>
  </VerticalSpacer>
}

export default BedbankOfferInclusions
