import { mediaQueryDown, mediaQueryUp } from 'components/utils/breakpoint'
import useHasMounted from 'hooks/useHasMounted'
import { rem } from 'polished'
import React, { CSSProperties, PropsWithChildren, forwardRef } from 'react'
import cn from 'clsx'
import styled, { keyframes } from 'styled-components'
import useDelayedTogglingBoolean from 'hooks/useDelayedTogglingBoolean'
import zIndex from 'styles/tools/z-index'
import eases from 'styles/tools/eases'

const ANIMATION_DURATION_MS = 300

const SlideFromBottom = keyframes`
  from {
    transform: translateY(120%);
    visibility: visible;
  }

  to {
    transform: translateY(0%);
    visibility: visible;
  }
`
const SlideToBottom = keyframes`
  from {
    transform: translateY(0%);
    visibility: visible;
  }

  to {
    transform: translateY(120%);
    visibility: hidden;
  }
`

const SlideFromRight = keyframes`
  from {
    transform: translateX(120%);
    visibility: visible;
  }

  to {
    transform: translateX(0%);
    visibility: visible;
  }
`
const SlideToRight = keyframes`
  from {
    transform: translateX(0%);
    visibility: visible;
  }

  to {
    transform: translateX(120%);
    visibility: hidden;
  }
`

const Container = styled.div`
  ${mediaQueryUp.tablet} {
    height: 0;
    width: 100%;
    position: absolute;
    top: 0; // do not delete this
    z-index: ${zIndex.stickyPromptCard};

    &.sticky {
      position: sticky;
    }
  }
`

const CardRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(12)};
  background-color: ${props => props.theme.palette.neutral.default.eight};
  box-shadow: ${props => props.theme.shadow.bottom.large};
  border-radius: ${props => props.theme.borderRadius.S};
  animation-duration: ${ANIMATION_DURATION_MS}ms;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-timing-function: ${eases.power2.out};
  visibility: hidden;
  max-width: calc(100vw - ${rem(32)});

  &.transition-in {
    visibility: visible;
  }

  ${mediaQueryDown.mobile} {
    /* center at bottom */
    z-index: ${zIndex.stickyPromptCard};
    position: fixed;
    bottom: ${rem(16)};
    left: ${rem(16)};
    right: ${rem(16)};
    animation-name: ${SlideToBottom};

    &.transition-in {
      animation-name: ${SlideFromBottom};
    }
  }

  ${mediaQueryUp.tablet} {
    /* top right corner */
    position: absolute;
    top: var(--prompt-card-sticky-top);
    right: 0;
    margin: ${rem(16)};
    animation-name: ${SlideToRight};

    &.transition-in {
      animation-name: ${SlideFromRight};
    }
  }
`

interface Props {
  show?: boolean;
  stickyTop?: number;
  className?: string;
  /**
   * Whether or not this will actually be a sticky element.
   *
   * @default true
   */
  sticky?: boolean;
}

const StickyPrompt = forwardRef<HTMLDivElement, PropsWithChildren<Props>>((props, ref) => {
  const {
    show = false,
    children,
    stickyTop = 0,
    className,
    sticky = true,
  } = props
  const isMounted = useHasMounted()

  const shouldRender = useDelayedTogglingBoolean(show, {
    offDelay: ANIMATION_DURATION_MS,
  })

  if (!shouldRender) {
    return null
  }

  if (!isMounted) {
    return null
  }

  return (
    <Container className={cn({ sticky })} style={{ '--prompt-card-sticky-top': rem(stickyTop) } as CSSProperties}>
      <CardRoot ref={ref} className={cn(className, { 'transition-in': show })}>
        {children}
      </CardRoot>
    </Container>
  )
})

export default StickyPrompt
