import { excludeNullOrUndefined, getBreakdownTitle } from 'checkout/utils'
import { DMY_CASUAL_FORMAT, ISO_DATE_FORMAT } from 'constants/dateFormats'
import { OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_BED_BANK, OFFER_TYPE_HOTEL, OFFER_TYPE_VILLA, OFFER_TYPE_TOUR } from 'constants/offer'
import { RESERVATION_TYPE_BOOK_LATER } from 'constants/reservation'
import { groupBy, sum } from 'lib/array/arrayUtils'
import { formatOccupantsShort, sumUpOccupancies } from 'lib/offer/occupancyUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import moment from 'moment'
import createSelector from 'lib/web/createSelector'

import { isAccommodationItem, isBedbankItem, isLEHotelItem } from 'lib/checkout/checkoutUtils'
import { isBedbankType } from 'lib/offer/offerTypes'

/**
 * @remarks
 * We need this selector for a few functions within this file but cannot use getAccommodationItems from /checkout/selectors/view/accommodation.ts
 * because this would create a circular import dependency. This is because accommodation.ts requires getHotelBreakdownView from this file.
 * We have the same problem in deposit.ts, so be sure to update all 3 functions. Looking to implement a better, long term solution soon.
 */
const getCartAccommodationItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.AccommodationItem> => items.filter(isAccommodationItem),

)

const getOrderAccommodationItems = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder,
  (existingOrder): Array<App.Checkout.AccommodationItem> => {
    return existingOrder?.items.reduce<Array<App.Checkout.AccommodationItem>>((acc, item) => {
      if ('roomRateId' in item.package && item.reservationType === 'instant_booking' && item.reservation) {
        acc.push({
          itemId: item.id,
          transactionKey: item.transactionKey,
          offerId: item.offer.id,
          duration: item.duration || 0,
          itemType: 'hotel',
          packageId: item.package.id,
          roomRateId: item.package.roomRateId,
          reservationType: item.reservationType,
          checkIn: item.reservation.startDate,
          checkOut: item.reservation.endDate,
          occupancy: {
            adults: item.reservation.adults,
            children: item.reservation.children,
            infants: item.reservation.infants,
            childrenAge: item.reservation.childrenAge,
          },
        })
      }
      return acc
    }, []).concat(existingOrder.bedbankItems.reduce<Array<App.Checkout.BedbankHotelItem>>((acc, item) => {
      return acc.concat(item.rooms.map(room => ({
        itemId: item.id,
        sessionId: '',
        roomId: item.roomTypeId,
        checkIn: moment(item.checkIn).format(ISO_DATE_FORMAT),
        checkOut: moment(item.checkIn).format(ISO_DATE_FORMAT),
        roomRateId: item.roomRateId,
        isFlightBundle: room.isFlightBundle,
        bedGroupId: '',
        transactionKey: item.transactionKey,
        offerId: item.offer.id,
        duration: item.duration,
        newPrice: item.total,
        itemType: 'bedbankHotel',
        occupancy: room.occupancy,
      })))
    }, [])) || []
  },
)

export const isPostPurchaseAncillaryPayment = createSelector(
  (state) => state.checkout.cart.postPurchase,
  (postPurchase) => postPurchase === 'deposit-balance' || postPurchase === 'instalment-balance' || postPurchase === 'instalment' || postPurchase === 'reserve-balance',
)

export const getAccommodationItems = createSelector(
  getCartAccommodationItems,
  getOrderAccommodationItems,
  isPostPurchaseAncillaryPayment,
  (accommodationItemsFromCart, accommodationItemsFromOrder, isAncillaryPayment): Array<App.Checkout.AccommodationItem> => {
    return isAncillaryPayment ? accommodationItemsFromOrder : accommodationItemsFromCart
  },
)

function getCancellationPolicyBreakdown(
  item: App.Checkout.LEAccommodationItemView | App.Checkout.BedbankAccommodationItemView,
  offerView: App.Checkout.LEAccommodationOfferView | App.Checkout.BedbankAccommodationOfferView,
) {
  const { offerType } = offerView

  switch (offerType) {
    case OFFER_TYPE_HOTEL: {
      const pkg = offerView.offer?.packages.find(pkg => pkg.uniqueKey === item.uniqueKey)
      return {
        cancellationPolicyDetails: pkg?.roomRate?.cancellationPolicy,
        checkInDate: moment(offerView.startDate),
        checkOutDate: moment(offerView.startDate).clone().add(offerView.duration, 'days'),
        occupants: item.occupancy,
      }
    }
    case OFFER_TYPE_BED_BANK:
      return {
        propertyTimezone: offerView.propertyTimezone,
        rate: item.rate,
        showNonCancellation: !(item.rate?.refundable),
      }
    case OFFER_TYPE_VILLA:
    case OFFER_TYPE_ALWAYS_ON: {
      const pkg = offerView.offer?.packages.find(pkg => pkg.uniqueKey === item.uniqueKey)
      return {
        cancellationPolicyDetails: pkg?.roomRate?.cancellationPolicy,
        ignoreDynamicCancellationPolicy: pkg?.ignoreDynamicCancellationPolicy ?? false,
        useDynamicCancellationPolicies: offerView.offer?.property?.useDynamicCancellationPolicies ?? false,
        timezoneOffset: offerView.offer?.property?.timezoneOffset,
        checkInDate: moment(offerView.startDate),
        checkOutDate: moment(offerView.startDate).clone().add(offerView.duration, 'days'),
        occupants: item.occupancy,
        offerId: offerView.offerId,
        uniqueKey: item.uniqueKey,
      }
    }
    default: undefined
  }
}

interface HotelBreakdownItems {
  title: string;
  additionalInfoText: Array<string>;
  additionalElements: Array<App.Checkout.ItemBreakdownElement>;
  price: number;
  memberPrice: number;
  soldOut?: boolean;
  offerId: string;
  isBundled?: boolean;
  itemType: 'accommodation'
  cancellationPolicy?: App.Checkout.CancellationPolicyBreakdownView;
  taxesAndFees?: number;
  businessTravellerCredits?: number;
  stayPayTier?: App.StayPayTier;
}

function getHotelBreakdownItems(
  itemViews: Array<App.Checkout.LEAccommodationItemView> | Array<App.Checkout.BedbankAccommodationItemView>,
  offerView: App.Checkout.LEAccommodationOfferView | App.Checkout.BedbankAccommodationOfferView,
  hidePrice: boolean,
): Array<App.Checkout.AccommodationItemBreakdownView> {
  return itemViews.map<App.Checkout.AccommodationItemBreakdownView>(
    (item: App.Checkout.LEAccommodationItemView | App.Checkout.BedbankAccommodationItemView, idx: number) => {
      const result: HotelBreakdownItems = {
        title: item.kind === 'le' ? item.mainSummaryLabel : item.mainLabel,
        additionalInfoText: [`${formatOccupantsShort(item.occupancy)}`],
        additionalElements: [],
        itemType: 'accommodation',
        price: hidePrice ? 0 : (item.price ?? 0) + (item.surcharge ?? 0) - item.taxesAndFees,
        memberPrice: (hidePrice || item.memberPrice === 0) ? 0 : (item.memberPrice ?? 0) + (item.surcharge ?? 0) - item.taxesAndFees,
        soldOut: item.soldOut,
        taxesAndFees: item.taxesAndFees,
        offerId: item.item.offerId ?? offerView.offerId,
        cancellationPolicy: getCancellationPolicyBreakdown(item, offerView),
        businessTravellerCredits: item.businessTravellerCredits,
      }

      if (item.kind === 'le' && item.extraGuestSurcharge && item.extraGuestSurcharge > 0) {
        result.additionalElements.push({
          id: `extra_guest_surcharge_${idx}`,
          left: {
            type: 'text',
            value: 'Extra guest surcharge',
          },
          right: {
            type: 'price',
            value: item.extraGuestSurcharge,
          },
        })
      }

      return result
    })
}

export const getLeHotelItems = createSelector(
  getAccommodationItems,
  (items): Array<App.Checkout.LEHotelItem> => items.filter(isLEHotelItem),
)

export const getBedbankItems = createSelector(
  getAccommodationItems,
  (items): Array<App.Checkout.BedbankHotelItem> => items.filter(isBedbankItem),
)

function getBNBLBreakdownItems(
  itemViews: Array<App.Checkout.LEAccommodationItemView | App.Checkout.BedbankAccommodationItemView>,
  hidePrice: boolean,
): Array<App.Checkout.AccommodationItemBreakdownView> {
  const itemGroups = groupBy(itemViews, item => item.mainLabel)
  return Array.from(itemGroups.entries()).map(
    ([mainLabel, groupedItems]) => {
      return {
        title: mainLabel,
        itemType: 'accommodation',
        offerId: groupedItems[0].item.offerId, // double check
        additionalInfoText: [pluralizeToString('package', groupedItems.length)],
        additionalElements: [],
        price: hidePrice ? undefined : sum(groupedItems, item => (item.price ?? 0) + (item.surcharge ?? 0) - item.taxesAndFees),
        memberPrice: hidePrice ? undefined : sum(groupedItems, item => item.memberPrice > 0 ? (item.memberPrice ?? 0) + (item.surcharge ?? 0) - item.taxesAndFees : 0),
        reservationType: RESERVATION_TYPE_BOOK_LATER,
      }
    })
}

export function getHotelBreakdownView(
  offerView: App.Checkout.LEAccommodationOfferView | App.Checkout.BedbankAccommodationOfferView,
  flightTotal: App.WithDataStatus<App.Checkout.ItemTotals>,
  hidePrice: boolean,
  isMemberOrHasSubscriptionInTheCart: boolean,
  hideCruiseV1: boolean = true,
): App.Checkout.PriceBreakdownView {
  const { offerType, durationLabel, startDate, endDate, itemViews, mainLabel, offer } = offerView
  const isCruiseV1 = offer?.type === OFFER_TYPE_TOUR && (offer)?.holidayTypes?.includes('Cruises')
  const isBedbank = isBedbankType(offerType)
  const isBNBL = offerView.reservationType == RESERVATION_TYPE_BOOK_LATER

  const guestCount = sumUpOccupancies(itemViews.map(itemView => itemView.occupancy).filter(excludeNullOrUndefined))
  const roomsAndGuestsLabel = [
    isCruiseV1 ? null : pluralizeToString('room', itemViews.length),
    guestCount && pluralizeToString('guest', guestCount),
  ].filter(excludeNullOrUndefined).join(' • ')

  const items = isBNBL ?
    getBNBLBreakdownItems(itemViews, hidePrice) :
    getHotelBreakdownItems(itemViews, offerView, hidePrice)

  const hotelPrice = sum(items, item => item.price ?? 0)
  const hotelMemberPrice = sum(items, item => item.memberPrice ?? 0)
  let price = offerView.isBundled ? hotelPrice + (flightTotal.data.price ?? 0) : hotelPrice
  const memberPrice = hotelMemberPrice > 0 ? offerView.isBundled ? hotelMemberPrice + (flightTotal.data.price ?? 0) : hotelMemberPrice : 0
  const soldOut = items.some(item => item.soldOut)

  const additionalInfoText = (isBNBL || !startDate || !endDate) ?
      [`${durationLabel} (${roomsAndGuestsLabel})`] :
      [
      `${moment(startDate).format(DMY_CASUAL_FORMAT)} - ${moment(endDate).format(DMY_CASUAL_FORMAT)}`,
      `${durationLabel} (${roomsAndGuestsLabel})`,
      ]

  const displayPricingAsPerPerson = offerType == OFFER_TYPE_BED_BANK && offer?.displayPricingAsPerPerson
  let pricePerPerson
  if (displayPricingAsPerPerson) {
    const adultsCount = sumUpOccupancies(itemViews.map(itemView => itemView.occupancy).filter(excludeNullOrUndefined), new Set(['adults']))
    if (adultsCount > 1) {
      pricePerPerson = Math.round(price / adultsCount)
    }
  }

  let hasMobilePromotion = false
  if (isBedbank) {
    hasMobilePromotion = offerView.itemViews.some(x => x?.rate?.mobilePromotion)
  }

  // This is here because we can still sell member only offers to non members when spoofing
  if (offerView.offer?.luxPlus?.access === 'memberOnly' && isMemberOrHasSubscriptionInTheCart) {
    price = memberPrice || price
  }

  return {
    title: offerView.isBundled ? 'Hotel + Flights Package' : getBreakdownTitle(offerView),
    description: mainLabel,
    additionalInfoText,
    items: isCruiseV1 && hideCruiseV1 ? [] : items,
    price: (hidePrice ? 0 : price),
    memberPrice: (hidePrice ? 0 : memberPrice),
    soldOut,
    displayPricingAsPerPerson,
    pricePerPerson,
    productType: offerView.offerType,
    hasMobilePromotion,
  }
}
