import offerTypeConfig from '../config/offer'
import { PublicOfferV2 } from '@luxuryescapes/contract-public-offer'
import { arrayToObject, min } from 'lib/array/arrayUtils'
import { isOfferDomestic } from 'lib/offer/isDomestic'

import imageMap from './imageMap'

import { getPackageUniqueKey } from 'lib/offer/offerUtils'
import { OFFER_TYPE_HOTEL, OFFER_TYPE_ALWAYS_ON } from 'constants/offer'

import { getBundlePackagesWithPrice } from 'lib/bundleAndSave/getBundlePackagesWithPrice'
import {
  mapHotelPackageOption,
  mapReserveForZero,
  mapRoomRate,
  mapRoomType,
  propertyMap,
  mapOfferUrgencyTags,
  mapOfferSchedule,
  packagePartnershipMap,
  mapTileInclusions,
  getTileInclusions,

} from './hotelOfferMap'
import config from 'constants/config'

interface RoomInfo {
  partnership: {
    [key: string]: App.PackagePartnership;
  }
  roomType: {
    [key: string]: App.PackageRoomType;
  }
  roomRate: {
    [key: string]: App.PackageRoomRate;
  }
}

// We don't actually get a 'long' version of the sale unit
// from the server, so add to this list as we find other
// types that that need extending
const saleUnitLongReplacements = {
  'apt.': 'apartment',
  apt: 'apartment',
}

function mapBundledOffer(bundledOffer: PublicOfferV2.LeBundledOffer, serverOffer: PublicOfferV2.LeHotelOffer, regionCode: string) {
  const property = propertyMap(serverOffer.properties?.[bundledOffer.fkPropertyId] ?? serverOffer.property)

  // Getting all packages which are related to current bundled offer
  const packagesIds = Object.values(serverOffer.packages)
    .filter((serverPkg) => serverPkg.fkOfferId === bundledOffer.id)
    .map((serverPkg) => serverPkg.id ?? serverPkg.lePackageId)

  // Getting all default options which are related to current bundled offer
  const defaultOptions = serverOffer.lowestOptions
    .filter((o) => packagesIds.includes(o.fkPackageId))
    .map((o) => getPackageUniqueKey(o.fkPackageId, o.duration, o.fkRoomRateId))

  const bundledOfferData: App.BundledOffer = {
    id: bundledOffer.id,
    name: bundledOffer.name,
    location: bundledOffer.location.description,
    locationHeading: bundledOffer.location.heading,
    locationSubheading: bundledOffer.location.subheading,
    type: bundledOffer.type as App.LEContractedOfferType,
    flightDestinationPort: bundledOffer.flightDestinationCode,
    isPartnerProperty: bundledOffer.isPartnerProperty,
    disableBestPriceGuarantee: bundledOffer.disableBestPriceGuarantee,
    shouldDisplayValue: bundledOffer.shouldDisplayValue,
    images: bundledOffer.images.map(imageMap),
    property,
    isDomestic: false,
    defaultOptions,
  }
  const isDomestic = isOfferDomestic(bundledOfferData, regionCode)
  return { ...bundledOfferData, isDomestic }
}

function mapBundlePackage(bundledOffer: PublicOfferV2.LeBundlePackage) {
  const bundlePackageData: App.BundlePackage = {
    id: bundledOffer.id,
    name: bundledOffer.name,
    description: bundledOffer.description,
    sortOrder: bundledOffer.sortOrder,
    options: bundledOffer.options,
  }
  return bundlePackageData
}

export function bundleOfferMap(serverOffer: PublicOfferV2.LeHotelOffer, regionCode: string): App.BundleOffer {
  const typeConfig = offerTypeConfig.bundle_and_save!

  const mappedTileInclusions = mapTileInclusions(serverOffer.inclusions.tileInclusions)
  const tileInclusions = getTileInclusions(mappedTileInclusions)

  const offer: App.BundleOffer = {
    type: typeConfig.type,
    productType: typeConfig.productType,
    walledGarden: typeConfig.walledGarden,
    hasBuyNowBookLater: typeConfig.hasBuyNowBookLater && !serverOffer.bundledWithFlightsOnly,
    hasBuyNowBookLaterPackages: false,
    parentType: typeConfig.parentType,
    typeLabel: typeConfig.typeLabel,
    overlayImageLabel: typeConfig.overlayImageLabel,
    analyticsType: typeConfig.analyticsType,
    id: serverOffer.id,
    name: serverOffer.name,
    experiencesInFlow: serverOffer.experiencesInFlow,
    experiencesCurated: serverOffer.experiencesCurated,
    showOnlyExperiencesCurated: serverOffer.showOnlyExperiencesCurated,
    location: serverOffer.location.description,
    locations: serverOffer.tags.location ?? [],
    // @ts-ignore: field might be undefined
    description: serverOffer.copy.description,
    highlights: serverOffer.copy.highlights,
    holidayTypes: serverOffer.tags.holidayTypes ?? [],
    whatWeLike: serverOffer.copy.whatWeLike,
    facilities: serverOffer.copy.facilities,
    finePrint: serverOffer.copy.finePrint,
    gettingThere: serverOffer.copy.gettingThere,
    insuranceCountries: serverOffer.insurance.countries,
    isSoldOut: serverOffer.isSoldOut ?? false,
    // @ts-ignore: field might be undefined
    bookByDate: serverOffer.schedules?.bookBy?.end,
    // @ts-ignore: field might be undefined
    travelToDate: serverOffer.schedules?.travelBy?.end,
    slug: serverOffer.slug,
    canEarnPartnershipPoints: serverOffer.partnerships.length > 0,
    durationLabel: serverOffer.durationLabel,
    tileDurationLabel: serverOffer.durationLabel,
    images: serverOffer.images.map(imageMap),
    image: imageMap(serverOffer.images[0]),
    visibilitySchedules: {
      // @ts-ignore: schedules field might be undefined
      world: serverOffer.schedules.listVisibility ? mapOfferSchedule(serverOffer.schedules.listVisibility) : undefined,
      // @ts-ignore: schedules field might be undefined
      [regionCode]: serverOffer.schedules.listVisibility ? mapOfferSchedule(serverOffer.schedules.listVisibility) : undefined,
    },
    // @ts-ignore: schedules field might be undefined
    onlinePurchaseSchedule: serverOffer.schedules.onlinePurchase ? mapOfferSchedule(serverOffer.schedules.onlinePurchase) : undefined,
    // @ts-ignore: schedules field might be undefined
    availabilitySchedule: serverOffer.schedules.availability ? mapOfferSchedule(serverOffer.schedules.availability) : undefined,
    // @ts-ignore: field might be undefined
    panelImageId: serverOffer.panelImage?.id,
    vimeoVideoId: serverOffer.video?.id,
    locationHeading: serverOffer.location.heading,
    locationSubheading: serverOffer.location.subheading,
    noIndex: serverOffer.noIndex,
    saleUnit: serverOffer.saleUnit,
    saleUnitLong: saleUnitLongReplacements[serverOffer.saleUnit] ?? serverOffer.saleUnit,
    flightPrices: serverOffer.flights?.[0]?.prices ?? {},
    flightDestinationPort: serverOffer.flights?.[0]?.destinationCode,
    flightsMaxArrivalTime: serverOffer.flights?.[0]?.latestDestinationArrivalTime,
    flightsMinReturningDepartureTime: serverOffer.flights?.[0]?.earliestDestinationDepartureTime,
    flightCacheDisabled: serverOffer.flights?.[0]?.cacheDisabled,
    flightsWarningHeadline: serverOffer.flights?.[0]?.warning?.heading,
    flightsWarningPopupBody: serverOffer.flights?.[0]?.warning?.description,
    urgencyTags: mapOfferUrgencyTags(serverOffer.tags.urgency),
    shouldDisplayValue: serverOffer.shouldDisplayValue,
    // @ts-ignore: maybe field should be optional?
    exclusiveExtras: undefined,
    exclusiveExtrasLarge: serverOffer.inclusions.description,
    inclusions: serverOffer.inclusions.description,
    inclusionsHeading: serverOffer.inclusions.heading,
    // @ts-ignore: field might be undefined
    additionalDescription: serverOffer.copy.additionalDescription,
    // tileDescription will be used as precaution fallback until migration is finished. TODO OPEX-1008 remove tileDescription
    tileDescription: serverOffer.inclusions.tileDescription,
    tileInclusions,
    tileInclusionsHeading: serverOffer.inclusions.tileHeading,
    // @ts-ignore: field might be undefined
    daysBeforeCheckInChangesDisallowed: serverOffer.daysBeforeCheckInChangesDisallowed,
    flightsWarningEnabled: !!serverOffer.flights?.[0]?.warning,
    offerFlightsEnabled: config.FLIGHT_ENABLED && (serverOffer.flights?.length ?? 0) > 0,
    // @ts-ignore: maybe field should be optional?
    pageViews: undefined,
    // @ts-ignore: maybe field should be optional?
    totalPurchases: undefined,
    badge: config.BRAND === 'luxuryescapes' ? serverOffer.badge : undefined,
    bundledWithFlightsOnly: serverOffer.bundledWithFlightsOnly,
    whitelistedCarrierCodes: serverOffer.whitelistedCarrierCodes ?? [],
    disableDeposit: serverOffer.disableDeposit,
    depositThresholds: serverOffer.depositThresholds,
    packages: [],
    defaultOptions: [],
    isPartnerProperty: serverOffer.isPartnerProperty,
    numberOfDateChangesAllowed: serverOffer.numberOfDateChanges ?? 'Unlimited',
    packageUpgradesAllowed: serverOffer.type === OFFER_TYPE_HOTEL && serverOffer.packageUpgradesAllowed,
    disableBestPriceGuarantee: serverOffer.disableBestPriceGuarantee,
    minDuration: Math.min(...serverOffer.options.map(o => o.duration)),
    ...(serverOffer.reserveForZero && { reserveForZero: mapReserveForZero(serverOffer.reserveForZero) }),
    ...((serverOffer.type === OFFER_TYPE_HOTEL || serverOffer.type === OFFER_TYPE_ALWAYS_ON) && serverOffer.forceBundleId && { forceBundleId: serverOffer.forceBundleId }),
    vendorName: serverOffer.vendorName,
    property: propertyMap(serverOffer.property),
    hasHiddenCancellationPolicy: Object.values(serverOffer.ratePlans).some(r => r.cancellationPolicy?.type === 'hidden-cancellation-policy'),
    bundledOffers: arrayToObject(Object.values(serverOffer.bundledOffers ?? {}),
      item => item.id,
      item => mapBundledOffer(item, serverOffer, regionCode),
    ),
    bundlePackages: arrayToObject(Object.values(serverOffer.bundlePackages ?? {}),
      item => item.id,
      item => mapBundlePackage(item),
    ),
    isDiscountPillHidden: serverOffer.isDiscountPillHidden,
    luxPlus: {},
    isAgentHubExclusive: config.agentHub.isEnabled && Boolean(serverOffer.isAgentHubExclusive),
  }

  const roomInfo = serverOffer.options.reduce<RoomInfo>((acc, serverOption) => {
    if (!acc.partnership[serverOption.fkPackageId]) {
      acc.partnership[serverOption.fkPackageId] = packagePartnershipMap(serverOffer.packages[serverOption.fkPackageId].partnerships)!
    }
    if (!acc.roomType[serverOption.fkRoomTypeId]) {
      acc.roomType[serverOption.fkRoomTypeId] = mapRoomType(serverOffer.roomTypes[serverOption.fkRoomTypeId])
    }
    if (!acc.roomRate[serverOption.fkRoomRateId]) {
      acc.roomRate[serverOption.fkRoomRateId] = mapRoomRate(serverOffer.roomRates[serverOption.fkRoomRateId], serverOffer.ratePlans[serverOption.fkRatePlanId])
    }
    return acc
  }, { roomType: {}, roomRate: {}, partnership: {} })

  offer.packages = serverOffer.options.map(option => mapHotelPackageOption({
    serverOption: option,
    serverOffer,
    roomInfo,
  }))
  offer.hasTactical = offer.packages.some(p => p.hasTactical)

  // Generating all unique options keys
  offer.defaultOptions = serverOffer.lowestOptions.map((o) => getPackageUniqueKey(o.fkPackageId, o.duration, o.fkRoomRateId))
  const bundlePackagesWithPrice = getBundlePackagesWithPrice(offer)
  offer.lowestPriceBundlePackage = min(bundlePackagesWithPrice, pkg => pkg.price || Number.MAX_SAFE_INTEGER)!
  offer.tileDurationLabel = offer.lowestPriceBundlePackage?.durationLabel ?? ''

  return offer
}
