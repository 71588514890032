import { ValueOf } from 'type-fest'
import { createContext } from 'react'
import noop from '../../../../lib/function/noop'

export enum SearchTileEvents {
  clickViewSimilarAltDates,
  viewAlternativeDatesBanner,
}

export type SearchTileEventsDispatchAction = ValueOf<Utils.FullActionMap<{
  [SearchTileEvents.clickViewSimilarAltDates]: {
    checkIn: string;
    checkOut: string;
  }
  [SearchTileEvents.viewAlternativeDatesBanner]: {
    checkIn: string;
    checkOut: string;
  }
}>>

export type SearchTileEventsHandler = (
  dispatchAction: SearchTileEventsDispatchAction,
) => void

const SearchTileEventsContext = createContext<SearchTileEventsHandler>(noop)

export default SearchTileEventsContext
