import React, { useContext, useEffect } from 'react'
import { connect } from 'react-redux'
import ModalContext from 'contexts/ModalContext'
import { useAppDispatch } from 'hooks/reduxHooks'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { checkCanViewLuxPlusBenefits, isEligibleLuxPlusFreePreview } from 'luxPlus/selectors/featureToggle'
import { pushWithRegion } from 'actions/NavigationActions'
import offerPageURL from 'lib/offer/offerPageURL'
import LuxPlusHiddenOfferModal from '../modals/LuxPlusHiddenOfferModal'
import LuxPlusFreePreviewModal from '../modals/LuxPlusFreePreviewModal'
import Modal from 'components/Luxkit/Modal/Modal'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import styled from 'styled-components'
import { rem } from 'polished'
import useLuxPlusFreePreviewUtils from 'luxPlus/hooks/useLuxPlusFreePreviewUtils'

const StyledLoadingIndicator = styled(LoadingIndicator)`
min-height: ${rem(200)};
`

interface MappedProps {
  isLoggedIn: boolean;
  isEligibleForFreePreview: boolean;
  canViewLuxPlusBenefits: boolean;
  isLoadingEligibilityOrSubscription: boolean;
}

interface Props extends MappedProps {
  offer: App.Offer | App.OfferSummary;
}

function LuxPlusHiddenOfferTransitionModal(props: Props) {
  const { offer, isEligibleForFreePreview, canViewLuxPlusBenefits, isLoadingEligibilityOrSubscription } = props
  const {
    showFreePreviewSignup,
  } = useLuxPlusFreePreviewUtils()

  const showModal = useContext(ModalContext)
  const dispatch = useAppDispatch()
  const context = useModalElementContext()
  useEffect(() => {
    if (isLoadingEligibilityOrSubscription) return
    context.resolve()
    if (!canViewLuxPlusBenefits) {
      if (showFreePreviewSignup) {
        showModal(<LuxPlusFreePreviewModal />)
      } else {
        showModal(<LuxPlusHiddenOfferModal />)
      }
    } else {
      dispatch(pushWithRegion(offerPageURL(offer)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEligibleForFreePreview, canViewLuxPlusBenefits, isLoadingEligibilityOrSubscription])

  return <Modal title="Loading">
    <StyledLoadingIndicator floating visible />
  </Modal>
}

const mapStateToProps = (state: App.State): MappedProps => ({
  isLoggedIn: selectLoggedIn(state),
  isEligibleForFreePreview: isEligibleLuxPlusFreePreview(state),
  canViewLuxPlusBenefits: checkCanViewLuxPlusBenefits(state),
  isLoadingEligibilityOrSubscription: state.luxPlus.products.freePreview.fetching || state.luxPlus.member.subscription.fetching,
})

export default connect(mapStateToProps)(LuxPlusHiddenOfferTransitionModal)
