import { API_CALL_FAILURE, API_CALL_REQUEST, API_CALL_SUCCESS, CLEAR_LUX_PLUS_MEMBER_SUBSCRIPTION, TOGGLE_SHOP_AS_MEMBER } from 'actions/actionConstants'
import { CREATE_LUX_PLUS_MEMBER_FREE_PREVIEW, FETCH_LUX_PLUS_MEMBER_FREE_PREVIEW_ELIGIBILITY, FETCH_LUX_PLUS_MEMBER_SUBSCRIPTION, FETCH_LUX_PLUS_PREFERRED_PAYMENT_METHOD, FETCH_LUX_PLUS_PRODUCT_SUBSCRIPTION, UPDATE_LUX_PLUS_MEMBER_SUBSCRIPTION, USER_LOGIN } from 'actions/apiActionConstants'
import { createReducer, reducerSwitch } from 'lib/redux/reducerUtils'

export const initialLuxPlusState: App.LuxPlusState = {
  member: {
    /** This is used so spoofed customers can be toggled between buying as a member or as a non member */
    disableMembership: false,
    subscription: {
      item: undefined,
      fetching: false,
      error: undefined,
      preferredPaymentMethod: undefined,
    },
  },
  products: {
    subscription: {
      offers: undefined,
      fetching: false,
      error: undefined,
    },
    freePreview: {
      offer: undefined,
      fetching: false,
      error: undefined,
    },
  },
}

const apiRequests = reducerSwitch<App.LuxPlusState>({
  [FETCH_LUX_PLUS_PRODUCT_SUBSCRIPTION]: (state) => ({
    products: {
      ...state.products,
      subscription: {
        fetching: true,
        error: undefined,
      },
    },
  }),
  [FETCH_LUX_PLUS_MEMBER_SUBSCRIPTION]: (state) => ({
    member: {
      ...state.member,
      subscription: {
        fetching: true,
        error: undefined,
      },
    },
  }),
  [UPDATE_LUX_PLUS_MEMBER_SUBSCRIPTION]: (state) => ({
    member: {
      ...state.member,
      subscription: {
        fetching: true,
        error: undefined,
      },
    },
  }),
  [FETCH_LUX_PLUS_MEMBER_FREE_PREVIEW_ELIGIBILITY]: (state) => ({
    products: {
      ...state.products,
      freePreview: {
        fetching: true,
        error: undefined,
      },
    },
  }),
  [CREATE_LUX_PLUS_MEMBER_FREE_PREVIEW]: (state) => ({
    member: {
      ...state.member,
      subscription: {
        fetching: true,
        error: undefined,
      },
    },
  }),
  [FETCH_LUX_PLUS_PREFERRED_PAYMENT_METHOD]: (state) => ({
    member: {
      ...state.member,
      subscription: {
        ...state.member.subscription,
        preferredPaymentMethod: undefined,
      },
    },
  }),
})

const apiSuccesses = reducerSwitch<App.LuxPlusState>({
  [USER_LOGIN]: (state, action) => ({
    member: {
      ...state.member,
      subscription: {
        item: action.data.account?.luxPlusMemberSubscription?.item,
        error: action.data.account?.luxPlusMemberSubscription?.error,
        fetching: false,
      },
    },
  }),
  [FETCH_LUX_PLUS_PRODUCT_SUBSCRIPTION]: (state, action) => ({
    products: {
      ...state.products,
      subscription: {
        offers: action.data,
        fetching: false,
        error: undefined,
      },
    },
  }),
  [FETCH_LUX_PLUS_MEMBER_SUBSCRIPTION]: (state, action) => ({
    member: {
      ...state.member,
      subscription: {
        item: action.data,
        fetching: false,
        error: undefined,
      },
    },
  }),
  [UPDATE_LUX_PLUS_MEMBER_SUBSCRIPTION]: (state, action) => ({
    member: {
      ...state.member,
      subscription: {
        item: action.data,
        fetching: false,
        error: undefined,
      },
    },
  }),
  [FETCH_LUX_PLUS_MEMBER_FREE_PREVIEW_ELIGIBILITY]: (state, action) => ({
    products: {
      ...state.products,
      freePreview: {
        offer: action.data,
        fetching: false,
        error: undefined,
      },
    },
  }),
  [CREATE_LUX_PLUS_MEMBER_FREE_PREVIEW]: (state, action) => ({
    member: {
      ...state.member,
      subscription: {
        item: action.data,
        fetching: false,
        error: undefined,
      },
    },
  }),
  [FETCH_LUX_PLUS_PREFERRED_PAYMENT_METHOD]: (state, action) => ({
    member: {
      ...state.member,
      subscription: {
        ...state.member.subscription,
        preferredPaymentMethod: action.data.paymentMethod,
      },
    },
  }),
})

const apiFailures = reducerSwitch<App.LuxPlusState>({
  [FETCH_LUX_PLUS_PRODUCT_SUBSCRIPTION]: (state, action) => ({
    products: {
      ...state.products,
      subscription: {
        fetching: false,
        error: action.error,
      },
    },
  }),
  [FETCH_LUX_PLUS_MEMBER_SUBSCRIPTION]: (state, action) => ({
    member: {
      ...state.member,
      subscription: {
        fetching: false,
        error: action.error,
      },
    },
  }),
  [UPDATE_LUX_PLUS_MEMBER_SUBSCRIPTION]: (state, action) => ({
    member: {
      ...state.member,
      subscription: {
        fetching: false,
        error: action.error,
      },
    },
  }),
  [FETCH_LUX_PLUS_MEMBER_FREE_PREVIEW_ELIGIBILITY]: (state, action) => ({
    products: {
      ...state.products,
      freePreview: {
        fetching: false,
        error: action.error,
      },
    },
  }),
  [CREATE_LUX_PLUS_MEMBER_FREE_PREVIEW]: (state, action) => ({
    member: {
      ...state.member,
      subscription: {
        fetching: false,
        error: action.error,
      },
    },
  }),
})

export default createReducer<App.LuxPlusState>(initialLuxPlusState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [CLEAR_LUX_PLUS_MEMBER_SUBSCRIPTION]: (state, _action) => ({
    member: {
      ...state.member,
      subscription: {
        ...state.member.subscription,
      },
    },
    products: {
      ...state.products,
      freePreview: {
        ...initialLuxPlusState.products.freePreview,
      },
    },
  }),
  [TOGGLE_SHOP_AS_MEMBER]: (state, action) => ({
    member: {
      ...state.member,
      disableMembership: action.disableMembership,
    },
  }),
})
