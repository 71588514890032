import React, { ComponentProps, useContext, useMemo } from 'react'
import GeoContext from 'contexts/geoContext'
import getUrgencyLabels from 'lib/offer/getUrgencyLabels'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import PromotionLabel from 'components/Luxkit/Label/PromotionLabel'
import UrgencyLabel from 'components/Luxkit/Label/UrgencyLabel'
import OfferUrgencyCountdownLabel from './OfferUrgencyCountdownLabel'
import LineAwardIcon from 'components/Luxkit/Icons/line/LineAwardIcon'
import LuxPlusLabel from 'luxPlus/components/LuxPlusLabel'
import LuxPlusLabelWithModal from 'luxPlus/components/LuxPlusLabelWithModal'
import { take } from 'lib/array/arrayUtils'
import LabelCountContext from 'contexts/LabelCountContext'

type UrgencyLabelVariant = React.ComponentProps<typeof UrgencyLabel>['variant']

const urgencyLabelTypeToVariant: Utils.PartialRecord<App.UrgencyLabelType, UrgencyLabelVariant> = {
  critical: 'warning',
  fast: 'speed',
  last_minute_hotel_travel_in: 'critical1',
  limited: 'warning',
  new: 'positive',
}

interface Props extends ComponentProps<typeof LabelGroup> {
  offer?: App.Offer | App.OfferSummary;
  urgencyLabels?: Array<App.OfferUrgencyLabel>;
  showLimitedAvailability?: boolean;
  countDownShowHourOnly?: boolean,
  saveAmount?: number;
  showMemberInclusionLabels?: boolean;
  memberInclusionsAmount?: number;
  luxPlusTileInclusions?: Array<App.TileInclusion>;
  luxPlusInclusions?: Array<App.PackageInclusion>;
}

const manuallyHandled = new Set<App.UrgencyLabelType>([
  'left',
  'popular',
  'lux_plus_general_release',
  'lux_plus_early_access',
  'lux_plus_member_only',
  'lux_plus_member_inclusions',
  'lux_plus_member_price',
  'lux_plus_member_inclusions',
])

const OfferLabels = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    offer,
    urgencyLabels,
    showLimitedAvailability,
    countDownShowHourOnly = false,
    saveAmount,
    memberInclusionsAmount,
    showMemberInclusionLabels,
    luxPlusTileInclusions,
    luxPlusInclusions,
    ...rest
  } = props

  const { currentRegionCode } = useContext(GeoContext)
  const maxLabels = useContext(LabelCountContext)

  const labels = useMemo(() => {
    const baseLabels = urgencyLabels ?? (offer && getUrgencyLabels(offer, currentRegionCode, showLimitedAvailability)) ?? []

    if (offer?.luxPlus?.access === 'earlyAccess') {
      baseLabels.unshift({ type: 'lux_plus_early_access' })
    } else if (offer?.luxPlus?.access === 'memberOnly') {
      baseLabels.unshift({ type: 'lux_plus_member_only' })
    } else if (saveAmount) {
      baseLabels.unshift({ type: 'lux_plus_member_price' })
    }

    if (offer?.luxPlus?.hasMemberInclusions && showMemberInclusionLabels) {
      baseLabels.unshift({ type: 'lux_plus_member_inclusions' })
    }

    if (offer?.isAgentHubExclusive) {
      baseLabels.unshift({ type: 'agenthub_exclusive' })
    }

    return maxLabels ? take(baseLabels, maxLabels) : baseLabels
  }, [urgencyLabels, offer, currentRegionCode, showLimitedAvailability, saveAmount, showMemberInclusionLabels, maxLabels])

  return <>
    {!!labels.length && <LabelGroup {...rest} ref={ref}>
      {labels.map(label => <React.Fragment key={label.type}>
        {/*
            The general release tag is only used for LuxPlus+ early access offers
            however the logic is slightly different to lux_plus_early_access so we need to handle it separately
        */}
        {label.type === 'lux_plus_general_release' && label.end && <OfferUrgencyCountdownLabel endDate={label.end} data-testid="UrgencyLabel" showHourOnly={countDownShowHourOnly} isLuxPlusEarlyAccess />}
        {label.type === 'lux_plus_early_access' && offer && <LuxPlusLabelWithModal type="early-access-offer" saveAmount={saveAmount} offer={offer} />}
        {label.type === 'lux_plus_member_only' && <LuxPlusLabel type="member-only-offer" />}
        {label.type === 'lux_plus_member_price' && offer && saveAmount && <LuxPlusLabelWithModal type="member-price-offer" saveAmount={saveAmount} offer={offer} tileInclusions={luxPlusTileInclusions} />}
        {label.type === 'lux_plus_member_inclusions' && offer && <LuxPlusLabelWithModal type="bonus-inclusions" offer={offer} inclusions={luxPlusInclusions} />}
        {label.type === 'agenthub_exclusive' && <PromotionLabel key="agentHubExclusive" variant="engagement">Agent Exclusive</PromotionLabel>}
        {label.type === 'left' && label.end && <OfferUrgencyCountdownLabel endDate={label.end} data-testid="UrgencyLabel" showHourOnly={countDownShowHourOnly} />}
        {label.type === 'popular' && label.message && <PromotionLabel variant="default" icon={<LineAwardIcon />} data-testid="UrgencyLabel">{label.message}</PromotionLabel>}
        {!manuallyHandled.has(label.type) && label.message && urgencyLabelTypeToVariant[label.type] && <UrgencyLabel
          data-testid="UrgencyLabel"
          variant={urgencyLabelTypeToVariant[label.type]!}
        >
          {label.message}
        </UrgencyLabel>}
      </React.Fragment>)}
    </LabelGroup>}
  </>
})

OfferLabels.displayName = 'OfferLabels'

export default OfferLabels
