import { Agent, Chat, Feedback, Message } from '@luxuryescapes/contract-support'

export function mapChat(result: Chat): App.Chat {
  const chat: App.Chat = {
    agents: result.agents.map<Agent>((agent) => {
      return {
        email: agent.email,
        id: agent.id,
        name: agent.name,
        title: agent.title,
      }
    }),
    agentType: result.agentType,
    createdAt: result.createdAt,
    customerId: result.customerId ?? undefined,
    id: result.id,
    lastMessageAt: result.lastMessageAt ?? undefined,
    status: result.status,
    title: result.title,
    updatedAt: result.updatedAt,
  }

  return chat
}

export function mapChats(result: Array<Chat>): Array<App.Chat> {
  const chats = result.map(mapChat)
  return chats
}

export function mapChatMessage(result: Message): App.ChatMessage {
  const chatMessage: App.ChatMessage = {
    createdAt: result.createdAt ?? new Date().toString(),
    feedback: result.feedback,
    id: result.id as string,
    meta: {
      action: result.meta?.action as App.ChatActions,
      content: result.meta?.content?.map((content) => {
        return {
          text: content.text ?? undefined,
          type: content.type ?? undefined,
          category: content.category ?? undefined,
          action: content.action,
          validation: {
            regex: content.validation?.regex ?? '',
            message: content.validation?.message ?? '',
          },
        }
      }),
      payload: {
        awaitingDates: result.meta?.payload?.awaitingDates,
        bookingNumber: result.meta?.payload?.bookingNumber,
        canBeCancelled: result.meta?.payload?.canBeCancelled,
        canChangeDates: result.meta?.payload?.canChangeDates,
        daysBeforeCheckInChangesDisallowed: result.meta?.payload?.daysBeforeCheckInChangesDisallowed,
        canChangeRooms: result.meta?.payload?.canChangeRooms,
        chatId: result.meta?.payload?.chatId,
        checkInDate: result.meta?.payload?.checkInDate,
        customerId: result.meta?.payload?.customerId,
        email: result.meta?.payload?.email,
        firstName: result.meta?.payload?.firstName,
        id_orders: result.meta?.payload?.id_orders,
        inputPayload: result.meta?.payload?.inputPayload,
        itemId: result.meta?.payload?.itemId,
        lastName: result.meta?.payload?.lastName,
        maskedPhone: result.meta?.payload?.maskedPhone,
        orderType: result.meta?.payload?.orderType,
      },
    },
    sender: result.sender,
    text: result.text,
    userId: result.userId,

  }

  return chatMessage
}

export function mapChatMessages(result: Chat): Array<App.ChatMessage> {
  return result.messages?.map(mapChatMessage) ?? []
}

export function mapMessageFeedback(result: Feedback): App.ChatMessageFeedback {
  const messageFeedback: App.ChatMessageFeedback = {
    chatId: result.chatId,
    customerId: result.customerId ?? undefined,
    id: result.id,
    issues: result.issues,
    message: result.message ?? undefined,
    messageId: result.messageId,
    sentiment: result.sentiment,
  }

  return messageFeedback
}
