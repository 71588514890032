import equal from 'fast-deep-equal'

import { CheckoutAction } from 'actions/CheckoutActions'
import {
  ACTIVATE_REBOOKING,
  API_CALL_FAILURE,
  API_CALL_REQUEST,
  API_CALL_SUCCESS,
  CHECKOUT_ADD_ITEM,
  CHECKOUT_APPLY_PARTNERSHIP_STATUS,
  CHECKOUT_APPLY_PROMO_CODE,
  CHECKOUT_APPLY_PROMO_CODE_FAILED,
  CHECKOUT_APPLY_VELOCITY_BURN,
  CHECKOUT_CLEAR_CART,
  CHECKOUT_CLEAR_VELOCITY_BURN, CHECKOUT_EDIT_FORM,
  CHECKOUT_FETCHING_PROMO_CODE,
  CHECKOUT_HIDE_PROMO_CORPORATE_BENEFITS,
  CHECKOUT_INITIALISE,
  CHECKOUT_MODIFY_FLIGHT_SEARCH_VIEW,
  CHECKOUT_PROCESS_CANCEL,
  CHECKOUT_PROCESS_FAILURE,
  CHECKOUT_PROCESS_START,
  CHECKOUT_PROCESS_SUCCESS,
  CHECKOUT_REMOVE_ITEM,
  CHECKOUT_RESET_FLIGHT_ITEMS,
  CHECKOUT_RESET_PROMO_CODE,
  CHECKOUT_RESTORE_CART,
  CHECKOUT_REMOVE_TRAVELLER_SCHEMA,
  CHECKOUT_RESTORE_FORM,
  CHECKOUT_RESTORE_PAYMENT,
  CHECKOUT_SET_FORM,
  CHECKOUT_SELECT_NO_INSURANCE,
  CHECKOUT_SET_CONNECT_ROOMS_SPECIAL_REQUEST,
  CHECKOUT_SET_RESTORE_CART_STATUS,
  CHECKOUT_TOGGLE_DEV_TOOLS,
  CHECKOUT_SET_INSURANCE_FETCH_PARAMETERS,
  CHECKOUT_SET_LUXPLUS_UPSELL_DATA,
  CHECKOUT_SET_POST_PURCHASE,
  CHECKOUT_SET_ROOMS_TO_BE_CONNECTED,
  CHECKOUT_SET_SPECIAL_REQUEST,
  CHECKOUT_SET_VELOCITY_BURN_MODAL_STATUS,
  CHECKOUT_SET_VELOCITY_SSO,
  CHECKOUT_SHOW_PROMO_CORPORATE_BENEFITS,
  CHECKOUT_SHOW_PROMO_FRIENDS_AND_FAMILY,
  CHECKOUT_HIDE_PROMO_FRIENDS_AND_FAMILY,
  CHECKOUT_TOGGLE_CREDIT,
  CHECKOUT_UPDATE_ARRIVAL_FLIGHT_NUMBER,
  CHECKOUT_UPDATE_BUNDLE_ITEM_PRICING, CHECKOUT_UPDATE_CS_DEPOSIT_OVERRIDE,
  CHECKOUT_UPDATE_EXPERIENCE_ITEM,
  CHECKOUT_UPDATE_FLIGHT_ITEM_PAX_BAGGAGE,
  CHECKOUT_UPDATE_FLIGHT_TOTAL_FARE,
  CHECKOUT_UPDATE_HOTEL_ITEM_PRICING,
  CHECKOUT_UPDATE_ITEM,
  CHECKOUT_UPDATE_PAYMENT_SELECTION,
  CHECKOUT_SET_MULTI_CART_ITEM_MODE,
  CHECKOUT_SET_MERCHANT_FEE_PAYMENT_TYPE,
  CHECKOUT_RESET_FORM,
  CHECKOUT_CLEAR_CART_RESTORE_PRICE,
  CHECKOUT_AGENT_BOOKING_DETAILS,
  CHECKOUT_SET_STRIPE_PAYMENT_METHOD,
  CHECKOUT_BUSINESS_BOOKING_DETAILS,
  CHECKOUT_ADD_ARRIVAL_DETAILS,
  CHECKOUT_SET_ARRIVAL_DETAILS_TIME,
  CHECKOUT_SET_ARRIVAL_DETAILS_FLIGHT_NUMBER,
  CHECKOUT_SET_PAYTO_BANK,
  CHECKOUT_SELECT_NO_TRAVEL_PROTECTION,
  CHECKOUT_SET_COMMS_RESUBSCRIBE,
  CHECKOUT_FETCHING_PAYMENT_LINK,
  CHECKOUT_FETCHING_PAYMENT_LINK_FAILED,
  CHECKOUT_SET_PAYMENT_LINK,
  CHECKOUT_SET_ORDER_ID,
} from 'actions/actionConstants'
import {
  CHECKOUT_CREATE_CART_QUOTE,
  CHECKOUT_UPDATE_CART_QUOTE,
  CHECKOUT_FETCH_BEDBANK_EXISTING_ORDER,
  CHECKOUT_INIT_BEDBANK_CHANGE_DATES_SESSION,
  CHECKOUT_REQUEST_CORPORATE_BENEFITS_PROMO,
  CHECKOUT_REQUEST_FRIENDS_AND_FAMILY_PROMO,
  CREATE_STRIPE_PAYMENT_METHOD,
  FETCH_COMMMISSION_PROMO,
  FETCH_FINAL_FLIGHT_FARES,
  FETCH_TRAVELLER_FORM_SCHEMA,
  FETCH_VELOCITY_MEMBER_DETAILS,
  UPDATE_BEDBANK_SESSION,
  FETCH_COMMISSION_PROMO_CODE,
} from 'actions/apiActionConstants'
import { CHECKOUT_ITEM_TYPE_BEDBANK, CHECKOUT_ITEM_TYPE_EXPERIENCE } from 'constants/checkout'
import { getConnectRoomsSpecialRequestMessage, isBundleAndSaveItem, isFlightItem, isInsuranceItem } from 'lib/checkout/checkoutUtils'
import { initialFormState, isValidFormState } from 'lib/checkout/form'
import { mergeObjects, omitKeys } from 'lib/object/objectUtils'
import { createReducer, reducerSwitch } from 'lib/redux/reducerUtils'
import uuidV4 from 'lib/string/uuidV4Utils'
import { generateNoProtectionItem, generateNoTravelProtectionItem } from 'lib/checkout/insurance/cart'
import { VELOCITY_SSO_STATUS } from 'constants/partnerships'
import { sortTravellers } from 'checkout/lib/utils/form/sortTravellers'
import config from 'constants/config'

export const initialState: App.CheckoutState = {
  cart: {
    businessBookingDetails: null,
    cartId: null,
    regionCode: null,
    currencyCode: null,
    items: [],
    specialRequests: {},
    insurance: null,
    transactionKey: uuidV4(),
    isGift: false,
    isDevToolsEnabled: false,
    isAbandonedCart: false,
    arrivalFlightNumber: null,
    isMultiItemMode: config.UNIVERSAL_CHECKOUT_MULTI_CART_ITEMS_ENABLED,
    restoredCartOriginalTotal: null,
    restoredCartOriginalMemberTotal: null,
    agentBookingDetails: null,
    csCartQuoteStatus: undefined,
    csCartQuoteId: undefined,
    arrivalDetails: {},
  },
  form: initialFormState,
  payment: {
    useCredit: false,
    paymentSelected: 'full',
    partnerships: {},
    modals: {
      velocity: {
        isActive: false,
        isClean: true,
      },
    },
    sso: {
      velocity: {
        loginStatus: VELOCITY_SSO_STATUS.LOGGED_OUT,
        isAuthenticated: false,
        token: undefined,
        isTokenExpired: false,
        paymentAttempted: undefined,
      },
    },
  },
  isPaymentRestored: false,
  schemaRequestData: null,
  isFormRestored: false,
  schemaData: null,
  isFetchingSchemaData: false,
  isFetchingVelocityData: false,
  isCartRestored: false,
  isFetchingBedbankOrder: false,
  isCreatingBedbankChangeDatesSession: false,
  error: undefined,
  promo: undefined,
  commission: undefined,
  corporatePromoForm: {
    state: 'hidden',
  },
  friendsAndFamilyPromoForm: {
    state: 'hidden',
  },
  processing: false,
  processed: false,
  cardProcessingErrorCount: 0,
  restoreCart: {
    status: 'idle',
    errorMsg: null,
  },
  modifyFlightView: 'home',
  csDepositPercentageOverride: false,
  merchantFeePaymentType: null,
  orderFailure: false,
  insuranceAgentUpsellData: undefined,
  luxPlusUpsellData: undefined,
  stripePaymentMethod: {
    isCreatingStripePaymentMethod: false,
    paymentMethodId: undefined,
    cardBrand: undefined,
    cardFunding: undefined,
  },
  selectedPayToBank: undefined,
  securePayment: {
    isFetchingLink: false,
    securePaymentLink: undefined,
  },
  orderId: undefined,
}

const apiRequests = reducerSwitch<App.CheckoutState>({
  [FETCH_COMMISSION_PROMO_CODE]: () => ({
    isFetchingCommmission: true,
    commissionPromoCode: undefined,
  }),
  [FETCH_COMMMISSION_PROMO]: () => ({
    isFetchingCommmission: true,
  }),
  [FETCH_TRAVELLER_FORM_SCHEMA]: (state, action) => ({
    schemaRequestData: action.schemaRequestData,
    isFetchingSchemaData: true,
  }),
  [FETCH_FINAL_FLIGHT_FARES]: (state, action) => {
    const { itemId } = action
    return {
      cart: {
        ...state.cart,
        items: state.cart.items.map((item) =>
          (item.itemId !== itemId) ? item : {
            ...item,
            hasFinalFare: false,
            fetchingFare: true,
            fareFetchError: undefined,
          }),
      },
    }
  },
  [CHECKOUT_REQUEST_CORPORATE_BENEFITS_PROMO]: (state, action) => ({
    corporatePromoForm: {
      ...state.corporatePromoForm,
      emailSubmitted: action.submittedEmail,
      state: 'loading',
    },
  }),
  [CHECKOUT_REQUEST_FRIENDS_AND_FAMILY_PROMO]: (state, action) => ({
    friendsAndFamilyPromoForm: {
      ...state.friendsAndFamilyPromoForm,
      emailSubmitted: action.submittedEmail,
      state: 'loading',
    },
  }),
  [FETCH_VELOCITY_MEMBER_DETAILS]: (state) => ({
    payment: {
      ...state.payment,
      partnerships: {
        ...state.payment.partnerships,
      },
    },
    isFetchingVelocityData: true,
  }),
  [CREATE_STRIPE_PAYMENT_METHOD]: () => ({
    stripePaymentMethod: {
      isCreatingStripePaymentMethod: true,
      cardBrand: undefined,
      cardFunding: undefined,
      paymentMethodId: undefined,
    },
  }),
  [CHECKOUT_FETCH_BEDBANK_EXISTING_ORDER]: () => ({
    isCartRestored: false,
    isFetchingBedbankOrder: true,
  }),
  [CHECKOUT_INIT_BEDBANK_CHANGE_DATES_SESSION]: () => ({
    isCreatingBedbankChangeDatesSession: true,
  }),
  [CHECKOUT_CREATE_CART_QUOTE]: (state) => ({
    cart: {
      ...state.cart,
      csCartQuoteStatus: 'loading',
    },
  }),
  [CHECKOUT_UPDATE_CART_QUOTE]: (state) => ({
    cart: {
      ...state.cart,
      csCartQuoteStatus: 'loading',
    },
  }),
})

const calculateFirstNotEditedField = (travellerFormLength: number, schemaPropertiesLength: number) => {
  if (schemaPropertiesLength > travellerFormLength) return travellerFormLength
  if (schemaPropertiesLength === 1) return 0
  return schemaPropertiesLength
}

/**
 * Rebuild the state of the traveller form provided a traveller form schema
 * This includes rebuilding the presentation state
 *
 *   i.e.
 *    - Which traveller form was being edited
 *    - Which traveller forms were "active" / "inactive"
 *
 * @param formState - A snapshot of `App.Checkout.FormState`
 * @param schema - Traveller form schema retrieved from `svc-traveller`
 *
 * @remarks
 * One key thing to note is that `formState` can persist across checkout sessions
 *
 * Preserving the presentation state is best-effort, as it can be impossible to fully rebuild some states
 *
 *  i.e.
 *    - Rebuilding from a previous `formState` where there is now one less traveller
 *
 */
const rebuildFormState = (
  formState: App.Checkout.FormState,
  schema: App.Checkout.FormSchema,
): App.Checkout.FormState => {
  const isValid = isValidFormState(schema, formState)
  if (isValid) {
  // Previous activeHeadIdx is no longer applicable when it exceeds the number of travellerForms
    const newActiveHeadIdx = formState.activeHeadIdx > formState.travellerForms.length ?
      formState.travellerForms.length :
      formState.activeHeadIdx

    // Preserve the editIdx if possible:
    // - editIdx is greater than activeHeadIdx (upper limit)
    //   this happens when there are less traveller forms than previously
    // - editIdx is null -> set to activeHeadIdx
    //   this happens when the form state was previously in a state when nothing was being edited
    const newEditIdx = (formState.editIdx === null || formState.editIdx > newActiveHeadIdx) ?
      newActiveHeadIdx :
      formState.editIdx

    return { ...formState, editIdx: newEditIdx, activeHeadIdx: newActiveHeadIdx, travellerForms: formState.travellerForms }
  }

  const schemaPropertiesLength = Object.values(schema?.properties ?? {}).length
  return {
    ...formState,
    editIdx: calculateFirstNotEditedField(formState.travellerForms.length, schemaPropertiesLength),
    activeHeadIdx: schemaPropertiesLength === 1 ? 0 : schemaPropertiesLength,
    travellerForms: sortTravellers(formState.travellerForms),
  }
}

const isFlightItemIdMatch = (
  item: App.Checkout.AnyItem,
  itemId: string,
): item is App.Checkout.FlightItem => {
  return item.itemId === itemId
}

const apiSuccesses = reducerSwitch<App.CheckoutState>({
  [FETCH_COMMISSION_PROMO_CODE]: (state, action) => ({
    isFetchingCommmission: false,
    commissionPromoCode: action.data,
  }),
  [FETCH_COMMMISSION_PROMO]: (state, action) => ({
    commission: action.data,
    isFetchingCommmission: false,
  }),
  [FETCH_TRAVELLER_FORM_SCHEMA]: (state, action) => {
    if (!equal(action.schemaRequestData, state.schemaRequestData)) {
      // Request mismatch
      return state
    }
    return {
      schemaData: action.data,
      isFetchingSchemaData: false,
      form: rebuildFormState(state.form, action.data.accommodationFlightSchema),
    }
  },

  [FETCH_FINAL_FLIGHT_FARES]: (state, action) => {
    const { itemId, data } = action

    return {
      cart: {
        ...state.cart,
        items: state.cart.items.map(item =>
          (!isFlightItemIdMatch(item, itemId)) ? item : {
            ...item,
            ...{
              totalFare: data.totalFare,
              otherFees: data.otherFees,
            },
            flights: item.flights?.map((flight, index) => ({
              ...flight,
              cost: data.costs[index],
            })),
            hasFinalFare: true,
            fetchingFare: false,
            fareFetchError: undefined,
          }),
      },
    }
  },
  [CHECKOUT_REQUEST_CORPORATE_BENEFITS_PROMO]: (state) => ({
    corporatePromoForm: {
      ...state.corporatePromoForm,
      state: 'done',
      error: undefined,
    },
  }),
  [CHECKOUT_REQUEST_FRIENDS_AND_FAMILY_PROMO]: (state) => ({
    friendsAndFamilyPromoForm: {
      ...state.friendsAndFamilyPromoForm,
      state: 'done',
      error: undefined,
    },
  }),
  [UPDATE_BEDBANK_SESSION]: (state, action) => {
    // Assumption, there is only a single bedbank offer (possibly multiple rooms) in the cart
    const { sessionId, oldSessionId } = action.data
    const newCartItems = state.cart.items.map((item) => {
      if (item.itemType !== CHECKOUT_ITEM_TYPE_BEDBANK || item.sessionId !== oldSessionId) { return item }
      return { ...item, sessionId }
    })

    return {
      cart: { ...state.cart, items: newCartItems },
    }
  },
  [FETCH_VELOCITY_MEMBER_DETAILS]: (state, action) => ({
    payment: {
      ...state.payment,
      partnerships: {
        ...state.payment.partnerships,
        velocity: {
          ...state.payment.partnerships.velocity,
          burn: {
            ...state.payment.partnerships.velocity?.burn,
            membershipId: action.data.membershipId,
            pointBalance: action.data.pointBalance,
            firstName: action.data.firstName,
            lastName: action.data.lastName,
          },

        },
      },
    },
    isFetchingVelocityData: false,
  }),
  [CREATE_STRIPE_PAYMENT_METHOD]: (_state, action) => ({
    stripePaymentMethod: {
      isCreatingStripePaymentMethod: false,
      cardBrand: action.data.paymentMethod.card.brand,
      cardFunding: action.data.paymentMethod.card.funding,
      paymentMethodId: action.data.paymentMethod.id,
    },
  }),
  [CHECKOUT_FETCH_BEDBANK_EXISTING_ORDER]: () => ({
    isCartRestored: true,
    isFetchingBedbankOrder: false,
  }),
  [CHECKOUT_INIT_BEDBANK_CHANGE_DATES_SESSION]: () => ({
    isCreatingBedbankChangeDatesSession: false,
  }),
  [CHECKOUT_CREATE_CART_QUOTE]: (state, action) => ({
    cart: {
      ...state.cart,
      csCartQuoteId: action.data.id,
      csCartQuoteStatus: 'created',
    },
  }),
  [CHECKOUT_UPDATE_CART_QUOTE]: (state) => ({
    cart: {
      ...state.cart,
      csCartQuoteStatus: 'created',
    },
  }),
})

const apiFailures = reducerSwitch<App.CheckoutState>({
  [FETCH_COMMISSION_PROMO_CODE]: () => ({
    isFetchingCommmission: false,
    commissionPromoCode: undefined,
  }),
  [FETCH_COMMMISSION_PROMO]: () => ({
    isFetchingCommmission: false,
  }),
  [FETCH_TRAVELLER_FORM_SCHEMA]: (state, action) => {
    if (!equal(action.schemaRequestData, state.schemaRequestData)) {
      // Request mismatch
      return {}
    }

    return {
      isFetchingSchemaData: false,
      schemaData: null,
      error: {
        name: action.error.message,
        status: action.error.status,
      },
    }
  },
  [FETCH_FINAL_FLIGHT_FARES]: (state, action) => ({
    cart: {
      ...state.cart,
      items: state.cart.items.map((item) =>
        (item.itemId !== action.itemId) ? item : {
          ...item,
          hasFinalFare: false,
          fetchingFare: false,
          fareFetchError: action.error,
        }),
    },
  }),
  [CHECKOUT_REQUEST_CORPORATE_BENEFITS_PROMO]: (state, action) => ({
    corporatePromoForm: {
      ...state.corporatePromoForm,
      state: 'error',
      emailSubmitted: state.corporatePromoForm.emailSubmitted,
      error: action?.error?.errors.map((m) => {
        if (m === 'matchingCorpEmailDomainRequiredForCreate') {
          return 'Email address is not valid for the coupon code'
        } else if (m === 'Service Error: Service Error received: 400') {
          return 'Failed to create promo code. Please try again later'
        } else {
          return m.message ? m.message : m.toString()
        }
      }),
    },
  }),
  [CHECKOUT_REQUEST_FRIENDS_AND_FAMILY_PROMO]: (state, action) => ({
    friendsAndFamilyPromoForm: {
      ...state.friendsAndFamilyPromoForm,
      state: 'error',
      emailSubmitted: state.friendsAndFamilyPromoForm.emailSubmitted,
      error: action?.error?.errors.map((m) => {
        if (m === 'matchingCorpEmailDomainRequiredForCreate') {
          return 'Email address is not valid for the coupon code'
        } else if (m === 'Service Error: Service Error received: 400') {
          return 'Failed to create promo code. Please try again later'
        } else {
          return m.message ? m.message : m.toString()
        }
      }),
    },
  }),
  [FETCH_VELOCITY_MEMBER_DETAILS]: (state) => ({
    payment: {
      ...state.payment,
      partnerships: {
        ...state.payment.partnerships,
      },
    },
    isFetchingVelocityData: false,
  }),
  [CREATE_STRIPE_PAYMENT_METHOD]: (state) => ({
    stripePaymentMethod: {
      ...state.stripePaymentMethod,
      isCreatingStripePaymentMethod: false,
    },
  }),
  [CHECKOUT_FETCH_BEDBANK_EXISTING_ORDER]: () => ({
    isCartRestored: false,
    isFetchingBedbankOrder: false,
  }),
  [CHECKOUT_INIT_BEDBANK_CHANGE_DATES_SESSION]: () => ({
    isCreatingBedbankChangeDatesSession: false,
  }),
  [CHECKOUT_CREATE_CART_QUOTE]: (state) => ({
    cart: {
      ...state.cart,
      csCartQuoteStatus: 'error',
    },
  }),
  [CHECKOUT_UPDATE_CART_QUOTE]: (state) => ({
    cart: {
      ...state.cart,
      csCartQuoteStatus: 'error',
    },
  }),
})

export default createReducer<App.CheckoutState, CheckoutAction>(initialState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [CHECKOUT_INITIALISE]: (_state, action) => {
    const transactionKey = uuidV4()
    return {
      ...initialState,
      cart: {
        ...initialState.cart,
        cartId: transactionKey,
        regionCode: action.params.regionCode,
        currencyCode: action.params.currencyCode,
        items: action.items,
        transactionKey,
        isGift: action.params.isGift,
        isAbandonedCart: false,
        isMultiItemMode: _state.cart.isMultiItemMode,
        restoredCartOriginalTotal: action.params.restoredCartOriginalTotal,
        restoredCartOriginalMemberTotal: action.params.restoredCartOriginalMemberTotal,
        postPurchase: action.params.postPurchase,
        existingOrder: action.params.order,
      },
      isCartRestored: true,
      isFormRestored: false,
    }
  },
  [CHECKOUT_RESTORE_CART]: (state, action) => ({
    cart: { ...state.cart, ...action.cartState, isAbandonedCart: false },
    isCartRestored: true,
    schemaData: null,
  }),
  [CHECKOUT_REMOVE_TRAVELLER_SCHEMA]: () => ({
    schemaData: null,
  }),
  [CHECKOUT_RESTORE_PAYMENT]: (state, action) => ({
    payment: { ...state.payment, ...action.state },
    isPaymentRestored: true,
  }),
  [CHECKOUT_CLEAR_CART]: (state) => ({
    ...state,
    cart: {
      ...initialState.cart,
    },
  }),
  [CHECKOUT_CLEAR_CART_RESTORE_PRICE]: (state) => ({
    ...state,
    cart: {
      ...state.cart,
      restoredCartOriginalTotal: null,
      restoredCartOriginalMemberTotal: null,
    },
  }),
  [CHECKOUT_RESTORE_FORM]: (state, action) => ({
    form: { ...state.form, ...action.formState },
    isFormRestored: true,
  }),
  [CHECKOUT_RESET_FORM]: () => ({
    form: initialFormState,
  }),
  [CHECKOUT_SET_COMMS_RESUBSCRIBE]: (state, action) => ({
    form: {
      ...state.form,
      commsResubscribe: action.commsResubscribe,
    },
  }),
  [CHECKOUT_UPDATE_ITEM]: (state, action) => {
    // Preserve ordering of items
    const idx = state.cart.items.findIndex(item => item.itemId === action.item.itemId)
    const newItems = idx === -1 ?
        [...state.cart.items, action.item] :
        [...state.cart.items.slice(0, idx), action.item, ...state.cart.items.slice(idx + 1)]

    const itemCountChanged = newItems.length !== state.cart.items.length
    return {
      cart: {
        ...state.cart,
        items: newItems,
        ...(itemCountChanged ? {
          restoredCartOriginalMemberTotal: null,
          restoredCartOriginalTotal: null,
        } : {}),
      },
      schemaData: action.resetFormSchema ? null : state.schemaData,
    }
  },
  [CHECKOUT_ADD_ITEM]: (state, action) => ({
    cart: {
      ...state.cart,
      items: [...state.cart.items, action.item],
      restoredCartOriginalTotal: null,
      restoredCartOriginalMemberTotal: null,
    },
    schemaData: action.resetFormSchema ? null : state.schemaData,
  }),
  [CHECKOUT_REMOVE_ITEM]: (state, action) => ({
    cart: {
      ...state.cart,
      items: state.cart.items.filter(item => item.itemId !== action.itemId),
      specialRequests: omitKeys([action.itemId], state.cart.specialRequests),
      restoredCartOriginalTotal: null,
      restoredCartOriginalMemberTotal: null,
    },
    schemaData: action.resetFormSchema ? null : state.schemaData,
  }),
  [CHECKOUT_SET_FORM]: (state, action) => {
    return { form: action.form }
  },
  [CHECKOUT_EDIT_FORM]: (state, action) => {
    return {
      form: { ...state.form, editIdx: action.editIdx },
    }
  },
  [CHECKOUT_UPDATE_PAYMENT_SELECTION]: (state, action) => ({
    payment: {
      ...state.payment,
      paymentSelected: action.paymentSelected,
    },
  }),
  [CHECKOUT_SET_SPECIAL_REQUEST]: (state, action) => ({
    cart: {
      ...state.cart,
      specialRequests: {
        ...state.cart.specialRequests,
        [action.itemId]: action.request,
      },
    },
  }),
  [CHECKOUT_SET_CONNECT_ROOMS_SPECIAL_REQUEST]: (state, action) => ({
    cart: {
      ...state.cart,
      specialRequests: {
        ...state.cart.specialRequests,
        ...mergeObjects(
          state.cart.items.map(item => ({
            [item.itemId]: (state.cart.specialRequests[item.itemId] ?? '') + getConnectRoomsSpecialRequestMessage(action.contactName),
          })),
        ),
      },
    },
  }),
  [CHECKOUT_SET_ROOMS_TO_BE_CONNECTED]: (state, action) => ({
    cart: {
      ...state.cart,
      roomsToBeConnected: action.roomsToBeConnected,
    },
  }),
  [CHECKOUT_SET_INSURANCE_FETCH_PARAMETERS]: (state, action) => ({
    cart: {
      ...state.cart,
      insurance: action.params,
    },
  }),
  [CHECKOUT_SET_LUXPLUS_UPSELL_DATA]: (state, action) => ({
    ...state,
    luxPlusUpsellData: action.eventData,
  }),
  [CHECKOUT_PROCESS_START]: () => ({
    error: undefined,
    processing: true,
    processed: false,
    orderId: undefined,
  }),
  [CHECKOUT_PROCESS_CANCEL]: (state) => ({
    ...state,
    cart: {
      ...state.cart,
      transactionKey: uuidV4(),
      items: state.cart.items.map(item => ({ ...item, transactionKey: uuidV4() })),
    },
    error: undefined,
    processing: false,
    processed: false,
    orderFailure: true,
    orderId: undefined,
  }),
  [CHECKOUT_PROCESS_SUCCESS]: (_state) => ({
    ...initialState,
    processing: false,
    processed: true,
    orderFailure: false,
  }),
  [CHECKOUT_PROCESS_FAILURE]: (state, action) => ({
    cart: {
      ...state.cart,
      transactionKey: uuidV4(),
      items: state.cart.items.map(item => ({ ...item, transactionKey: uuidV4() })),
    },
    error: action.data,
    processing: false,
    processed: false,
    cardProcessingErrorCount: action.data.errorMsg?.includes(
      'An error occurred while processing your card',
    ) ? state.cardProcessingErrorCount + 1 : state.cardProcessingErrorCount,
    // See https://github.com/lux-group/www-le-customer/pull/4393 for explanation of this
    orderFailure: true,
  }),
  [CHECKOUT_APPLY_VELOCITY_BURN]: (state, action) => ({
    payment: {
      ...state.payment,
      partnerships: {
        ...state.payment.partnerships,
        velocity: {
          ...state.payment.partnerships.velocity,
          burn: {
            ...state.payment.partnerships.velocity?.burn,
            pointsBurned: action.points,
            isApplied: true,
          },
        },
      },
      modals: {
        ...state.payment.modals,
        isVelocityBurnActive: false,
      },
    },
  }),
  [CHECKOUT_CLEAR_VELOCITY_BURN]: (state) => ({
    payment: {
      ...state.payment,
      partnerships: {
        ...state.payment.partnerships,
        velocity: {
          ...state.payment.partnerships.velocity,
          burn: {
            ...state.payment.partnerships.velocity?.burn,
            pointsBurned: 0,
            isApplied: false,
          },
        },
      },
    },
  }),
  [CHECKOUT_APPLY_PARTNERSHIP_STATUS]: (state, action) => ({
    payment: {
      ...state.payment,
      partnerships: {
        ...state.payment.partnerships,
        [action.partnership]: {
          ...state.payment.partnerships[action.partnership],
          burn: {
            ...state.payment.partnerships[action.partnership]?.burn,
            isApplied: action.status,
          },
        },
      },
    },
  }),
  [CHECKOUT_SET_VELOCITY_BURN_MODAL_STATUS]: (state, action) => ({
    payment: {
      ...state.payment,
      modals: {
        ...state.payment.modals,
        velocity: {
          ...state.payment.modals.velocity,
          ...action.data,
        },
      },
    },
  }),
  [CHECKOUT_TOGGLE_CREDIT]: (state, action) => ({
    payment: {
      ...state.payment,
      useCredit: action.useCredit ?? !state.payment.useCredit,
    },
  }),
  [CHECKOUT_UPDATE_FLIGHT_ITEM_PAX_BAGGAGE]: (state, action) => {
    const itemIdx = state.cart.items.findIndex(item => item.itemId === action.itemId)
    if (itemIdx === -1) { return state }

    const flightItem = state.cart.items[itemIdx] as App.Checkout.FlightItem
    const flightData = flightItem.flights[action.flightIndex]

    const newFlights = flightItem.flights.map((flight, index) => {
      if (index === action.flightIndex) {
        return {
          ...flightData,
          extras: {
            ...flightData.extras,
            baggage: action.paxBaggageMap,
          },
        }
      }

      return flight
    })

    const newFlightItem = { ...flightItem, flights: newFlights }

    // Preserve ordering of items
    const newItemList = [...state.cart.items.slice(0, itemIdx), newFlightItem, ...state.cart.items.slice(itemIdx + 1)]

    return {
      cart: { ...state.cart, items: newItemList },
    }
  },
  [CHECKOUT_UPDATE_FLIGHT_TOTAL_FARE]: (state, action) => {
    const itemIdx = state.cart.items.findIndex(item => item.itemId === action.itemId)
    if (itemIdx === -1) { return state }

    const flightItem = state.cart.items[itemIdx] as App.Checkout.FlightItem
    const departing = flightItem.flights[0] ? { ...(flightItem.flights[0]) } : undefined
    const returning = flightItem.flights[1] ? { ...flightItem.flights[1] } : undefined

    if (departing && action.newDepartingTotalFare) {
      departing.cost = action.newDepartingTotalFare
    }

    if (returning && action.newReturningTotalFare) {
      returning.cost = action.newReturningTotalFare
    }

    let flights = flightItem.flights

    if (flights.length === action.newFlightLegFares?.length) {
      flights = flights.map((flight, index) => ({
        ...flight,
        cost: action.newFlightLegFares[index],
      }))
    }

    const newFlightItem = { ...flightItem, totalFare: action.newTotalFare, flights, departing, returning }

    // Preserve ordering of items
    const newItemList = [...state.cart.items.slice(0, itemIdx), newFlightItem, ...state.cart.items.slice(itemIdx + 1)]
    return { cart: { ...state.cart, items: newItemList } }
  },
  [CHECKOUT_UPDATE_EXPERIENCE_ITEM]: (state, action) => {
    const idx = state.cart.items.findIndex(item => item.itemType === CHECKOUT_ITEM_TYPE_EXPERIENCE && item.experienceId === action.id)
    if (idx === -1) { return {} }

    const experienceItem = state.cart.items[idx] as App.Checkout.ExperienceItem
    const newExperienceItem = {
      ...experienceItem,
      ...action.data.details,
      tickets: action.data?.tickets ?? experienceItem.tickets,
    }

    // Preserve ordering of cart items
    const newItemList = [...state.cart.items.slice(0, idx), newExperienceItem, ...state.cart.items.slice(idx + 1)]
    return {
      cart: { ...state.cart, items: newItemList },
    }
  },
  [CHECKOUT_APPLY_PROMO_CODE]: (state, action) => {
    const velocity = state.payment.partnerships.velocity ? {
      ...state.payment.partnerships.velocity,
      pointsBurned: 0, // Invalidate the partnership
    } : null

    return {
      promo: action.promotion,
      payment: {
        ...state.payment,
        partnerships: {
          ...(velocity && { velocity }),
        },
      },
      isFetchingPromo: false,
    }
  },
  [CHECKOUT_RESET_PROMO_CODE]: () => ({
    promo: undefined,
    fetchingPromoError: undefined,
  }),
  [CHECKOUT_FETCHING_PROMO_CODE]: () => ({
    isFetchingPromo: true,
    fetchingPromoError: undefined,
  }),
  [CHECKOUT_APPLY_PROMO_CODE_FAILED]: (state, action) => ({
    fetchingPromoError: action.error,
    isFetchingPromo: false,
  }),
  [CHECKOUT_SHOW_PROMO_CORPORATE_BENEFITS]: (state, action) => ({
    corporatePromoForm: {
      state: 'pending-input',
      sourceCodeName: action.promoCode,
      allowedEmailDomains: action.allowedEmailDomains,
      corporateName: action.corporateName,
    },
    isFetchingPromo: false,
  }),
  [CHECKOUT_HIDE_PROMO_CORPORATE_BENEFITS]: (state) => ({
    corporatePromoForm: {
      state: 'hidden',
      error: undefined,
      emailSubmitted: state.corporatePromoForm.emailSubmitted,
    },
  }),
  [CHECKOUT_SHOW_PROMO_FRIENDS_AND_FAMILY]: (state, action) => ({
    friendsAndFamilyPromoForm: {
      state: 'pending-input',
      sourceCodeName: action.promoCode,
      category: action.category,
    },
    isFetchingPromo: false,
  }),
  [CHECKOUT_HIDE_PROMO_FRIENDS_AND_FAMILY]: () => ({
    friendsAndFamilyPromoForm: {
      state: 'hidden',
    },
  }),
  [CHECKOUT_RESET_FLIGHT_ITEMS]: (state) => ({
    cart: {
      ...state.cart,
      items: state.cart.items.filter(item => !isFlightItem(item)),
    },
  }),
  [CHECKOUT_SET_RESTORE_CART_STATUS]: (state, action) => ({
    restoreCart: {
      ...state.restoreCart,
      ...action.data,
    },
  }),
  [CHECKOUT_MODIFY_FLIGHT_SEARCH_VIEW]: (state, action) => ({
    modifyFlightView: action.modifyFlightView,
  }),
  [CHECKOUT_UPDATE_HOTEL_ITEM_PRICING]: (state, action) => {
    const itemIdx = state.cart.items.findIndex(item => item.itemId === action.itemId)
    if (itemIdx === -1) { return state }

    const hotelItem = state.cart.items[itemIdx]
    const newHotelItem = {
      ...hotelItem,
      newPrice: action.newPrice,
      newSurcharge: action.surcharge?.newSurcharge,
      newExtraGuestSurcharge: action.surcharge?.newExtraGuestSurcharge,
    }

    // Preserve ordering of items
    const newItemList = [...state.cart.items.slice(0, itemIdx), newHotelItem, ...state.cart.items.slice(itemIdx + 1)]
    return { cart: { ...state.cart, items: newItemList } }
  },
  [CHECKOUT_UPDATE_BUNDLE_ITEM_PRICING]: (state, action) => {
    const itemIdx = state.cart.items.findIndex(item => item.itemId === action.itemId)
    if (itemIdx === -1) { return state }

    const hotelItem = state.cart.items[itemIdx]

    if (isBundleAndSaveItem(hotelItem)) {
      const hotelData = hotelItem.dates[action.offerId]

      if (hotelData) {
        const newHotelData = {
          ...hotelData,
          newPrice: action.newPrice,
          newSurcharge: action.surcharge?.newSurcharge,
          newExtraGuestSurcharge: action.surcharge?.newExtraGuestSurcharge,
        }
        const newHotelItem = {
          ...hotelItem,
          dates: {
            ...hotelItem.dates,
            [action.offerId]: newHotelData,
          },
        }

        const newItemList = [...state.cart.items.slice(0, itemIdx), newHotelItem, ...state.cart.items.slice(itemIdx + 1)]

        return { cart: { ...state.cart, items: newItemList } }
      }
    }

    return state
  },
  [CHECKOUT_TOGGLE_DEV_TOOLS]: (state) => ({
    cart: {
      ...state.cart,
      isDevToolsEnabled: !state.cart.isDevToolsEnabled,
    },
  }),
  [CHECKOUT_SET_POST_PURCHASE]: (state, action) => ({
    cart: {
      ...state.cart,
      existingOrder: action.order,
      postPurchase: action.postPurchase,
    },
  }),
  [CHECKOUT_SET_VELOCITY_SSO]: (state, action) => ({
    payment: {
      ...state.payment,
      sso: {
        ...state.payment.sso,
        velocity: {
          ...state.payment.sso.velocity,
          ...action.data,
        },
      },
    },
  }),
  [CHECKOUT_UPDATE_ARRIVAL_FLIGHT_NUMBER]: (state, action) => ({
    cart: {
      ...state.cart,
      arrivalFlightNumber: action.data,
    },
  }),
  [CHECKOUT_AGENT_BOOKING_DETAILS]: (state, action) => ({
    cart: {
      ...state.cart,
      agentBookingDetails: action.data,
    },
  }),
  [CHECKOUT_BUSINESS_BOOKING_DETAILS]: (state, action) => ({
    cart: {
      ...state.cart,
      businessBookingDetails: action.data,
    },
  }),
  [CHECKOUT_SELECT_NO_INSURANCE]: (state) => {
    return {
      cart: {
        ...state.cart,
        items: state.cart.items.filter(item => !isInsuranceItem(item)).concat(generateNoProtectionItem()),
      },
    }
  },
  [CHECKOUT_SELECT_NO_TRAVEL_PROTECTION]: (state) => {
    return {
      cart: {
        ...state.cart,
        items: state.cart.items.filter(item => !isInsuranceItem(item)).concat(generateNoTravelProtectionItem()),
      },
    }
  },
  [CHECKOUT_UPDATE_CS_DEPOSIT_OVERRIDE]: (state, action) => ({
    ...state,
    csDepositPercentageOverride: action.depositOverride,
  }),
  [ACTIVATE_REBOOKING]: (state, action) => ({
    ...state,
    payment: {
      ...state.payment,
      rebookingID: action.data.rebookingID,
    },
  }),
  [CHECKOUT_SET_MULTI_CART_ITEM_MODE]: (state, action) => ({
    ...state,
    cart: {
      ...state.cart,
      isMultiItemMode: action.isMultiItemMode,
    },
  }),
  [CHECKOUT_SET_MERCHANT_FEE_PAYMENT_TYPE]: (state, action) => ({
    ...state,
    merchantFeePaymentType: action.paymentType,
  }),
  [CHECKOUT_SET_STRIPE_PAYMENT_METHOD]: (state, action) => ({
    ...state,
    stripePaymentMethod: {
      ...state.stripePaymentMethod,
      ...action.paymentMethod,
    },
  }),
  [CHECKOUT_ADD_ARRIVAL_DETAILS]: (state, action) => {
    const { itemId, arrivalDetails } = action
    return {
      cart: {
        ...state.cart,
        arrivalDetails: {
          ...state.cart.arrivalDetails,
          [itemId]: arrivalDetails,
        },
      },
    }
  },
  [CHECKOUT_SET_ARRIVAL_DETAILS_TIME]: (state, action) => {
    const { itemId, arrivalTime } = action
    return {
      cart: {
        ...state.cart,
        arrivalDetails: {
          ...state.cart.arrivalDetails,
          [itemId]: {
            ...state.cart.arrivalDetails[itemId],
            arrivalTime,
          },
        },
      },
    }
  },
  [CHECKOUT_SET_ARRIVAL_DETAILS_FLIGHT_NUMBER]: (state, action) => {
    const { itemId, arrivalFlightNumber } = action
    return {
      cart: {
        ...state.cart,
        arrivalDetails: {
          ...state.cart.arrivalDetails,
          [itemId]: {
            ...state.cart.arrivalDetails[itemId],
            arrivalFlightNumber,
          },
        },
      },
    }
  },
  [CHECKOUT_SET_PAYTO_BANK]: (state, action) => ({
    ...state,
    selectedPayToBank: action.selectedPayToBank,
  }),
  [CHECKOUT_FETCHING_PAYMENT_LINK]: () => ({
    securePayment: {
      isFetchingLink: true,
      securePaymentLink: undefined,
    },
  }),
  [CHECKOUT_FETCHING_PAYMENT_LINK_FAILED]: (state) => ({
    securePayment: {
      ...state.securePayment,
      isFetchingLink: false,
    },
  }),
  [CHECKOUT_SET_PAYMENT_LINK]: (_state, action) => ({
    securePayment: {
      isFetchingLink: false,
      securePaymentLink: action.data,
    },
  }),
  [CHECKOUT_SET_ORDER_ID]: (state, action) => ({
    ...state,
    orderId: action.orderId,
  }),
})
