import { isOrderPast } from 'lib/order/isUpcoming'

export function checkOrderAcceptsReview(order?: App.Order) {
  if (!order) return false

  const isTravelledOrder = isOrderPast(order)
  const isValidOrderType = !!(order.items.length || order.tourItems.length || order.experienceItems.length || order.bedbankItems.length)
  const isCompletedItem = isOrderItemCompleted(order)

  return isTravelledOrder && isValidOrderType && isCompletedItem
}

/**
 * For now we're only getting the first item inside order to leave reviews
 * So we get only 1 review by order. For this reason, we check status of the first item
 */
export const isOrderItemCompleted = (order: App.Order): boolean => {
  return !!order.items.length && order.items[0].status === 'completed' ||
    !!order.bedbankItems.length && order.bedbankItems[0].status === 'completed' ||
    !!order.tourItems.length && order.tourItems[0].status === 'completed' ||
    !!order.experienceItems.length && order.experienceItems[0].status === 'completed'
}

export const showUserReviewsRating = (rating?: number, reviewsTotal?: number): boolean => {
  if (!rating || !reviewsTotal) return false

  return (reviewsTotal >= 25)
}

export const getRecommendsLabel = (review: App.Review) => {
  const label = review.rating >= 6 ? 'Recommends' : 'Does not recommend'
  if (review.experienceId) return label + ' this experience'
  if (review.tourId) return label + ' this tour'

  return label + ' this hotel'
}
