import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import React from 'react'
import { getInclusionIcon } from 'lib/offer/getInclusionIcon'

interface Props {
  inclusions?: Array<App.PackageInclusion> | Array<App.OrderLuxPlusInclusion>,
  inclusionsV2?: Array<App.PackageInclusionV2>,
  tileInclusions?: Array<App.TileInclusion>,
}

function LuxPlusInclusionsList(props: Props) {
  const {
    inclusions,
    inclusionsV2,
    tileInclusions,
  } = props

  if (!inclusions && !inclusionsV2 && !tileInclusions) {
    return null
  }

  return (
    <VerticalSpacer gap={4} data-testid="luxury-plus-inclusions">
      {!!tileInclusions?.length && tileInclusions.map((inclusion, idx) => {
        const InclusionIcon = getInclusionIcon(inclusion.icon)

        return (
          <BodyTextBlock
            key={idx}
            variant="medium"
            weight="bold"
            startIcon={<InclusionIcon colour="loyalty" size="S" />}
          >
            {inclusion.text}
          </BodyTextBlock>
        )
      })}
      {!!inclusions?.length && inclusions.map((inclusion, idx) => {
        const isLoyaltyMemberInclusion = 'categoryIcon' in inclusion
        const InclusionIcon = getInclusionIcon(isLoyaltyMemberInclusion ? inclusion.categoryIcon : '')
        const { description } = inclusion
        const inclusionDescription = description?.startsWith('-') ? description.slice(1).trim() : description

        return (
          <BodyTextBlock
            key={idx}
            variant="medium"
            weight="bold"
            startIcon={<InclusionIcon colour="loyalty" size="S" />}
          >
            {inclusionDescription}
          </BodyTextBlock>
        )
      })}
      {!!inclusionsV2?.length && inclusionsV2.map(({ inclusion }, idx) => {
        const InclusionIcon = getInclusionIcon(inclusion.category?.icon ?? '')
        const { name } = inclusion

        return (
          <BodyTextBlock
            key={idx}
            variant="medium"
            weight="bold"
            startIcon={<InclusionIcon colour="loyalty" size="S" />}
          >
            {name}
          </BodyTextBlock>
        )
      })}
    </VerticalSpacer>
  )
}

export default LuxPlusInclusionsList
