import React, { useContext } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import Group from '../../utils/Group'
import { formatDateRangesForAlternativeDates } from '../../../lib/search/searchUtils'
import moment from 'moment'
import { calculateNights } from '../../../../tripPlanner/utils'
import Plural from '../../utils/Formatters/Plural'
import TextButton from '../../Luxkit/Button/TextButton'
import BodyText from '../../Luxkit/Typography/BodyText'
import SearchTileEventsContext, { SearchTileEvents } from '../../OfferList/OfferListTiles/SearchOfferTiles/SearchTileEventsContext'
import { useInView } from 'react-intersection-observer'
import { OfferUnavailableReason } from '../../../lib/search/constants'

const StyledGroup = styled(Group)`
  background-color: ${props => props.theme.palette.highlight.primary.lightBackground};
  color: ${props => props.theme.palette.highlight.primary.lightContrast};
  padding: ${rem(12)} ${rem(16)};
  gap: ${rem(8)};
`

interface Props {
  checkIn: string;
  checkOut: string;
  metadata?: App.OfferListMetaData;
  className?: string
}

function SearchAltDatesBanner({ checkIn, checkOut, metadata, className }: Props) {
  const searchTileEventsDispatch = useContext(SearchTileEventsContext)
  const [inViewRef, hasBeenInView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  })

  const nights = calculateNights(moment(checkIn), moment(checkOut))

  if (hasBeenInView) {
    searchTileEventsDispatch({
      type: SearchTileEvents.viewAlternativeDatesBanner,
      checkIn,
      checkOut,
    })
  }

  const handleClick = () => {
    searchTileEventsDispatch({
      type: SearchTileEvents.clickViewSimilarAltDates,
      checkIn,
      checkOut,
    })
  }

  const tagLine = metadata?.unavailableReason === OfferUnavailableReason.NUM_NIGHTS_UNSUPPORTED ?
    'Unavailable for your exact dates.' :
    'Sold out for your exact dates.'

  return (
    <StyledGroup ref={inViewRef} verticalAlign="center" onClick={handleClick} className={className} direction="horizontal" horizontalAlign="space-between">
      <BodyText variant="medium">{tagLine} <strong>Available {formatDateRangesForAlternativeDates(checkIn, checkOut)} (<Plural count={nights!} singular="night" withCount />)</strong></BodyText>
      <TextButton kind="secondary">View similar dates</TextButton>
    </StyledGroup>
  )
}

export default SearchAltDatesBanner
