import qs from 'qs'
import {
  API_CALL, CRUISE_IN_FLOW_CHECKOUT_STEP,
  RESET_REVELEX_CALLS,
  RESET_CABIN_AVAILABILITY,
  SAVE_CABIN_OPTION,
  CRUISE_SET_SEARCH_FACETS,
  RESET_BOOKING_CABIN_SELECTION,
  SELECT_BOOKING_CRUISE_ITEM,
  SET_BOOKING_INTEREST_IN_CONNECTED_CABINS,
} from './actionConstants'
import { sortBy } from 'lib/array/arrayUtils'

import { expireCruiseTimer, getCruiseFilters, resetCruiseTimer } from 'lib/cruises/cruiseUtils'
import { cruiseFacetsMap } from 'api/mappers/Cruise/cruiseMap'
import { CruisesContract } from '@luxuryescapes/contract-svc-cruise'
import GlobalSearchState from 'contexts/GlobalSearch/GlobalSearchState'
import { CheckoutPageId } from 'checkout/constants/pages'
import {
  FETCH_CRUISE_OFFER,
  FETCH_CRUISES_LIST_RECOMMENDATION,
  FETCH_CRUISE_SHIP_DECK,
  FETCH_CRUISE_SHIP,
  FETCH_CRUISE_SEARCH_COUNT,
  FETCH_CRUISE_SEARCH_FACETS,
  FETCH_CRUISE_BOOKING_RATE_LIST,
  FETCH_CRUISE_BOOKING_CABIN_LIST,
  FETCH_CRUISE_BOOKING_CABIN_DETAILS_LIST,
  FETCH_CRUISE_BOOKING_CABIN_SELECTION,
  FETCH_CRUISE_BOOKING_CABIN_PRICING,
  FETCH_CRUISE_BOOKING_RATE_DETAILS_LIST,
  FETCH_CRUISE_BOOKING_CABIN_RELEASE,
  FETCH_CRUISE_BOOKING_BY_ID,
  FETCH_CRUISE_DEPARTURE_BY_ID,
  FETCH_CRUISE_LINES,
  FETCH_CRUISE_PROMO_BANNERS,
  FETCH_CRUISE_LOWEST_PRICE_BY_MONTH,
  RESET_SESSION_ID,
  FETCH_CRUISE_CONSOLIDATED_PAYMENT_SCHEDULE,
} from './apiActionConstants'
import { getCruiseItems } from 'checkout/selectors/view/cruise'
import {
  CruiseAPI,
  findBookingRateList,
  findBookingMultipleCabinList,
  findBookingRateDetailsList,
  findBookingCabinSelection,
  findBookingCabinPricing,
  cruiseBookingCabinRelease,
  findBookingCabinDetailsListMultiple,
  getCruiseBookingById,
  getCruiseSearchFacets,
  getCruiseDepartureById,
  getCruiseLines,
  getCruiseLowestPriceByMonth,
  getCruiseOffer,
  getCruiseSearchList,
  getCruiseShipDeck,
  getCruisePromoBanners,
  getCruiseSearchCount,
  findCruiseShip,
  getConsolidatedPaymentSchedule,
} from 'api/cruises'
import { CRUISE_CHECKOUT_INFLOW_V2 } from 'constants/checkout'
import getObjectKey from 'lib/object/getObjectKey'
import { pushWithRegion } from 'actions/NavigationActions'
import { updateCheckoutItem } from 'actions/CheckoutActions'
import {
  generateCabinTravelerKey,
  checkIfNeedsToReleaseCabin,
  groupCabinListByNumber,
  getRateListByCruiseItem,
  getCabinListByCruiseItem,
  getBookingCabinTypes,
} from 'checkout/lib/utils/cruises/booking'
import { navigateToCheckout } from 'checkout/actions/navigateActions'
import * as Analytics from 'analytics/analytics'
import { addToCart } from 'analytics/snowplow/events'
import { CRUISE_AVAILABLE_REGIONS } from 'constants/config/region'

export function fetchCruiseLines(filter?: CruisesContract.OfferParams) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const region = state.geo.currentRegionCode

    if (region && !CRUISE_AVAILABLE_REGIONS.has(region.toUpperCase())) return

    if (!state.cruise.cruiseLines.length && !state.cruise.fetchingCruiseLines) {
      dispatch({
        type: API_CALL,
        api: FETCH_CRUISE_LINES,
        request: () => getCruiseLines(filter, region),
      })
    }
  }
}

export function fetchCruiseSearchListRecommendation() {
  return {
    type: API_CALL,
    api: FETCH_CRUISES_LIST_RECOMMENDATION,
    request: () => getCruiseSearchList({}),
  }
}

export function fetchCruiseOffer(offerId: string, allowInvalidOffer?: boolean) {
  return (dispatch, getState) => {
    const state: App.State = getState()
    const currency = state.geo.currentCurrency
    const regionCode = state.geo.currentRegionCode

    if (state.cruise.cruiseOffersLoading[offerId] ||
      state.cruise.cruiseOffers[offerId]) {
      return
    }

    dispatch({
      offerId,
      type: API_CALL,
      api: FETCH_CRUISE_OFFER,
      request: () => getCruiseOffer(offerId, regionCode, currency, allowInvalidOffer),
    })
  }
}

export function fetchCruiseSearchCount(params: App.OfferListFilters) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const key = getObjectKey(params)
    if (state.cruise.cruiseSearchCount?.[key]) {
    //   already have it or are currently fetching it
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_CRUISE_SEARCH_COUNT,
      request: () => getCruiseSearchCount(params, state.geo.currentRegionCode),
      key,
    })
  }
}

export function fetchCruiseSearchFacets(params: App.CruiseSearchFacetParams) {
  return (dispatch, getState) => {
    const key = getObjectKey(params)
    const state = getState() as App.State

    if (state.cruise.cruiseFacets[key]) {
      // already have it or are currently fetching it
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_CRUISE_SEARCH_FACETS,
      request: () => getCruiseSearchFacets(params, state.geo.currentRegionCode),
      key,
    })
  }
}

export function fetchCruiseLowestPriceByMonth(
  params: App.LowestPriceByMonthParams,
) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const key = getObjectKey(params)
    if (state.cruise.cruiseLowestPricesByMonth[key]) {
      // we're already fetching or have already fetched it
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_CRUISE_LOWEST_PRICE_BY_MONTH,
      request: () => getCruiseLowestPriceByMonth(params, state.geo.currentRegionCode),
      key,
    })
  }
}

export function fetchCruiseShipDeck(deckId: string) {
  return (dispatch, getState: () => App.State) => {
    const state = getState()

    if (state.cruise.deckSelections && deckId in state.cruise.deckSelections) { return }

    dispatch({
      deckId,
      type: API_CALL,
      api: FETCH_CRUISE_SHIP_DECK,
      request: () => getCruiseShipDeck(deckId),
    })
  }
}

export function fetchCruisePromoBanners() {
  return (dispatch, getState) => {
    const state = getState() as App.State
    if (!state.cruise.cruisePromoBanners?.length && !state.cruise.fetchingCruisePromoBanners) {
      const region = state.geo.currentRegionCode
      dispatch({
        type: API_CALL,
        api: FETCH_CRUISE_PROMO_BANNERS,
        request: () => getCruisePromoBanners(region),
      })
    }
  }
}

export function saveCabinOptions(cruiseItemId: string) {
  return {
    type: SAVE_CABIN_OPTION,
    cruiseItemId,
  }
}

export function resetSessionId() {
  return {
    type: RESET_SESSION_ID,
  }
}

export function resetCabinRates() {
  return {
    type: RESET_REVELEX_CALLS,
  }
}

export function resetCabinAvailability() {
  return {
    type: RESET_CABIN_AVAILABILITY,
  }
}

export function resetBookingCabinSelection() {
  return {
    type: RESET_BOOKING_CABIN_SELECTION,
  }
}

export function selectBookingCruiseItem(cruiseItem: App.Checkout.CruiseItem | null) {
  return {
    type: SELECT_BOOKING_CRUISE_ITEM,
    cruiseItemId: cruiseItem?.itemId || null,
  }
}

export function setBookingCruiseItem(cruiseItemId: string | null) {
  return {
    type: SELECT_BOOKING_CRUISE_ITEM,
    cruiseItemId,
  }
}

export function updateBookingItemData(data: Partial<App.Checkout.CruiseItem>) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const cruiseItems = getCruiseItems(state)
    const { selectedCruiseItemId } = state.cruise.multiBooking
    const updateAllItems = !selectedCruiseItemId && !data.cabinNumber

    if (updateAllItems) {
      cruiseItems.forEach((item) => {
        dispatch(updateCheckoutItem({ ...item, ...data }))
      })
    } else {
      const currentItem = cruiseItems.find((item) => item.itemId === selectedCruiseItemId)
      if (currentItem) {
        dispatch(updateCheckoutItem({ ...currentItem, ...data }))
      }
    }
  }
}

export function setNextBookingItemOrStep(nextStep: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const cruiseItems = getCruiseItems(state)
    const { selectedCruiseItemId } = state.cruise.multiBooking

    const currentIndex = cruiseItems.findIndex((item) => item.itemId === selectedCruiseItemId)
    const hasNextItem = currentIndex !== -1 ? cruiseItems[currentIndex + 1] : null

    if (hasNextItem) {
      dispatch(setBookingCruiseItem(hasNextItem.itemId))
    } else {
      if (nextStep === CheckoutPageId.Purchase) {
        dispatch(resetBookingCabinSelection())
        dispatch(navigateToCheckout())
        Analytics.trackEvent(addToCart())
      } else {
        dispatch(setInFlowCheckoutStep(nextStep))
      }

      if (selectedCruiseItemId) {
        // When selectedCruiseItemId is null, it means all items are selected at the same time.
        // When selectedCruiseItemId is not null, set the first item before moving to the next step
        dispatch(setBookingCruiseItem(cruiseItems[0].itemId))
      }
    }
  }
}

export function selectAvailableCabinType() {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const rateListMap = state.cruise.multiBooking.rateList
    const cruiseOffers = state.cruise.cruiseOffers

    const cruiseItems = getCruiseItems(state)

    cruiseItems.forEach((cruiseItem) => {
      const offer = cruiseOffers[cruiseItem.offerId]
      const rateListData = getRateListByCruiseItem(cruiseItem, rateListMap)

      if (offer && rateListData) {
        const cabinTypes = getBookingCabinTypes(rateListData, offer)
        const cabinTypesAvailable = cabinTypes.filter((cabinType) => !!cabinType.lowestRatePrice)

        // TODO: https://aussiecommerce.atlassian.net/browse/CRUZ-2550
        if (cabinTypesAvailable.length === 1 && cabinTypesAvailable[0].name === 'Suite') {
          const data = {
            cabinType: cabinTypesAvailable[0].name,
            cabinCodes: undefined,
            cabinCode: undefined,
            deckId: undefined,
            cabinNumber: undefined,
            componentId: undefined,
          }

          dispatch(updateCheckoutItem({ ...cruiseItem, ...data }))
        }
      }
    })

    dispatch(setInFlowCheckoutStep(CRUISE_CHECKOUT_INFLOW_V2.CABIN_CATEGORY))
  }
}

export function fetchCruiseBookingById(bookingId: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const { cruiseBookingInfoLoading, cruiseBookingInfo } = state.cruise

    if (cruiseBookingInfo[bookingId] || cruiseBookingInfoLoading?.[bookingId]) return

    dispatch({
      id: bookingId,
      type: API_CALL,
      api: FETCH_CRUISE_BOOKING_BY_ID,
      request: () => getCruiseBookingById(bookingId),
    })
  }
}

export function fetchCruiseDepartureById(departureId: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const { cruiseDepartureLoading, cruiseDeparture } = state.cruise

    if (cruiseDeparture[departureId] || cruiseDepartureLoading?.[departureId]) return

    dispatch({
      departureId,
      type: API_CALL,
      api: FETCH_CRUISE_DEPARTURE_BY_ID,
      request: () => getCruiseDepartureById(departureId),
    })
  }
}

export function setInFlowCheckoutStep(data?: string) {
  return {
    type: CRUISE_IN_FLOW_CHECKOUT_STEP,
    data,
  }
}

export function addFlashCruiseFacets(
  params: App.CruiseSearchFacetParams,
  facets: Array<App.CruiseSearchFacet>,
) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const key = getObjectKey(params)
    const oldFacets = state.cruise.cruiseFacets[key]?.facets || []

    const newFacets = cruiseFacetsMap(facets)
    const uniqueFacets = [...newFacets, ...oldFacets].reduce((acc, facet) => {
      const { category } = facet
      const name = facet.name.trim()
      let key = `${name}_${category}`

      // ignore the destination/departure full name to generate the group key. ex: "Sydney, Australia" will be "Sydney"
      if (['destination_ports', 'departure_ports'].includes(category) && !facet.isTrend) {
        key = `${name.split(',')[0]}_${category}`
      }

      const exists = acc[key]
      if (exists) {
        if (facet.category === 'cruise_prices') {
          acc[key] = {
            ...facet,
            max: Math.max(exists.max || 0, facet.max || 0),
            min: Math.min(exists.min || 0, facet.min || 0),
            maxPerNight: Math.max(exists.maxPerNight || 0, facet.maxPerNight || 0),
            minPerNight: Math.min(exists.minPerNight || 0, facet.minPerNight || 0),
          }
        } else {
          acc[key] = { ...facet, flashOfferCount: exists.flashOfferCount }
        }
      } else {
        acc[key] = facet
      }

      return acc
    }, {} as Record<string, App.CruiseSearchFacet>)

    const sortedFacets = sortBy(Object.values(uniqueFacets), (facet) => {
      if (facet.category === 'cruise_lines') {
        return facet.order || oldFacets.length + 1
      }

      if (facet.category === 'departure_ports') {
        return facet.order || 0
      }

      // flash offer facets should be at the end
      return facet.flashOfferCount ? 2 : 1
    }, 'asc')

    dispatch({
      type: CRUISE_SET_SEARCH_FACETS,
      key,
      data: sortedFacets,
    })
  }
}

export function pushWithCruiseFilters(
  path: string,
  globalSearch: GlobalSearchState,
  queryParams?: URLSearchParams,
) {
  return (dispatch) => {
    const filters = getCruiseFilters(globalSearch, queryParams)
    const query = qs.stringify(filters, { arrayFormat: 'repeat' })
    dispatch(pushWithRegion(path, query))
  }
}

// BOOKING FLOW V2
function getCruiseRegion(state: App.State) {
  return state.geo.currentRegionCode as Cruises.Region
}

function getRegionParams(state: App.State) {
  return {
    currencyCode: state.geo.currentCurrency,
    region: state.geo.currentRegionCode as 'au' | 'AU' | 'nz' | 'NZ',
  }
}

export function fetchBookingRateList(cruiseItem: App.Checkout.CruiseItem) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const rateListData = getRateListByCruiseItem(cruiseItem, state.cruise.multiBooking.rateList)
    if (rateListData.loading) return

    // Expire timer
    expireCruiseTimer()

    // Release cabin if needed
    if (checkIfNeedsToReleaseCabin(cruiseItem)) {
      dispatch(fetchBookingCabinRelease(cruiseItem))
    }

    const payload = {
      ...getRegionParams(state),
      departureId: cruiseItem.departureId,
      travelers: {
        adult: cruiseItem.occupancy.adults,
        child: (cruiseItem.occupancy.children ?? 0) + (cruiseItem.occupancy.infants ?? 0),
        childBirthDate: (cruiseItem.occupancy.childrenBirthDate ?? []) as Array<string>,
      },
    }

    dispatch({
      type: API_CALL,
      api: FETCH_CRUISE_BOOKING_RATE_LIST,
      cartItemId: cruiseItem.itemId,
      request: () => findBookingRateList(payload),
    })
  }
}

export function fetchBookingCabinList(
  sessionId: string,
  cruiseItem: App.Checkout.CruiseItem,
  componentIds: Array<Array<string>>,
) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const cabinList = getCabinListByCruiseItem(cruiseItem, state.cruise.multiBooking.cabinList)
    if (cabinList.loading || cabinList.error) return

    const params = { ...getRegionParams(state), sessionId }

    dispatch({
      type: API_CALL,
      api: FETCH_CRUISE_BOOKING_CABIN_LIST,
      travelerKey: generateCabinTravelerKey(cruiseItem),
      request: async() => {
        const newCabins = await findBookingMultipleCabinList(params, componentIds)
        const cabinByNumber = groupCabinListByNumber(cabinList.cabins, newCabins)

        return {
          cabins: Object.values(cabinByNumber),
          cabinByNumber,
        }
      },
    })
  }
}

export function fetchBookingCabinDetailsList(
  cruiseItem: App.Checkout.CruiseItem,
  cabinNumberGroups: Array<Array<string>>,
) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const region = getCruiseRegion(state)
    const sessionId = cruiseItem.sessionId as string

    dispatch({
      type: API_CALL,
      api: FETCH_CRUISE_BOOKING_CABIN_DETAILS_LIST,
      travelerKey: generateCabinTravelerKey(cruiseItem),
      cabinNumbers: cabinNumberGroups.flat(),
      request: () => findBookingCabinDetailsListMultiple({ sessionId, region }, cabinNumberGroups),
    })
  }
}

export function fetchBookingRateDetailsList(cruiseItem: App.Checkout.CruiseItem, componentId: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const region = getCruiseRegion(state)
    const sessionId = cruiseItem.sessionId as string

    dispatch({
      type: API_CALL,
      api: FETCH_CRUISE_BOOKING_RATE_DETAILS_LIST,
      cartItemId: cruiseItem.itemId,
      componentId,
      request: () => findBookingRateDetailsList({
        sessionId,
        region,
        componentIds: [componentId],
      }),
    })
  }
}

export function fetchBookingCabinSelection(
  cruiseItemId: string,
  params: Omit<CruiseAPI.CruiseCabinSelectionBody, 'region' | 'currencyCode'>,
) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const region = getCruiseRegion(state)
    const currencyCode = state.geo.currentCurrency
    resetCruiseTimer()

    if (!state.cruise.multiBooking.cabinSelection[cruiseItemId]?.loading) {
      dispatch({
        type: API_CALL,
        api: FETCH_CRUISE_BOOKING_CABIN_SELECTION,
        cruiseItemId,
        request: () => findBookingCabinSelection({ ...params, region, currencyCode }),
      })
    }
  }
}

export function fetchBookingCabinPricing(
  cruiseItem: App.Checkout.CruiseItem,
  params: Omit<CruiseAPI.CruiseCabinPricingBody, 'region' | 'currencyCode'>,
) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const cruiseItemId = cruiseItem.itemId
    const region = getCruiseRegion(state)
    const currencyCode = state.geo.currentCurrency

    if (!state.cruise.multiBooking.cabinPricing[cruiseItemId]?.loading) {
      dispatch({
        type: API_CALL,
        api: FETCH_CRUISE_BOOKING_CABIN_PRICING,
        cruiseItemId,
        request: () => findBookingCabinPricing({ ...params, region, currencyCode }),
      })
    }
  }
}

export function fetchBookingCabinRelease(cruiseItem: App.Checkout.CruiseItem) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const { itemId: cruiseItemId, sessionId, bookingId, departureId } = cruiseItem
    const region = getCruiseRegion(state)

    if (!sessionId || !bookingId) return

    expireCruiseTimer()
    dispatch({
      type: API_CALL,
      api: FETCH_CRUISE_BOOKING_CABIN_RELEASE,
      cruiseItemId,
      request: () => cruiseBookingCabinRelease({
        sessionId,
        bookingId,
        region,
        departureId,
      }),
    })
  }
}

export function fetchCruiseShip(id: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    if (state.cruise.ships[id]) return

    dispatch({
      type: API_CALL,
      api: FETCH_CRUISE_SHIP,
      request: () => findCruiseShip(id),
      shipId: id,
    })
  }
}

// When the user goes back to a previous booking step, we want to clear the cart item data from all subsequent steps
export function resetCartDataByStep(checkoutStep: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const cruiseItems = getCruiseItems(state)

    cruiseItems.forEach((cruiseItem) => {
      const dataByStep = [
        { step: CRUISE_CHECKOUT_INFLOW_V2.TRAVELERS, data: ['occupancy'] },
        { step: CRUISE_CHECKOUT_INFLOW_V2.CABIN_TYPE, data: ['cabinType'] },
        { step: CRUISE_CHECKOUT_INFLOW_V2.CABIN_CATEGORY, data: ['cabinCodes'] },
        { step: CRUISE_CHECKOUT_INFLOW_V2.CABIN_SELECTION, data: ['cabinNumber', 'cabinCode', 'deckId'] },
        { step: CRUISE_CHECKOUT_INFLOW_V2.RATE_SELECTION, data: ['componentId', 'cabinPreferences'] },
      ]

      const currentStepIndex = dataByStep.findIndex((step) => step.step === checkoutStep)
      if (currentStepIndex === -1) return

      const nextSteps = dataByStep.slice(currentStepIndex)
      let newItem = { ...cruiseItem }
      nextSteps.forEach((step) => {
        step.data.forEach((key) => {
          const value = key === 'occupancy' ? { adults: 0, children: 0, infants: 0 } : undefined
          newItem = { ...newItem, [key]: value }
        })
      })

      dispatch(updateCheckoutItem(newItem))
    })

    // select the first cruise item
    if (state.cruise.multiBooking.selectedCruiseItemId) {
      dispatch(setBookingCruiseItem(cruiseItems[0].itemId))
    }
  }
}

export function setBookingInterestInConnectedCabins(data: boolean) {
  return {
    type: SET_BOOKING_INTEREST_IN_CONNECTED_CABINS,
    data,
  }
}

export function fetchCruiseConsolidatedPaymentSchedule(bookingIds: Array<string>) {
  return (dispatch) => {
    dispatch({
      type: API_CALL,
      api: FETCH_CRUISE_CONSOLIDATED_PAYMENT_SCHEDULE,
      request: () => getConsolidatedPaymentSchedule(bookingIds),
    })
  }
}
