import React, { useCallback, useContext, useState } from 'react'
import styled from 'styled-components'
import cn from 'clsx'
import { rem } from 'polished'
import Caption from 'components/Luxkit/Typography/Caption'
import eases from 'styles/tools/eases'
import Clickable from './Clickable'
import HiddenInput from './Form/Input/HiddenInput'
import noop from 'lib/function/noop'
import { ProductPaletteContext } from 'contexts/ProductPaletteContext'
import Group from 'components/utils/Group'

const Container = styled(Clickable)`
  background-color: ${props => props.theme.palette.neutral.default.four};
  height: ${rem(24)};
  width: ${rem(48)};
  border-radius: ${props => props.theme.borderRadius.round};
  transition: background-color 0.3s ease;

  &.toggled {
    background-color: ${props => props.theme.palette.messaging.success.normalBackground};
  }

  &.product-palette-loyalty {
    &.toggled {
      background-color: ${props => props.theme.palette.product.loyalty.background};
    }
  }
`

const Circle = styled.div`
  background-color: ${props => props.theme.palette.neutral.default.eight};
  box-shadow: ${props => props.theme.shadow.flat.small};
  transform: translate3d(0, 0, 0);
  height: ${rem(24)};
  width: ${rem(24)};
  border-radius: ${props => props.theme.borderRadius.round};
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
  transition: transform .35s ${eases.iosBounce.inOut};

  &.toggled {
    transform: translate3d(100%, 0, 0);
  }
`

const Wrapper = styled(Group)`
  position: relative;
`

interface Props {
  defaultToggled?: boolean;
  toggled?: boolean;
  onToggle?: (e: React.MouseEvent<HTMLButtonElement>, value: boolean) => void;
  label?: string;
  className?: string;
  name?: string;
  ariaLabel?: string;
  nonInteractive?: boolean;
}

function SwitchButton(props: Props) {
  const { defaultToggled, toggled, onToggle = noop, name, label, className, ariaLabel, nonInteractive } = props
  const [localToggle, setLocalToggle] = useState(toggled ?? defaultToggled)
  const productPalette = useContext(ProductPaletteContext)

  const finalToggled = toggled !== undefined ? toggled : localToggle

  const onToggleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setLocalToggle(value => !value)
    onToggle(e, !localToggle)
  }, [onToggle, localToggle])

  return (
    <Wrapper
      direction="horizontal"
      verticalAlign="center"
      role="switch"
      aria-checked={finalToggled}
      className={className}
      gap={8}
    >
      {name && <HiddenInput name={`${name}::boolean`} value={finalToggled ? 'true' : 'false'} />}
      {!!label && <Caption variant="large" as="label">{label}</Caption>}
      <Container
        aria-label={ariaLabel}
        onClick={nonInteractive ? undefined : onToggleClick}
        as={nonInteractive ? 'div' : undefined}
        className={cn({ toggled: finalToggled, [`product-palette-${productPalette}`]: productPalette })}
      >
        <Circle aria-hidden="true" className={cn({ toggled: finalToggled })} />
      </Container>
    </Wrapper>
  )
}

export default SwitchButton
