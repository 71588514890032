import createSelector from 'lib/web/createSelector'
import { OptimizelyExperiments, OptimizelyFeatureFlags } from 'constants/optimizely'

export const isNextHotelIndexEnabled = createSelector(
  (state: App.State) => state.optimizely.optimizelyFeatureFlags,
  (featureFlags): boolean => {
    return !!featureFlags[OptimizelyFeatureFlags.searchNewHotelIndex]
  },
)

export const isSearchPricingEnabled = createSelector(
  (state: App.State) => state.optimizely.optimizelyFeatureFlags,
  (state: App.State) => state.optimizely.optimizelyExperiments,
  (featureFlags, experiments): boolean => {
    return !!featureFlags[OptimizelyFeatureFlags.searchPricing] &&
            !featureFlags?.[OptimizelyFeatureFlags.searchStreaming] &&
            !experiments?.[OptimizelyExperiments.lereSearchModelV2]?.enabled &&
            !experiments?.[OptimizelyExperiments.searchAltAvailabilityV2]?.enabled
  },
)
