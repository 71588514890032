import switchFunc from 'lib/function/switchFunc'

import { SvgIconProps } from 'components/Luxkit/SvgIcon'

import LineAppleIcon from 'components/Luxkit/Icons/line/LineAppleAltIcon'
import LineBathIcon from 'components/Luxkit/Icons/line/LineBathIcon'
import LineBedDoubleIcon from 'components/Luxkit/Icons/line/LineBedDoubleIcon'
import LineBusIcon from 'components/Luxkit/Icons/line/LineBusIcon'
import LineCarIcon from 'components/Luxkit/Icons/line/LineCarIcon'
import LineChairWithAirplaneIcon from 'components/Luxkit/Icons/line/LineChairWithAirplaneIcon'
import LineCheckIcon from 'components/Luxkit/Icons/line/LineCheckIcon'
import LineChildWithPlusIcon from 'components/Luxkit/Icons/line/LineChildWithPlusIcon'
import LineClochIcon from 'components/Luxkit/Icons/line/LineClochIcon'
import LineClockWithTickIcon from 'components/Luxkit/Icons/line/LineClockWithTickIcon'
import LineCoffeeIcon from 'components/Luxkit/Icons/line/LineCoffeeIcon'
import LineDelayIcon from 'components/Luxkit/Icons/line/LineDelayIcon'
import LineDiningCreditIcon from 'components/Luxkit/Icons/line/LineDiningCreditIcon'
import LineDollarSignIcon from 'components/Luxkit/Icons/line/LineDollarSignIcon'
import LineDrinksTrayIcon from 'components/Luxkit/Icons/line/LineDrinksTrayIcon'
import LineFireIcon from 'components/Luxkit/Icons/line/LineFireIcon'
import LineFlightCreditIcon from 'components/Luxkit/Icons/line/LineFlightCreditIcon'
import LineGlassMartiniAltIcon from 'components/Luxkit/Icons/line/LineGlassMartiniAltIcon'
import LineGlassMartiniIcon from 'components/Luxkit/Icons/line/LineGlassMartiniIcon'
import LineGolfIcon from 'components/Luxkit/Icons/line/LineGolfIcon'
import LineHappyHourIcon from 'components/Luxkit/Icons/line/LineHappyHourIcon'
import LineKidIcon from 'components/Luxkit/Icons/line/LineKidIcon'
import LineNoFeesIcon from 'components/Luxkit/Icons/line/LineNoFeesIcon'
import LineOneWayFlightIcon from 'components/Luxkit/Icons/line/LineOneWayFlightIcon'
import LineParkingCircleIcon from 'components/Luxkit/Icons/line/LineParkingCircleIcon'
import LineReceiptIcon from 'components/Luxkit/Icons/line/LineReceiptIcon'
import LineReturnFlightIcon from 'components/Luxkit/Icons/line/LineReturnFlightIcon'
import LineRoomUpgradeIcon from 'components/Luxkit/Icons/line/LineRoomUpgradeIcon'
import LineSkiLiftIcon from 'components/Luxkit/Icons/line/LineSkiLiftIcon'
import LineSnowFlakeIcon from 'components/Luxkit/Icons/line/LineSnowFlakeIcon'
import LineSpaCreditIcon from 'components/Luxkit/Icons/line/LineSpaCreditIcon'
import LineTagIcon from 'components/Luxkit/Icons/line/LineTagIcon'
import LineTaxiIcon from 'components/Luxkit/Icons/line/LineTaxiIcon'
import LineTennisBallIcon from 'components/Luxkit/Icons/line/LineTennisBallIcon'
import LineUtensilsIcon from 'components/Luxkit/Icons/line/LineUtensilsIcon'
import LineWaterIcon from 'components/Luxkit/Icons/line/LineWaterIcon'
import LineWellnessIcon from 'components/Luxkit/Icons/line/LineWellnessIcon'
import LineWifiIcon from 'components/Luxkit/Icons/line/LineWifiIcon'
import LineWifiRouterIcon from 'components/Luxkit/Icons/line/LineWifiRouterIcon'
import LineWineBeerIcon from 'components/Luxkit/Icons/line/LineWineBeerIcon'
import LineTvRetroIcon from 'components/Luxkit/Icons/line/LineTvRetroIcon'
import LineShowerIcon from 'components/Luxkit/Icons/line/LineShowerIcon'
import LineTreesIcon from 'components/Luxkit/Icons/line/LineTreesIcon'
import LineOvenIcon from 'components/Luxkit/Icons/line/LineOvenIcon'
import LineBoxIcon from 'components/Luxkit/Icons/line/LineBoxIcon'
import LineToasterIcon from 'components/Luxkit/Icons/line/LineToasterIcon'
import LineCookingPanIcon from 'components/Luxkit/Icons/line/LineCookingPanIcon'
import LineMicrowaveIcon from 'components/Luxkit/Icons/line/LineMicrowaveIcon'
import LineBlenderIcon from 'components/Luxkit/Icons/line/LineBlenderIcon'
import LineWashingMachineIcon from 'components/Luxkit/Icons/line/LineWashingMachineIcon'
import LineHairDryerIcon from 'components/Luxkit/Icons/line/LineHairDryerIcon'
import LineFridgeIcon from 'components/Luxkit/Icons/line/LineFridgeIcon'
import LineWindIcon from 'components/Luxkit/Icons/line/LineWindIcon'
import LineSunIcon from 'components/Luxkit/Icons/line/LineSunIcon'
import LineIronIcon from 'components/Luxkit/Icons/line/LineIronIcon'
import LineKettleIcon from 'components/Luxkit/Icons/line/LineKettleIcon'
import LineDesktopIcon from 'components/Luxkit/Icons/line/LineDesktopIcon'
import LineGlassIcon from 'components/Luxkit/Icons/line/LineGlassIcon'
import LineSanitizerIcon from 'components/Luxkit/Icons/line/LineSanitizerIcon'
import LineDumbbellIcon from 'components/Luxkit/Icons/line/LineDumbbellIcon'
import LineWheelchairIcon from 'components/Luxkit/Icons/line/LineWheelchairIcon'
import LineDryerIcon from 'components/Luxkit/Icons/line/LineDryerIcon'
import LinePlaneDepartureIcon from 'components/Luxkit/Icons/line/LinePlaneDepartureIcon'
import LineSwimmerIcon from 'components/Luxkit/Icons/line/LineSwimmerIcon'

const INCLUSION_ICONS: Record<
  string,
  React.FunctionComponent<SvgIconProps>
> = {
  apple: LineAppleIcon,
  bed_with_upwards_arrow: LineRoomUpgradeIcon,
  bus: LineBusIcon,
  car: LineCarIcon,
  check: LineCheckIcon,
  childs_face: LineKidIcon,
  childs_face_with_plus: LineChildWithPlusIcon,
  cloche: LineClochIcon,
  clock_with_check: LineClockWithTickIcon,
  clock_with_plus: LineDelayIcon,
  coffee_cup: LineCoffeeIcon,
  crossed_utensils: LineUtensilsIcon,
  crossed_utensils_with_plus: LineDiningCreditIcon,
  dollar_sign_in_circle: LineDollarSignIcon,
  dollar_sign_in_circle_with_line_through: LineNoFeesIcon,
  drink_tray: LineDrinksTrayIcon,
  golf: LineGolfIcon,
  lotus: LineWellnessIcon,
  lotus_with_plus: LineSpaCreditIcon,
  martini_glass: LineGlassMartiniAltIcon,
  p_in_circle: LineParkingCircleIcon,
  plane_with_arrow: LineOneWayFlightIcon,
  plane_with_arrows: LineReturnFlightIcon,
  plane_with_plus: LineFlightCreditIcon,
  plane_departure: LinePlaneDepartureIcon,
  receipt: LineReceiptIcon,
  seat_with_plane: LineChairWithAirplaneIcon,
  ski_lift: LineSkiLiftIcon,
  tag: LineTagIcon,
  taxi: LineTaxiIcon,
  tennis_ball: LineTennisBallIcon,
  wifi_signal: LineWifiIcon,
  wine_glass: LineGlassMartiniIcon,
  wine_glass_and_beer_glass: LineWineBeerIcon,
  wine_glass_with_clock: LineHappyHourIcon,
  ocean: LineWaterIcon,
  air_conditioning: LineSnowFlakeIcon,
  internet_connection: LineWifiRouterIcon,
  beach: LineWaterIcon,
  parking: LineParkingCircleIcon,
  king_size_bed: LineBedDoubleIcon,
  ensuite: LineBathIcon,
  heating: LineFireIcon,
  wifi: LineWifiIcon,
  tv: LineTvRetroIcon,
  pool: LineSwimmerIcon,
  toiletries: LineSanitizerIcon,
  shower: LineShowerIcon,
  garden: LineTreesIcon,
  underground_parking: LineParkingCircleIcon,
  terrace: LineTreesIcon,
  balcony: LineTreesIcon,
  bathtub: LineBathIcon,
  dining_area: LineUtensilsIcon,
  kitchen: LineOvenIcon,
  wardrobe: LineBoxIcon,
  toaster: LineToasterIcon,
  cook: LineCookingPanIcon,
  oven: LineOvenIcon,
  microwave: LineMicrowaveIcon,
  blender: LineBlenderIcon,
  washing_machine: LineWashingMachineIcon,
  hair_dryer: LineHairDryerIcon,
  fridge: LineFridgeIcon,
  coffee: LineCoffeeIcon,
  heat: LineFireIcon,
  wind: LineWindIcon,
  sun: LineSunIcon,
  iron: LineIronIcon,
  stereo: LineWifiRouterIcon,
  kettle: LineKettleIcon,
  desk: LineDesktopIcon,
  juice: LineGlassIcon,
  gym: LineDumbbellIcon,
  laundry: LineWashingMachineIcon,
  wheelchair: LineWheelchairIcon,
  dryer: LineDryerIcon,
}

const INCLUSION_MAP = {
  wifi: 'wifi',
  parking: 'parking',
  bed: 'king_size_bed',
  kitchen: 'kitchen',
  wardrobe: 'wardrobe',
  cook: 'cook',
  stove: 'cook',
  pool: 'pool',
  coffee: 'coffee',
  heat: 'heat',
  ocean: 'beach',
  wind: 'wind',
  cutlery: 'dining_area',
  sun: 'sun',
  iron: 'iron',
  garden: 'garden',
  juice: 'juice',
  breakfast: 'coffee',
  washer: 'laundry',
  outdoor: 'garden',
}

const inclusionSwitch = switchFunc(INCLUSION_ICONS, LineCheckIcon)

export function getInclusionIcon(value: string = '') {
  let val = value.replaceAll(' ', '_').replaceAll('-', '').toLowerCase()
  for (const key in INCLUSION_MAP) {
    if (val.includes(key)) {
      val = INCLUSION_MAP[key]
      break
    }
  }
  return inclusionSwitch(val)
}
