import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import config from 'constants/config'
import { connect } from 'react-redux'
import TextButton from 'components/Luxkit/Button/TextButton'
import { buildSuggestedDatesParamsKey, buildSuggestedDatesString, debugSearchPricingDifferences, formatDateRangesForAlternativeDates } from 'lib/search/searchUtils'
import { getSuggestedDates } from 'selectors/offerSelectors'
import BusinessTravellerOfferCreditsTextLink from 'businessTraveller/components/offer-credits/BusinessTravellerOfferCreditsTextLink'
import { pluralizeToString } from 'lib/string/pluralize'
import getBedbankPromoExtraValue, { getDisplayTotalPrice, getDisplayTotalPricePerNight } from 'lib/hotels/bedbankUtils'
import useBedbankOfferSell from 'hooks/useBedbankOfferSell'
import BedbankTwinShare from 'components/OfferList/OfferListTiles/Support/BedbankTwinShare'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import { useBedbankRoomRatePromotions } from 'hooks/OfferPage/useBedbankPromotions'
import moment from 'moment'
import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import { getOptimizelyExperimentVariation } from '../../../../lib/optimizely/optimizelyUtils'
import { OptimizelyExperiments } from 'constants/optimizely'
import { calculateDiscount } from '../../../../lib/payment/calculateDiscount'
import OfferPriceDetailsRow from 'components/Common/PriceDetails/OfferPriceDetailsRow'
import { mediaQueryUp } from '../../../utils/breakpoint'
import useOfferAlternativeDate from '../../../../hooks/Offers/useOfferAlternativeDate'
import { useAppSelector } from 'hooks/reduxHooks'
import BedbankMarginText from 'components/OfferPage/Bedbank/BedbankMargin'
import { max } from 'lib/array/arrayUtils'
import useOfferList from '../../../../hooks/Offers/useOfferList'
import useOfferMetaData from '../../../../hooks/Offers/useOfferMetaData'
import { SEARCH_VERTICALS } from '../../../../constants/search'
import { isSearchPricingEnabled } from '../../../../selectors/hotelSearchSelectors'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  
  ${mediaQueryUp.tablet} {
    align-self: flex-start;
  }
`

const UpsellCTAButton = styled(TextButton)`
  align-self: flex-end;
`

interface MappedStateProps {
  suggestedDates?: App.OfferSuggestedDates;
  isPerNightPricingTestEnabled: boolean;
  region: string;
  currency: string;
}

interface Props {
  rate?: App.BedbankRate;
  bundleRate?: App.BedbankRate;
  rooms: Array<App.Occupants>;
  offer: App.BedbankOffer | App.BedbankOfferSummary;
  filters?: App.OfferListFilters;
  offerUrl: string;
  promotions: Array<App.BedbankPromotion>;
  isUpsell?: boolean;
  flightPrice: number | undefined
  flightsEnabled: boolean;
  className?: string;
  allRates?: Array<App.BedbankRate>
}

function BedbankOfferTilePriceDetails(props: Props & MappedStateProps) {
  const {
    rate,
    bundleRate,
    rooms,
    offer,
    filters,
    offerUrl,
    suggestedDates,
    isUpsell,
    flightPrice,
    flightsEnabled,
    promotions,
    isPerNightPricingTestEnabled,
    region,
    currency,
    className,
    allRates,
  } = props

  const { alternativeDate } = useOfferAlternativeDate(offer.id, 'bedbank', filters ?? {})
  const offerList = useOfferList(filters, { noFetch: true })
  const offerMetadata = useOfferMetaData(offer.id, filters)
  const isSearchPricingFlagEnabled = useAppSelector(state => isSearchPricingEnabled(state))

  const isFlexibleSearch = filters?.flexibleNights && suggestedDates?.checkIn && suggestedDates?.checkOut

  let duration = rate?.nights
  if (promotions.length && !filters?.checkIn && !filters?.checkOut) {
    duration = Math.min(...promotions.map(x => x.los))
  }

  const sell = useBedbankOfferSell(offer, duration, rooms)
  const promo = useBedbankRoomRatePromotions(
    offer.promotions,
    filters?.checkIn ? moment(filters.checkIn) : undefined,
    filters?.checkOut ? moment(filters.checkOut) : undefined,
  )

  const taxesAndFees = rate?.totals.taxesAndFees ?? sell?.taxesAndFees ?? 0
  const propertyFees = rate?.totals.propertyFees ?? sell?.propertyFees ?? 0
  const bundleTaxesAndFees = bundleRate?.totals.taxesAndFees || taxesAndFees
  const bundlePropertyFees = bundleRate?.totals.propertyFees || propertyFees

  const hotelPrice = suggestedDates?.price ?? (rate ? rate.totals.inclusive : sell?.price ?? 0)
  const bundlePrice = bundleRate?.totals.inclusive || hotelPrice

  let hotelValue: undefined | number
  let bundleValue: undefined | number

  const hotelNights = rate?.nights ?? sell?.los
  let hotelDiscount: number

  // Best margin %, not value (aud)
  const rateWithBestMargin = allRates && max(allRates, (rate) => rate.margin ?? Number.NEGATIVE_INFINITY)
  const margin = rateWithBestMargin?.margin
  const marginAud = rateWithBestMargin?.marginAud
  const supplier = rateWithBestMargin?.supplier
  const isSpoofed = useAppSelector(state => state.auth.account.isSpoofed)
  const showMarginInSpoofMode = useAppSelector(state => state.config.showMarginInSpoofMode)
  const displayMargin = Boolean(margin && marginAud && supplier && isSpoofed && showMarginInSpoofMode)

  if (rate && 'packages' in offer) {
    const pkg = rate.room
    const isPromoEnabledRate = !!promo[rate.id]

    if (isPromoEnabledRate) {
      const inclusionsAmount = pkg?.sell ? getBedbankPromoExtraValue({
        price: pkg.sell.price,
        value: pkg.sell.value,
        minLos: pkg.sell.los,
        duration,
      }) : 0
      hotelValue = inclusionsAmount + (rate.totals.inclusive)
      bundleValue = inclusionsAmount + (bundleRate?.totals.inclusive || hotelValue)
      hotelDiscount = Number((inclusionsAmount / hotelValue).toFixed(2))
    } else {
      hotelValue = rate.value
      bundleValue = bundleRate?.value || hotelValue
      hotelDiscount = rate.discount
    }
  } else if (suggestedDates?.value) {
    hotelValue = suggestedDates.value
    hotelDiscount = sell?.discount ?? 0
  } else {
    hotelValue = sell?.value ?? 0
    bundleValue = sell?.value
    hotelDiscount = sell?.discount ?? 0
  }

  const totalPrices = getDisplayTotalPrice({
    hotelPrice: hotelPrice + propertyFees,
    hotelValue,
    bundlePrice: bundlePrice + bundlePropertyFees,
    bundleValue,
    hotelDiscount,
    checkIn: filters?.checkIn,
    flightPrice,
    rooms,
    displayPricingAsPerPerson: offer.displayPricingAsPerPerson,
  })
  const totalPricesPerNight = getDisplayTotalPricePerNight(totalPrices, hotelNights || 1)

  const promoteAsBundle = flightsEnabled && !!flightPrice && offer.promoteAsBundle

  let offerPrice = promoteAsBundle ? totalPrices.totals.flightBundlePrice : totalPrices.totals.price
  const perNightOfferPrice = promoteAsBundle ? totalPricesPerNight.totals.flightBundlePrice : totalPricesPerNight.totals.price

  const isAnytimeSearch = (!filters?.checkIn && !filters?.checkOut) && !isFlexibleSearch

  const showOneNightPrice = !isPerNightPricingTestEnabled && isAnytimeSearch && !offer.promotions.length
  const nightsToDisplay = showOneNightPrice ? 1 : (hotelNights || 0)
  offerPrice = showOneNightPrice ? (offerPrice / hotelNights!) : offerPrice

  const baseSaleUnit = isPerNightPricingTestEnabled ? 'night' : 'total'
  const saleUnit = offer.displayPricingAsPerPerson ? 'person' : baseSaleUnit
  offerPrice = isPerNightPricingTestEnabled ? perNightOfferPrice : offerPrice

  let value = promoteAsBundle ? totalPrices.totals.flightBundleValue : totalPrices.totals.value
  value = (value / hotelNights!) * nightsToDisplay
  let price = promoteAsBundle ? totalPrices.totals.flightBundlePrice : totalPrices.totals.price
  price = (price / hotelNights!) * nightsToDisplay
  const discountPercentage = calculateDiscount(price, value)
  const offerLocations: Array<string> = useMemo(() => {
    const locations = [
      offer.property?.address.city,
      offer.property?.address.stateProvinceName || '',
      offer.property?.address.countryName]
      .filter(Boolean)

    return locations
  }, [offer])

  useEffect(() => {
    if (offerList.searchVertical === SEARCH_VERTICALS.HOTELS && isSearchPricingFlagEnabled) {
      const searchPrices = offerMetadata?.pricing
      if (!searchPrices) {
        return
      }

      const lePricing = {
        price: totalPrices.totals.price,
        tax: taxesAndFees,
        value: totalPrices.totals.value,
        memberPrice: 0, // bedbank doesn't support lux plus pricing
      }
      const searchPricing = {
        price: (searchPrices.lowestPrice ?? 0) + (searchPrices.lowestPricePropertyFees ?? 0),
        tax: searchPrices.lowestPriceTaxes ?? 0,
        value: searchPrices.lowestPriceValue ?? 0,
        memberPrice: 0,
      }
      debugSearchPricingDifferences(lePricing, searchPricing, { offer, region, currency, filters: filters ?? {}, rate, searchPropertyFees: searchPrices.lowestPricePropertyFees })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchPricingFlagEnabled]) // log only once

  return <Root className={className}>
    <PriceRowPriceCaption>
      {!!alternativeDate && `${formatDateRangesForAlternativeDates(alternativeDate.checkIn, alternativeDate.checkOut)} `}
      {isFlexibleSearch && buildSuggestedDatesString(suggestedDates.checkIn, suggestedDates.checkOut)}
      {!isFlexibleSearch && `${pluralizeToString('night', nightsToDisplay)}`}
      {promoteAsBundle && ' + flights'}
      {' '}from
    </PriceRowPriceCaption>
    <PriceRowPrice
      size="L"
      price={offerPrice}
      saleUnit={saleUnit}
    />
    {offer.displayPricingAsPerPerson && <BedbankTwinShare rooms={rooms} />}
    {discountPercentage >= 5 && <PriceRowValueDiscountWithCaption
      size="M"
      originalValue={value}
      discountPercentage={discountPercentage}
    />}

    {config.businessTraveller.currentAccountMode === 'business' && <BusinessTravellerOfferCreditsTextLink
      type="estimate"
      offer={offer}
      totalCost={promoteAsBundle ? totalPrices.original.flightBundlePrice : totalPrices.original.price}
      numberOfNights={hotelNights}
    />}

    <PriceRowAgentHubCommission
      size="L"
      productType={offer.productType}
      offerId={offer.id}
      offerLocations={offerLocations}
    />

    {displayMargin && <BedbankMarginText
      margin={margin!}
      marginAud={marginAud!}
      supplier={supplier!}
      type="bedbank_estimate"
    />}

    {isUpsell && <UpsellCTAButton kind="primary" fit="full-width" to={offerUrl}>
      View Offer
    </UpsellCTAButton>}

    {rate && !isUpsell && (<OfferPriceDetailsRow
      trigger="price-row"
      triggerSize="M"
      offer={offer}
      rooms={rooms}
      propertyFees={promoteAsBundle ? bundlePropertyFees : propertyFees}
      taxesAndFees={promoteAsBundle ? bundleTaxesAndFees : taxesAndFees}
      hotelPrice={promoteAsBundle ? bundlePrice : hotelPrice}
      flightPrice={promoteAsBundle ? flightPrice : undefined}
      duration={hotelNights}
      cta={
        <TextButton kind="primary" fit="flex" to={offerUrl}>
          View Offer
        </TextButton>
      }
      context="search"
      hasMobilePromotion={rate.mobilePromotion}
    />)}
  </Root>
}

export default connect<MappedStateProps, undefined, Props, App.State>((appState, ownProps) => {
  const flexibleSearchFilterKey = buildSuggestedDatesParamsKey(ownProps.filters?.flexibleMonths, ownProps.filters?.flexibleNights, ownProps.filters?.rooms)
  return {
    suggestedDates: getSuggestedDates(appState, flexibleSearchFilterKey, ownProps.offer.id),
    isPerNightPricingTestEnabled: !!getOptimizelyExperimentVariation(appState, OptimizelyExperiments.pricePerNight),
    region: appState.geo.currentRegionCode,
    currency: appState.geo.currentCurrency,
  }
})(BedbankOfferTilePriceDetails)
