import { io, Socket } from 'socket.io-client'
import config from 'constants/config'
import { SocketEvents } from '@luxuryescapes/contract-search'

export let globalSearchSocket: Socket<SocketEvents> | undefined = undefined

export function getSearchSocket() {
  if (!globalSearchSocket) {
    const protocol = config.SCHEME === 'http' ? 'ws' : 'wss'
    const uri = `${protocol}://${config.API_HOST}`

    globalSearchSocket = io(uri, {
      transports: ['websocket'],
      path: '/api/search/hotel/v1/stream/socket.io',
    })
  }
  return globalSearchSocket
}
