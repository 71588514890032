import React, { useCallback, useContext } from 'react'
import cn from 'clsx'
import styled from 'styled-components'
import { rem } from 'polished'
import { mediaQueryUp } from 'components/utils/breakpoint'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import Pane from 'components/Common/Pane'
import { take } from 'lib/array/arrayUtils'
import Image from 'components/Common/Image'
import Heading from 'components/Luxkit/Typography/Heading'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import OffsetBookmarkContainer from 'tripPlanner/components/Bookmark/Common/OffsetBookmarkContainer'
import ImageCarousel from 'components/Luxkit/Carousel/ImageCarousel'
import GeoContext from 'contexts/geoContext'
import AspectRatio from 'components/utils/AspectRatio'
import { connect } from 'react-redux'
import { ImageParams } from 'components/Common/ResponsiveImage'
import NumberRating from 'components/Common/NumberRating/NumberRating'
import { showUserReviewsRating } from 'lib/order/reviewUtils'
import Group from 'components/utils/Group'
import { isBedbankFlightBookingEnabled } from 'selectors/flightsSelectors'
import TextButton from 'components/Luxkit/Button/TextButton'
import { DMY_CASUAL_FORMAT } from 'constants/dateFormats'
import moment from 'moment'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Subtitle from 'components/Luxkit/Typography/Subtitle'
import OfferBadgeTag from 'components/Common/OfferBadgeTag'
import TextLink from 'components/Luxkit/TextLink'
import BedbankOfferPriceDetails from 'components/OfferList/OfferCards/BedbankOfferPriceDetails'
import PriceRowFlightPackage from 'components/Luxkit/PricePoints/PriceRowFlightPackage'
import WalledContent from 'components/Common/WalledContent'
import OfferTileLoggedInButtons from './Support/OfferTileLoggedInButtons'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'

const Main = styled(Pane)`
  display: grid;
  position: relative;
  z-index: 0;

  ${mediaQueryUp.desktop} {
    grid-template-columns: 67% 1fr;
    grid-template-rows: 1fr;
  }
`

const DetailContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${mediaQueryUp.desktop} {
    flex: 1;
    padding: ${rem(16)};
  }
`

const DetailSegment = styled.div`
  position: relative;
  padding: ${rem(20)};

  ${mediaQueryUp.tablet} {
    padding: ${rem(24)};
  }

  ${mediaQueryUp.desktop} {
    flex: 1;
    text-align: center;
  }
`

const TopDetailSegment = styled.div`
  width: 100%;

  > * + * {
    margin-top: ${rem(12)};

    ${mediaQueryUp.desktop} {
      margin-top: ${rem(8)};
    }
  }
`

const OfferTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-column-gap: ${rem(16)};
  grid-auto-flow: column;

  ${mediaQueryUp.desktop} {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-row-gap: ${rem(8)};
    justify-items: center;
  }
`

const OfferTitleText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const OfferContent = styled.div`
  > * + * {
    margin-top: ${rem(8)};

    ${mediaQueryUp.desktop} {
      margin-top: ${rem(12)};
    }
  }
`

const MainImageSegment = styled.div`
  display: grid;
  gap: ${rem(4)};
  position: relative;

  ${mediaQueryUp.desktop} {
    &.additional {
      grid-template-rows: 1fr ${rem(120)};
    }
  }
`

const AdditionalImages = styled.div`
  display: none;

  ${mediaQueryUp.desktop} {
    display: flex;
    gap: ${rem(4)};
  }
`

const OfferOverlayContainer = styled(LabelGroup)`
  position: absolute;
  top: ${rem(12)};
  left: ${rem(12)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const USDesktopRating = styled(NumberRating)`
  justify-content: center;
`

const UrgencyLabelsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-column: 1 / 1;

  ${mediaQueryUp.desktop} {
    justify-content: center;
  }

  > * + * {
    margin-left: ${rem(4)};

    ${mediaQueryUp.desktop} {
      margin-left: ${rem(12)};
    }
  }
`

const LogoWrapper = styled.div`
  grid-column-start: 2;
  grid-row: span 2;
  align-self: center;

  ${mediaQueryUp.desktop} {
    grid-row: auto;
    grid-column-start: auto;
  }
`

const Logo = styled(Image)`
  width: ${rem(90)};
  height: ${rem(60)};

  ${mediaQueryUp.desktop} {
    width: ${rem(150)};
    height: ${rem(100)};
  }
`

const BottomSegmentDetails = styled(Group)``
const BottomSegmentCTA = styled(Group)``
const BottomSegmentExtra = styled(Group)``

const BottomSegmentGrid = styled.div`
  display: grid;
  grid-column-gap: ${rem(8)};
  grid-template:
    "details cta"
    "extra extra" auto / 1fr ${rem(120)};

  > ${BottomSegmentDetails} {
    grid-area: details;
  }
  > ${BottomSegmentCTA} {
    grid-area: cta;
    align-self: end;
  }
  > ${BottomSegmentExtra} {
    grid-area: extra;
    margin-top: ${rem(16)};
  }

  ${mediaQueryUp.desktop} {
    grid-template:
      "details"
      "." ${rem(16)}
      "cta"
      "extra" auto / 1fr;
  }
`

interface MappedProps {
  isBedbankFlightBookingEnabled: boolean;
}

interface Props {
  offer: App.BedbankOffer;
  eagerLoadFirstImage?: boolean;
  filters?: App.OfferListFilters;
  urgencyLabels?: JSX.Element;
}

const imageParams: ImageParams = {
  aspectRatio: '16:9',
  desktopWidth: '67vw',
  largeDesktopWidth: '750px',
}

function FrontPageBedbankOfferTile(props: Props & MappedProps) {
  const {
    offer,
    eagerLoadFirstImage,
    urgencyLabels,
  } = props

  const {
    property,
    name,
  } = offer

  const { currentRegionCode } = useContext(GeoContext)

  const showAdditionalImages = offer.images.length > 3
  const location = [
    offer.property.address.city,
    offer.property.address.countryName,
  ]
    .filter(Boolean)
    .join(', ')

  const isUSRegion = currentRegionCode === 'US'
  const hasReview = !isUSRegion && offer.property && showUserReviewsRating(offer.property.rating, offer.property.reviewsTotal)
  const hasTravelUntil = !!offer.promotions[0]?.travelPeriod?.to

  const { currentRegionHasFlights } = useContext(GeoContext)
  const showFlightPricingDetails = !offer.walledGarden && currentRegionHasFlights && offer.flights.flightsEnabled

  const wall = <OfferTileLoggedInButtons
    signUpText="Sign up for free"
    align="none"
    fullWidth
  />

  const dispatchOfferListEvent = useContext(OfferListEventsContext)
  const handleImageLoaded = useCallback(() => {
    dispatchOfferListEvent({ type: OfferListEvents.offerReady })
  }, [dispatchOfferListEvent])

  return (
    <Main type="clean">
      <MainImageSegment className={cn({ additional: showAdditionalImages })}>
        <AspectRatio ratio="16:9" fillHeight>
          <ImageCarousel
              images={offer.images}
              imageParams={imageParams}
              eagerLoadFirstImage={eagerLoadFirstImage}
              onImageLoad={handleImageLoaded}
            />
        </AspectRatio>
        {showAdditionalImages && <AdditionalImages>
          {take(offer.images, 3, 1).map((image) => (
            <Image
              key={image.id}
              id={image.id}
              alt={name}
              height={120}
              width={245}
              loading="lazy"
              fit="center"
            />
          ))}
        </AdditionalImages>}
        <OfferOverlayContainer>
          <ProductTypeLabel productType={offer.productType} />
        </OfferOverlayContainer>
      </MainImageSegment>
      <DetailContainer>
        <TripGuard>
          <CSSBreakpoint min="desktop">
            <Group direction={(!hasReview) ? 'horizontal-reverse' : 'horizontal'} horizontalAlign="space-between">
              {offer.property && hasReview && (
                <NumberRating
                  variant="medium"
                  rating={offer.property.rating}
                  total={offer.property.reviewsTotal}
                  ratingSubtitle={offer.property.reviewsSource === 'google' ? 'From online reviews' : undefined}
                />
              )}
              <BookmarkButton offer={offer} />
            </Group>
          </CSSBreakpoint>
        </TripGuard>
        <DetailSegment>
          <TripGuard>
            <CSSBreakpoint max="tablet">
              <OffsetBookmarkContainer>
                <BookmarkButton offer={offer} />
              </OffsetBookmarkContainer>
            </CSSBreakpoint>
          </TripGuard>
          <Group direction="vertical" gap={20} verticalAlign="space-between" fullHeight fullWidth>
            <TopDetailSegment>
              <OfferTitle>
                <LogoWrapper>
                  {offer.logoImageId && <Logo id={offer.logoImageId} width={300} aspectRatio="3:2" alt={`${property.name || 'Vendor'} logo`}/>}
                </LogoWrapper>
                {urgencyLabels}
                <OfferTitleText>
                  <Subtitle variant="subtitle3">{location}</Subtitle>
                  <Heading variant="heading5">{property.name ?? offer.name}</Heading>
                </OfferTitleText>
              </OfferTitle>
              {offer.property && showUserReviewsRating(offer.property.rating, offer.property.reviewsTotal) && (
                <>
                  <CSSBreakpoint max="tablet">
                    <NumberRating
                      inlineLabel
                      variant="xs"
                      rating={offer.property.rating}
                      total={offer.property.reviewsTotal}
                      ratingSubtitle={offer.property.reviewsSource === 'google' ? 'From online reviews' : undefined}
                    />
                  </CSSBreakpoint>
                  {isUSRegion && (
                    <CSSBreakpoint min="desktop">
                      <USDesktopRating
                        inlineLabel
                        variant="xs"
                        rating={offer.property.rating}
                        total={offer.property.reviewsTotal}
                        ratingSubtitle={offer.property.reviewsSource === 'google' ? 'From online reviews' : undefined}
                      />
                    </CSSBreakpoint>
                  )}
                </>
              )}

              <OfferContent>
                <BodyText variant="large" lineClamp={3}>{offer.amenities}</BodyText>
                {hasTravelUntil && <TextLink size="medium" underline={false}>
                  Travel until {moment(offer.promotions[0].travelPeriod.to).format(DMY_CASUAL_FORMAT)}
                </TextLink>}
                {!!offer.badge && <UrgencyLabelsWrapper>
                  <OfferBadgeTag badge={offer.badge} />
                </UrgencyLabelsWrapper>}
              </OfferContent>
            </TopDetailSegment>
            <WalledContent enforced={offer.walledGarden} hidePricing={offer.hidePricing} wall={wall}>
              <BottomSegmentGrid>
                <BottomSegmentDetails direction="vertical" horizontalAlign="start" desktopHorizontalAlign="center">
                  <BedbankOfferPriceDetails offer={offer} horizontalAlign="center" />
                </BottomSegmentDetails>
                <BottomSegmentCTA direction="vertical">
                  <TextButton kind="primary">
                    <CSSBreakpoint max="tablet">View</CSSBreakpoint>
                    <CSSBreakpoint min="desktop">View Offer</CSSBreakpoint>
                  </TextButton>
                </BottomSegmentCTA>
                <BottomSegmentExtra direction="vertical" horizontalAlign="start" desktopHorizontalAlign="center" noDisplayWhenEmpty>
                  {showFlightPricingDetails && <PriceRowFlightPackage price={undefined} product="hotel" />}
                </BottomSegmentExtra>
              </BottomSegmentGrid>
            </WalledContent>
          </Group>
        </DetailSegment>
      </DetailContainer>
    </Main>
  )
}

const mapStateToProps = (state: App.State): MappedProps => ({
  isBedbankFlightBookingEnabled: isBedbankFlightBookingEnabled(state),
})

export default connect(mapStateToProps)(FrontPageBedbankOfferTile)
