import createSelector from 'lib/web/createSelector'
import { getExperienceItemsView, getTransferItemsView } from 'checkout/selectors/view/experience'
import { excludeNullOrUndefined } from 'checkout/utils'
import {
  CHECKOUT_ITEM_TYPE_BEDBANK,
  CHECKOUT_ITEM_TYPE_BOOKING_PROTECTION,
  CHECKOUT_ITEM_TYPE_CAR_HIRE,
  CHECKOUT_ITEM_TYPE_EXPERIENCE,
  CHECKOUT_ITEM_TYPE_FLIGHT,
  CHECKOUT_ITEM_TYPE_INSURANCE,
  CHECKOUT_ITEM_TYPE_LE_HOTEL,
  CHECKOUT_ITEM_TYPE_TOUR_V2,
} from 'constants/checkout'
import { OFFER_TYPE_CRUISE, OFFER_TYPE_HOTEL, OFFER_TYPE_TOUR, OFFER_TYPE_TOUR_V2 } from 'constants/offer'
import { RESERVATION_TYPE_INSTANT_BOOKING } from 'constants/reservation'
import { getInsuranceItems } from 'checkout/selectors/view/insurance'
import { getOccupantsFromCartItems, getOccupantsFromExistingOrder } from 'checkout/selectors/request/insurance'
import { groupBy, sortBy } from 'lib/array/arrayUtils'
import { getCarHireItemsView } from '../view/carHire'
import { getCruiseItems } from '../view/cruise'
import { getTourV2IdForCheckoutItem, isTourV1Item } from 'lib/checkout/checkoutUtils'
import { getBookingSessionData } from 'checkout/selectors/cruiseSelectors'
import { DEFAULT_TOUR_V2_PRIVATE_MAX_CHILD_AGE } from 'constants/tours'
import { checkoutAccommodationGroupingKey, getAccommodationItems } from '../view/accommodation'
import { getBundleAndSaveItems } from '../view/bundleAndSave'
import { getFlightItems } from '../view/flights'
import { PROVIDERS } from 'constants/experience'
import { getVillaItems } from '../view/villa'
import { isStandaloneLuxPlusSubscription } from '../view/luxPlusSubscription'
import { isBookingProtectionSelected } from '../view/bookingProtection'
import { getBookingProtectionOccupantsFromCartItems } from './bookingProtection'
import config from 'constants/config'
import { selectSelectedTravellerEmployees } from 'businessTraveller/selectors/businessTravellerEmployeeSelectors'
import { getAvailableAccommodationBenefits } from 'luxPlus/selectors/benefits/accommodation'
import { checkCanRedeemLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'

interface Occupant {
  type: 'adult' | 'child' | 'infant'
  relatedItem?: {
    index: number
    unit: 'room'
  }
  age?: number
  birthDate?: string
}

const transformOccupantsData = (occupancy: App.Occupants, relatedItem?: Occupant['relatedItem']) => {
  const result: Array<Occupant> = []
  for (let i = 0; i < occupancy.adults; i++) {
    result.push({
      type: 'adult',
      relatedItem,
    })
  }
  for (let i = 0; i < (occupancy.children || 0); i++) {
    const childOccupant: Occupant = {
      type: 'child',
      relatedItem,
    }
    if (occupancy?.childrenAge?.length) {
      childOccupant.age = occupancy.childrenAge[i]
    }
    if (occupancy?.childrenBirthDate?.length) {
      childOccupant.birthDate = occupancy.childrenBirthDate[i]
    }
    result.push(childOccupant)
  }
  for (let i = 0; i < (occupancy.infants || 0); i++) {
    const infantOccupant: Occupant = {
      type: 'infant',
      relatedItem,
    }

    if (occupancy?.childrenBirthDate?.length) {
      infantOccupant.birthDate = occupancy.childrenBirthDate[i]
    }

    result.push(infantOccupant)
  }
  return result
}

const transformCruiseOccupantsData = (occupancy: App.Occupants, infantMaxAge?: number) => {
  const result: Array<Occupant> = []

  for (let i = 0; i < occupancy.adults; i++) {
    result.push({ type: 'adult' })
  }

  sortBy(occupancy.childrenAge ?? [], (age) => age, 'desc')
    .forEach((age, index) => {
      const type = !!infantMaxAge && age <= infantMaxAge ? 'infant' : 'child'
      const birthDate = occupancy.childrenBirthDate?.[index]
      result.push({ type, age, birthDate })
    })

  return result
}

const passengersToTravellers = (passengers: Array<App.Checkout.FlightPassenger>) => {
  return passengers.map(passenger => {
    return {
      type: passenger.type,
    }
  })
}

const getExperiencesSchemaRequestItems = createSelector(
  getExperienceItemsView,
  (experienceItemsView) => {
    const items = experienceItemsView.data
      .filter(excludeNullOrUndefined)
      .filter(item => item.type !== 'addon' && !item.isSessionPurchaseLimitReached)
      .map(item => {
        const availableTickets = item.ticketViews.filter(ticket => !ticket.unavailable)
        if (availableTickets.length === 0) {
          return null
        }
        const providerKey = item.experienceId.slice(0, 3)

        return {
          type: CHECKOUT_ITEM_TYPE_EXPERIENCE,
          offerId: item.experienceId,
          tickets: availableTickets.map(ticket => ({
            ticketId: ticket.id,
            name: ticket.name,
            fareType: ticket.fareType,
            count: ticket.count,
          })),
          provider: PROVIDERS[providerKey],
        }
      }).filter(excludeNullOrUndefined)
    return items
  },
)

const getCarHireSchemaRequestItems = createSelector(
  getCarHireItemsView,
  (carHireItemsView) => {
    const items = carHireItemsView.data
      .filter(excludeNullOrUndefined)
      .map(car => {
        return {
          type: CHECKOUT_ITEM_TYPE_CAR_HIRE,
          offerId: car.item.offerId,
          dobReference: car.item.pickUpDate,
        }
      })
    return items
  },
)

const getTransferSchemaRequestItems = createSelector(
  getTransferItemsView,
  (transferViews) => {
    const items = transferViews.data
      .filter(excludeNullOrUndefined)
      .map(item => {
        const providerKey = item.experienceId.slice(0, 3)

        return {
          type: CHECKOUT_ITEM_TYPE_EXPERIENCE,
          offerId: item.experienceId,
          tickets: [{
            ticketId: item.transfer.option?.id,
            name: item.transfer.option?.name,
            fareType: item.transfer.option?.name,
            count: 1,
          }],
          provider: PROVIDERS[providerKey],
        }
      }).filter(excludeNullOrUndefined)
    return items
  },
)

const getFlightSchemaRequestItems = createSelector(
  getFlightItems,
  (flightItems: Array<App.Checkout.FlightItem>) => {
    return flightItems.reduce((list, item) => {
      return [
        ...list,
        ...item.flights.map(flight => ({
          type: CHECKOUT_ITEM_TYPE_FLIGHT,
          searchId: item.searchId,
          journeyId: flight?.journeyId,
          selectedUpsellOptionId: flight?.fareFamily?.id,
          occupants: passengersToTravellers(item.passengers),
        })),

      ]
    }, [] as Array<any>)
  },
)

/**
 * LE Tour V1 items are handled differently to other accommodation types
 * because they do not have an 'occupancy' attribute.
 *
 * Each LE TourV1 cart item corresponds to 1 adult ticket
 * This selector builds an equivalent item (containing the 'occupancy' attribute)
 */
const getAccommodationTourV1RequestItems = createSelector(
  getAccommodationItems,
  (accomItems) => {
    const tourItems = accomItems.filter(isTourV1Item)
    const itemMap = groupBy<string, App.Checkout.LETourV1Item>(tourItems, checkoutAccommodationGroupingKey)

    return Array.from(itemMap.values()).map(elementList => {
      const firstItem = elementList[0]

      return {
        offerId: firstItem.offerId,
        type: OFFER_TYPE_TOUR,
        occupants: elementList.map(item => ({
          type: item.occupancy.children ? 'child' : 'adult',
        })),
        dobReference: firstItem.startDate,
      }
    })
  },
)

const getCruiseRequestItems = createSelector(
  getCruiseItems,
  (state: App.State) => state.cruise.cruiseOffers,
  getBookingSessionData,
  (items, offers, sessionData) => {
    if (!offers || Object.keys(offers).length === 0) { return [] }
    const { personTitles } = sessionData
    // TODO: ADD TRAVELLER FOR FETCHING SPECIFIC FOR CRUISES
    // const cruiseMatadata = {
    //   departure: item.departure,
    // }
    return items.map((item) => {
      const offer = offers?.[item.offerId]
      if (offer) {
        return {
          type: OFFER_TYPE_CRUISE,
          offerId: item.offerId,
          itemId: item.itemId,
          occupants: transformCruiseOccupantsData(item.occupancy, offer.ship.occupancies?.infantMaximumAge),
          departureId: item.departureId,
          dobReference: item.startDate,
          cruiseLine: offer.cruiseLine.name,
          personTitles: personTitles?.map((title) => title.code),
          childMaxAge: offer.ship.occupancies?.childMaximumAge ?? 17,
          childMinAge: offer.ship.occupancies?.childMinimumAge ?? 2,
          minimumSupervisorAge: offer.ship.occupancies?.minimumSupervisorAge ?? 18,
          infantMinAge: offer.ship.occupancies?.infantMinimumAge ?? 0,
        }
      }
    })
  },
)

const getBundleAndSaveSchemaRequestItems = createSelector(
  getBundleAndSaveItems,
  (items) => {
    return items.map((item) => {
      return {
        type: 'hotel',
        reservationType: RESERVATION_TYPE_INSTANT_BOOKING,
        occupants: transformOccupantsData(item.occupancy),
        offerId: item.offerId,
      }
    })
  },
)

const getAccommodationSchemaRequestItems = createSelector(
  getAccommodationItems,
  getAccommodationTourV1RequestItems,
  getCruiseRequestItems,
  (state: App.State) => state.offer.tourV2Offers,
  (accommodationItems, tourV1RequestItems, cruiseItems, tourOffers) => {
    const items = accommodationItems.map((item, itemIndex) => {
      const baseMetadata = { offerId: item.offerId }

      switch (item.itemType) {
        case CHECKOUT_ITEM_TYPE_TOUR_V2:
          const tourV2Offer = tourOffers[getTourV2IdForCheckoutItem(item)]
          const variation = tourV2Offer?.variations[item.purchasableOption.fkVariationId]
          const childMaxAge = (() => {
            if (tourV2Offer?.isLeTour) {
              return variation?.minChildPriceAge &&
                variation?.minChildPriceAge > 0 ?
                variation?.minChildPriceAge - 1 :
                  -1
            }
            if (item.privateRequestKey) {
              return DEFAULT_TOUR_V2_PRIVATE_MAX_CHILD_AGE
            }
            return variation?.maxChildPriceAge
          })()
          return {
            offerId: item.offerId,
            type: OFFER_TYPE_TOUR_V2,
            occupants: transformOccupantsData(item.occupancy, {
              index: itemIndex,
              unit: 'room',
            }),
            departureId: item.purchasableOption.fkDepartureId,
            dobReference: item.startDate,
            childMinAge: item.privateRequestKey ? 0 : (variation?.minChildPriceAge ?? 0),
            childMaxAge: childMaxAge ?? DEFAULT_TOUR_V2_PRIVATE_MAX_CHILD_AGE,
            offerType: tourV2Offer?.productType,
          }
        case CHECKOUT_ITEM_TYPE_BEDBANK:
          return {
            ...baseMetadata,
            type: OFFER_TYPE_HOTEL,
            occupants: transformOccupantsData(item.occupancy),
          }
        case CHECKOUT_ITEM_TYPE_LE_HOTEL:
          return {
            ...baseMetadata,
            type: OFFER_TYPE_HOTEL,
            reservationType: item.reservationType,
            ...(item.reservationType == RESERVATION_TYPE_INSTANT_BOOKING && item.occupancy &&
              { occupants: transformOccupantsData(item.occupancy) }),
          }
        default:
          return null
      }
    }).filter(excludeNullOrUndefined)

    return [
      ...items,
      ...tourV1RequestItems,
      ...cruiseItems,
    ]
  },
)

const getVillasSchemaRequestItems = createSelector(
  getVillaItems,
  (items) => {
    return items.map(item => ({
      offerId: item.offerId,
      type: OFFER_TYPE_HOTEL,
      reservationType: item.reservationType,
      occupants: transformOccupantsData(item.occupancy),
    }))
  },
)

const getInsuranceSchemaRequestItems = createSelector(
  getInsuranceItems,
  (state: App.State) => state.insurance.quotes,
  getOccupantsFromCartItems,
  getOccupantsFromExistingOrder,
  (insuranceItems, quotes, occupantsFromCartItems, occupantsFromExistingOrder) => {
    const item = insuranceItems[0]
    if (!item || !quotes.length) {
      return []
    }

    const occupants = occupantsFromCartItems || occupantsFromExistingOrder
    if (occupants) {
      return [{
        productId: item.productId,
        quoteId: 'deprecated',
        type: CHECKOUT_ITEM_TYPE_INSURANCE,
        travellers: transformOccupantsData(occupants),
      }]
    } else {
      return []
    }
  },
)

const getBookingProtectionSchemaRequestItems = createSelector(
  isBookingProtectionSelected,
  getBookingProtectionOccupantsFromCartItems,
  (hasBookingSelected, occupants) => {
    if (!hasBookingSelected) return []

    if (occupants) {
      return [{
        type: CHECKOUT_ITEM_TYPE_BOOKING_PROTECTION,
        travellers: transformOccupantsData(occupants),
      }]
    } else {
      return []
    }
  },
)

export const isTravellerFormNeeded = createSelector(
  isStandaloneLuxPlusSubscription,
  (state: App.State) => state.checkout.cart.postPurchase,
  (
    isStandaloneLuxPlusSubscription,
    postPurchase,
  ) => {
    return !isStandaloneLuxPlusSubscription && (!postPurchase || postPurchase === 'insurance' || postPurchase === 'select-date')
  },
)

const finaliseInsuranceItems = (insuranceItems: Array<{
  productId: string;
  quoteId: string;
  type: string;
  travellers: Array<Occupant>;
}>, flightItems: Array<any>) => {
  // Flight adult vs child ages differ from insurance
  // If both are there traveller form schema adds an additional occupant which results in the insurance being cancelled later
  // In this specific scenario treat flight occupancies as override
  return flightItems.length && insuranceItems.length ?
    // Adjust the number of adults and children when flights are involved.
    // This is necessary because a 12-year-old is considered a child for accommodation but an adult for flights.
    insuranceItems.map(item => {
      const flightAdults = flightItems[0].occupants.filter(occupant => occupant.type === 'adult')
      const flightChildren = flightItems[0].occupants.filter(occupant => occupant.type === 'child')
      const insuranceAdults = item.travellers.filter(traveller => traveller.type === 'adult')
      const insuranceChildren = item.travellers.filter(traveller => traveller.type === 'child')

      // For multi-item checkouts, the number of travelers can differ between items.
      // The insurance quote is based on the highest number of travelers.
      // In cases where adult counts differ, use the adult count from the flight data.
      const adults = flightAdults.length > insuranceAdults.length && flightChildren.length < insuranceChildren.length ? flightAdults : insuranceAdults
      // If we use flight adults, we also need to use flight children.
      const children = adults.length === flightAdults.length ? flightChildren : insuranceChildren

      const itemWithoutAdultsAndChildren = item.travellers.filter(traveller => traveller.type !== 'adult' && traveller.type !== 'child')

      return {
        ...item,
        travellers: [...itemWithoutAdultsAndChildren, ...adults, ...children],
      }
    }) :
    insuranceItems
}

export const getIsLuxPlusMemberRedeemingBenefitSchemaRequest = createSelector(
  checkCanRedeemLuxPlusBenefits,
  getAvailableAccommodationBenefits,
  (state: App.State) => state.checkout.cart.isGift,
  (canRedeemLuxPlusBenefits, accomBenefits, isGift): boolean => {
    const hasBenefitsWithEditablePrimaryTravellerDetails = accomBenefits.isBundledWithFlights || accomBenefits.isTourWithMemberPrice
    const hasBenefitsWithLockedPrimaryTravellerDetails = (accomBenefits.isMemberOnly ||
      accomBenefits.hasEarlyAccess ||
      accomBenefits.hasLuxPlusInclusions ||
      (accomBenefits.totalMemberPriceSavings ?? 0) > 0)

    return canRedeemLuxPlusBenefits &&
    !isGift &&
    !hasBenefitsWithEditablePrimaryTravellerDetails &&
    hasBenefitsWithLockedPrimaryTravellerDetails
  })

export const getTravellerFormSchemaRequest = createSelector(
  getCarHireSchemaRequestItems,
  getAccommodationSchemaRequestItems,
  getBundleAndSaveSchemaRequestItems,
  getExperiencesSchemaRequestItems,
  getTransferSchemaRequestItems,
  getFlightSchemaRequestItems,
  getInsuranceSchemaRequestItems,
  getBookingProtectionSchemaRequestItems,
  getVillasSchemaRequestItems,
  selectSelectedTravellerEmployees,
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => state.auth.account.memberId,
  (state: App.State) => state.checkout.cart.isGift,
  (state: App.State) => state.checkout.cart.currencyCode,
  getIsLuxPlusMemberRedeemingBenefitSchemaRequest,
  (
    carHireItems,
    accommodationItems,
    bundleAndSaveItems,
    experienceItems,
    transferItems,
    flightItems,
    insuranceItems,
    bookingProtectionItems,
    villaItems,
    selectedTravellerEmployees,
    region,
    customer_id,
    isGift,
    currencyCode,
    isLuxPlusMemberRedeemingBenefitSchemaRequest,
  ) => {
    // Use customerId of employee being booked on behalf of on LEBT
    const hasBusinessEmployeesSelected = config.businessTraveller.currentAccountMode === 'business' && selectedTravellerEmployees.length > 0
    return {
      items: [
        ...carHireItems,
        ...accommodationItems,
        ...bundleAndSaveItems,
        ...experienceItems,
        ...transferItems,
        ...flightItems,
        ...finaliseInsuranceItems(insuranceItems, flightItems),
        ...bookingProtectionItems,
        ...villaItems,
      ],
      region,
      customer_id: hasBusinessEmployeesSelected ? selectedTravellerEmployees[0].customerId : customer_id,
      isGift,
      currencyCode,
      isLuxPlusMember: isLuxPlusMemberRedeemingBenefitSchemaRequest,
    }
  },
)
