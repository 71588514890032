import React from 'react'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { connect } from 'react-redux'
import LabeledContainer from 'components/Common/Labels/LabeledContainer'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import LuxPlusInclusionsList from './LuxPlusInclusionsList'
import LuxPlusLabelWithModal from './LuxPlusLabelWithModal'
import { LUXURY_PLUS } from 'luxPlus/constants/base'
import { INCLUSIONS_TITLE_LUXPLUS } from 'constants/inclusions'
import { isLEOffer } from 'lib/offer/offerTypes'
import FormatCurrency from 'components/Common/FormatCurrency'

interface MappedProps {
  subscription: App.ProductSubscription;
  isLuxPlusEnabled: boolean;
}

interface Props extends MappedProps {
  inclusions?: Array<App.PackageInclusion> | Array<App.OrderLuxPlusInclusion>;
  inclusionsV2?: Array<App.PackageInclusionV2>;
  tileInclusions?: Array<App.TileInclusion>;
  accommodationOffer?: App.Offer | App.OfferSummary;
  hideUpsellModal?: boolean;
}

function LuxPlusInclusions(props: Props) {
  const {
    isLuxPlusEnabled,
    inclusions,
    inclusionsV2,
    tileInclusions,
    accommodationOffer,
    subscription,
    hideUpsellModal,
  } = props

  const hasInclusions = !!inclusions?.length || !!inclusionsV2?.length || !!tileInclusions?.length

  if (!isLuxPlusEnabled) return null

  return <LabeledContainer
      data-testid="lux-plus-bonus-inclusions"
      size="medium"
      colour="loyalty"
      labels={
        <>
          <CSSBreakpoint not="tablet">
            <LuxPlusLabelWithModal
              type="bonus-inclusions"
              inclusions={inclusions}
              inclusionsV2={inclusionsV2}
              offer={accommodationOffer}
              hideUpsellModal={hideUpsellModal}
            />
          </CSSBreakpoint>
          <CSSBreakpoint only="tablet">
            <LuxPlusLabelWithModal
              type="only-logo"
              offer={accommodationOffer}
              inclusions={inclusions}
              inclusionsV2={inclusionsV2}
              hideUpsellModal={hideUpsellModal}
              modalTitle={`Join ${LUXURY_PLUS.PROGRAM_NAME} to enjoy ${INCLUSIONS_TITLE_LUXPLUS} for this offer and more!`}
              modalSubTitle={<>
                Become a member of our VIP travel club for <FormatCurrency value={subscription.offers?.[0].price ?? 0} format="rounded" />{' '}
                per year to unlock {INCLUSIONS_TITLE_LUXPLUS} when you book{' '}
                {isLEOffer(accommodationOffer) ? <b>{accommodationOffer?.property?.name}</b> : 'this hotel'}.
              </>}
            />
          </CSSBreakpoint>
        </>
      }
    >
    {hasInclusions && <LuxPlusInclusionsList
      inclusions={inclusions}
      inclusionsV2={inclusionsV2}
      tileInclusions={tileInclusions}
    />}
  </LabeledContainer>
}

function mapStateToProps(state: App.State) {
  return {
    isLuxPlusEnabled: isLuxPlusEnabled(state),
    subscription: state.luxPlus.products.subscription,
  }
}

export default connect(mapStateToProps)(LuxPlusInclusions)
