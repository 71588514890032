import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'

export function luxPlusContext(state: App.State) {
  return comluxgroup.createLuxPlus_1_0_0({
    isMember: state.luxPlus.member.subscription.item?.status === 'ACTIVE',
    tier: state.luxPlus.member.subscription.item?.tier,
  })
}

export function luxPlusContextOnPurchaseSuccess(state: App.State, order: App.Order) {
  if (!order.subscriberTier) {
    return luxPlusContext(state)
  }

  return comluxgroup.createLuxPlus_1_0_0({
    isMember: true,
    tier: order.subscriberTier,
  })
}
