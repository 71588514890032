import { addTotals } from 'checkout/lib/utils/payments/totals'
import { nonNullable, sum } from 'lib/array/arrayUtils'
import createSelector from 'lib/web/createSelector'
import {
  getAccommodationPayable,
  getAccommodationTotals,
  selectEarnableAccommodationBusinessTravellerCreditsTotal,
} from 'checkout/selectors/payment/accommodation'
import { selectEarnableFlightBusinessTravellerCreditsTotal } from 'checkout/selectors/view/flights'
import { getExperienceTotals, getTransferTotals } from 'checkout/selectors/payment/experience'
import { getFlightTotals } from 'checkout/selectors/payment/flights'
import { getInsuranceTotals } from 'checkout/selectors/payment/insurance'
import { checkoutAccommodationOfferView, getAccommodationItems } from 'checkout/selectors/view/accommodation'
import { computeCruiseDepositAmount, computeDepositAmount } from 'checkout/lib/utils/payments/deposit'
import { getAccommodationDepositSettings } from 'checkout/lib/utils/accommodation/deposit'
import { getCarHireTotals } from './carHire'
import { getDefaultDepositAmountPercentage, getDepositServiceFeeConfig } from '../featureConfig/deposit'
import config from 'constants/config'
import { VIRGIN_VELOCITY_BURN_RATE } from 'constants/partnerships'
import { getBundleAndSaveTotals } from './bundleAndSave'
import { getGiftCardTotals } from 'checkout/selectors/payment/giftCard'
import { calculateDaysBetweenDates, isLastInstalment } from 'lib/payment/payInInstalmentsUtils'
import { PAYMENT_OPTIONS } from 'constants/payment'
import { getVillaTotals } from './villas'
import { getBookingProtectionTotals } from './bookingProtection'
import { getLuxPlusSubscriptionItems, getLuxPlusSubscriptionsTotalPrice, isLuxPlusSubscriptionInCart } from 'checkout/selectors/view/luxPlusSubscription'
import { getCruiseItems } from '../view/cruise'
import { getCalenderDaysByCheckIn } from 'lib/offer/offerCalendarUtils'
import generateOccupancyStringByRoom from 'lib/offer/generateOccupancyStringByRoom'
import { getItemUniqueKey } from 'checkout/lib/utils/accommodation/cart'
import { OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_HOTEL, OFFER_TYPE_TOUR, OFFER_TYPE_TOUR_V2 } from 'constants/offer'
import { buildAvailableRateKey } from 'lib/offer/availabilityUtils'
import { getTourV2Items } from '../view/toursv2'
import { isTourV2Offer } from 'lib/offer/offerTypes'
import { getFlightItemsView } from '../view/flights'
import { isCruiseOfferView } from 'checkout/lib/utils/cruises/view'
import { getCruiseOfferPriceVariation } from 'lib/cruises/cruiseUtils'
import { getLuxPlusSubscriptionTotals, getSubscriptionJoinFeeTotals } from './luxPlusSubscription'
import { getTourSelectablePurchasableOptionsWithPriceByOccupant } from '../tourV2Selectors'
import { getTourExperienceTotals } from 'checkout/selectors/payment/toursv2'
import { LUXURY_PLUS } from 'luxPlus/constants/base'
import { getTourItemKey } from 'lib/tours/tourUtils'
import { getCreditBalanceForCheckoutCurrency } from './generic'
import { getCarHireItems } from '../view/carHire'
import { isSpoofed } from 'selectors/featuresSelectors'
import { floatify } from 'lib/maths/mathUtils'
import { breakdownView } from '../view/generic'
import { fareTypeLabelMap } from 'constants/flight'

export const isUsingCredit = (state: App.State) => state.checkout.payment.useCredit
/**
 * getDiscountTotal returns the total dollar amount the promo code is worth for this order.
 * Note: commission is basically a promo code too
 *
 * Old Cart uses the clients-side logic below for the promo calculation
 * New Checkout uses Server-Side promo (or PromoV2) calculations via making a POST request to /api/promo/discount with a 'Discount Request Order'
 */
export const getDiscountTotal = createSelector(
  (state: App.State) => state.checkout.promo,
  (state: App.State) => state.checkout.commission,
  (promotion, commission) => {
    const promoTotal = promotion?.discountTotal || 0
    const commissionTotal = commission?.discountTotal || 0

    return floatify(promoTotal + commissionTotal)
  },
)

export const getTotalsWithoutInsurance = createSelector(
  getAccommodationTotals,
  getBundleAndSaveTotals,
  getExperienceTotals,
  getTransferTotals,
  getFlightTotals,
  getCarHireTotals,
  getGiftCardTotals,
  getVillaTotals,
  getTourExperienceTotals,
  selectEarnableAccommodationBusinessTravellerCreditsTotal,
  selectEarnableFlightBusinessTravellerCreditsTotal,
  (...itemTotals: Array<App.WithDataStatus<App.Checkout.ItemTotals>>): App.WithDataStatus<App.Checkout.ItemTotals> => {
    return addTotals(itemTotals)
  },
)

const getAncillaryPaymentTotals = createSelector(
  (state: App.State) => state.checkout.cart.postPurchase,
  (state: App.State) => state.checkout.cart.existingOrder,
  (postPurchaseMode, existingOrder): App.Checkout.ItemTotals | undefined => {
    if (existingOrder) {
      if (postPurchaseMode === 'deposit-balance' && existingOrder.depositDetails) {
        return {
          price: existingOrder.depositDetails.balance_amount,
          memberPrice: 0,
          taxesAndFees: 0,
        }
      } else if (existingOrder.instalmentDetails && (postPurchaseMode === 'instalment-balance' || isLastInstalment(existingOrder.instalmentDetails))) {
        return {
          price: existingOrder.instalmentDetails.balance_amount,
          memberPrice: 0,
          taxesAndFees: 0,
        }
      } else if (postPurchaseMode === 'instalment' && existingOrder.instalmentDetails) {
        return {
          price: existingOrder.instalmentDetails.per_instalment_amount,
          memberPrice: 0,
          taxesAndFees: 0,
        }
      } else if (postPurchaseMode === 'reserve-balance' && existingOrder.reserveForZeroDetails) {
        return {
          price: existingOrder.reserveForZeroDetails.payableAmount,
          memberPrice: 0,
          taxesAndFees: 0,
        }
      } else if (postPurchaseMode === 'custom-offer' && existingOrder.customOfferItems.length > 0) {
        return {
          price: parseFloat(existingOrder.customOfferItems[0].total),
          memberPrice: 0,
          taxesAndFees: 0,
        }
      }
    }

    return
  },
)

export const getCheckoutTotals = createSelector(
  getTotalsWithoutInsurance,
  getInsuranceTotals,
  getAncillaryPaymentTotals,
  getBookingProtectionTotals,
  getLuxPlusSubscriptionTotals,
  getSubscriptionJoinFeeTotals,
  (totals, insurance, ancillaryTotals, bookingProtection, luxPlusTotal, joinTotal): App.WithDataStatus<App.Checkout.ItemTotals> => {
    if (ancillaryTotals) {
      return { hasRequiredData: true, data: ancillaryTotals }
    } else {
      return addTotals([totals, insurance, bookingProtection, luxPlusTotal, joinTotal])
    }
  },
)

export const getCheckoutTotal = createSelector(
  getCheckoutTotals,
  (checkoutTotals): number => {
    const { price, surcharge, otherFees } = checkoutTotals.data

    // need to adjust values to account for value possibly being undefined
    const adjPrice = price ?? 0
    const adjSurcharge = surcharge ?? 0
    const adjOtherFees = otherFees ? sum(Object.values(otherFees)) : 0
    return floatify(adjPrice + adjSurcharge + adjOtherFees)
  },
)
export const getCheckoutTotalNoCruiseValidation = createSelector(
  getCheckoutTotals,
  (checkoutTotals): number => {
    const { price, surcharge, otherFees } = checkoutTotals.data
    // need to adjust values to account for value possibly being undefined
    const adjPrice = price ?? 0
    const adjSurcharge = surcharge ?? 0
    const adjOtherFees = otherFees ? sum(Object.values(otherFees)) : 0
    return floatify(adjPrice + adjSurcharge + adjOtherFees)
  },
)

export const getDueAtProperty = createSelector(
  getCheckoutTotals,
  (checkoutTotals): number => {
    const { otherFees } = checkoutTotals.data
    return (otherFees?.propertyFees || 0) + (otherFees?.extraGuestSurcharge || 0)
  },
)

export const getPayableTotal = createSelector(
  getCheckoutTotal,
  getDueAtProperty,
  (total, dueAtProperty): number => floatify(total - dueAtProperty),
)

export const getPayInFull = createSelector(
  getCheckoutTotalNoCruiseValidation,
  getDueAtProperty,
  (total, dueAtProperty): number => floatify(total - dueAtProperty),
)

export const checkoutPromotionView = createSelector(
  (state: App.State) => state.checkout.promo,
  getDiscountTotal,
  getPayableTotal,
  (state: App.State) => !!state.checkout.isFetchingPromo,
  (promotion, discount, payableTotal, fetching): App.Checkout.PromotionView => {
    if (discount > payableTotal) {
      return {
        promotion,
        discount: payableTotal,
        fetching,
      }
    }
    return {
      promotion,
      discount,
      fetching,
    }
  },
)

export const checkoutCommissionView = createSelector(
  (state: App.State) => state.checkout.commission,
  getDiscountTotal,
  getPayableTotal,
  (state: App.State) => !!state.checkout.isFetchingCommmission,
  (promotion, discount, payableTotal, fetching): App.Checkout.PromotionView => {
    if (discount > payableTotal) {
      return {
        promotion,
        discount: payableTotal,
        fetching,
      }
    }
    return {
      promotion,
      discount,
      fetching,
    }
  },
)

export const getPayableAfterDiscount = createSelector(
  getPayableTotal,
  getDiscountTotal,
  (payableTotal, discount): number => {
    if (discount > payableTotal) {
      return 0
    }
    return floatify(payableTotal - discount)
  },
)

/**
 * Calculate total modifying amount for partnerships
 *
 * @remarks
 * Currently applicable only for:
 * - VIRGIN_VELOCITY
 */
export const getPartnershipModifierAmount = createSelector(
  (state: App.State) => state.checkout.payment.partnerships,
  (partnerships) => {
    let result = 0

    if (partnerships.velocity?.burn?.isApplied && partnerships.velocity?.burn?.pointsBurned) {
      result += Math.round(((partnerships.velocity.burn.pointsBurned * VIRGIN_VELOCITY_BURN_RATE) + Number.EPSILON) * 100) / 100
    }

    return result
  },
)

export const getPayableAfterModifiers = createSelector(
  getPayableTotal,
  getDiscountTotal,
  getPartnershipModifierAmount,
  (payableTotal, promoDiscountAmount, partnershipModifierAmount): number => {
    if (promoDiscountAmount > (payableTotal + partnershipModifierAmount)) {
      return 0
    }
    return floatify(payableTotal - promoDiscountAmount - partnershipModifierAmount)
  },
)

/**
 * Keep this selector internal
 * An exported version can be found in:
 * {@link checkout/selectors/payment/deposit#getDepositAmountBeforeCreditApplied}
 *
 * @remark
 * We copy it because we cannot import it without a circular import problem
 * There is no easy way to untangle it either...
 */
const getDepositAmountBeforeCreditApplied = createSelector(
  getPayableTotal,
  getAccommodationPayable,
  checkoutAccommodationOfferView,
  getDefaultDepositAmountPercentage,
  (payableTotal, accommodationPayable, viewWithStatus, defaultDepositAmountPercentage): number => {
    const isCruise = viewWithStatus?.data[0]?.offerType === 'cruise'

    const depositSettings = viewWithStatus.hasRequiredData && viewWithStatus.data.length > 0 ?
      getAccommodationDepositSettings(viewWithStatus.data[0], defaultDepositAmountPercentage) :
      undefined
    const depositAmount = isCruise ?
      computeCruiseDepositAmount(accommodationPayable, depositSettings) :
      computeDepositAmount(accommodationPayable, depositSettings)

    return floatify(payableTotal - accommodationPayable + depositAmount)
  },
)

const getServiceFeeAmount = createSelector(
  getPayableAfterModifiers,
  getDepositAmountBeforeCreditApplied,
  getDepositServiceFeeConfig,
  (payable, depositAmount, serviceFee) => {
    const depositBalance = payable - depositAmount
    const serviceFeeAmount = floatify(serviceFee.percentage / 100 * depositBalance)
    return serviceFeeAmount
  },
)

export const getCreditPayableAmount = createSelector(
  getPayableAfterModifiers,
  (state: App.State) => state.auth.account.creditsByCurrency[state.checkout.cart.currencyCode],
  isSpoofed,
  getDepositAmountBeforeCreditApplied,
  (state: App.State) => state.checkout.payment.rebookingID,
  getServiceFeeAmount,
  isLuxPlusSubscriptionInCart,
  getLuxPlusSubscriptionItems,
  (payable, credits, isSpoofed, depositAmount, rebookingID, serviceFee, isLuxPlusSubscriptionInCart, luxPlusCartSubscriptionItems): number => {
    let maxCreditTotal: number
    const creditBalance = credits?.balance ?? 0
    if (isSpoofed && rebookingID) {
      const rebookingPayable = depositAmount + serviceFee
      maxCreditTotal = creditBalance < rebookingPayable ? creditBalance : rebookingPayable
    } else {
      maxCreditTotal = Math.min(creditBalance, payable)
    }

    if (isLuxPlusSubscriptionInCart) {
      const subscriptionItem = luxPlusCartSubscriptionItems[0]
      const maxCreditTotalPayableForSubscription = floatify(payable - subscriptionItem.amount)

      if (maxCreditTotal > maxCreditTotalPayableForSubscription) {
        maxCreditTotal = maxCreditTotalPayableForSubscription
      }
    }

    return floatify(maxCreditTotal)
  },
)

export const getApplyCreditDisclaimer = createSelector(
  (state: App.State) => state.auth.account.creditsByCurrency[state.checkout.cart.currencyCode],
  getPayableAfterModifiers,
  isLuxPlusSubscriptionInCart,
  getLuxPlusSubscriptionItems,
  (credits, payable, isLuxPlusSubscriptionInCart, luxPlusCartSubscriptionItems): string | undefined => {
    const creditBalance = credits?.balance ?? 0

    if (isLuxPlusSubscriptionInCart) {
      const subscriptionItem = luxPlusCartSubscriptionItems[0]
      const maxCreditTotalPayableForSubscription = floatify(payable - subscriptionItem.amount)

      if (creditBalance > maxCreditTotalPayableForSubscription) {
        return `Your ${LUXURY_PLUS.PROGRAM_NAME} annual subscription fee must be paid via card payment.`
      }
    }
  },
)

export const getCreditPayAmount = createSelector(
  isUsingCredit,
  getCreditPayableAmount,
  (useCredit, amount) => useCredit ? amount : 0,
)

const getExcludedMerchantFeeTotal = createSelector(
  getLuxPlusSubscriptionsTotalPrice,
  getSubscriptionJoinFeeTotals,
  (luxPlusTotal, joinFeeTotals) => {
    return floatify(luxPlusTotal + joinFeeTotals.data.price)
  },
)

export const getMerchantFeeAmount = createSelector(
  getDepositAmountBeforeCreditApplied,
  getCreditPayAmount,
  getPayableAfterModifiers,
  (state: App.State) => state.config.merchantFeeConfigs,
  (state: App.State) => state.checkout.merchantFeePaymentType,
  (state: App.State) => state.checkout.cart.postPurchase,
  (_state: App.State, paymentType: PAYMENT_OPTIONS) => paymentType,
  getExcludedMerchantFeeTotal,
  isSpoofed,
  (state: App.State) => state.checkout.payment.rebookingID,
  (state: App.State) => state.checkout.payment.useCredit,
  getCreditBalanceForCheckoutCurrency,
  (depositAmount, creditAmount, payableAfterModifiers, merchantFeeConfigs, merchantFeePaymentType, postPurchase, paymentType, excludedTotals, isSpoofed, rebookingID, useLECredits, creditBalance) => {
    // Don't show merchant fees for post purchases
    if (postPurchase) return 0
    if (paymentType === PAYMENT_OPTIONS.RESERVE_FOR_FREE) return 0
    if (!merchantFeePaymentType || merchantFeeConfigs.length === 0) return 0

    const merchantFeePercentage = merchantFeeConfigs.find((config) => config.id === merchantFeePaymentType)?.percentage
    if (!merchantFeePercentage) return 0

    let payableStripeTotal = 0
    switch (paymentType) {
      case PAYMENT_OPTIONS.FULL:
        payableStripeTotal = floatify(payableAfterModifiers - creditAmount - excludedTotals)
        if (payableStripeTotal < 0) {
          payableStripeTotal = 0
        }
        break
      case PAYMENT_OPTIONS.DEPOSIT:
        payableStripeTotal = (isSpoofed && rebookingID && useLECredits) ?
          Math.max(0, floatify(depositAmount - creditBalance - excludedTotals)) : floatify(depositAmount - excludedTotals)
        break
    }
    const merchantFee = floatify(payableStripeTotal * (merchantFeePercentage / 100))
    return merchantFee
  },
)

export const getCheckoutCruiseBrochure = createSelector(
  getCruiseItems,
  (cruiseItems) => {
    if (cruiseItems.length) {
      return cruiseItems.map((cruiseItem) => {
        const totalPassengers = Object.values(cruiseItem.occupancy).reduce((a, b) => a + b, 0)
        const brochurePrice = cruiseItem.brochureInfo?.price || 0
        return floatify(brochurePrice * totalPassengers)
      }).reduce((total, currentPrice) => floatify(total + currentPrice), 0)
    }
    return 0
  },
)

export const checkoutTotalsView = createSelector(
  getCheckoutTotals,
  getCheckoutTotal,
  getPayableTotal,
  getDiscountTotal,
  getCreditPayableAmount,
  getCreditPayAmount,
  (state: App.State) => state.checkout.payment.partnerships.velocity,
  getPartnershipModifierAmount,
  (state: App.State) => getMerchantFeeAmount(state, state.checkout.payment.paymentSelected),
  (state: App.State) => state.checkout.payment.paymentSelected,
  getServiceFeeAmount,
  getCheckoutCruiseBrochure,
  checkoutAccommodationOfferView,
  (state: App.State) => state.checkout.commission,
  getCarHireItems,
  (
    checkoutTotals,
    total,
    payableTotal,
    discount,
    amountPayableByCredit,
    creditPaymentAmount,
    velocityState,
    partnershipModifierAmount,
    merchantFee,
    paymentSelected,
    serviceFee,
    cruiseBrochurePrice,
    accommodationViews,
    commission,
    carHireItems,
  ): App.WithDataStatus<App.Checkout.TotalsView> => {
    const {
      price,
      value,
      surcharge,
      taxesAndFees,
      otherFees,
      propertiesFees,
      businessTravellerCredits,
      carHirePaidOnPickUpFees,
      carHirePayOnArrival,
      carHireAddonFees,
      paidPrice,
      newPrice,
      memberPrice,
    } = checkoutTotals.data

    // need to adjust values to account for value possibly being undefined
    const adjPrice = price ?? 0
    const adjSurcharge = surcharge ?? 0
    const adjValue = value ?? 0
    const adjCarHirePaidOnPickUpFees = carHirePaidOnPickUpFees ?? 0
    const adjCarHireAddonsFees = carHireAddonFees ?? 0
    const adjCarHirePayOnArrival = carHirePayOnArrival ?? 0

    let subTotal = adjPrice + adjSurcharge - taxesAndFees + adjCarHireAddonsFees + adjCarHirePayOnArrival
    // Need to round to avoid long decimals
    subTotal = floatify(subTotal)
    const totalValue = adjValue + adjSurcharge + cruiseBrochurePrice

    const hideDiscountPercentage = accommodationViews.data.some(view => view.offer &&
      'isDiscountPillHidden' in view.offer && view.offer.isDiscountPillHidden,
    )

    // Value always includes `taxesAndFees`
    const savedTotal = (totalValue - (subTotal + taxesAndFees + adjCarHirePaidOnPickUpFees))
    const savedPercentage = hideDiscountPercentage ? 0 : Math.floor((savedTotal / totalValue) * 100)

    const payableServiceFee = paymentSelected === PAYMENT_OPTIONS.DEPOSIT ? serviceFee : 0

    let grandTotal = floatify(
      total -
      creditPaymentAmount -
      partnershipModifierAmount +
      adjCarHirePaidOnPickUpFees +
      merchantFee +
      payableServiceFee +
      adjCarHirePayOnArrival,
    )

    if (discount > grandTotal) {
      grandTotal = 0
    } else {
      grandTotal -= discount
    }

    // Need to round to avoid long decimals
    grandTotal = floatify(grandTotal)

    let payableNow = payableTotal - creditPaymentAmount - partnershipModifierAmount + merchantFee + payableServiceFee

    if (discount > payableNow) {
      payableNow = 0
    } else {
      payableNow -= discount
    }

    payableNow = floatify(payableNow)

    const businessTravellerCredit = config.businessTraveller.currentAccountMode === 'business' && businessTravellerCredits ?
        { totalCreditValue: businessTravellerCredits } :
      undefined

    const velocityPointsApplied = (velocityState?.burn?.isApplied) ? velocityState.burn.pointsBurned : null

    return {
      hasRequiredData: checkoutTotals.hasRequiredData,
      data: {
        savedPercentage: Number.isFinite(savedPercentage) ? savedPercentage : 0,
        savedTotal,
        amountPayableByCredit,
        creditPaymentAmount,
        otherFees,
        propertiesFees,
        taxesAndFees,
        subTotal,
        commissionTotal: commission?.discountTotal ?? 0,
        commissionGrossTotal: grandTotal + (commission?.discountTotal ?? 0) + (creditPaymentAmount),
        grandTotal,
        payableNow,
        value: totalValue,
        businessTravellerCredit,
        carHirePaidOnPickUpFees: adjCarHirePaidOnPickUpFees,
        carHireAddonFees: adjCarHireAddonsFees,
        carHirePayOnArrival: adjCarHirePayOnArrival,
        carHirePickupDate: carHireItems[0]?.pickUpDate,
        ...(velocityPointsApplied && { velocityPointsApplied }),
        paidPrice,
        newPrice,
        merchantFee,
        serviceFee: payableServiceFee,
        isMemberPrice: memberPrice > 0,
      },
    }
  },
)

export const getCheckoutTotalsForPaymentSchedule = createSelector(
  getPayableTotal,
  getDiscountTotal,
  getCreditPayAmount,
  getPartnershipModifierAmount,
  (state: App.State, paymentType: PAYMENT_OPTIONS) => getMerchantFeeAmount(state, paymentType),
  (payableTotal, discountTotal, creditTotal, partnershipModifierAmount, merchantFee) => {
    const total = floatify(
      payableTotal -
      creditTotal -
      partnershipModifierAmount +
      merchantFee,
    )

    if (discountTotal > total) {
      return 0
    }

    return floatify(total - discountTotal)
  },
)

export const getSoldOutOffers = createSelector(
  getAccommodationItems,
  (state: App.State) => state.offer.offers,
  (state: App.State) => state.calendar.calendarsByOccupancy,
  (state: App.State) => state.calendar.calendarsByPackageKey,
  (state: App.State) => state.offer.offerAvailableRatesByOccupancy,
  (state: App.State) => checkoutTotalsView(state),
  (state: App.State) => state.checkout.cart.existingOrder,
  (items, offers, calendarsByOccupancy, calendarsByPackageKey, availableRatesByOccupancy, totalsView, existingOrder): Array<App.Offer> => {
    const itemsAvailableRooms = items.map(item => {
      const offer = offers[item.offerId]
      const uniqueKey = getItemUniqueKey(item)

      if (existingOrder || !offer || !('checkIn' in item)) return null

      if (
        (offer.type === OFFER_TYPE_ALWAYS_ON || offer.type === 'rental') &&
        offer.property?.useDynamicOccupancy || offer.property?.useDynamicCancellationPolicies
      ) {
        const key = buildAvailableRateKey(item.checkIn, item.checkOut, [item.occupancy])
        const availableRates = availableRatesByOccupancy[offer.id]?.[key]?.rates
        const availableRate = availableRates?.find(rate => rate.packageUniqueKey === uniqueKey)

        return totalsView.hasRequiredData && availableRate?.availableRooms === 0 ? offer : null
      } else {
        const occupancyCalendar = calendarsByOccupancy?.[generateOccupancyStringByRoom(item.occupancy as App.Occupants)]
        const pkgCalendar = occupancyCalendar?.[uniqueKey] ?? calendarsByPackageKey?.[uniqueKey]
        const pkgByDay = pkgCalendar && getCalenderDaysByCheckIn(pkgCalendar)[item.checkIn]
        // Because not having a pkgByDay will return !hasRequiredData so we can't include this in the check
        // assumption is that if a pkgByDay is loaded the 'required data' is ready to evaluate the sold out condition
        return pkgByDay?.availableRooms === 0 || (!pkgByDay && pkgCalendar && offer && !offer.isSoldOut) ? offer : null
      }
    })

    return nonNullable(itemsAvailableRooms)
  },
)

export const getSoldOutTourV2Offer = createSelector(
  getTourV2Items,
  (state: App.State) => state.offer.offers,
  (state: App.State) => state.offer.tourV2Offers,
  (tourItems, offers, tourOffers): Tours.TourV2Offer | undefined => {
    let soldOutOffer
    tourItems.map(tourItem => {
      const tourItemKey = getTourItemKey(tourItem)
      const offer = (offers[tourItemKey] ?? tourOffers[tourItemKey]) as App.AnyOffer

      if (isTourV2Offer(offer)) {
        const departure = offer.departures[tourItem.purchasableOption.fkDepartureId]

        if (!departure) {
          soldOutOffer = offer
          return
        }

        const isUnavailable = departure.status === 'unavailable' || departure.status === 'unavailable-inactive-campaign'
        const datesNotMatching = departure.startDate !== tourItem.startDate || departure.endDate !== tourItem.endDate

        if (isUnavailable || datesNotMatching) {
          soldOutOffer = offer
        }
      }
    })

    return soldOutOffer
  },
)

export const getSignUpOfferDetails = createSelector(
  getFlightItemsView,
  checkoutAccommodationOfferView,
  (state: App.State) => state.geo.airports,
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => state.experience.experiences,
  getTourSelectablePurchasableOptionsWithPriceByOccupant,
  (state: App.State) => state.flights.searchV2Flights,
  (state: App.State) => state.offer.offers,
  checkoutTotalsView,
  (
    flightViews,
    offerViews,
    airports,
    checkoutItems,
    experiences,
    tourPurchasableOptionsByOccupant,
    flightSearchV2,
    offers,
    checkoutTotals,
  ): App.AccountModalOfferDetails | undefined => {
    const item = checkoutItems[0]

    if (!item) return

    if (item.itemType === 'hotel') {
      const hotelOffer = offers[item.offerId]
      const hotelViews = offerViews.data.filter(view => view.offerType === OFFER_TYPE_HOTEL)
      // Check for required data
      if (!checkoutTotals.hasRequiredData || !hotelOffer || !offerViews.hasRequiredData || !hotelViews?.length) return

      const { grandTotal, savedPercentage } = checkoutTotals.data

      const firstHotelItemView = hotelViews[0].itemViews[0]
      if (firstHotelItemView.kind !== 'le') return

      const isBundled = hotelViews.some(view => view.isBundled)
      const hasFlightItem = checkoutItems.some(item => item.itemType === 'flight')

      return {
        title: hotelOffer.property?.name || firstHotelItemView.mainLabel,
        duration: item.duration,
        value: grandTotal,
        image: firstHotelItemView.image,
        imageTitle: hotelOffer.property?.name || firstHotelItemView.mainLabel,
        discountPercentage: savedPercentage > 0 ? savedPercentage : 0,
        isBundledWithFlights: isBundled && hasFlightItem,
      }
    } else if (item.itemType === 'experience') {
      const experience = experiences[item.experienceId]
      if (!checkoutTotals.hasRequiredData) return

      const { grandTotal, savedPercentage } = checkoutTotals.data

      return {
        title: experience.name,
        durationLabel: 'From',
        value: grandTotal,
        image: experience.images[0],
        imageTitle: experience.images[0].title || experience.name,
        discountPercentage: savedPercentage,
      }
    } else if (item.itemType === 'flight') {
      const flightItem = flightViews.data[0].item
      const originAirport = airports.find(airport => airport.code === flightItem.originAirportCode)
      const destinationAirport = airports.find(airport => airport.code === flightItem.destinationAirportCode)

      const journeyId = item.flights[0]?.journeyId

      const journeysV2 = Object.values(flightSearchV2).flatMap(flight => flight?.fares[0])
      const journeyv2 = journeysV2.find(journey => journey?.id === journeyId)

      return {
        title: `${originAirport?.name} to ${destinationAirport?.name}`,
        durationLabel: `${fareTypeLabelMap[flightItem.fareType]} from`,
        value: journeyv2?.price.all.totalRoundTripPrice || 0,
        image: flightItem.flights[0]?.carrierImage,
        imageTitle: flightItem.flights[0]?.carrierImage?.title || '',
        addImagePadding: true,
      }
    } else if (item.itemType === 'tourV1') {
      const tourOfferView = offerViews.data.find(view => view.offerType === OFFER_TYPE_TOUR)

      if (!tourOfferView || !checkoutTotals.hasRequiredData) return

      const { grandTotal, savedPercentage } = checkoutTotals.data

      return {
        title: tourOfferView.mainLabel,
        durationLabel: `${tourOfferView.durationLabel} from`,
        value: grandTotal,
        image: tourOfferView.image,
        imageTitle: tourOfferView.mainLabel,
        discountPercentage: savedPercentage > 0 ? savedPercentage : 0,
      }
    } else if (item.itemType === 'tourV2') {
      const tourOfferView = offerViews.data.find(view => view.offerType === OFFER_TYPE_TOUR_V2)

      if (!tourOfferView || !checkoutTotals.hasRequiredData) return

      const { grandTotal, savedPercentage } = checkoutTotals.data

      const tourPurchasableOptions = tourPurchasableOptionsByOccupant.get(item.occupancy)
      const tourOptions = tourPurchasableOptions ? Array.from(tourPurchasableOptions.values()) : []

      // Get checkout total, tourItem total price or cheapest purchasable option
      const value = grandTotal || item.total || tourOptions[0].totalPrice || 0

      return {
        title: tourOfferView.mainLabel,
        durationLabel: `${tourOfferView.durationLabel} from`,
        duration: item.duration,
        value,
        imageId: item.image.id,
        imageTitle: item.image.title || tourOfferView.mainLabel,
        discountPercentage: savedPercentage,
      }
    } else if (item.itemType === 'car-hire') {
      return {
        title: item.offer.name,
        duration: calculateDaysBetweenDates(item.dropOffDate, item.pickUpDate),
        value: item.totalPrice,
        image: item.offer.vehicle.image,
        imageTitle: item.offer.vehicle.image.title || item.offer.name,
        addImagePadding: true,
      }
    } else if (item.itemType === 'cruise') {
      const cruiseView = offerViews.data.filter(isCruiseOfferView)[0]
      const offer = cruiseView?.offer
      if (!offer || !checkoutTotals.hasRequiredData) return

      const { lowestOverallPriceDetails } = offer.mainDepartureDetails
      const { discountPills } = lowestOverallPriceDetails
      const { primaryPrice } = getCruiseOfferPriceVariation(offer, lowestOverallPriceDetails)

      const { grandTotal, savedPercentage } = checkoutTotals.data

      return {
        title: cruiseView.mainLabel,
        duration: cruiseView.duration,
        value: grandTotal || primaryPrice.total,
        image: cruiseView.image,
        imageTitle: cruiseView.image?.title || cruiseView.mainLabel,
        discountPercentage: savedPercentage || discountPills?.discountPercentage,
      }
    }
  },
)

export const getPackageNamesFromCart = createSelector(
  breakdownView,
  (breakdownView): Array<string> | undefined => {
    if (!breakdownView.hasRequiredData) return

    return breakdownView.data
      .map(breakdown => breakdown.description)
      .filter(description => description !== undefined)
  },
)
