import { API_CALL, SET_CUSTOMER_VIEW_TOGGLE } from 'actions/actionConstants'
import requestGetAccountAgent from 'agentHub/api/requestGetAccountAgent'
import { FETCH_ACCOUNT_AGENT_HUB, FETCH_AGENT_STATUS, ONBOARD_AGENCY, FETCH_AGENT_AFFILIATION_OPTIONS, FETCH_AGENT_COMMISSION_CONFIG } from './apiActionConstants'
import requestGetAgentAccountById from 'agentHub/api/requestGetAgentAccountById'
import requestGetAgentStatus from 'agentHub/api/requestGetAgentStatus'
import requestPostSetAgencyDetails, { SetAgencyDetailsParams } from 'agentHub/api/requestPostSetAgencyDetails'
import requestPostMarkAgentAsOnboarded from 'agentHub/api/requestPostMarkAgentAsOnboarded'
import { getCommissions } from 'agentHub/api/agentHubCommission'
import { getAgentAffiliationOptions } from 'agentHub/api/agentHubAffiliationOptions'

/**
 * Fetches the agent hub account for the current user
 *
 * Note: Will only work for onboarded users
 */
export function fetchAccountAgentHub(accessToken?: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    if (state.agentHub.agentHubAccount.account?.status === 'onboarded') {
      // already have the onboarded version, no need to refetch
      // (we might have the pending version, so if that's the case, refetch it)
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_ACCOUNT_AGENT_HUB,
      request: () => requestGetAccountAgent(accessToken),
    })
  }
}

/**
 * Fetches the agent hub account for the agent given.
 *
 * Note: Will work for accounts that are still pending onboarding
 * @param agentId The ID of the agent to fetch the account for
 */
export function fetchAgentAccountByAgentId(agentId: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    if (state.agentHub.agentHubAccount.account?.agentId === agentId) {
      // already have the current agents data, no need to refetch
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_ACCOUNT_AGENT_HUB,
      request: () => requestGetAgentAccountById(agentId),
    })
  }
}

export function fetchAgentAffiliationOptions() {
  return (dispatch, getState) => {
    const state = getState() as App.State

    if (state.agentHub?.affiliationOptions?.options?.length > 0) {
      // already have the affiliation options, no need to refetch
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_AGENT_AFFILIATION_OPTIONS,
      request: () => getAgentAffiliationOptions(state.geo.currentRegionCode),
    })
  }
}

export function fetchAgentHubCommissionConfig() {
  return (dispatch, getState) => {
    const state = getState() as App.State

    dispatch({
      type: API_CALL,
      api: FETCH_AGENT_COMMISSION_CONFIG,
      request: () => getCommissions(state.geo.currentRegionCode),
    })
  }
}
export function checkAgentHubStatus({ agentEmail, agentId }: { agentEmail?: string, agentId?: string }) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    if (state.agentHub.agentStatus[agentEmail ?? agentId] !== undefined) {
      // already checked it
      return
    }
    dispatch({
      type: API_CALL,
      api: FETCH_AGENT_STATUS,
      key: agentEmail ?? agentId,
      request: () => requestGetAgentStatus({ agentEmail, agentId }),
    })
  }
}

export function toggleCustomerView(customerView?: boolean) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    dispatch({
      type: SET_CUSTOMER_VIEW_TOGGLE,
      value: customerView ?? !state.agentHub.customerView,
    })
  }
}

export function onboardAgency(agencyDetails: SetAgencyDetailsParams) {
  return {
    type: API_CALL,
    api: ONBOARD_AGENCY,
    request: async() => {
      await requestPostSetAgencyDetails(agencyDetails.agentId, agencyDetails)
      const updatedAccount = await requestPostMarkAgentAsOnboarded(agencyDetails.agentId)
      return updatedAccount
    },
  }
}
