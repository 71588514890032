import { ADD_CHAT_MESSAGE, API_CALL, SET_CHAT_OUTAGE_MESSAGE, SET_CURRENT_CHAT_ID, UPDATE_CHAT, UPDATE_CHAT_TITLE } from 'actions/actionConstants'
import { AppApiAction } from 'actions/ActionTypes'
import { CREATE_NEW_CHAT, FETCH_CHAT_MESSAGES, FETCH_CHAT_WELCOME_MESSAGE, FETCH_CHATS, POST_MESSAGE_FEEDBACK } from 'actions/apiActionConstants'
import { createChat, createChatApi, getChatMessages, getChats, getChatWelcomeMessage, postFeedback, putServerChat, sendChatMessage } from 'api/supportAssistant'
import { EmptyArray } from 'lib/array/arrayUtils'
import { getCurrentUserId, getCustomerDetails } from 'selectors/accountSelectors'
import { chatActions } from 'supportAssistant/constants/chatActions'
import { selectCurrentChat } from 'supportAssistant/selectors/selectCurrentChat'
import { buildChatMessagePayload } from 'supportAssistant/utils/messageUtils'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import { getUpcomingOrders } from 'selectors/orderSelectors'
import { globalSocket } from 'supportAssistant/hooks/useSocket'
import { checkCanViewLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import { getLuxPlusSupportContactNumber } from 'selectors/supportContactSelector'
import { isHotelOrder } from 'lib/order/orderUtils'
import { isCurrentOrCloseToCheckIn } from 'lib/order/isUpcoming'

export function fetchChats(upcoming: Array<App.Order>, chatDeepLink: App.ChatDeepLinks): AppApiAction {
  return (dispatch, getState) => {
    const state = getState()

    const {
      geo: { currentRegionCode: regionCode },
    } = state

    const customerId = getCurrentUserId(state)
    const customerDetails = getCustomerDetails(state)
    const currentChat = selectCurrentChat(state)
    const canViewLuxPlusBenefits = checkCanViewLuxPlusBenefits(state)
    const upcomingHotelOrders = upcoming.filter(order => isHotelOrder(order)).map(({ id, items }) => ({
      id,
      itemCount: items.length,
      orderType: 'hotel',
    }))

    if (!customerId) return

    dispatch({
      type: API_CALL,
      api: FETCH_CHATS,
      request: async() => {
        // If transitioning from logged out to logged in, add CustomerId to current chat.
        if (globalSocket && currentChat && !currentChat.customerId) {
          if (currentChat.id === 'default') {
            await createChat(regionCode, customerId, customerDetails.givenName, canViewLuxPlusBenefits)
          } else {
            await putServerChat({ id: currentChat.id, customerId })
          }
        }

        if (chatDeepLink === 'list-orders' && upcomingHotelOrders.length > 0) {
          const payload: App.ChatApi = {
            customerName: customerDetails.givenName,
            customerId,
            upcomingOrders: upcomingHotelOrders,
            isLuxPlusMember: canViewLuxPlusBenefits,
            chatDeepLink,
            regionCode,
            isCurrentOrder: upcoming.every(isCurrentOrCloseToCheckIn),
          }

          await createChatApi(payload)
        }

        const chats = await getChats()

        // chat always come from the server in descing order
        let currentChatId = chats.length > 0 ? chats[0].id : undefined

        const activeChat = chats.find((chat) => chat.status === 'ACTIVE')
        if (!activeChat) {
          const [newChat] = await createChat(regionCode, customerId, customerDetails.givenName, canViewLuxPlusBenefits)
          chats.push(newChat)
          currentChatId = newChat.id
        }

        return {
          chats,
          currentChatId,
        }
      },
    })
  }
}

export function createNewChat(messages: Array<Partial<App.ChatMessage>> = EmptyArray) {
  return (dispatch, getState) => {
    const state = getState()

    const regionCode = state.geo.currentRegionCode
    const customerId = getCurrentUserId(state)
    const customerDetails = getCustomerDetails(state)
    const canViewLuxPlusBenefits = checkCanViewLuxPlusBenefits(state)

    dispatch({
      type: API_CALL,
      api: CREATE_NEW_CHAT,
      request: async() => {
        const [newChat, newMessages] = await createChat(regionCode, customerId, customerDetails.givenName, canViewLuxPlusBenefits)

        messages.forEach(message => sendChatMessage({ ...message, chatId: newChat.id }))
        return {
          chat: newChat,
          messages: [...newMessages, ...messages],
        }
      },
    })
  }
}

export function fetchChatWelcomeMessage(): AppApiAction {
  return {
    type: API_CALL,
    api: FETCH_CHAT_WELCOME_MESSAGE,
    request: () => getChatWelcomeMessage(),
  }
}

export function setCurrentChatId(chatId: string) {
  return async(dispatch) => {
    dispatch({
      type: SET_CURRENT_CHAT_ID,
      chatId,
    })
  }
}

export function setChatOutageMessage(error: string | undefined) {
  return async(dispatch) => {
    dispatch({
      type: SET_CHAT_OUTAGE_MESSAGE,
      error,
    })
  }
}

export function fetchChatMessages() {
  return (dispatch, getState) => {
    const state = getState()
    const currentChat = selectCurrentChat(state)

    if (currentChat) {
      dispatch({
        api: FETCH_CHAT_MESSAGES,
        type: API_CALL,
        request: async() => {
          const messages = await getChatMessages(currentChat.id)
          return {
            chatId: currentChat.id,
            messages,
          }
        },
      })
    }
  }
}

export function postMessageFeedback(feedback: App.ChatMessageFeedback) {
  return (dispatch) => {
    dispatch({
      api: POST_MESSAGE_FEEDBACK,
      type: API_CALL,
      request: async() => {
        const response = await postFeedback(feedback)

        if (response) {
          showSnackbar(
            'Thank you for your valuable feedback. It greatly helps us improve the user experience.',
            'success',
            { heading: 'Feedback successfully submitted' },
          )
        }
        return response
      },
    })
  }
}

export function addChatMessage(message: string, chatMessageMeta?: App.ChatMessageMeta) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const canViewLuxPlusBenefits = checkCanViewLuxPlusBenefits(state)
    const luxPlusContact = getLuxPlusSupportContactNumber(state)

    const currentChatId = state.supportAssistant.currentChatId ?? 'default'
    const customerId = getCurrentUserId(state)
    const customerDetails = getCustomerDetails(state)
    const upcoming = getUpcomingOrders(state)
    const isCurrentOrder = upcoming.length === 1 && upcoming.every(isCurrentOrCloseToCheckIn)

    const messageMetaPayload = buildChatMessagePayload({
      chatMessageMeta,
      currentChatId,
      customerDetails,
      customerId,
      hasUpcomingBooking: !!upcoming.length,
      canViewLuxPlusBenefits,
      luxPlusContact,
      message,
      isCurrentOrder,
    })

    if (currentChatId === 'default') {
      dispatch(createNewChat([messageMetaPayload]))
    } else {
      sendChatMessage(messageMetaPayload)
    }

    dispatch({
      type: ADD_CHAT_MESSAGE,
      message: messageMetaPayload,
      chatId: currentChatId,
    })

    // trigger customer portal actions for certain chat actions.  I.e. create Genesys chat.
    const messageAction = chatMessageMeta?.action ? chatActions[chatMessageMeta.action] : undefined
    if (messageAction && messageMetaPayload.meta?.payload) {
      messageAction?.(messageMetaPayload.meta?.payload)
    }
  }
}

export function addChatResponse(message: App.ChatMessage) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    // block the duplicate welcome message coming through.
    const messageAlreadyExists = state.supportAssistant.messages[message.chatId!]?.some(mess => mess.id === message.id)
    if (messageAlreadyExists) {
      return
    }

    dispatch({
      type: ADD_CHAT_MESSAGE,
      message,
      chatId: message.chatId,
    })
  }
}

export function updateChatTitle(chatTitle: App.ChatTitle) {
  return {
    type: UPDATE_CHAT_TITLE,
    chatId: chatTitle.chatId,
    title: chatTitle.title,
  }
}

export function updateChat(chat: App.Chat) {
  return {
    type: UPDATE_CHAT,
    chat,
  }
}
