/** query param keys **/

// search query params
export const queryKeyAdults = 'adults'
export const queryKeyChildren = 'children'
export const queryKeyDestinationName = 'destinationName'
export const queryKeyDestinationId = 'destinationId'
export const queryKeyLandmarkName = 'landmarkName'
export const queryKeyLandmarkId = 'landmarkId'
export const queryKeyPropertyName = 'propertyName'
export const queryKeyPropertyId = 'propertyId'
export const queryKeyExperienceName = 'experienceName'
export const queryKeyExperienceId = 'experienceId'
export const queryKeyPropertyTypes = 'propertyTypes'
export const queryKeyBounds = 'bounds'
export const queryKeyCheckIn = 'checkIn'
export const queryKeyCheckOut = 'checkOut'
export const queryKeyPriceLte = 'priceLte'
export const queryKeyPriceGte = 'priceGte'
export const queryFlexibleWithDate = 'flexibleWithDate'
export const queryKeyFlexibleNights = 'flexibleNights'
export const queryKeyFlexibleMonths = 'flexibleMonths'
export const queryKeyUrlOfferId = 'offerId'
export const queryKeyUrlOfferType = 'offerType'
export const queryKeyUserSelectedFlexibleMonths = 'userSelectedFlexibleMonths'
export const queryKeyLocations = 'locations'
export const queryKeySelectedOfferIds = 'selectedOfferIds'
export const queryKeyHolidayTypes = 'holidayTypes'
export const queryKeyHolidayTypesScoped = 'holidayTypesScoped'
export const queryKeyCustomerRatingGte = 'customerRatingGte'
export const queryKeyAmenities = 'amenities'
export const queryKeyInclusions = 'inclusions'
export const queryKeyBundledOfferId = 'bundledOfferId'
export const queryKeyMapPinCount = 'mapPinCount'
export const queryKeyBedrooms = 'bedroomsGte'
export const queryKeyBeds = 'bedsGte'
export const queryKeyBathrooms = 'bathroomsGte'
export const queryKeyLuxPlusFeatures = 'luxPlusFeatures'
export const queryKeyPriceNightlyGte = 'priceNightlyGte'
export const queryKeyPriceNightlyLte = 'priceNightlyLte'

// queryKeyPages represents the current page in a context of paginated results
export const queryKeyPages = 'pages'

export const queryKeyFilters = [
  queryKeyLocations,
  queryKeyHolidayTypes,
  queryKeyHolidayTypesScoped,
  queryKeyAmenities,
  queryKeyPropertyTypes,
  queryKeyInclusions,
  queryKeyPriceGte,
  queryKeyPriceLte,
  queryKeyCustomerRatingGte,
  queryKeyUrlOfferType,
  queryKeyBedrooms,
  queryKeyBeds,
  queryKeyBathrooms,
  queryKeyLuxPlusFeatures,
  queryKeyPriceNightlyGte,
  queryKeyPriceNightlyLte,
]
export const queryKeySortBy = 'sortBy'

// experience page query params
export const queryKeyDate = 'date'
export const queryKeyTime = 'time'
export const queryKeyPickupPointId = 'pickupPointId'
export const queryKeyRedemptionLocationId = 'redemptionLocationId'
export const queryKeyLanguageId = 'languageId'

/** URL hashes **/

// offer page
export const urlHashDatePicker = '#date-picker'
export const urlHashPackageOptions = '#Package-Options'

// url patterns
export const bookingPaymentUrlPattern = new RegExp('^/[a-z]{2}/booking/payment')

export const checkoutPaymentUrlPattern = new RegExp('^/[a-z]{2}/checkout/(payment|[^\/]*/purchase)')

export const bookingUrlPattern = new RegExp('^/[a-z]{2}/booking/(?!confirmation)')

export const checkoutUrlPattern = new RegExp('^/[a-z]{2}/checkout/(?!confirmation)')

export const tripPlannerUrlPattern = new RegExp('^/[a-z]{2}/trip-planner/trip/.+/(summary|itinerary|explore|documents)')

export const changePackageUrlPattern = new RegExp('^/[a-z]{2}/(booking|checkout)/change-package')

export const changeDatesUrlPattern = new RegExp('^/[a-z]{2}/booking/change-dates')

export const reviewUrlPattern = new RegExp('^/[a-z]{2}/checkout/review')

export const experiencesURLPattern = new RegExp('^/[a-z]{2}/booking/experiences')

export const cruiseCheckoutURLPattern = new RegExp('^/[a-z]{2}/checkout/cruises')
