import { CRUISE_CHECKOUT_INFLOW_V2 } from 'constants/checkout'
import { sortBy } from 'lib/array/arrayUtils'

const CRUISE_IN_FLOW_STEPS = new Set(['cruises', 'cruises-v2', 'cabin-location'])
export function getIsInFlowStep(stepId: string): boolean {
  return CRUISE_IN_FLOW_STEPS.has(stepId)
}

export function getIsCabinRateStep(stepId: string): boolean {
  return stepId === 'cruises' || stepId === 'cruises-v2'
}

export function getCheckoutRouteFromURL(pathname: string): string {
  const split = pathname.split('/').pop() || ''
  return split?.split('?')[0]
}

export function getCruiseCheckoutStep(items: Array<App.Checkout.CruiseItem>): string {
  const checkoutStepList = Object.values(CRUISE_CHECKOUT_INFLOW_V2)

  const stepsByItem = items.map((item) => {
    const adults = item.occupancy?.adults || 0

    // get the booking flow step based on the item data
    if ((item.cabinCode && item.cabinNumber) || item.componentId) return CRUISE_CHECKOUT_INFLOW_V2.RATE_SELECTION
    if (item.cabinCodes?.length) return CRUISE_CHECKOUT_INFLOW_V2.CABIN_SELECTION
    if (item.cabinType) return CRUISE_CHECKOUT_INFLOW_V2.CABIN_CATEGORY
    if (adults) return CRUISE_CHECKOUT_INFLOW_V2.CABIN_TYPE

    return CRUISE_CHECKOUT_INFLOW_V2.TRAVELERS
  })

  // We need to restore the booking flow using the earliest step when there are multiple items
  const [earliestStep] = sortBy(
    stepsByItem,
    (step) => checkoutStepList.indexOf(step) || 0,
    'asc',
  )

  return earliestStep
}
