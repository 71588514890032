import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import AdditionalGuestsPopup from 'components/Common/AdditionalGuestsPopup/AdditionalGuestsPopup'
import { hasIncludedGuestsExceeded } from 'lib/checkout/cartReservationUtils'
import config from 'constants/config'
import { OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_HOTEL, OFFER_TYPE_LAST_MINUTE } from 'constants/offer'
import { MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE } from 'constants/content'
import TextButton from 'components/Luxkit/Button/TextButton'
import getLPCRegionLOS from 'lib/offer/getLPCRegionLOS'
import moment from 'moment'
import { buildSuggestedDatesString, debugSearchPricingDifferences, formatDateRangesForAlternativeDates, getMinimumDurationFromFlexibleNights } from 'lib/search/searchUtils'
import { connect } from 'react-redux'
import ReserveForZeroTag from 'components/Common/ReserveForZeroTagAndTooltip/ReserveForZeroTag'
import { isPackageEligibleForZeroReservation } from 'lib/offer/reserveForZeroUtils'
import BusinessTravellerOfferCreditsTextLink from 'businessTraveller/components/offer-credits/BusinessTravellerOfferCreditsTextLink'
import { pluralizeToString } from 'lib/string/pluralize'
import { FLEXIBLE_DURATION_RANGE, SEARCH_VERTICALS } from 'constants/search'
import { EmptyArray, unique } from 'lib/array/arrayUtils'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { fetchOfferExtraById } from 'actions/OfferActions'
import { isVillaOffer } from 'lib/offer/offerTypes'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import OfferTilePricingUrgencyTags from './OfferTilePricingUrgencyTags'
import Group from 'components/utils/Group'
import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import ModalContext from 'contexts/ModalContext'
import CruisesDiscountTsAndCsModal from 'components/Cruises/CruisesDiscountTsAndCsModal'
import PriceRowCaption from 'components/Luxkit/PricePoints/PriceRowCaption'
import PriceRowValueLastMinuteDiscount from 'components/Luxkit/PricePoints/Value/PriceRowValueLastMinuteDiscount'
import LuxPlusPriceStack from 'luxPlus/components/LuxPlusPriceStack'
import { safeDivideAndCeil } from 'lib/maths/mathUtils'
import { useSearchPrices } from 'hooks/Search/useSearchPrices'
import OfferPriceDetailsRow from 'components/Common/PriceDetails/OfferPriceDetailsRow'
import useOfferAlternativeDate from '../../../../hooks/Offers/useOfferAlternativeDate'
import { RouterLocation } from 'connected-react-router'
import useOfferList from '../../../../hooks/Offers/useOfferList'
import useOfferMetaData from '../../../../hooks/Offers/useOfferMetaData'
import { isSearchPricingEnabled } from '../../../../selectors/hotelSearchSelectors'
import PriceRowMarginText from '../../../OfferPage/PriceRowMarginText'

interface Props {
  offer: App.Offer | App.OfferSummary;
  className?: string;
  pkg: App.Package;
  pricing?: App.OfferAvailableRate;
  hasDates?: boolean;
  align?: 'start' | 'center' | 'end';
  desktopAlign?: 'start' | 'center' | 'end';
  offerUrl: string;
  checkInDate?: moment.Moment;
  filters?: App.OfferListFilters;
  showCompact?: boolean
  isPricePerNight?: boolean
}

interface MappedStateProps {
  isSpoofed: boolean;
  location: RouterLocation<unknown>;
  region: string;
  currency: string;
}
// Export is only temporary for experiment
export const URGENCY_TAG_THRESHOLD = 5

function OfferTilePricing(props: Props & MappedStateProps) {
  const {
    offer,
    className,
    pkg,
    pricing,
    hasDates,
    align,
    desktopAlign,
    offerUrl,
    checkInDate,
    isSpoofed,
    location,
    region,
    currency,
    filters,
    showCompact = false,
    isPricePerNight,
  } = props

  const offerList = useOfferList(filters, { noFetch: true })
  const { alternativeDate } = useOfferAlternativeDate(offer.id, 'le', filters ?? {})
  const offerMetadata = useOfferMetaData(offer.id, filters)
  const isCruise = (offer.holidayTypes ?? []).map(i => i.toLowerCase()).includes('cruises')
  const isVillas = isVillaOffer(offer)
  const rooms = filters?.rooms ?? EmptyArray
  const isSearchPricingFlagEnabled = useAppSelector(state => isSearchPricingEnabled(state))
  const showMarginInSpoofMode = useAppSelector(state => state.config.showMarginInSpoofMode)
  const displayMarginInfo = showMarginInSpoofMode && !!offerMetadata?.marginInfo

  const shouldShowDiscountPercent = offer.type === OFFER_TYPE_LAST_MINUTE

  const duration = filters?.flexibleMonths && filters.flexibleNights ? getMinimumDurationFromFlexibleNights(filters.flexibleNights as FLEXIBLE_DURATION_RANGE) : offer.lowestPricePackage?.duration
  let {
    totalPrice,
    totalMemberPrice,
    totalValueBase,
    discountPercent,
    hotelPrice,
    hotelMemberPrice,
    showMemberPrice,
    propertyFees,
    showPerNightPricing,
    suggestedDates,
  } = useSearchPrices({
    offer,
    pkg,
    rate: pricing,
    duration,
    filters,
  })

  const hasSuggestedCheckInCheckOut = suggestedDates?.checkIn && suggestedDates?.checkOut
  const totalValue = showPerNightPricing ? Math.ceil(totalValueBase / (duration || 1)) : totalValueBase
  const baseTaxesAndFees = (pricing?.taxesAndFees ?? pkg.taxesAndFees) || 0
  const taxesAndFees = showPerNightPricing ? safeDivideAndCeil(baseTaxesAndFees, duration || 1) : baseTaxesAndFees
  const showPriceDetails = taxesAndFees > 0 || propertyFees > 0 || config.agentHub.isEnabled

  const shouldShowValue = pkg.value > 0 && !!discountPercent && pkg.shouldDisplayValue &&
    !shouldShowDiscountPercent && (offer.type == OFFER_TYPE_HOTEL || !pkg.roomRate?.inclusionsHideValue)

  const showIncludedGuestsExceeded = useMemo(() => {
    if (!pkg.roomType || (pkg.roomRate?.extraGuestSurcharges?.length || 0) > 0) {
      return false
    }

    return rooms?.some(room => hasIncludedGuestsExceeded(room, pkg, offer))
  }, [pkg, rooms, offer])

  const shouldShowRate = !offer.isDiscountPillHidden && discountPercent >= MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE

  let staysFrom = offer.minDuration
  const LPCRegionLOS = getLPCRegionLOS(offer)
  if (LPCRegionLOS && offer.packages.some(p => p.duration === LPCRegionLOS)) {
    staysFrom = LPCRegionLOS
  }
  const showStaysFromLabel = !showCompact && !hasDates && offer.type === OFFER_TYPE_ALWAYS_ON && LPCRegionLOS && (staysFrom ?? 0) < pkg.duration

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchOfferExtraById(offer.id))
  }, [dispatch, offer.id])

  const isReserveForZeroEnabled = config.RESERVE_FOR_ZERO_ENABLED
  const isReservableForZero = (isReserveForZeroEnabled || isSpoofed) && isPackageEligibleForZeroReservation(offer, pkg.roomRate?.isReservableForZero, checkInDate)
  const getDuration = useCallback((singular: string, count: number) => {
    if (isCruise && count) {
      // FOR FLASH CRUISES WE NEED TO
      // SUBTRACT 1 NIGHT FROM THE DURATION.
      // BECAUSE DURATION COMES IN DAYS RATHER THAN NIGHTS.
      // E.G. 7 DAYS CRUISE IS 6 NIGHTS
      return pluralizeToString(singular, count - 1)
    }
    return pluralizeToString(singular, count)
  }, [isCruise])

  const durationText = useMemo(() => {
    if (hasDates) {
      const text = getDuration('night', pkg.duration)
      if (alternativeDate) {
        const alternativeDatesInfo = alternativeDate ? `${formatDateRangesForAlternativeDates(alternativeDate.checkIn, alternativeDate.checkOut)}` : ''
        return `${alternativeDatesInfo} (${text}) from`
      }
      return `${text} from`
    }

    const isFlexibleSearch = filters?.flexibleNights && suggestedDates?.checkIn && suggestedDates?.checkOut
    const isAnytimeSearch = (!filters?.checkIn && !filters?.checkOut) && !isFlexibleSearch

    if (!isAnytimeSearch && hasSuggestedCheckInCheckOut && suggestedDates) {
      return `${buildSuggestedDatesString(suggestedDates.checkIn, suggestedDates.checkOut)} from`
    }

    if (suggestedDates && duration) {
      return `${getDuration('night', duration)} from`
    }

    if (pkg.duration) {
      return `${getDuration('night', pkg.duration)} from`
    }

    if (offer.tileDurationLabel) {
      return `${offer.tileDurationLabel.toLocaleLowerCase()} from`
    }

    return 'From'
  }, [hasDates, filters?.flexibleNights, filters?.checkIn, filters?.checkOut, suggestedDates, hasSuggestedCheckInCheckOut, duration, pkg.duration, offer.tileDurationLabel, getDuration, alternativeDate])

  const durationResolved = useMemo(() => {
    if (hasDates) return pkg.duration
    if (suggestedDates && duration) return duration
    if (pkg.duration) return pkg.duration
  }, [duration, hasDates, pkg.duration, suggestedDates])

  const saleUnit = useMemo(() => {
    if (isVillas && !showPerNightPricing) return 'total'
    if (rooms.length) return showPerNightPricing ? 'night' : 'total'
    if (isPricePerNight) return 'night'
    if (offer.saleUnit.toLowerCase() === 'cruise') return 'person'
    return showPerNightPricing ? 'night' : offer.saleUnit
  }, [isPricePerNight, isVillas, offer.saleUnit, rooms.length, showPerNightPricing])

  const showModal = useContext(ModalContext)
  const openCruiseDiscountTermsAndConditionsModal = useCallback(() => {
    showModal(<CruisesDiscountTsAndCsModal />)
  }, [showModal])

  if (showPerNightPricing) {
    totalPrice = Math.ceil(totalPrice / (durationResolved || 1))
  }

  const offerLocations = useMemo(() => unique(offer.locations.concat(offer.location)),
    [offer.locations, offer.location])
  const vendorName = offer.vendorName

  const isSearchPage = location.pathname.includes('search')

  useEffect(() => {
    if (offerList.searchVertical === SEARCH_VERTICALS.HOTELS && isSearchPricingFlagEnabled) {
      const searchPrices = offerMetadata.pricing
      const lePricing = {
        price: totalPrice,
        memberPrice: totalMemberPrice ?? 0,
        tax: taxesAndFees,
        value: totalValue,
      }
      const searchPricing = {
        price: searchPrices.lowestPrice ?? 0,
        memberPrice: searchPrices.lowestMemberPrice ?? 0,
        tax: searchPrices.lowestPriceTaxes ?? 0,
        value: searchPrices.lowestPriceValue ?? 0,
      }
      debugSearchPricingDifferences(lePricing, searchPricing, { offer, region, currency, filters: filters ?? {}, pkg, rate: pricing! })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchPricingFlagEnabled]) // log only once

  return (
    <Group
      className={className}
      direction="vertical"
      horizontalAlign={align}
      desktopHorizontalAlign={desktopAlign}
      gap={4}
    >
      <Group direction="vertical" horizontalAlign={align} desktopHorizontalAlign={desktopAlign}>
        {!showCompact && !isSearchPage && <CSSBreakpoint min="tablet">
          <OfferTilePricingUrgencyTags offer={offer} filters={filters} />
        </CSSBreakpoint>}
        {isReservableForZero && <ReserveForZeroTag />}
      </Group>
      <Group direction="vertical" horizontalAlign={align} desktopHorizontalAlign={desktopAlign}>
        <PriceRowPriceCaption>{durationText}</PriceRowPriceCaption>
        <LuxPlusPriceStack
          price={totalPrice}
          saleUnit={saleUnit}
          size={showCompact ? 'S' : 'L'}
          memberPrice={totalMemberPrice}
          testid="search-price"
          desktopHorizontalAlign={desktopAlign}
          offerConfig={offer.luxPlus}
        />
        {shouldShowValue && isCruise && !isPricePerNight && <PriceRowValueDiscountWithCaption
          data-testid="value-box"
          size="M"
          originalValue={Math.ceil(totalValue)}
          discountPercentage={shouldShowRate ? discountPercent : undefined}
          onInfoIconClick={openCruiseDiscountTermsAndConditionsModal}
        />}
        {showStaysFromLabel && <PriceRowCaption>
          Stays from {staysFrom} available
        </PriceRowCaption>}
        {!!pkg.roomOccupancy && offer.type === 'tour' && <PriceRowCaption>{pkg.roomOccupancy}</PriceRowCaption>}
        {showIncludedGuestsExceeded && (
          <AdditionalGuestsPopup
            complex={false}
            description="+ Extra guest surcharge"
            modalContent={pkg.roomPolicyDescription}
          />
        )}
        {shouldShowValue && !isCruise && <PriceRowValueDiscountWithCaption
          data-testid="value-box"
          size="M"
          originalValue={Math.ceil(totalValue)}
          discountPercentage={shouldShowRate ? discountPercent : undefined}
        />}
        {shouldShowDiscountPercent && discountPercent >= config.displayDiscountThreshold.lastMinute && <PriceRowValueLastMinuteDiscount
          discountPercentage={discountPercent}
        />}
        <Group direction="vertical" horizontalAlign={align} desktopHorizontalAlign={desktopAlign} gap={4}>
          {showPriceDetails && <OfferPriceDetailsRow
            trigger="price-row"
            triggerSize="M"
            offer={offer}
            duration={pkg.duration}
            propertyFees={propertyFees}
            hotelPrice={hotelPrice}
            taxesAndFees={baseTaxesAndFees}
            rooms={rooms}
            extraGuestMessage={showIncludedGuestsExceeded ? pkg.roomPolicyDescription : ''}
            dueAtPropertyMessage={offer.property?.taxesAndFeesContent ? offer.property?.taxesAndFeesContent : ''}
            hotelMemberPrice={hotelMemberPrice}
            showMemberPrice={showMemberPrice}
            context={isSearchPage ? 'search' : undefined}
            cta={
              <TextButton kind="primary" fit="flex" to={offerUrl}>
                View Offer
              </TextButton>
            }
          />}
          {displayMarginInfo && (
            <PriceRowMarginText marginValue={totalPrice * offerMetadata.marginInfo!.percentage} margin={offerMetadata.marginInfo!.percentage} size="M" type="le_estimate" colour="critical" />
          )}
          {config.businessTraveller.currentAccountMode === 'business' && <BusinessTravellerOfferCreditsTextLink
            type="estimate"
            offer={offer}
            totalCost={totalPrice}
            numberOfNights={pkg.duration}
          />}
          <PriceRowAgentHubCommission
            size="L"
            productType={isCruise ? 'cruise-flash' : offer.productType}
            offerId={offer.id}
            offerLocations={offerLocations}
            vendorName={vendorName}
          />
        </Group>
      </Group>
    </Group>
  )
}

export default connect<MappedStateProps, undefined, Props, App.State>((appState) => {
  return {
    isSpoofed: appState.auth.account.isSpoofed,
    location: appState.router.location,
    region: appState.geo.currentRegionCode,
    currency: appState.geo.currentCurrency,
  }
})(OfferTilePricing)
