import {
  API_CALL,
  API_CALL_SUCCESS,
  API_CALL_REQUEST,
  API_CALL_FAILURE,
  OFFER_PUSH_TO_LIST_BOTTOM,
  OFFER_SET_SEARCH_RESULT_METADATA,
  OFFER_SET_TOUR_SEARCH_RESULT_METADATA,
  OFFER_SHARE_COMPLETE,
  OFFER_SHARE_START,
  OFFER_UPDATE_AVAILABLE_RATES,
  OFFER_UPDATE_PRICING_DATA,
  OFFER_VIEWED,
  OFFERS_CLEAR,
  REMOVE_OFFER,
  ADD_OFFER_LIST_EXTRA,
} from './actionConstants'
import {
  FETCH_ALTERNATIVE_OFFERS,
  FETCH_AVAILABILITY_RATES_FOR_OFFER,
  FETCH_BEST_OFFER_FOR_PROPERTY,
  FETCH_BEST_PRICE_FOR_OFFER,
  FETCH_OFFER,
  FETCH_OFFER_EXTRA,
  FETCH_OFFER_FLIGHT_PRICE,
  FETCH_OFFER_LIST,
  FETCH_OFFER_LIST_FILTERS,
  FETCH_OFFER_LOCATION_BREADCRUMBS,
  FETCH_OFFERS,
  FETCH_RELATED_TRAVEL_ITEMS,
  FETCH_TOUR_SEARCH_FACETS,
  FETCH_TRADER_INFORMATION,
  FETCH_POPULAR_FILTERS,
  FETCH_ALTERNATIVE_DATES,
  FETCH_SURCHARGE_MARGIN,
} from './apiActionConstants'

import * as CalendarV2Service from 'api/calendarV2'
import * as OfferService from 'api/offer'
import { OfferListResult, streamRequest, streamScrollRequest } from 'api/offer'
import * as SearchService from 'api/search'
import * as RecommendationService from 'api/recommendations'
import {
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_BED_BANK,
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_HOTEL_SLUG,
  OFFER_TYPE_LAST_MINUTE,
  OFFER_TYPE_TOUR,
  OFFER_TYPE_TOUR_SLUG,
  OFFER_TYPE_TOUR_V2,
  PRODUCT_TYPE_ULTRALUX_SLUG,
} from 'constants/offer'
import placesTree from 'constants/placesTree'
import { arrayToObject, groupBy, min, skip, sortBy, take, unique, uniqueBy } from 'lib/array/arrayUtils'
import { buildAvailableRateKey } from 'lib/offer/availabilityUtils'
import getOfferListKey from 'lib/offer/offerListKey'
import { isBedbank, isBundleOffer, isCruiseOffer, isLEOffer, isTourV1Offer } from 'lib/offer/offerTypes'
import { urlTransform } from 'lib/string/removeSpaceUtils'
import {
  buildDestinationSearchParamsKey,
  buildSearchParamsFromFilters,
  buildSearchParamsKey,
  buildSuggestedDatesParamsKey,
  generateAlternativeDates,
  getOccupancyFromSearchStrings,
  updateLeBrandSpecificFilters,
} from 'lib/search/searchUtils'
import { getDefaultAirportCode } from 'selectors/flightsSelectors'
import { convertBedbankOfferToSummary } from './BedbankOfferActions'
import { getCurrentUserId } from 'selectors/accountSelectors'
import { timeNowInSecond } from 'lib/datetime/time'
import getObjectKey from 'lib/object/getObjectKey'
import invariant from 'tiny-invariant'
import { saveHighIntentOffersToLocal } from 'components/Recommendations/utils/highIntentLocal'
import { checkCanViewLuxPlusBenefits, isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { isPaidSession } from 'selectors/offerSelectors'
import { getDomainUserId } from 'analytics/snowplow/helpers/domainUserId'
import { AppApiAction } from './ActionTypes'
import { getRatesForBedbankOffer } from 'api/bedbankRates'
import { getSearchSocket } from '../../search/initialiseSearchSocket'
import { mapSearchResultToOfferListMetaData } from 'api/mappers/hotelOfferMap'
import { paths } from '@luxuryescapes/contract-search'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import moment from 'moment'
import { calculateNights } from 'tripPlanner/utils'
import { isNextHotelIndexEnabled } from 'selectors/hotelSearchSelectors'
import { isSpoofed } from '../selectors/featuresSelectors'
import { batchFetchSurchargeMargin } from 'api/reservation'

export function offerShareButtonClick(pageId: string, pageType: string) {
  return {
    type: OFFER_SHARE_START,
    data: {
      pageId,
      pageType,
    },
  }
}

export function offerShareComplete(pageId: string, pageType: string, shareMethod: string) {
  return {
    type: OFFER_SHARE_COMPLETE,
    data: {
      pageId,
      pageType,
      shareMethod,
    },
  }
}

export function fetchAccumulatedOfferById(
  offerId: string,
  regionCode?: string,
  flightOrigin?: string,
) {
  return (dispatch, getState) => {
    const state: App.State = getState()
    if (state.offer.offerErrors[offerId] || state.offer.offersLoading[offerId] || state.offer.offers[offerId]) {
      return
    }

    const isSpoofed = state?.auth?.account.isSpoofed

    dispatch({
      type: API_CALL,
      api: FETCH_OFFER,
      request: async() => {
        const offer = await OfferService.getAccumulatedOfferById(offerId, regionCode || state.geo.currentRegionCode, flightOrigin || getDefaultAirportCode(state), state.geo.currentCurrency, isSpoofed)

        if (!offer) {
          return Promise.reject(`Offer not found ${offerId}`)
        }

        return offer
      },
      offerId,
    })
  }
}

interface FetchOneOfferOptions { regionCode?: string, flightOrigin?: string, allPackages?: boolean, privateRequestKey?: string }

export function fetchOfferById(offerId: string, {
  regionCode,
  flightOrigin,
  allPackages = false,
  privateRequestKey,
}: FetchOneOfferOptions = {}) {
  return (dispatch, getState) => {
    const state: App.State = getState()
    const isSpoofed = state?.auth?.account.isSpoofed

    const offerKey = privateRequestKey ? `${offerId}-${privateRequestKey}` : offerId

    if (state.offer.offerErrors[offerKey] || state.offer.offersLoading[offerKey] || state.offer.offers[offerKey]) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_OFFER,
      request: () => OfferService.getOfferById(offerId, {
        region: regionCode || state.geo.currentRegionCode,
        flightOrigin: flightOrigin || getDefaultAirportCode(state),
        allPackages,
        privateRequestKey,
        currentCurrency: state.geo.currentCurrency,
        isSpoofed,
      }),
      offerId: offerKey,
    })
  }
}

export function fetchOfferExtraById(
  offerId: string,
  regionCode?: string,
) {
  return (dispatch, getState) => {
    const state: App.State = getState()

    if (state.offer.offerExtras[offerId]) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_OFFER_EXTRA,
      request: () =>
        OfferService.getOfferExtraById(
          offerId,
          regionCode || state.geo.currentRegionCode,
        ),
      offerId,
    })
  }
}

export function fetchOfferFlightPrice(offerId: string, flightOrigin: string) {
  return (dispatch, getState) => {
    const state: App.State = getState()

    const existingOffer = state.offer.offers[offerId]
    if (existingOffer?.flightPrices[flightOrigin] || !existingOffer) {
      // already have price, no need to re-load
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_OFFER_FLIGHT_PRICE,
      request: () => OfferService.getOfferFlightPrice({
        flightOrigin,
        flightDestination: existingOffer.flightDestinationPort,
        currency: state.geo.currentCurrency,
        region: state.geo.currentRegionCode,
        duration: existingOffer.lowestPricePackage?.duration ?? 1,
        forceBundleId: existingOffer.forceBundleId,
        travelToDate: existingOffer.travelToDate,
      }),
      offerId,
      flightOrigin,
    })
  }
}

function convertLEOfferToSummary(offer: App.Offer): App.OfferSummary {
  const {
    packages,
    highlights,
    whatWeLike,
    facilities,
    finePrint,
    gettingThere,
    ...offerSummary
  } = offer
  return {
    ...offerSummary,
    // offer summaries have the field present, so it can work in tandem with an actual offer
    // but the packages will always be empty to save space
    packages: [],
  }
}

export function convertOfferToSummary(
  offer: App.Offer | App.BedbankOffer,
): App.OfferSummary | App.BedbankOfferSummary {
  if (isBedbank(offer)) {
    return convertBedbankOfferToSummary(offer)
  } else if (isBundleOffer(offer) || isCruiseOffer(offer)) {
    // some offer type doesn't know how to summary yet
    return offer
  } else {
    return convertLEOfferToSummary(offer)
  }
}

type FetchOfferOptions = {
  regionCode?: string;
  includePackages?: boolean;
}

/**
 * Bulk fetch of offers
 *
 * @remarks
 * Before requesting the fetch,
 * Checks are made against offers currently being loaded and offers that are missing data
 *
 * This ensures we only fetch when absolutely necessary
 */
export function fetchOffersById(
  offerIds: Array<string> = [],
  options: FetchOfferOptions = {},
) {
  return (dispatch, getState) => {
    const state: App.State = getState()
    const { includePackages, regionCode } = options
    const missingOfferIds = unique(offerIds.filter(function(id) {
      if (
        // don't have the offer at all and it's not currently being loaded
        (!state.offer.offers[id] && !state.offer.offersLoading[id] && !state.offer.offerErrors[id]) ||
        // needs packages but is missing them, we have to refetch
        (includePackages && state.offer.offers[id]?.packages.length === 0)
      ) {
        return true
      }
    }))

    if (missingOfferIds.length === 0) {
      // already have all the offers
      return
    }

    const authState: App.AuthState = state.auth

    const fetchOptions = {
      ...options,
      flightOrigin: getDefaultAirportCode(state),
      currentCurrency: state.geo.currentCurrency,
      isSpoofed: authState.account.isSpoofed,
    }

    dispatch({
      type: API_CALL,
      api: FETCH_OFFERS,
      request: () => OfferService.getOffersById(missingOfferIds, regionCode || state.geo.currentRegionCode, fetchOptions),
      offerIds: missingOfferIds,
    })
  }
}

/**
 * Deduplicates metadata when combining the results of two getOfferList requests,
 * preferring metadata with suggestedTravelDates if it exists
 *
 * @param metaDataArray
 * @returns deduplicated metaData
 */
export function deduplicateMetaData(metaDataArray: Array<App.OfferListMetaData> | undefined): Array<App.OfferListMetaData> | undefined {
  if (!metaDataArray) {
    return metaDataArray
  }
  const metaDataMap = new Map<string, App.OfferListMetaData>()

  for (const metaData of metaDataArray) {
    const existingMetaData = metaDataMap.get(metaData.offerId)

    if (!existingMetaData) {
      metaDataMap.set(metaData.offerId, metaData)
    } else if (!existingMetaData.suggestedTravelDates && metaData.suggestedTravelDates) {
      metaDataMap.set(metaData.offerId, metaData)
    }
  }

  return Array.from(metaDataMap.values())
}

interface Options {
  clientCheckAvailability?: boolean;
  evVersion?: 'current' | 'next' | 'luxplus_boost' | 'weighted_recency_v1';
}

export function fetchOfferList(filters: App.OfferListFilters = {}, options: Options = {}) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const canViewLuxPlusBenefits = checkCanViewLuxPlusBenefits(state)
    const luxPlusEnabled = isLuxPlusEnabled(state)
    const isPaidSessionEnabled = isPaidSession(state)
    const isNextSearchIndex = isNextHotelIndexEnabled(state)

    const offerListKey = getOfferListKey(filters)

    if (state.offer.offerLists[offerListKey]) {
      // already have the results for this set of params
      return
    }

    if (filters.checkIn && !filters.checkOut) {
      // This request is only valid if both checking and checkout dates are provided
      return
    }

    const domainUserId = filters.shouldUsePersonalizedSearch ? getDomainUserId() : undefined
    const memberId = filters.shouldUsePersonalizedSearch ? state.auth.account.memberId : undefined

    dispatch({
      type: API_CALL,
      api: FETCH_OFFER_LIST,
      request: () => OfferService.getOfferList({
        region: state.geo.currentRegionCode,
        filters,
        canViewLuxPlusBenefits,
        isLuxPlusEnabled: luxPlusEnabled,
        clientCheckAvailability: options.clientCheckAvailability,
        evVersion: options.evVersion || ((luxPlusEnabled && canViewLuxPlusBenefits) ? 'luxplus_boost' : undefined),
        isPaidSession: isPaidSessionEnabled,
        memberId,
        domainUserId,
        isNextSearchIndex,
        isSpoofed: isSpoofed(state),
        accessToken: state.auth.accessToken,
      }).then((results) => {
        let metaData: Array<App.OfferListMetaData> = results.metaData || []
        const offerIds: Array<string> = results.offerIds
        // Finding all bundle offers and creating a map object
        const mapBundledOffers = arrayToObject(
          metaData.filter(m => m.bundledOfferId && m.available),
          r => r.bundledOfferId!,
          r => r.offerId,
        )

        // Adding for single offers property bundleOfferId
        metaData = metaData.map(r => mapBundledOffers[r.offerId] ? {
          ...r,
          bundleOfferId: mapBundledOffers[r.offerId],
        } : r)

        if (metaData.length > 0 && (filters.landmarkId || filters.destinationId || filters.propertyId || filters.bounds)) {
          dispatch(setSearchResultMetadata(metaData, filters))
        }

        const tourMetadata = results.tourMetadata || []
        if ((tourMetadata.length > 0) && (filters.destinationId)) {
          dispatch(setTourSearchResultMetadata(tourMetadata, filters))
        }

        if (results.filters) {
          const filters = updateLeBrandSpecificFilters(results.filters) as App.OfferListAvailableFilters
          const filterOrder = updateLeBrandSpecificFilters(results.filterOrder) ?? { amenities: {}, holidayTypes: {}, locations: {} }
          const offerListFilters: Partial<App.OfferListFilterOptions> = {
            filters,
            filterOrder,
            orderedFilters: {
              locations: sortBy(Object.entries(filters.locations), ([filter]) => filterOrder[filter], 'desc').map(entry => ({ value: entry[0], count: entry[1] })),
              holidayTypes: sortBy(Object.entries(filters.holidayTypes), ([filter]) => filterOrder[filter], 'desc').map(entry => ({ value: entry[0], count: entry[1] })),
              amenities: sortBy(Object.entries(filters.amenities), ([filter]) => filterOrder[filter], 'desc').map(entry => ({ value: entry[0], count: entry[1] })),
            },
          }
          dispatch({
            type: API_CALL_SUCCESS,
            api: FETCH_OFFER_LIST_FILTERS,
            data: offerListFilters,
            key: offerListKey,
          })
        }

        return {
          mainOfferList: unique(filters.limit ? take(offerIds, filters.limit) : offerIds),
          offerCount: results.offerCount,
          searchVertical: results.searchVertical,
        }
      }),
      key: offerListKey,
    })
  }
}

export function fetchOfferListFilters(filters: App.OfferListFilters) {
  // filters come with the offer list in search now
  return fetchOfferList(filters)
}

export function fetchTourSearchFacets(filters: App.OfferListFilters = {}) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    const destinationId = filters.destinationId
    const excludeIds = filters.offerIdsToExclude?.join(',')

    const params = {
      placeId: destinationId,
      region: state.geo.currentRegionCode,
      category: filters.categories?.[0],
      luxPlusExclusive: filters.luxPlusFeatures,
      priceGte: filters.priceGte ? parseFloat(filters.priceGte) : undefined,
      priceLte: filters.priceLte ? parseFloat(filters.priceLte) : undefined,
      onSale: filters.onSale,
      tourLengthGte: filters.tourLengthGte ? parseFloat(filters.tourLengthGte) : undefined,
      tourLengthLte: filters.tourLengthLte ? parseFloat(filters.tourLengthLte) : undefined,
    }

    const key = getObjectKey(params)
    if (state.tour.tourFacets[key] && !excludeIds) {
      // already have the results for this set of params
      return
    }
    if ((filters.offerTypes?.includes(OFFER_TYPE_TOUR) || filters.offerTypes?.includes(OFFER_TYPE_TOUR_V2)) && destinationId) {
      dispatch({
        type: API_CALL,
        api: FETCH_TOUR_SEARCH_FACETS,
        request: () => SearchService.getTourFacets({ ...params, placeId: destinationId, excludeIds }),
        key,
      })
    }
  }
}

interface BestPriceParams {
  checkIn: string;
  checkOut: string;
  occupants: Array<App.Occupants>;
  bundledOfferId?: string;
}

export function fetchBestPriceForOffer(offer: App.Offer | App.OfferSummary, params: BestPriceParams) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const key = buildSearchParamsKey(params.checkIn, params.checkOut, params.occupants, params.bundledOfferId)
    const offerId = offer.id

    if (
      state.offer.offerBestPrices[offerId]?.[key] ||
      state.offer.offerPricesLoading[offerId]?.[key] ||
      state.offer.offerPricesErrors[offerId]?.[key]
    ) {
      // Already have details, or are loading them, or encountered an error while loading them
      return
    }

    // occupants we're given don't transform children/infant categories
    // based on property, so we need to do it here
    const rooms = params.occupants.map(occupants =>
      getOccupancyFromSearchStrings(
        occupants.adults,
        occupants.childrenAge,
        offer.property?.maxChildAge,
        offer.property?.maxInfantAge,
      ),
    )

    dispatch({
      key,
      offerId,
      type: API_CALL,
      api: FETCH_BEST_PRICE_FOR_OFFER,
      request: () => CalendarV2Service.getLowestPriceForOffer({
        offerType: offer.type,
        offerIds: [offerId],
        regionCode: state.geo.currentRegionCode,
        currencyCode: state.geo.currentCurrency,
        timezone: offer.property?.timezone ?? 'UTC',
        checkIn: params.checkIn,
        checkOut: params.checkOut,
        rooms,
        bundledOfferId: params.bundledOfferId,
      }),
    })
  }
}

export function fetchBestOfferForProperty(propertyId: string) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const [propertyType, id] = propertyId.split(':')

    if (state.offer.bestPropertyOffer[propertyId]) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_BEST_OFFER_FOR_PROPERTY,
      request: () => SearchService.getAlternativeOffers(
        id,
        propertyType === 'le' ? 'le' : 'bedbank',
        state.geo.currentRegionCode,
      ).then(result => {
        const leResultsByType = groupBy(result.leOfferDetails, res => res.type)

        // priority list of flash -> TAO -> LME -> bedbank
        const best = (
          leResultsByType.get(OFFER_TYPE_HOTEL) ??
          leResultsByType.get(OFFER_TYPE_ALWAYS_ON) ??
          leResultsByType.get(OFFER_TYPE_LAST_MINUTE)
        )
        return { offerId: best?.[0].offerId ?? result.bedbank[0], type: best?.[0].offerId ? 'le' : 'bedbank' }
      }),
      propertyId,
    })
  }
}

function setSearchResultMetadata(
  metaData: Array<App.OfferListMetaData>,
  filters: App.OfferListFilters,
) {
  const searchTargetId = filters.landmarkId ?? filters.destinationId ?? filters.propertyId ?? ''
  const availabilityKey = buildDestinationSearchParamsKey(searchTargetId, filters.checkIn, filters.checkOut, filters.rooms)
  const suggestedDatesKey = buildSuggestedDatesParamsKey(filters.flexibleMonths, filters.flexibleNights, filters.rooms)
  const offerMetaDataKey = getOfferListKey(filters)

  return {
    type: OFFER_SET_SEARCH_RESULT_METADATA,
    data: {
      distanceFromSearchTarget: {
        [searchTargetId]: arrayToObject(metaData, r => r.offerId, r => r.distance),
      },
      // all offers include unavailable ones
      offerAvailabilityFromSearchTarget: {
        [availabilityKey]: arrayToObject(metaData, r => r.offerId, r => r.available),
      },
      offerSuggestedDates: {
        [suggestedDatesKey]: arrayToObject(metaData, r => r.offerId, r => r.suggestedTravelDates),
      },
      offerMetaData: {
        [offerMetaDataKey]: arrayToObject(metaData, r => r.offerId, r => r),
      },
    },
    searchTargetId,
    availabilityKey,
    suggestedDatesKey,
    offerMetaDataKey,
  }
}

function setTourSearchResultMetadata(
  tourMetadata: Array<App.TourListMetadata>,
  filters: App.OfferListFilters,
) {
  const tourMetadataKey = getOfferListKey(filters)

  return {
    type: OFFER_SET_TOUR_SEARCH_RESULT_METADATA,
    data: {
      tourMetadata: {
        [tourMetadataKey]: arrayToObject(tourMetadata, r => r.tourId, r => r),
      },
    },
    tourMetadataKey,
  }
}

export interface AvailableRatesParams {
  checkIn: string;
  checkOut: string;
  occupants: Array<App.Occupants>;
  currencyCode?: string;
  flightOrigin?: string;
  bundleOfferId?: string;
}

export function fetchAvailableRatesForEachRoom(offer: App.Offer, params: AvailableRatesParams) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const offerId = offer.id
    const offerAvailableRatesByOccupancy = state.offer.offerAvailableRatesByOccupancy[offerId]
    const fetchedKeys = new Set(Object.keys(offerAvailableRatesByOccupancy || {}))

    params.occupants.map(occupants => {
      const key = buildAvailableRateKey(params.checkIn, params.checkOut, [occupants])
      if (!fetchedKeys.has(key)) {
        fetchedKeys.add(key)
        dispatch(fetchAvailableRates(offer, {
          ...params,
          occupants: [occupants],
        }))
      }
    })
  }
}

function updateAvailableRatesForOffer(offerId: string, key: string) {
  return {
    type: OFFER_UPDATE_AVAILABLE_RATES,
    offerId,
    key,
  }
}

export function fetchAvailableRatesForOffer(offer: App.Offer | App.OfferSummary | App.BundledOffer, params: AvailableRatesParams) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const offerId = offer.id
    const offerAvailableRates = state.offer.offerAvailableRatesByOccupancy[offerId]
    const currentOfferAvailableRates = state.offer.offerAvailableRates[offerId]

    const key = buildAvailableRateKey(params.checkIn, params.checkOut, params.occupants)

    if (!offerAvailableRates || !(key in offerAvailableRates)) {
      // we have not fetched data with this key before, call the API
      dispatch(fetchAvailableRates(offer, params))
    } else if (!!currentOfferAvailableRates && !(key in currentOfferAvailableRates)) {
      // we did fetched it before but offerAvailableRates is not using data with that key
      dispatch(updateAvailableRatesForOffer(offerId, key))
    }
  }
}

function fetchAvailableRates(offer: App.Offer | App.OfferSummary | App.BundledOffer, params: AvailableRatesParams) {
  return (dispatch, getState) => {
    const state = getState() as App.State
    const offerId = offer.id
    const key = buildAvailableRateKey(params.checkIn, params.checkOut, params.occupants)

    // occupants we're given don't transform children/infant categories
    // based on property, so we need to do it here
    const rooms = params.occupants.map(occupants =>
      getOccupancyFromSearchStrings(
        occupants.adults,
        occupants.childrenAge,
        offer.property?.maxChildAge,
        offer.property?.maxInfantAge,
      ),
    )

    dispatch({
      type: API_CALL,
      api: FETCH_AVAILABILITY_RATES_FOR_OFFER,
      request: () => CalendarV2Service.getAvailableRatesForOffer({
        offerType: offer.type,
        offerIds: [params.bundleOfferId ?? offerId],
        regionCode: state.geo.currentRegionCode,
        currencyCode: params.currencyCode || state.geo.currentCurrency,
        timezone: offer.property?.timezone ?? 'UTC',
        checkIn: params.checkIn,
        checkOut: params.checkOut,
        rooms,
        dynamic: true,
        flightOrigin: params.flightOrigin,
        bundledOfferId: params.bundleOfferId ? offerId : undefined,
      }),
      offerId,
      key,
    })
  }
}

function searchForLocation(location: string, place: App.Place): Array<App.Place> | undefined {
  if (place.name === location || place.canonicalName === location) {
    return [place]
  }
  if (place.children) {
    for (const child of place.children) {
      const result = searchForLocation(location, child)
      if (result !== undefined) {
        return [place, ...result]
      }
    }
  }
  return undefined
}

function getDeepestLocation(locations: Array<string>): Array<string> {
  let bestLocationPaths = [undefined] as Array<App.Place | undefined>
  for (const location of locations) {
    const locationPath = searchForLocation(location, { id: '', name: 'root', children: placesTree })
    if (locationPath !== undefined && locationPath.length > bestLocationPaths.length) {
      bestLocationPaths = locationPath
    }
  }

  return bestLocationPaths.length > 1 ? skip(bestLocationPaths, 1).map(path => path!.name) : take(locations, 1)
}

export function getRelatedTravelItemsForTour(offer: App.TourOffer): Array<App.BreadcrumbItem> {
  const locations = getDeepestLocation(offer.locations)
  const breadcrumbs: Array<App.BreadcrumbItem> = [
    { text: 'Tours', url: `/${OFFER_TYPE_TOUR_SLUG}` },
    ...locations.map(location => ({ text: location, url: `/${urlTransform(`${location}.Tours & Cruises`)}`.toLowerCase() })),
    { text: offer.lowestPricePackage.tour.name },
  ]
  return breadcrumbs
}

function breadcrumbURLConstructor(destination, index: number, checkIn?: string, checkOut?: string, rooms?: Array<App.Occupants>) {
  if (checkIn && checkOut) {
    const searchParams = buildSearchParamsFromFilters({
      checkIn,
      checkOut,
      rooms,
    })
    return `/search?destinationName=${urlTransform(destination[0].name)}&destinationId=${destination[0].place_id}&${searchParams}`
  }
  return index === 0 ? `/hotels/country/${urlTransform(`${destination.map(destination => destination.name).join('/')}`)}`.toLowerCase() : `/hotels/location/${urlTransform(`${destination.map(destination => destination.name).join('/')}`)}`.toLowerCase()
}

export async function breadcrumbsFetcher(offer: App.Offer | App.BedbankOffer, state?: App.State, checkIn?: string, checkOut?: string, rooms?: Array<App.RoomOccupants>): Promise<Array<App.BreadcrumbItem>> {
  invariant(offer.property, 'Missing property on offer ' + offer.id)
  const leOffer = isLEOffer(offer)

  let firstItem = { text: 'Hotels', url: `/${OFFER_TYPE_HOTEL_SLUG}` }
  if (leOffer && offer.property.isUltraLux) {
    firstItem = { text: 'Ultra Lux', url: `/${PRODUCT_TYPE_ULTRALUX_SLUG}` }
  }

  let destinations: Array<SearchService.Destination>
  if (state?.offer.relatedTravelItems[offer.id]) {
    destinations = state.offer.relatedTravelItems[offer.id].map((destination) => {
      if (destination.placeId) {
        return { name: destination.text, place_id: destination.placeId }
      }
    }).filter((breadcrumb) => breadcrumb !== undefined) as Array<SearchService.Destination>
  } else {
    destinations = await SearchService.getBreadcrumbs(offer.property.id, (leOffer ? 'le' : 'bedbank'), false)
    destinations = destinations.reverse()
  }
  const locationPathDeduplicated = uniqueBy(destinations, d => d.name + '|' + d.place_id)
  const breadcrumbs = locationPathDeduplicated
    .map((location, index) => [location, ...locationPathDeduplicated.slice(0, index).reverse()])
    .map((locationPath, index) => ({
      text: locationPath[0].name,
      url: breadcrumbURLConstructor(locationPath, index, checkIn, checkOut, rooms),
      placeId: locationPath[0].place_id,
    }))

  let lastItem: App.BreadcrumbItem
  if (leOffer) {
    lastItem = {
      text: offer.property.name,
      url: `/offer/${offer.slug}/${offer.id}`,
    }
  } else {
    let propertyUrl = `/partner/${offer.slug}/${offer.id}`
    if (state?.geo.currentRegionCode) {
      const alternativeOffers = await SearchService.getAlternativeOffers(offer.id, 'bedbank', state.geo.currentRegionCode)
      if (alternativeOffers.leOfferDetails.length) {
        if (alternativeOffers.leOfferDetails[0].type !== OFFER_TYPE_BED_BANK) {
          propertyUrl = `/property/${offer.slug}-le:${alternativeOffers.leOfferDetails[0].propertyId}`
        }
      }
    }
    lastItem = {
      text: offer.name,
      url: propertyUrl,
    }
  }

  return [
    firstItem,
    ...breadcrumbs,
    lastItem,
  ]
}

export function fetchRelatedTravelItems(offer: App.Offer | App.BedbankOffer, checkIn?: string, checkOut?: string, rooms?: Array<App.RoomOccupants>) {
  return (dispatch, getState) => {
    const state = getState() as App.State

    if (isTourV1Offer(offer)) {
      if (state.offer.relatedTravelItems[offer.id] !== undefined) {
        return
      }
      dispatch({
        type: API_CALL_SUCCESS,
        api: FETCH_RELATED_TRAVEL_ITEMS,
        offerId: offer.id,
        data: getRelatedTravelItemsForTour(offer),
      })
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_RELATED_TRAVEL_ITEMS,
      request: () => breadcrumbsFetcher(offer, state, checkIn, checkOut, rooms),
      offerId: offer.id,
    })
  }
}

export function fetchAlternativeOffers(propertyId: string, type: 'le' | 'bedbank', additionalKey: string = '') {
  const key = [propertyId, additionalKey].join('')
  return (dispatch, getState) => {
    const state: App.State = getState()

    dispatch({
      type: API_CALL,
      api: FETCH_ALTERNATIVE_OFFERS,
      request: () => SearchService.getAlternativeOffers(propertyId, type, state.geo.currentRegionCode),
      key,
    })
  }
}

// NOTE: this is a temporary feature that will be moved to the server
// after search implements its new streaming API
interface fetchAlternativeDatesParams {
  offer: App.OfferSummary | App.BedbankOfferSummary;
  filters: App.OfferListFilters;
  offerPosition: number;
  kind: 'le' | 'bedbank';
  numDatesToSearch?: number;
  minPackageDuration?: number;
}
export function fetchAlternativeDates(
  {
    offer,
    filters,
    offerPosition,
    kind,
    numDatesToSearch = 10,
    minPackageDuration,
  } : fetchAlternativeDatesParams,
): AppApiAction {
  // rooms should always be defined, but TS doesn't know that
  const MAX_POSITION_TO_CHECK_FOR = 15
  const { checkIn, checkOut, rooms = [] } = filters
  const offerId = offer.id

  const listKey = getOfferListKey(filters)

  return (dispatch, getState) => {
    const state = getState()

    const altDatesAlreadyExist = state.offer.alternativeDates[listKey]?.[offerId]

    if (state.offer.alternativeDatesLoading[offerId] ||
      altDatesAlreadyExist ||
      (!checkIn || !checkOut) ||
      offerPosition > MAX_POSITION_TO_CHECK_FOR) {
      return
    }

    if (minPackageDuration) {
      const difference = Math.abs(calculateNights(moment(checkIn), moment(checkOut))! - minPackageDuration)
      // We don't want to fetch alternative dates for flash offers that are too far from the original checkIn date
      if (difference > 2) {
        return
      }
    }

    const regionCode = state.geo.currentRegionCode
    const currencyCode = state.geo.currentCurrency
    const timezone = offer.property?.timezone ?? 'UTC'

    const dates = generateAlternativeDates(checkIn, minPackageDuration ? moment(checkIn).add(minPackageDuration, 'days').format(ISO_DATE_FORMAT) : checkOut, numDatesToSearch)

    if (kind === 'bedbank') {
      dispatch({
        type: API_CALL,
        api: FETCH_ALTERNATIVE_DATES,
        request: () => Promise.all(dates.map(date => getRatesForBedbankOffer({ checkIn: date.checkIn, checkOut: date.checkOut, id: offerId, region: regionCode, rooms, timezone })))
          .then(results => {
            const alternativeDates: Array<App.AlternativeDatesWithRates> = results.map((rates, index) => {
              const cheapest = min(rates, rate => rate?.totals?.inclusive ?? Infinity)
              return {
                checkIn: dates[index].checkIn,
                checkOut: dates[index].checkOut,
                rate: cheapest!,
              }
            })
            return alternativeDates.filter(alt => !!alt.rate)
          }),
        offerId,
        listKey,
      })
    } else if (kind === 'le') {
      // copied logic from fetchBestPriceForOffer
      // handle properties maxChildAge and maxInfantAge to increment children/infants
      const enrichedRooms = rooms.map(occupants =>
        getOccupancyFromSearchStrings(
          occupants.adults,
          occupants.childrenAge,
          (offer as App.OfferSummary).property?.maxChildAge,
          (offer as App.OfferSummary).property?.maxInfantAge,
        ),
      )
      const request = () => Promise.all(dates.map(date => CalendarV2Service.getLowestPriceForOffer({
        checkIn: date.checkIn,
        checkOut: date.checkOut,
        regionCode,
        currencyCode,
        offerIds: [offerId],
        timezone,
        offerType: offer.type,
        rooms: enrichedRooms,
      })))

      dispatch({
        type: API_CALL,
        api: FETCH_ALTERNATIVE_DATES,
        request: () => request().then(results => results.filter(rate => rate.available)),
        offerId,
        listKey,
      })
    }
  }
}

export function clearOffers(
  ids: {
    offerIds?: Array<string>,
    bedbankOfferIds?: Array<string>,
    tourV2OfferIds?: Array<string>,
  },
) {
  return {
    type: OFFERS_CLEAR,
    ids,
  }
}

export function fetchTraderInformation(
  offerId: string,
  regionCode?: string,
) {
  return (dispatch, getState) => {
    const state: App.State = getState()

    if (state.offer.traderInformation[offerId]) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_TRADER_INFORMATION,
      request: () =>
        OfferService.getTraderInformation(
          offerId,
          regionCode || state.geo.currentRegionCode,
        ),
      offerId,
    })
  }
}

export function offerViewed(offerId: string, offerType: App.OfferType, isPriceMissing: boolean = false) {
  return (dispatch, getState) => {
    const newView: App.RecentlyViewedOffer = {
      offerId,
      offerType,
      creationTime: timeNowInSecond(), // store view time as sec
      category: 'recently_viewed',
      lereVersion: 'recently_viewed_local',
      isPriceMissing,
    }

    saveHighIntentOffersToLocal([newView])

    dispatch({
      type: OFFER_VIEWED,
      view: newView,
    })

    const state: App.State = getState()
    const userId = getCurrentUserId(state)
    const isLuxPlusMember = checkCanViewLuxPlusBenefits(state)
    if (userId) {
      RecommendationService.saveRecentlyViewedOffersWithDebounce(userId, [newView], state.geo.currentRegionCode, isLuxPlusMember)
    }
  }
}

export function fetchOfferLocationBreadcrumbs(offerId: string, type: 'le' | 'bedbank', showAllLocations: boolean) {
  return (dispatch, getState) => {
    const state: App.State = getState()

    if (state.offer.locationBreadcrumbs[offerId]) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_OFFER_LOCATION_BREADCRUMBS,
      request: () => SearchService.getBreadcrumbs(offerId, type, showAllLocations),
      offerId,
    })
  }
}

export interface PricingData {
  price: number;
  value: number;
  taxesAndFees: number;
  propertyFees: number;
}
export function updateHotelPackagePricingData(
  offerId: string,
  uniqueKey: string,
  data: Partial<PricingData>,
) {
  return {
    type: OFFER_UPDATE_PRICING_DATA,
    offerId,
    uniqueKey,
    data,
  }
}

export function fetchPopularHotelFilters() {
  return (dispatch, getState) => {
    const state = getState() as App.State

    dispatch({
      type: API_CALL,
      api: FETCH_POPULAR_FILTERS,
      request: () => SearchService.getPopularHotelFilters(state.geo.currentRegionCode),
    })
  }
}

export function pushOfferToListBottom(offerId: string, listKey: string, reason: string) {
  return {
    type: OFFER_PUSH_TO_LIST_BOTTOM,
    offerId,
    listKey,
    reason,
  }
}

function addOfferListExtra(offerListKey: string, extra: App.OfferListExtra) {
  return {
    type: ADD_OFFER_LIST_EXTRA,
    key: offerListKey,
    extra,
  }
}

export type StreamingHotelSearchOffersResponse = paths['/api/search/hotel/v1/list']['get']['responses']['200']['content']['application/json']['result']

// Temporary : A specific type will be made for this
export type StreamingHotelSearchFilterResponse = Omit<paths['/api/search/hotel/v1/list']['get']['responses']['200']['content']['application/json'], 'results'> & {
  searchId: string
}

export enum SEPARATOR_TYPE {
  NEARBY_OFFERS = 'NEARBY_OFFERS',
}

export function offerListScroll(filters: App.OfferListFilters = {}, limit: number) {
  return (_, getState) => {
    const state = getState() as App.State

    const offerListKey = getOfferListKey(filters)
    const streamingId = state.offer.offerLists[offerListKey]?.streamingId

    if (!streamingId) {
      return
    }

    streamScrollRequest({
      streamingId,
      limit,
    })
  }
}

export function streamOfferList(filters: App.OfferListFilters = {}, streamingId: string | undefined, offset?: number, options: Options = {}) {
  return (dispatch, getState) => {
    const searchSocket = getSearchSocket()

    const state = getState() as App.State
    const canViewLuxPlusBenefits = checkCanViewLuxPlusBenefits(state)
    const luxPlusEnabled = isLuxPlusEnabled(state)
    const isPaidSessionEnabled = isPaidSession(state)
    const domainUserId = filters.shouldUsePersonalizedSearch ? getDomainUserId() : undefined
    const memberId = filters.shouldUsePersonalizedSearch ? state.auth.account.memberId : undefined

    const offerListKey = getOfferListKey(filters)

    // We want to retry fetching if we have gotten a disconnected error
    if (state.offer.offerLists[offerListKey] && state.offer.offerLists[offerListKey].error !== 'disconnected') {
      // already have the results for this set of params
      return
    }

    if (filters.checkIn && !filters.checkOut) {
      // This request is only valid if both checking and checkout dates are provided
      return
    }

    // We only want to dispatch API_CALL_REQUEST when we are making a request before any error
    if (!state.offer.offerLists[offerListKey]?.error) {
      dispatch({
        type: API_CALL_REQUEST,
        api: FETCH_OFFER_LIST,
        key: offerListKey,
      })
    }

    const handleFiltersResponse = (response: StreamingHotelSearchFilterResponse) => {
      const offerListFilterResults = mapOfferListFiltersResponse(response)

      if (offerListFilterResults.filters) {
        dispatch({
          type: API_CALL_SUCCESS,
          api: FETCH_OFFER_LIST_FILTERS,
          data: {
            ...getOfferListFilters(offerListFilterResults),
            offerCount: offerListFilterResults.offerCount,
            streamingId: response.searchId,
          },
          key: offerListKey,
        })
      }
    }

    const handleOfferResponse = (response: StreamingHotelSearchOffersResponse) => {
      const OfferListOfferResponse = mapOfferListResponse(response)

      const metaData = mapOfferListMetaData(OfferListOfferResponse)

      if (metaData.length > 0 && (filters.landmarkId || filters.destinationId || filters.propertyId || filters.bounds)) {
        dispatch(setSearchResultMetadata(metaData, filters))
      }

      const offerIds = unique(filters?.limit ? take(OfferListOfferResponse.offerIds, filters.limit) : OfferListOfferResponse.offerIds)
      dispatch({
        type: API_CALL_SUCCESS,
        api: FETCH_OFFER_LIST,
        key: offerListKey,
        data: {
          streamedOfferIds: offerIds,
        },
      })
    }

    const handleSeparatorResponse = (response: {
      type: SEPARATOR_TYPE;
      position: number;
    }) => {
      const extra = mapSeparatorToExtra(response)

      if (!extra) return

      dispatch(addOfferListExtra(offerListKey, extra))
    }

    const handleError = () => {
      dispatch({
        type: API_CALL_FAILURE,
        api: FETCH_OFFER_LIST,
        key: offerListKey,
        error: 'streaming error',
      })
    }

    const handleDisconnection = () => {
      dispatch({
        type: API_CALL_FAILURE,
        api: FETCH_OFFER_LIST,
        key: offerListKey,
        error: 'disconnected',
      })
    }

    streamRequest({
      region: state.geo.currentRegionCode,
      filters,
      canViewLuxPlusBenefits,
      isLuxPlusEnabled: luxPlusEnabled,
      clientCheckAvailability: options.clientCheckAvailability,
      evVersion: options.evVersion || (luxPlusEnabled && canViewLuxPlusBenefits) ? 'luxplus_boost' : undefined,
      isPaidSession: isPaidSessionEnabled,
      memberId,
      domainUserId,
      streamingId,
      offset,
    })

    searchSocket?.on('search:response:offers', handleOfferResponse)
    searchSocket?.on('search:response:filters', handleFiltersResponse)
    searchSocket?.on('search:response:separator', handleSeparatorResponse)

    searchSocket?.on('search:error', handleError)
    searchSocket?.on('disconnect', handleDisconnection)
  }
}

function mapOfferListResponse(
  result: StreamingHotelSearchOffersResponse,
): { offerIds: Array<string>; metaData?: Array<App.OfferListMetaData>} {
  return {
    offerIds: result.map(res => res.id),
    metaData: result.map(mapSearchResultToOfferListMetaData),
  }
}

function mapSeparatorToExtra(
  response: {
    type: SEPARATOR_TYPE;
    position: number;
  },
): App.OfferListExtra | undefined {
  let type: App.OfferListExtraType

  switch (response.type) {
    case SEPARATOR_TYPE.NEARBY_OFFERS:
      type = 'nearby'
      break
    default:
      return
  }

  return {
    type,
    position: response.position,
  }
}

function mapOfferListFiltersResponse(response: StreamingHotelSearchFilterResponse): Omit<OfferListResult, 'offerIds' | 'metaData'> {
  return {
    filters: {
      amenities: response.filters.amenities,
      bedrooms: response.filters.bedrooms,
      campaigns: response.filters.campaigns,
      customerRatings: response.filters.customerRatings,
      holidayTypes: response.filters.holidayTypes,
      inclusions: response.filters.inclusions,
      locations: response.filters.locations,
      propertyTypes: response.filters.propertyTypes,
      offerTypes: response.filters.type,
      luxPlusFeatures: response.filters.luxPlusExclusive ?? {},
      total: response.total,
    },
    filterOrder: response.filterOrder,
    offerCount: response.total,
  }
}

function mapOfferListMetaData(results: Omit<OfferListResult, 'offerIds'>): Array<App.OfferListMetaData> {
  let metaDataList: Array<App.OfferListMetaData> = results.metaData || []
  const mapBundledOffers = arrayToObject(
    metaDataList.filter(m => m.bundledOfferId && m.available),
    r => r.bundledOfferId!,
    r => r.offerId,
  )

  // Adding for single offers property bundleOfferId
  metaDataList = metaDataList.map(metaData => mapBundledOffers[metaData.offerId] ? {
    ...metaData,
    bundleOfferId: mapBundledOffers[metaData.offerId],
  } : metaData)

  return metaDataList
}

function getOfferListFilters(results: Omit<OfferListResult, 'offerIds' | 'metaData'>): Omit<App.OfferListFilterOptions, 'error' | 'fetching' | 'streamingId'> {
  const filters = updateLeBrandSpecificFilters(results.filters) as App.OfferListAvailableFilters
  const filterOrder = updateLeBrandSpecificFilters(results.filterOrder) ?? { amenities: {}, holidayTypes: {}, locations: {} }
  return {
    filters,
    filterOrder,
    orderedFilters: {
      locations: sortBy(Object.entries(filters.locations), ([filter]) => filterOrder[filter], 'desc').map(entry => ({
        value: entry[0],
        count: entry[1],
      })),
      holidayTypes: sortBy(Object.entries(filters.holidayTypes), ([filter]) => filterOrder[filter], 'desc').map(entry => ({
        value: entry[0],
        count: entry[1],
      })),
      amenities: sortBy(Object.entries(filters.amenities), ([filter]) => filterOrder[filter], 'desc').map(entry => ({
        value: entry[0],
        count: entry[1],
      })),
    },
  }
}

export function removeOffer(offerId: string) {
  return {
    type: REMOVE_OFFER,
    offerId,
  }
}

export function fetchCalculatedSurchargeMargin(
  roomRateId: string,
  surchargeMarginItem: App.ToBeCalculatedSurchargeMarginItem,
  surchargeMarginKey: string,
): AppApiAction {
  return (dispatch, getState) => {
    const state = getState()
    const currentSurchargeMargin = state.offer.surchargeMargins[surchargeMarginKey]

    if (!currentSurchargeMargin) {
      dispatch({
        type: API_CALL,
        api: FETCH_SURCHARGE_MARGIN,
        surchargeMarginKey,
        request: () => batchFetchSurchargeMargin([roomRateId, surchargeMarginItem], undefined),
      })
    }
  }
}
