import React, { useCallback, useMemo, useRef } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import IconButton from 'components/Luxkit/Button/IconButton'
import LineBellIcon from 'components/Luxkit/Icons/line/LineBellIcon'
import Caption from 'components/Luxkit/Typography/Caption'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import useToggle from 'hooks/useToggle'
import Group from 'components/utils/Group'
import BodyText from 'components/Luxkit/Typography/BodyText'
import TextLink from 'components/Luxkit/TextLink'
import config from 'constants/config'
import HeaderNotificationItem from './HeaderNotificationItem'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import { useIsMobileScreen } from 'lib/web/deviceUtils'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { viewAllUserNotifications } from 'actions/AuthActions'
import MessageBanner from 'components/Luxkit/Banners/MessageBanner'

const StyledNotificationIconButton = styled(IconButton)`
  position: relative;
`

const RedDotOnSignUp = styled.div`
  position: absolute;
  top: ${rem(2)};
  right: ${rem(2)};
  padding: ${rem(2)} ${rem(4)};
  border-radius: ${props => props.theme.borderRadius.round};
  background-color: ${props => props.theme.palette.messaging.critical.normalBackground};
`

const EmptyState = styled(Group)`
  padding: ${rem(64)} ${rem(20)};
`

function HeaderNotification() {
  const { toggle, value: isMenuOpen } = useToggle()

  const dispatch = useAppDispatch()
  const isMobileScreen = useIsMobileScreen()
  const notifications = useAppSelector(state => state.auth.account.notifications)
  const newNotifications = useMemo(() => notifications.filter(item => !item.viewed).length, [notifications])
  const buttonRef = useRef<HTMLButtonElement>(null)
  const isSubscribed = useAppSelector(state => state.leSubscriptions.settings.app_push_subscribed)

  const onButtonClick = useCallback(() => {
    if (isMenuOpen) {
      dispatch(viewAllUserNotifications())
    }
    toggle()
  }, [isMenuOpen, dispatch, toggle])

  const onCloseNotifications = useCallback(() => {
    dispatch(viewAllUserNotifications())
    toggle()
  }, [dispatch, toggle])

  const Content = useMemo(() => (
    <>
      {!notifications.length && (
        <EmptyState direction="vertical" gap={8} horizontalAlign="center">
          <LineBellIcon />
          <Group direction="vertical" horizontalAlign="center">
            <BodyText variant="large" weight="bold">No new notifications</BodyText>
            {isSubscribed && <BodyText variant="medium">We’ll let you know when updates arrive.</BodyText>}
          </Group>
        </EmptyState>
      )}
      <Group direction="vertical" noDisplayWhenEmpty>
        {notifications?.map(notification => <HeaderNotificationItem key={notification.id} notification={notification} />)}
      </Group>
    </>
  ), [isSubscribed, notifications])

  return (
    <>
      <StyledNotificationIconButton
        ref={buttonRef}
        kind="tertiary"
        variant="default"
        role="menuitem"
        onClick={onButtonClick}
      >
        <LineBellIcon />
        {!!newNotifications && (
          <RedDotOnSignUp>
            <Caption variant="small" colour="neutral-eight">{newNotifications > 9 ? '9+' : newNotifications}</Caption>
          </RedDotOnSignUp>
        )}
      </StyledNotificationIconButton>

      <CSSBreakpoint max="mobile">
        <ModalBase height="full" isOpen={isMenuOpen && isMobileScreen} onClose={onCloseNotifications}>
          <ModalHeader title="Notifications" onCloseButtonClick={onCloseNotifications}/>
          <ModalBody>
            {!isSubscribed && <MessageBanner kind="warning" description={<BodyText variant="medium">Enable notifications to get the latest updates as soon as they arrive. <TextLink to={config.header.emailPreferences.to} target="_blank">Go to settings</TextLink></BodyText>} />}
            <Group direction="vertical">
              {Content}
            </Group>
          </ModalBody>
        </ModalBase>
      </CSSBreakpoint>

      <CSSBreakpoint min="tablet">
        <DropdownList
          size="M"
          open={isMenuOpen}
          anchorRef={buttonRef}
          triggerRef={buttonRef}
          placement="bottom-end"
          onClose={onCloseNotifications}
          headerExtension={!isSubscribed && <MessageBanner kind="warning" description={<BodyText variant="medium">Enable notifications to get the latest updates as soon as they arrive. <TextLink to={config.header.emailPreferences.to} target="_blank">Go to settings</TextLink></BodyText>} />}
        >
          {Content}
        </DropdownList>
      </CSSBreakpoint>
    </>
  )
}

export default HeaderNotification
