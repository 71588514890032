import {
  FETCH_EXPERIENCE,
  FETCH_EXPERIENCE_LIST,
  FETCH_EXPERIENCE_DATES,
  FETCH_EXPERIENCE_TIMESLOTS,
  FETCH_EXPERIENCES,
  FETCH_EXPERIENCE_CATEGORIES,
  FETCH_EXPERIENCE_LIST_FACETS,
  FETCH_EXPERIENCE_VOUCHER,
  FETCH_BOOKING_DETAILS,
  FETCH_REGION_HEROES,
  MARK_EXPERIENCE_BOOKING_DETAILS_AS_REDEEMED,
  FETCH_TRANSFER_OPTIONS,
  USER_LOGOUT,
  USER_LOGIN,
  FETCH_REFUND_DETAILS,
} from 'actions/apiActionConstants'
import { reducerSwitch, createReducer } from 'lib/redux/reducerUtils'
import {
  API_CALL_REQUEST,
  API_CALL_FAILURE,
  API_CALL_SUCCESS,
  EXPERIENCE_VIEWED,
  SET_EXPERIENCE_PLACE,
  SET_CANCELLING_EXPERIENCES,
  CHECKOUT_PROCESS_SUCCESS,
} from 'actions/actionConstants'
import { arrayToObject, take, unique } from 'lib/array/arrayUtils'
import { AnyAction } from 'redux'

export const initialExperienceState: App.ExperienceState = {
  experiencePlace: { type: 'currentLocation', userSelected: false },
  experiences: {},
  experienceErrors: {},
  bookingDetails: {},
  bookingDetailsErrors: {},
  fetchingBookingDetails: {},
  fetchingExperiences: {},
  refundDetails: {},
  fetchingRefundDetails: {},
  refundDetailsErrors: {},
  experienceLists: {},
  experienceDates: {},
  experienceTimes: {},
  experienceTransferOptions: {},
  experienceCategories: [],
  experienceListFacets: {},
  experienceVoucher: {},
  recentlyViewedExperienceIds: [],
  experienceRegionHeroes: undefined,
  experienceRegionHeroesError: undefined,
  fetchingRegionHeroes: false,
  fetchingCategories: false,
  experienceCategoriesError: undefined,
  markingBookingDetailsAsRedeemed: {},
  cancellingExperiences: undefined,
}

const apiRequests = reducerSwitch<App.ExperienceState>({
  [FETCH_EXPERIENCE_LIST]: (state, action) => ({
    experienceLists: {
      ...state.experienceLists,
      [action.key]: {
        ids: [],
        fetching: true,
        error: undefined,
      },
    },
  }),
  [FETCH_EXPERIENCE_DATES]: (state, action) => ({
    experienceDates: {
      ...state.experienceDates,
      [action.experienceId]: {
        ...state.experienceDates[action.experienceId],
        [action.key]: {
          ...state.experienceDates[action.experienceId]?.[action.key],
          fetching: true,
          error: undefined,
        },
      },
    },
  }),
  [FETCH_EXPERIENCE_TIMESLOTS]: (state, action) => ({
    experienceTimes: {
      ...state.experienceTimes,
      [action.experienceId]: {
        ...state.experienceTimes[action.experienceId],
        [action.key]: {
          ...state.experienceTimes[action.experienceId]?.[action.key],
          fetching: true,
          error: undefined,
        },
      },
    },
  }),
  [FETCH_EXPERIENCE]: (state, action) => ({
    fetchingExperiences: {
      ...state.fetchingExperiences,
      [action.id]: true,
    },
  }),
  [FETCH_BOOKING_DETAILS]: (state, action) => ({
    fetchingBookingDetails: {
      ...state.fetchingBookingDetails,
      ...arrayToObject((action.ids as Array<string>), id => id, () => true),
    },
  }),
  [FETCH_REFUND_DETAILS]: (state, action) => ({
    fetchingRefundDetails: {
      ...state.fetchingRefundDetails,
      ...arrayToObject((action.ids as Array<string>), id => id, () => true),
    },
  }),
  [MARK_EXPERIENCE_BOOKING_DETAILS_AS_REDEEMED]: (state, action) => ({
    markingBookingDetailsAsRedeemed: {
      ...state.markingBookingDetailsAsRedeemed,
      [action.experienceItemId]: true,
    },
  }),
  [FETCH_EXPERIENCES]: (state, action) => ({
    fetchingExperiences: {
      ...state.fetchingExperiences,
      ...arrayToObject((action.ids as Array<string>), id => id, () => true),
    },
  }),
  [FETCH_EXPERIENCE_LIST_FACETS]: (state, action) => ({
    experienceListFacets: {
      ...state.experienceListFacets,
      [action.key]: {
        fetching: true,
        facets: {
          category: [],
        },
      },
    },
  }),
  [FETCH_EXPERIENCE_VOUCHER]: (state, action) => ({
    experienceVoucher: {
      ...state.experienceVoucher,
      [action.experienceItemId]: {
        fetching: true,
        error: undefined,
      },
    },
  }),
  [FETCH_REGION_HEROES]: () => ({
    fetchingRegionHeroes: true,
    experienceRegionHeroesError: undefined,
  }),
  [FETCH_EXPERIENCE_CATEGORIES]: () => ({
    fetchingCategories: true,
    experienceCategoriesError: undefined,
  }),
  [FETCH_TRANSFER_OPTIONS]: (state, action) => ({
    experienceTransferOptions: {
      ...state.experienceTransferOptions,
      [action.experienceId]: {
        fetching: true,
      },
    },
  }),
})

const apiSuccesses = reducerSwitch<App.ExperienceState>({
  [FETCH_EXPERIENCE_LIST]: (state, action) => ({
    experienceLists: {
      ...state.experienceLists,
      [action.key]: {
        ids: action.data,
        fetching: false,
      },
    },
  }),
  [FETCH_EXPERIENCE_LIST_FACETS]: (state, action) => ({
    experienceListFacets: {
      ...state.experienceListFacets,
      [action.key]: {
        fetching: false,
        facets: action.data,
      },
    },
  }),
  [FETCH_EXPERIENCE]: (state, action) => ({
    experiences: {
      ...state.experiences,
      [action.id]: action.data,
    },
    fetchingExperiences: {
      ...state.fetchingExperiences,
      [action.id]: false,
    },
  }),
  [FETCH_BOOKING_DETAILS]: (state, action) => ({
    bookingDetails: {
      ...state.bookingDetails,
      ...arrayToObject((action.data as Array<App.ExperienceBookingDetails>), bookingDetail => bookingDetail.experienceItemId),
    },
    fetchingBookingDetails: {
      ...state.fetchingBookingDetails,
      ...arrayToObject((action.ids as Array<string>), id => id, () => false),
    },
  }),
  [FETCH_REFUND_DETAILS]: (state, action) => ({
    refundDetails: {
      ...state.refundDetails,
      ...arrayToObject((action.data as Array<App.OrderItemRefundDetails>), refundDetail => refundDetail.orderItemId),
    },
    fetchingRefundDetails: {
      ...state.fetchingRefundDetails,
      ...arrayToObject((action.ids as Array<string>), id => id, () => false),
    },
  }),
  [MARK_EXPERIENCE_BOOKING_DETAILS_AS_REDEEMED]: (state, action) => ({
    markingBookingDetailsAsRedeemed: {
      ...state.markingBookingDetailsAsRedeemed,
      [action.experienceItemId]: false,
    },
    bookingDetails: {
      ...state.bookingDetails,
      [action.experienceItemId]: { ...state.bookingDetails[action.experienceItemId], markedAsRedeemed: true },
    },
  }),
  [FETCH_EXPERIENCES]: (
    state,
    action: AnyAction & { data: { experiences: Array<App.ExperienceOffer>, errors: Record<string, any> } },
  ) => {
    return {
      experiences: {
        ...state.experiences,
        ...arrayToObject(action.data.experiences, experience => experience.id),
      },
      experienceErrors: {
        ...state.experienceErrors,
        ...action.data.errors,
      },
      fetchingExperiences: {
        ...state.fetchingExperiences,
        ...arrayToObject((action.ids as Array<string>), id => id, () => false),
      },
    }
  },
  [FETCH_EXPERIENCE_DATES]: (state, action) => ({
    experienceDates: {
      ...state.experienceDates,
      [action.experienceId]: {
        ...state.experienceDates[action.experienceId],
        [action.key]: {
          ...state.experienceDates[action.experienceId]?.[action.key],
          dates: action.data,
          fetching: false,
        },
      },
    },
  }),
  [FETCH_EXPERIENCE_TIMESLOTS]: (state, action) => ({
    experienceTimes: {
      ...state.experienceTimes,
      [action.experienceId]: {
        ...state.experienceTimes[action.experienceId],
        [action.key]: {
          ...state.experienceTimes[action.experienceId]?.[action.key],
          slots: action.data,
          fetching: false,
          error: undefined,
        },
      },
    },
  }),
  [FETCH_EXPERIENCE_CATEGORIES]: (state, action) => ({
    experienceCategories: action.data,
    fetchingCategories: false,
  }),
  [FETCH_EXPERIENCE_VOUCHER]: (state, action) => ({
    experienceVoucher: {
      ...state.experienceVoucher,
      [action.experienceItemId]: {
        fetching: false,
        data: action.data,
      },
    },
  }),
  [FETCH_REGION_HEROES]: (state, action) => ({
    experienceRegionHeroes: action.data,
    fetchingRegionHeroes: false,
  }),
  [FETCH_TRANSFER_OPTIONS]: (state, action) => ({
    experienceTransferOptions: {
      ...state.experienceTransferOptions,
      [action.experienceId]: {
        ...state.experienceTransferOptions[action.experienceId],
        fetching: false,
        transfers: action.data,
      },
    },
  }),
  [USER_LOGOUT]: () => ({
    experienceDates: {},
    experienceTimes: {},
  }),
  [USER_LOGIN]: () => ({
    experienceDates: {},
    experienceTimes: {},
  }),
})

const apiFailures = reducerSwitch<App.ExperienceState>({
  [FETCH_EXPERIENCE_LIST]: (state, action) => ({
    experienceLists: {
      ...state.experienceLists,
      [action.key]: {
        ids: [],
        fetching: false,
        error: action.error,
      },
    },
  }),
  [FETCH_EXPERIENCE_LIST_FACETS]: (state, action) => ({
    experienceListFacets: {
      ...state.experienceListFacets,
      [action.key]: {
        fetching: false,
        error: action.error,
      },
    },
  }),
  [FETCH_EXPERIENCE_DATES]: (state, action) => ({
    experienceDates: {
      ...state.experienceDates,
      [action.experienceId]: {
        ...state.experienceDates[action.experienceId],
        [action.key]: {
          ...state.experienceDates[action.experienceId]?.[action.key],
          fetching: false,
          error: action.error,
        },
      },
    },
  }),
  [FETCH_EXPERIENCE_TIMESLOTS]: (state, action) => ({
    experienceTimes: {
      ...state.experienceTimes,
      [action.experienceId]: {
        ...state.experienceTimes[action.experienceId],
        [action.key]: {
          ...state.experienceTimes[action.experienceId]?.[action.key],
          fetching: false,
          error: action.error,
        },
      },
    },
  }),
  [FETCH_EXPERIENCE]: (state, action) => ({
    fetchingExperiences: {
      ...state.fetchingExperiences,
      [action.id]: false,
    },
    experienceErrors: {
      ...state.experienceErrors,
      [action.id]: action.error,
    },
  }),
  [FETCH_BOOKING_DETAILS]: (state, action) => ({
    fetchingBookingDetails: {
      ...state.fetchingBookingDetails,
      ...arrayToObject((action.ids as Array<string>), id => id, () => false),
    },
    bookingDetailsErrors: {
      ...state.bookingDetailsErrors,
      ...arrayToObject((action.ids as Array<string>), id => id, () => action.error),
    },
  }),
  [FETCH_REFUND_DETAILS]: (state, action) => ({
    fetchingRefundDetails: {
      ...state.fetchingRefundDetails,
      ...arrayToObject((action.ids as Array<string>), id => id, () => false),
    },
    refundDetailsErrors: {
      ...state.refundDetailsErrors,
      ...arrayToObject((action.ids as Array<string>), id => id, () => action.error),
    },
  }),
  [MARK_EXPERIENCE_BOOKING_DETAILS_AS_REDEEMED]: (state, action) => ({
    markingBookingDetailsAsRedeemed: {
      ...state.markingBookingDetailsAsRedeemed,
      [action.experienceItemId]: false,
    },
  }),
  [FETCH_EXPERIENCES]: (state, action) => ({
    fetchingExperiences: {
      ...state.fetchingExperiences,
      ...arrayToObject((action.ids as Array<string>), id => id, () => false),
    },
    fetchingErrors: {
      ...state.experienceErrors,
      ...arrayToObject((action.ids as Array<string>), id => id, () => action.error),
    },
  }),
  [FETCH_EXPERIENCE_VOUCHER]: (state, action) => ({
    experienceVoucher: {
      ...state.experienceVoucher,
      [action.experienceItemId]: {
        fetching: false,
        error: action.error,
      },
    },
  }),
  [FETCH_REGION_HEROES]: (state, action) => ({
    fetchingRegionHeroes: false,
    experienceRegionHeroesError: action.error,
  }),
  [FETCH_EXPERIENCE_CATEGORIES]: (state, action) => ({
    fetchingCategories: false,
    experienceCategoriesError: action.error,
  }),
  [FETCH_TRANSFER_OPTIONS]: (state, action) => ({
    experienceTransferOptions: {
      ...state.experienceTransferOptions,
      [action.experienceId]: {
        fetching: false,
        error: action.error,
      },
    },
  }),
})

export default createReducer<App.ExperienceState>(initialExperienceState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [EXPERIENCE_VIEWED]: (state, action) => ({
    recentlyViewedExperienceIds: take(
      unique([action.id, ...state.recentlyViewedExperienceIds]),
      5,
    ),
  }),
  [SET_EXPERIENCE_PLACE]: (state, action) => ({
    experiencePlace: {
      placeId: action.placeId,
      type: action.placeType,
      userSelected: action.userSelected,
      saved: action.save,
    },
  }),
  [CHECKOUT_PROCESS_SUCCESS]: () => ({
    experienceTimes: {},
    experienceDates: {},
  }),
  [SET_CANCELLING_EXPERIENCES]: (state, action) => ({
    cancellingExperiences: action.experiences,
  }),
})
