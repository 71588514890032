import React, { ComponentProps, PropsWithChildren, forwardRef } from 'react'
import _FloatingDropdown from '../_FloatingDropdown'
import DropdownListSheet from './DropdownListSheet'

type InheritedSheetProps = Pick<ComponentProps<typeof DropdownListSheet>, 'size'>
type InheritedFloatingDropdownProps = Pick<ComponentProps<typeof _FloatingDropdown>, 'placement' | 'crossAxisShift' | 'anchorRef' | 'anchorOffset' | 'boundaryRef' | 'triggerRef' | 'open' | 'portaled' | 'onClose' | 'id' | 'data-testid'>

interface Props extends PropsWithChildren, InheritedSheetProps, InheritedFloatingDropdownProps {
  enableFlip?: boolean
}

const DropdownListBase = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    children,
    size,
    placement,
    enableFlip,
    crossAxisShift,
    open,
    anchorRef,
    anchorOffset,
    boundaryRef,
    triggerRef,
    'data-testid': dataTestId,
    id,
    onClose,
    portaled,
  } = props

  return <_FloatingDropdown
    ref={ref}
    width={size === 'fill-anchor' ? 'match-anchor' : 'auto'}
    placement={placement}
    anchorRef={anchorRef}
    anchorOffset={anchorOffset}
    boundaryRef={boundaryRef}
    triggerRef={triggerRef}
    open={open}
    disableFlip={!enableFlip}
    crossAxisShift={crossAxisShift}
    data-testid={dataTestId}
    id={id}
    onClose={onClose}
    portaled={portaled}
  >
    <DropdownListSheet size={size}>
      {children}
    </DropdownListSheet>
  </_FloatingDropdown>
})

DropdownListBase.displayName = 'DropdownListBase'

export default DropdownListBase
