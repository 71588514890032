import moment from 'moment/moment'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import qs from 'qs'
import { getInflowFlightsCheckoutPath } from 'lib/url/pathsUtils'
import { last } from 'lib/array/arrayUtils'
import { FlightsFareTypes } from 'constants/flight'

export function getCheckoutFlightV2SearchUrl(
  journeys: Array<App.JourneyV2>,
): string {
  if (!journeys[0] && !journeys[1]) {
    return getInflowFlightsCheckoutPath()
  }

  const queryParams = {
    departDate: journeys[0]?.flightGroup.flights[0].departingDate,
    returnDate: journeys[1]?.flightGroup.flights[0].departingDate,
    originAirportCode: journeys[0]?.flightGroup.flights[0].departureAirport,
    destinationAirportCode: journeys[1]?.flightGroup.flights[0].departureAirport,
  }

  return [getInflowFlightsCheckoutPath(), qs.stringify(queryParams)].join('?')
}

export function getStandaloneFlightsV2SearchUrl(
  item: App.Checkout.FlightItem,
  airports: Array<App.Airport>,
  journeys: Record<string, App.JourneyV2> = {},
): string {
  if (!item.originAirportCode && !item.destinationAirportCode && !item.travelStart && !item.travelEnd) {
    return '/flights'
  }

  let originAirportCode = item.originAirportCode
  let originAirportName = airports.find((airport) => airport.code === item.originAirportCode)?.name
  let destinationAirportCode = item.destinationAirportCode
  let destinationAirportName = airports.find((airport) => airport.code === item.destinationAirportCode)?.name

  const departing = journeys[item.flights[0].journeyId]
  const returning = item.flights[1] ? journeys[item.flights[1].journeyId] : undefined

  // pick from the departing journey if available
  if (departing) {
    originAirportCode = departing?.flightGroup.flights[0].departureAirport
    originAirportName = departing?.flightGroup.flights[0].departureAirportName
    destinationAirportCode = last(departing?.flightGroup.flights ?? [])?.arrivalAirport
    destinationAirportName = last(departing?.flightGroup.flights ?? [])?.arrivalAirportName
  }

  const numberOfAdults = item.occupants.adults
  const numberOfChild = item.occupants.children
  const numberOfInfant = item.occupants.infants

  const queryParams = {
    originAirportCode,
    originAirportName,
    destinationAirportCode,
    destinationAirportName,
    departDate: moment(departing?.flightGroup.flights[0].departingDate || item.travelStart).format(ISO_DATE_FORMAT),
    returnDate: returning || item.travelEnd ? moment(returning?.flightGroup?.flights[0].departingDate || item.travelEnd).format(ISO_DATE_FORMAT) : null,
    adults: numberOfAdults,
    children: numberOfChild,
    infants: numberOfInfant,
    fareClass: 'economy',
    fareType: item.fareType,
  }

  if (item.fareType === FlightsFareTypes.MULTI_CITY) {
    const flightsWithJourneys = item.flights.filter(flight => journeys[flight.journeyId])

    if (!flightsWithJourneys.length) {
      return '/flights'
    }

    const flights = flightsWithJourneys.map(flight => {
      const journey = journeys[flight.journeyId]
      const departingFlight = journey.flightGroup.flights[0]
      const arrivalFlight = last(journey.flightGroup.flights || [])

      return {
        originAirportCode: departingFlight.departureAirport,
        originAirportName: departingFlight.departureAirportName,
        destinationAirportCode: arrivalFlight.arrivalAirport,
        destinationAirportName: arrivalFlight.arrivalAirportName,
        departDate: departingFlight.departingDate,
      }
    })

    return `/flights-search-results-multi-city?${qs.stringify({ ...queryParams, flights })}`
  }

  return `/flights-search-results?${qs.stringify(queryParams)}`
}
