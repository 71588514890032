import React, { useMemo } from 'react'
import moment from 'moment'
import OfferLabels from 'components/Common/Labels/OfferLabels'
import SearchTileCancellationPolicy from 'components/SearchV2/SearchTileCancellationPolicy/SearchTileCancellationPolicy'
import Group from 'components/utils/Group'
import TileInclusions from '../../Support/OfferTileInclusions'
import { connect } from 'react-redux'
import { useOfferInclusions } from 'hooks/useOfferInclusions'
import { useTacticalUpsellBanner } from 'hooks/useTacticalUpsellBanner'
import LuxPlusInclusions from 'luxPlus/components/LuxPlusInclusions'
import { OFFER_TYPE_HOTEL, OFFER_TYPE_LAST_MINUTE } from 'constants/offer'
import styled from 'styled-components'
import OfferTilePricingUrgencyTags from '../../Support/OfferTilePricingUrgencyTags'
import { mediaQueryUp } from 'components/utils/breakpoint'
import config from 'constants/config'
import { mapInclusionsToTileInclusions } from 'lib/offer/inclusionUtils'
import { useSearchPrices } from 'hooks/Search/useSearchPrices'
import { isLpcHotel } from 'lib/offer/offerTypes'
import { mapHotelPackageToTileInclusions } from 'lib/homesAndVillas/getAmenityPriority'
import { take } from 'lib/array/arrayUtils'
import { isCustomerSupportMode } from 'selectors/featuresSelectors'

const NUMBER_OF_LPC_AMENITIES = 7

const MaxMobileGroup = styled(Group)`
  ${mediaQueryUp.tablet} {
    display: none;
  }
`

const StyledMaxWidthGroup = styled(Group)`
  width: 100%;
`

interface MappedStateProps {
  windowSearch: string;
  isCustomerSupportMode: boolean;
}

interface Props {
  location: string;
  cancellationPolicyType: string;
  offer: App.OfferSummary;
  checkInDate: moment.Moment | undefined;
  filters: App.OfferListFilters | undefined;
  offerUrl: string;
  bestPricePackage?: App.Package;
  showCompact?: boolean;
  maxInclusions?: number;
  maxInclusionLines?: number;
  bestPriceForDates?: App.OfferAvailableRate;
  showInclusionUpsell?: boolean;
}

function SearchTilePropertyDetails(props: Props & MappedStateProps) {
  const {
    cancellationPolicyType,
    offer,
    checkInDate,
    offerUrl,
    filters,
    bestPricePackage,
    showCompact = false,
    maxInclusions,
    maxInclusionLines,
    bestPriceForDates,
    showInclusionUpsell = false,
    isCustomerSupportMode,
  } = props

  const {
    tileInclusions,
    tileInclusionsHeading,
    tileDescription,
    luxPlusTileInclusions,
    type,
  } = offer

  let inclusionsHeading: string

  const offerTileInclusions = useMemo(() => {
    if (tileInclusions?.length) {
      return tileInclusions
    }
    const pkgWithInclusions = offer.packages.find((p) => p.inclusions?.length)

    return pkgWithInclusions?.inclusions?.filter((inclusion) => inclusion.displayContext === 'Rate')
  }, [offer.packages, tileInclusions])

  const lpcOfferTileAmenities = useMemo<Array<App.TileInclusion>>(() => {
    if (!offer || !isLpcHotel(offer) || offerTileInclusions?.length) {
      return []
    }

    return take(mapHotelPackageToTileInclusions(offer.packages), NUMBER_OF_LPC_AMENITIES)
  }, [offerTileInclusions, offer])

  if (type === OFFER_TYPE_LAST_MINUTE) {
    inclusionsHeading = tileInclusionsHeading ?? 'Exclusive extras:'
  } else if (!tileInclusions?.length && offerTileInclusions?.length) {
    inclusionsHeading = `${config.title} inclusions: (select packages only):`
  } else if (!tileInclusions?.length && !offerTileInclusions?.length && lpcOfferTileAmenities.length) {
    inclusionsHeading = 'Enjoy these top amenities (select packages only):'
  } else {
    inclusionsHeading = tileInclusionsHeading ?? 'Handpicked inclusions:'
  }

  const shouldShowLPCTacticalUpsell = useTacticalUpsellBanner(offer, bestPricePackage, filters)
  const showLPCTacticalLabel = offer?.hasTactical && bestPricePackage?.hasTactical && !shouldShowLPCTacticalUpsell
  if (showLPCTacticalLabel) {
    inclusionsHeading = 'Your handpicked inclusions:'
  }

  const checkIn = filters?.checkIn ? moment(filters.checkIn) : undefined
  const checkOut = filters?.checkOut ? moment(filters.checkOut) : undefined
  const { inclusions: commonInclusions, bonusInclusions, luxPlusInclusions } = useOfferInclusions(bestPricePackage, { checkIn, checkOut })

  const hasLuxPlusTileInclusions = !!luxPlusTileInclusions?.length
  const showLuxPlusInclusions = hasLuxPlusTileInclusions || !!luxPlusInclusions?.length
  const showMemberInclusionLabel = !!offer.luxPlus.hasMemberInclusions && !showLuxPlusInclusions

  const allBonusInclusions = useMemo(() => mapInclusionsToTileInclusions([...commonInclusions, ...bonusInclusions]), [bonusInclusions, commonInclusions])
  const showBonusInclusions = showLPCTacticalLabel && allBonusInclusions.length > 0

  const shouldShowInclusions =
  type === OFFER_TYPE_HOTEL || !!lpcOfferTileAmenities.length ||
    (!!offerTileInclusions &&
    !!offerTileInclusions?.length &&
    !showInclusionUpsell)

  let inclusionsCount = 5

  if (maxInclusions) {
    inclusionsCount = maxInclusions
  }

  const { totalPrice, totalMemberPrice, showMemberPrice } = useSearchPrices({
    offer,
    pkg: bestPricePackage,
    rate: bestPriceForDates,
    filters,
  })

  const saveAmount = totalPrice - (totalMemberPrice || 0)

  const showLpcAmenities = !!(!offerTileInclusions?.length && lpcOfferTileAmenities.length)

  return (
    <Group direction="vertical" gap={showLuxPlusInclusions ? 16 : 8}>
      <Group direction="vertical" gap={4}>
        <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="start">
          <StyledMaxWidthGroup direction="vertical" >
            <Group direction="horizontal" gap={4} verticalAlign="center" wrap="wrap">
              {!isCustomerSupportMode && <OfferLabels
                offer={offer}
                saveAmount={showMemberPrice ? saveAmount : undefined}
                showMemberInclusionLabels={showMemberInclusionLabel}
                luxPlusTileInclusions={luxPlusTileInclusions}
              />}
              <MaxMobileGroup direction="horizontal" gap={4} verticalAlign="center">
                <OfferTilePricingUrgencyTags offer={offer} filters={filters} />
              </MaxMobileGroup>
            </Group>
          </StyledMaxWidthGroup>
        </Group>
        {!showCompact && <SearchTileCancellationPolicy
          cancellationPolicyType={cancellationPolicyType}
          checkInDate={checkInDate}
          timezoneOffset={offer?.property?.timezoneOffset}
          offerType={offer.type}
        />}
      </Group>
      <Group direction="vertical" gap={showLuxPlusInclusions ? 16 : 8}>
        {showLuxPlusInclusions && <LuxPlusInclusions
          inclusions={luxPlusInclusions}
          tileInclusions={luxPlusTileInclusions}
          accommodationOffer={offer}
          hideUpsellModal={!!luxPlusTileInclusions?.length}
        />}
        {(!showBonusInclusions &&
          (!!offerTileInclusions?.length || !!tileDescription || !!lpcOfferTileAmenities.length) &&
          shouldShowInclusions) && (
            <TileInclusions
              offerTitle={offer.property?.name ?? offer.name}
              offerURL={offerUrl}
              content={tileDescription}
              tileInclusionsList={!showLpcAmenities ? offerTileInclusions : lpcOfferTileAmenities}
              heading={(showCompact || showLuxPlusInclusions) ? '' : inclusionsHeading}
              inclusionsModalHeading={showLpcAmenities ? inclusionsHeading : undefined}
              viewMoreText={showLpcAmenities ? 'amenity' : undefined}
              maxInclusionsCount={inclusionsCount}
              maxLineCount={maxInclusionLines}
              filters={filters}
            />
        )}
        {showBonusInclusions && (
          <TileInclusions
            offerTitle={offer.property?.name ?? offer.name}
            offerURL={offerUrl}
            tileInclusionsList={allBonusInclusions}
            heading={(showCompact || showLuxPlusInclusions) ? '' : inclusionsHeading}
            maxInclusionsCount={inclusionsCount}
            maxLineCount={maxInclusionLines}
          />
        )}
      </Group>
    </Group>
  )
}

export default connect<MappedStateProps, undefined, Props, App.State>(
  (state): MappedStateProps => ({
    windowSearch: state.router.location.search,
    isCustomerSupportMode: isCustomerSupportMode(state),
  }),
)(SearchTilePropertyDetails)
