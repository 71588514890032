import { optimizelyIsReady, userContext } from 'analytics/optimizely/optimizely'
import createSelector from 'lib/web/createSelector'
import { reportClientError } from 'services/errorReportingService'
import config from 'constants/config'
import { setCookie } from 'lib/web/cookieUtils'
import moment from 'moment'
import { OptimizelyDecideOption } from '@optimizely/optimizely-sdk'
import { OptimizelyExperiments, OptimizelyFeatureFlags } from 'constants/optimizely'

export function setOptimizelyTriggeredCookie(flagKey: OptimizelyExperiments) {
  const expirationTimestamp = moment().add(2, 'months').toDate().getTime()
  const currentDateTime = moment().format('YYYY-MM-DD:HH-mm')
  setCookie(`${flagKey}_triggered`, currentDateTime, expirationTimestamp, '/', 'Lax')
}

/**
 * This is the only function that will put users into the report for your AB test.
 * We need to label our control group in Optimizely as 'control' as this means Optimizely will
 * return the 'enabled' value as true for both control and variant which we can use to determine if
 * a user has triggered the experiment report.  We then use our own 'enabled'
 * key to return true 'only' if a user is in the variant. This function should only be called by it's Action.
 */
export async function activateOptimizelyExperiment(flagKey: OptimizelyExperiments | OptimizelyFeatureFlags) {
  // This function shouldn't be called on server
  if (typeof window === 'undefined') {
    return
  }

  const success = await optimizelyIsReady

  if (success) {
    try {
      // DISABLE_DECISION_EVENT means that getting this decision won't trigger the user into the report.
      // We rely on a snowplow event sent from the action to trigger the user into the report.
      const decision = userContext.decideForKeys([flagKey], [OptimizelyDecideOption.DISABLE_DECISION_EVENT])[flagKey]

      const experimentValues = {
        variationKey: decision.variationKey,
        enabled: getOptimizelyEnabledDecision(decision.variationKey),
        triggered: !!decision.enabled,
        variables: decision.variables,
      }

      return experimentValues
    } catch (e) {
      reportClientError(e, { flagKey }) }
  }
}

export function isFeatureFlagExperiment(experimentId: OptimizelyExperiments | OptimizelyFeatureFlags): experimentId is OptimizelyFeatureFlags {
  return typeof experimentId === 'string' && Object.values(OptimizelyFeatureFlags).includes(experimentId as OptimizelyFeatureFlags)
}

export const getOptimizelyExperimentVariation = createSelector(
  (_: App.State, experimentId: OptimizelyExperiments) => experimentId,
  (state: App.State, experimentId: OptimizelyExperiments) => state.optimizely.optimizelyExperiments[experimentId],
  (experimentId, experiment): string | undefined => {
    // For devs to set a specific variation locally. Set REACT_APP_OPTIMIZELY_[experimentId]=true for variant or false for control in .env,
    if (config[`OPTIMIZELY_${experimentId}`] !== undefined) { return config[`OPTIMIZELY_${experimentId}`] }
    if (!experiment?.enabled) {
      return
    }

    if (experiment.enabled && Object.keys(experiment.variables).length) {
      return experiment.variables[experiment.variationKey]
    }

    return experiment.variationKey
  },
)

export function getOptimizelyFeatureFlagVariation(state: App.State, experimentId: OptimizelyFeatureFlags) {
  return !!state.optimizely.optimizelyFeatureFlags[experimentId]
}

export function getOptimizelyEnabledDecision(experimentVariationKey: string | null): boolean {
  return !!(experimentVariationKey && (experimentVariationKey !== 'off' && experimentVariationKey !== 'control'))
}
