import { CRUISE_CHECKOUT_INFLOW_V2 } from 'constants/checkout'
import { getCheckoutRouteFromURL } from 'checkout/lib/utils/cruises/steps'
import { CheckoutPageId } from 'checkout/constants/pages'

export const CRUISE_CHECKOUT_STEPS_V2: Array<App.Checkout.CheckoutStep> = [
  {
    label: 'Passengers',
    key: CRUISE_CHECKOUT_INFLOW_V2.TRAVELERS,
    subSteps: [],
    isRoute: false,
    number: 1,
  },
  {
    label: 'Type & Category',
    key: 'cabin-type-category',
    subSteps: [
      {
        label: 'Cabin type',
        key: CRUISE_CHECKOUT_INFLOW_V2.CABIN_TYPE,
        isRoute: false,
        number: 2,
      },
      {
        label: 'Cabin category',
        key: CRUISE_CHECKOUT_INFLOW_V2.CABIN_CATEGORY,
        isRoute: false,
        number: 2,
      },
    ],
    isRoute: false,
    number: 2,
  },
  {
    label: 'Location',
    key: CRUISE_CHECKOUT_INFLOW_V2.CABIN_SELECTION,
    isRoute: false,
    subSteps: [],
    number: 3,
  },
  {
    label: 'Package',
    key: CRUISE_CHECKOUT_INFLOW_V2.RATE_SELECTION,
    isRoute: false,
    subSteps: [],
    number: 4,
  },
  {
    label: 'Review & Payment',
    key: 'purchase',
    subSteps: [],
    isRoute: true,
    number: 5,
  },
]

export function getCruiseCurrentStep(inFlowCheckoutStep: string | undefined, pathname: string): string | undefined {
  const checkoutRouteStepName = getCheckoutRouteFromURL(pathname) as CheckoutPageId

  return CheckoutPageId.CruiseV2 === checkoutRouteStepName ? inFlowCheckoutStep : checkoutRouteStepName
}
