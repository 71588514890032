import { getAccommodationDepositSettings, isDepositPercentageDefault } from 'checkout/lib/utils/accommodation/deposit'
import { computeCruiseDepositAmount, computeDepositAmount } from 'checkout/lib/utils/payments/deposit'
import { getCruiseDeposits, getCruiseBalanceDueDate } from 'checkout/lib/utils/cruises/booking'
import { getCreditPayAmount, getDiscountTotal, getMerchantFeeAmount, getPayableTotal } from 'checkout/selectors/payment/checkout'
import createSelector from 'lib/web/createSelector'
import { checkoutAccommodationOfferView } from '../view/accommodation'
import { getAccommodationPayable } from './accommodation'
import { arrayToMap, min, nonNullable } from 'lib/array/arrayUtils'
import {
  getTourV2IdForCheckoutItem,
  isAccommodationItem,
  isBNBLLEHotelItem,
  isCruiseItem,
  isInstantBookingHotelItem,
  isInstantBookingLEHotelItem,
  isLEHotelItem,
  isTourV1Item,
  isTourV2Item,
} from 'lib/checkout/checkoutUtils'
import { dateDifference, subDays } from 'lib/datetime/dateUtils'
import { OFFER_TYPE_ALWAYS_ON } from 'constants/offer'
import {
  getAlwaysOnCancellationDays,
  getDepositMinimumValueForCurrencyCode,
  getTourV2DepositMinimumValueForCurrencyCode,
  isDepositsEnabled,
} from 'lib/payment/depositsUtils'
import { CHECKOUT_DUE_DATE_DAY_THRESHOLD } from 'constants/checkout'
import { TOUR_V2_OFFER_DUE_DATE_DAY_THRESHOLD } from 'constants/tours'
import { isStandaloneFlights } from 'checkout/selectors/view/flights'
import { getBookingCabinPricingData, getCruiseMultiCabinBookingEnabled, getOverallConsolidatedPaymentSchedule } from 'checkout/selectors/cruiseSelectors'
import config from 'constants/config'
import { getItemUniqueKey } from 'checkout/lib/utils/accommodation/cart'
import { getDefaultDepositAmountPercentage } from '../featureConfig/deposit'
import { cartIncludesFlights } from '../view/flights'

import { buildAvailableRateKey } from 'lib/offer/availabilityUtils'
import { PAYMENT_OPTIONS } from 'constants/payment'
import { getOptimizelyExperimentVariation } from 'lib/optimizely/optimizelyUtils'
import { OptimizelyExperiments } from 'constants/optimizely'
import { getCreditBalanceForCheckoutCurrency } from './generic'
import { isSpoofed } from 'selectors/featuresSelectors'
import { floatify } from 'lib/maths/mathUtils'

export const getDepositSettings = createSelector(
  checkoutAccommodationOfferView,
  getDefaultDepositAmountPercentage,
  (viewsWithStatus, defaultDepositAmountPercentage): App.Checkout.DepositSettings | undefined => {
    if (viewsWithStatus.hasRequiredData && viewsWithStatus.data.length > 0) {
      const offerView = viewsWithStatus.data[0]
      return getAccommodationDepositSettings(offerView, defaultDepositAmountPercentage)
    }
  },
)

export const getAccommodationDepositAmount = createSelector(
  getAccommodationPayable,
  getDepositSettings,
  checkoutAccommodationOfferView,
  (accommodationPayable, depositSettings, viewWithStatus): number => {
    const isCruise = viewWithStatus?.data[0]?.offerType === 'cruise'
    return isCruise ?
      computeCruiseDepositAmount(accommodationPayable, depositSettings) :
      computeDepositAmount(accommodationPayable, depositSettings)
  },
)

const getDepositAmountBeforeCreditApplied = createSelector(
  getPayableTotal,
  getAccommodationPayable,
  getAccommodationDepositAmount,
  (payableTotal, accommodationPayable, depositAmount): number => {
    return floatify(payableTotal - accommodationPayable + depositAmount)
  },
)

export const getDepositRemaining = createSelector(
  getPayableTotal,
  getDiscountTotal,
  getDepositAmountBeforeCreditApplied,
  getCreditPayAmount,
  (state: App.State) => state.checkout.payment.rebookingID,
  (total, discount, deposit, credit, rebookingID): number => {
    let depositRemaining = total - discount - deposit
    if (!rebookingID) {
      depositRemaining -= credit
    }
    return floatify(depositRemaining)
  },
)

export const getServiceFee = createSelector(
  getDepositRemaining,
  getDepositSettings,
  (remaining, depositSettings): number => {
    if (depositSettings?.serviceFee) {
      const { isEnabled, percentage } = depositSettings.serviceFee
      return isEnabled ? floatify(remaining * percentage / 100) : 0
    }
    return 0
  },
)

/**
 * @remarks
 * We need this selector for the rebooking flow and cannot use getDepositAmountBeforeCreditApplied
 * because it is needed to calculate service fee. This would create a circular dependency if we
 * added service fee to it.
 */
export const getDepositAmountBeforeCreditAppliedIncludingSvcFee = createSelector(
  getDepositAmountBeforeCreditApplied,
  getServiceFee,
  (depositAmount, serviceFee): number => floatify(depositAmount + serviceFee),
)

export const getDepositAmount = createSelector(
  getDepositAmountBeforeCreditApplied,
  getServiceFee,
  isSpoofed,
  (state: App.State) => state.checkout.payment.rebookingID,
  (state: App.State) => state.checkout.payment.useCredit,
  getCreditBalanceForCheckoutCurrency,
  (state: App.State) => getMerchantFeeAmount(state, PAYMENT_OPTIONS.DEPOSIT),
  (depositAmountBeforeCreditApplied, serviceFee, isSpoofed, rebookingID, useLECredits, creditBalance, merchantFee): number => {
    let depositTotal = depositAmountBeforeCreditApplied + serviceFee + merchantFee

    if (isSpoofed && rebookingID && useLECredits) {
      depositTotal = creditBalance < depositTotal ? depositTotal - creditBalance : 0
    }

    return floatify(depositTotal)
  },
)

export const getDepositBalance = createSelector(
  getDepositRemaining,
  (remaining): number => remaining,
)

/**
 * @remarks
 * We need this selector for a few functions within this file but cannot use getAccommodationItems from /checkout/selectors/view/accommodation.ts
 * because this would create a circular import dependency. This is because accommodation.ts requires getDepositAmountPercentage from this file.
 * We have the same problem in hotels.ts, so be sure to update all 3 functions. Looking to implement a better, long term solution soon.
 */
const getAccommodationItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.AccommodationItem> => items.filter(isAccommodationItem),

)

const getItemsDepositInfo = createSelector(
  getAccommodationItems,
  (state: App.State) => state.offer.offers,
  getBookingCabinPricingData,
  (state: App.State) => state.offer.offerAvailableRates,
  (state: App.State) => state.offer.tourV2Offers,
  (state: App.State) => state.checkout.cart.isGift,
  isSpoofed,
  (accommodationItems, offers, cabinPricingData, offersAvailableRates, tourV2Offers, isGift, isSpoofed): Array<App.Checkout.ItemDepositInfo> => {
    return accommodationItems
      .map((item) => {
        // Gift items should never be Deposit orders
        if (isGift) {
          return depositDisabled(item)
        }

        if (isTourV1Item(item)) {
          const offer = offers[item.offerId]
          return getTourDepositInfo(item, offer)
        }
        if (isTourV2Item(item)) {
          const offer = tourV2Offers[getTourV2IdForCheckoutItem(item)]
          return offer ? getTourV2DepositInfo(item, offer) : depositDisabled(item)
        }

        if (isCruiseItem(item) && cabinPricingData.hasCabinPricing) {
          const paymentSchedule = getCruiseDeposits(cabinPricingData)
          if (paymentSchedule?.schedule.finalPayment) return getCruiseDepositInfo(item)
        }

        if (!isLEHotelItem(item)) {
          // bedbank items
          return depositDisabled(item)
        }

        // Disable deposits if specified at an offer level
        if (offers[item.offerId]?.disableDeposit) {
          return depositDisabled(item)
        }

        if (isInstantBookingLEHotelItem(item)) {
          const offer = offers[item.offerId]
          const availableRatesKey = buildAvailableRateKey(item.checkIn, item.checkOut, [item.occupancy])
          // all available room rates, using only if flag useDynamicCancellationPolicies is true
          const offerAvailableRates = offersAvailableRates?.[item.offerId]?.[availableRatesKey]
          return offer ? getLeHotelDepositInfo(item, offer, offerAvailableRates) : depositDisabled(item)
        }

        if (isBNBLLEHotelItem(item) && !config.BNBL_DEPOSITS_ENABLED && !isSpoofed) {
          return depositDisabled(item)
        }

        // multi-room should be disabled for BNBL for deposits
        if (accommodationItems.filter(item => isBNBLLEHotelItem(item)).length > 1) {
          return depositDisabled(item)
        }

        // BNBL items, have no dates
        return {
          itemId: item.itemId,
        }
      })
  },
)

function getTourDepositInfo(
  item: App.Checkout.TourItem,
  offer?: App.Offer | Tours.TourV2Offer,
): App.Checkout.ItemDepositInfo {
  const offerDueDate = offer?.depositThresholds?.numberOfDays || TOUR_V2_OFFER_DUE_DATE_DAY_THRESHOLD
  return {
    itemId: item.itemId,
    dueDaysLeft: offerDueDate,
    depositDaysLeft: offerDueDate,
  }
}

function getTourV2DepositInfo(
  item: App.Checkout.TourItem,
  offer: Tours.TourV2Offer,
): App.Checkout.ItemDepositInfo {
  return offer.depositType === 'noDeposit' ? depositDisabled(item) : getTourDepositInfo(item, offer)
}

function getCruiseDepositInfo(
  item: App.Checkout.CruiseItem,
): App.Checkout.ItemDepositInfo {
  return {
    itemId: item.itemId,
    dueDaysLeft: 18,
    depositDaysLeft: 18,
  }
}

function getLeHotelDepositInfo(
  item: App.Checkout.InstantBookingLEHotelItem | App.Checkout.BedbankHotelItem,
  offer: App.Offer,
  offerAvailableRates?: App.OfferAvailableRates,
): App.Checkout.ItemDepositInfo {
  if (offer.depositThresholds?.numberOfDays) {
    return {
      itemId: item.itemId,
      dueDaysLeft: offer.depositThresholds.numberOfDays,
      depositDaysLeft: offer.depositThresholds.numberOfDays,
    }
  }

  if (offer.type == OFFER_TYPE_ALWAYS_ON) {
    const pkg = offer.packages.find(pkg => pkg.uniqueKey == getItemUniqueKey(item))
    const useDynamicCancellationPolicies = offer.property?.useDynamicCancellationPolicies ?? false
    const cancellationPolicies = pkg && useDynamicCancellationPolicies ? offerAvailableRates?.rates?.find(rate => rate.packageUniqueKey === pkg.uniqueKey)?.cancellationPolicies : undefined
    const cancellationPolicyDays = pkg ? getAlwaysOnCancellationDays(item.checkIn, pkg, cancellationPolicies) : null
    return cancellationPolicyDays ? {
      itemId: item.itemId,
      dueDaysLeft: cancellationPolicyDays + 15,
      depositDaysLeft: cancellationPolicyDays + 16,
    } : depositDisabled(item)
  }

  return {
    itemId: item.itemId,
    dueDaysLeft: CHECKOUT_DUE_DATE_DAY_THRESHOLD,
    depositDaysLeft: CHECKOUT_DUE_DATE_DAY_THRESHOLD,
  }
}

function depositDisabled(item: App.Checkout.AnyItem): App.Checkout.ItemDepositInfo {
  return {
    itemId: item.itemId,
    depositDisabled: true,
  }
}

export const getDepositDueDaysLeft = createSelector(
  getItemsDepositInfo,
  (itemDepositInfo): number => {
    return Math.max(...(nonNullable(Object.values(itemDepositInfo).map(deposit => deposit.dueDaysLeft))))
  },
)

export const getDepositDueDate = createSelector(
  getAccommodationItems,
  getItemsDepositInfo,
  getBookingCabinPricingData,
  getOverallConsolidatedPaymentSchedule,
  getCruiseMultiCabinBookingEnabled,
  isSpoofed,
  (accommodationItems, itemsDepositInfo, cabinPricingData, consolidatedPaymentSchedule, isCruiseMultiCabinBookingEnabled, isSpoofed): Date | undefined => {
    /**
     * The deposit/balance calculations (dates and amounts) are performed in svc-cruise,
     * however there is no reason for FE to perform new calculations. For cruises,
     * we only need to render what the API gives us. If the deposit values
     * returned by the API do not exist, we must disable the deposit
     *
     * NOTE: the selector name is misleading, as it is not deposit due date, but final payment due date
    */
    if (accommodationItems.every(item => isCruiseItem(item))) {
      return getCruiseBalanceDueDate(isCruiseMultiCabinBookingEnabled, consolidatedPaymentSchedule, cabinPricingData)
    }

    // BNBLs have no deposit due date as dates are not selected yet
    if ((config.BNBL_DEPOSITS_ENABLED || isSpoofed) && accommodationItems.some(item => isBNBLLEHotelItem(item))) {
      // beginning of epoch as placeholder and will be used to signal BNBL deposit order
      return new Date(0)
    }

    const depositInfoMapping = arrayToMap(itemsDepositInfo, (item) => item.itemId)
    const dueDates = nonNullable(accommodationItems
      .filter(isInstantBookingHotelItem)
      .map(item => {
        const depositDueDaysLeft = depositInfoMapping.get(item.itemId)?.dueDaysLeft
        const startDate = getStartDate(item)
        return (depositDueDaysLeft && startDate) ? subDays(startDate, depositDueDaysLeft) : undefined
      }))
    return min(dueDates)
  },
)

export const isBeforeDepositStartDate = createSelector(
  getAccommodationItems,
  getItemsDepositInfo,
  (accommodationItems, itemsDepositInfo): boolean => {
    const depositInfoMapping = arrayToMap(itemsDepositInfo, (item) => item.itemId)
    const eligibleItems = accommodationItems.filter(isInstantBookingHotelItem)

    return eligibleItems.length > 0 &&
      eligibleItems.every(item => {
        const depositDaysLeft = depositInfoMapping.get(item.itemId)?.depositDaysLeft
        const startDate = getStartDate(item)
        if (!startDate || !depositDaysLeft) return false

        return dateDifference(startDate).days > depositDaysLeft
      })
  },
)

export function getStartDate(item: App.Checkout.InstantBookingItem): Date | undefined {
  const startDate = (isTourV1Item(item) || isTourV2Item(item) || isCruiseItem(item)) ? item.startDate : item.checkIn
  if (startDate) {
    return new Date(startDate)
  }
  return undefined
}

const isDepositEnabled = createSelector(
  (state: App.State) => state.checkout.cart.currencyCode,
  (state: App.State) => state.config.depositConfigs,
  (currency, depositConfigs): boolean => isDepositsEnabled(currency) && !!depositConfigs,
)

const getMinDepositValue = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => state.geo.currentCurrency,
  (items, currency): number => {
    return items.some(isTourV2Item) ?
      getTourV2DepositMinimumValueForCurrencyCode(currency) :
      getDepositMinimumValueForCurrencyCode(currency)
  },
)

const isPriceOverMinDepositValue = createSelector(
  getPayableTotal,
  getMinDepositValue,
  (payableTotal, minDeposit): boolean => payableTotal >= minDeposit,
)

const isDepositAllowedByOrderValue = createSelector(
  getPayableTotal,
  isSpoofed,
  (state: App.State) => !!getOptimizelyExperimentVariation(state, OptimizelyExperiments.paymentsHotelsDepositsAu),
  (state: App.State) => !!getOptimizelyExperimentVariation(state, OptimizelyExperiments.paymentsHotelsDepositsNonAu),
  (state: App.State) => state.checkout.cart.currencyCode,
  (payableTotal, isSpoofed, shouldEnableDepositByExperimentAu, shouldEnableDepositByExperimentNonAu, currencyCode): boolean => {
    if (isSpoofed) {
      return true
    }

    if (config.DEPOSITS_LOW_ORDER_VALUE_ENABLED) {
      return true
    }

    // These three brands share the same inventory and have higher than average order values so are excluded
    // for low deposit threshold which is for white labels which have lower than average order values.
    if (!['luxuryescapes', 'leagenthub', 'lebusinesstraveller'].includes(config.BRAND) && ['AUD', 'NZD'].includes(currencyCode) && payableTotal >= 1998) {
      return true
    }

    if (currencyCode === 'AUD' && payableTotal >= 5000) {
      return true // Allow deposits for orders from 5000 and above in AUD
    }

    if (currencyCode === 'AUD' && payableTotal >= 1999 && payableTotal <= 4999) {
      return shouldEnableDepositByExperimentAu // Decide based on the experiment flag for orders between 1999 and 4999 in AUD
    }

    if (currencyCode !== 'AUD' && payableTotal >= 1999) {
      return shouldEnableDepositByExperimentNonAu // Enable deposits based on the experiment for orders greater than 1999 in non-AUD currencies
    }

    return false // Disallow for orders less than 1999 in all currencies
  },
)

export const shouldTriggerForHotelDepositsHighOrderValueExperimentAu = createSelector(
  getPayableTotal,
  isSpoofed,
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => state.checkout.cart.currencyCode,
  (payableTotal, iSpoofed, items, currencyCode): boolean => {
    if (iSpoofed) {
      return false
    }

    return payableTotal >= 1999 && payableTotal <= 4999 && items.some(isLEHotelItem) && currencyCode === 'AUD'
  },
)

export const shouldTriggerForHotelDepositsHighOrderValueExperimentNonAu = createSelector(
  getPayableTotal,
  isSpoofed,
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => state.checkout.cart.currencyCode,
  (payableTotal, iSpoofed, items, currencyCode): boolean => {
    if (iSpoofed) {
      return false
    }

    return payableTotal >= 1999 && items.some(isLEHotelItem) && currencyCode !== 'AUD'
  },
)

const isAnyItemDepositDisabled = createSelector(
  getItemsDepositInfo,
  (itemsDepositInfo): boolean => itemsDepositInfo.some(item => item.depositDisabled),
)

const isDepositEnabledForFlights = createSelector(
  isStandaloneFlights,
  (isStandaloneFlights): boolean => {
    return isStandaloneFlights ? false : config.FLIGHTS_DEPOSITS_ENABLED
  },
)

export const isDepositAvailable = createSelector(
  isDepositEnabled,
  getDepositRemaining,
  cartIncludesFlights,
  isDepositEnabledForFlights,
  isAnyItemDepositDisabled,
  isPriceOverMinDepositValue,
  isBeforeDepositStartDate,
  isDepositAllowedByOrderValue,
  isSpoofed,
  (state: App.State) => state.checkout.cart.isMultiItemMode,
  (state: App.State) => !!state.checkout.payment.rebookingID,
  (state: App.State) => state.checkout.cart.postPurchase,
  (state: App.State) => state.checkout.cart.items.some(isLEHotelItem),
  (
    isDepositEnabled,
    depositRemaining,
    cartIncludesFlights,
    isDepositEnabledForFlights,
    anyItemDepositDisabled,
    isPriceOverMinDepositValue,
    isBeforeDepositStartDate,
    isDepositAllowedByOrderValue,
    isSpoofed,
    isMultiItemMode,
    rebookingID,
    postPurchase,
    hasLeHotel,
  ): boolean => {
    return (
      isDepositEnabled &&
      depositRemaining > 0 &&
      (cartIncludesFlights ? isDepositEnabledForFlights : true) &&
      !anyItemDepositDisabled &&
      isPriceOverMinDepositValue &&
      isBeforeDepositStartDate &&
      !postPurchase &&
      !isMultiItemMode &&
      (!hasLeHotel || isDepositAllowedByOrderValue)
    ) || (isSpoofed && rebookingID && !isMultiItemMode)
  },
)

export const isOfferUsingDefaultDepositPercentage = createSelector(
  getAccommodationItems,
  (state: App.State) => state.offer.offers,
  (state: App.State) => state.offer.tourV2Offers,
  (accommodationItems, offers, tourV2Offers): boolean => {
    const item = accommodationItems[0]

    if (item) {
      if (isLEHotelItem(item)) {
        if (isInstantBookingLEHotelItem(item)) {
          const offer = offers[item.offerId]
          return isDepositPercentageDefault(offer)
        }
      }

      if (isTourV1Item(item)) {
        const offer = offers[item.offerId]
        return isDepositPercentageDefault(offer)
      }

      if (isTourV2Item(item)) {
        const offer = tourV2Offers[getTourV2IdForCheckoutItem(item)]
        return offer?.depositType === 'default'
      }
    }

    return false
  },
)
