import createSelector from 'lib/web/createSelector'
import { getTourV2Items } from 'checkout/selectors/view/toursv2'
import { sortBy } from 'lib/array/arrayUtils'
import { sumUpOccupancies } from 'lib/offer/occupancyUtils'
import { TOUR_V2_TARGET_OCCUPANT_TYPES } from 'constants/tours'
import generateDepartureRoomPriceBreakdown from 'lib/tours/generateDepartureRoomPriceBreakdown'
import {
  getPackageOptionType,
  getRoomTypeCapacity,
  hasRoomTypeUpgrade,
  formatRoomType, checkPurchasableOptionHasPackageUpgradeOrRoomType,
} from 'lib/tours/tourUtils'
import { EmptyObject, isEmptyObject } from 'lib/object/objectUtils'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { OptimizelyFeatureFlags } from 'constants/optimizely'
import { isLuxPlusAvailableToAddToCart } from './navigationSelectors'
import { getTourV2CheckoutItems } from 'checkout/selectors/view/accommodation'
import { POST_PURCHASE_TOUR_EXPERIENCE } from 'constants/checkout'
import config from 'constants/config'

export const getTourAvailablePackageDetails = createSelector(
  getTourV2Items,
  (state: App.State) => state.offer.tourV2Offers,
  (items, tourOffers) => {
    const item = items[0]
    return tourOffers?.[item?.offerId]?.variations?.[item?.purchasableOption?.fkVariationId]?.availablePackageUpgrades ?? []
  },
)

export const getTourV2Offer = createSelector(
  getTourV2CheckoutItems,
  (state: App.State) => state.offer.tourV2Offers,
  (items, tourOffers): Tours.TourV2Offer => {
    const item = items[0]
    return tourOffers?.[item?.offerId]
  },
)

export const isPostPurchaseTourOptionalExperience = createSelector(
  (state: App.State) => state.checkout.cart.postPurchase,
  (postPurchase) => postPurchase === POST_PURCHASE_TOUR_EXPERIENCE,
)

export const getTourPurchasableAndPriceOptionsByPackage = createSelector(
  getTourV2Items,
  (state: App.State) => state.offer.tourV2Offers,
  (items, tourOffers): Map<string, Array<[Tours.TourV2OfferPurchasableOption, Tours.V2OfferDeparturePriceBreakdown]>> => {
    const purchasablePriceOptionsPackageMap = new Map()
    items.forEach(item => {
      tourOffers?.[item.offerId]?.purchasableOptions
        .filter(
          purchasableOption => purchasableOption.fkDepartureId === item.purchasableOption.fkDepartureId &&
          getRoomTypeCapacity(purchasableOption.roomType!) === sumUpOccupancies([item.occupancy], TOUR_V2_TARGET_OCCUPANT_TYPES),
        )
        .forEach(purchasableOption => {
          const variation = tourOffers?.[item.offerId]?.variations?.[item.purchasableOption.fkVariationId]
          const roomTypePrice = item.purchasableOption.fkRoomTypePricingId ? tourOffers?.[item.offerId]?.roomTypePricing?.[item.purchasableOption.fkRoomTypePricingId] : undefined
          const priceBreakdown = generateDepartureRoomPriceBreakdown(
            item.occupancy,
            purchasableOption,
            roomTypePrice,
            variation,
          )
          const packageOptionType = getPackageOptionType(purchasableOption.roomType!)
          const purchasableOptionsWithPriceBreakDownByOption = purchasablePriceOptionsPackageMap.get(packageOptionType) ?? []
          purchasableOptionsWithPriceBreakDownByOption.push([purchasableOption, priceBreakdown])
          purchasablePriceOptionsPackageMap.set(packageOptionType, purchasableOptionsWithPriceBreakDownByOption)
        })
    })
    purchasablePriceOptionsPackageMap.forEach((purchasableOptionsWithPriceBreakDown: Array<[Tours.TourV2OfferPurchasableOption, Tours.V2OfferDeparturePriceBreakdown]>, packageOption) => {
      purchasablePriceOptionsPackageMap.set(packageOption, sortBy(
        purchasableOptionsWithPriceBreakDown,
        ([purchasableOption, _]) => purchasableOption.price,
        'asc',
      ))
    })
    return purchasablePriceOptionsPackageMap
  },
)

export const getTourV2InventorySummaryOfCartItems = createSelector(
  getTourV2Items,
  (state: App.State) => state.offer.tourV2Offers,
  (items, tourOffers): Map<string, number> => {
    const departurePurchasableOptions = tourOffers?.[items[0]?.offerId]?.purchasableOptions.filter(purchasableOption => {
      return purchasableOption.fkDepartureId === items[0]?.purchasableOption.fkDepartureId // All items will have same departure Id
    })
    const roomTypeInventoryMap = new Map()
    departurePurchasableOptions?.forEach(purchasableOption => {
      roomTypeInventoryMap.set(purchasableOption.roomType!, purchasableOption.inventoryLeft)
    })
    return roomTypeInventoryMap
  },
)

export const isTourItineraryPDFEnabled = createSelector(
  (state: App.State) => state.auth.account.isSpoofed,
  (isSpoofed):boolean => {
    return config.TOUR_ITINERARY_PDF_DOWNLOAD || isSpoofed
  },
)

export const getTourSelectablePurchasableOptionsByOccupant = createSelector(
  getTourV2Items,
  (state: App.State) => state.offer.tourV2Offers,
  (items, tourOffers): Map<string, Array<Tours.TourV2OfferPurchasableOption>> => {
    const selectablePurchasableOptionsOccupantMap = new Map()
    items.forEach(item => {
      const purchasableOptionsByDeparture = tourOffers?.[item.offerId]?.purchasableOptions.filter(purchasableOption =>
        purchasableOption.fkDepartureId === item.purchasableOption.fkDepartureId &&
        getRoomTypeCapacity(purchasableOption.roomType!) === sumUpOccupancies([item.occupancy], TOUR_V2_TARGET_OCCUPANT_TYPES),
      )
      selectablePurchasableOptionsOccupantMap.set(JSON.stringify(item.occupancy), purchasableOptionsByDeparture)
    })
    return selectablePurchasableOptionsOccupantMap
  },
)

export const getTourSelectablePurchasableOptionsWithPriceByOccupant = createSelector(
  getTourV2Items,
  (state: App.State) => state.offer.tourV2Offers,
  (items, tourOffers): Map<App.Occupants, Map<Tours.TourV2OfferPurchasableOption, Tours.V2OfferDeparturePriceBreakdown>> => {
    const selectablePurchasableOptionsOccupantMap = new Map()
    items.forEach(item => {
      const purchasableOptionsByDeparture = tourOffers?.[item.offerId]?.purchasableOptions.filter(purchasableOption =>
        purchasableOption.fkDepartureId === item.purchasableOption.fkDepartureId &&
        getRoomTypeCapacity(purchasableOption.roomType!) === sumUpOccupancies([item.occupancy], TOUR_V2_TARGET_OCCUPANT_TYPES),
      ) ?? []
      const variation = tourOffers?.[item.offerId]?.variations?.[item.purchasableOption.fkVariationId]
      const purchasableOptionsWithPriceBreakDownByOption = new Map()
      purchasableOptionsByDeparture.forEach(purchasableOption => {
        const roomTypePrice = tourOffers?.[item.offerId]?.roomTypePricing?.[purchasableOption.fkRoomTypePricingId!]
        const priceBreakdown = generateDepartureRoomPriceBreakdown(item.occupancy, purchasableOption, roomTypePrice, variation)
        purchasableOptionsWithPriceBreakDownByOption.set(purchasableOption, priceBreakdown)
      })
      selectablePurchasableOptionsOccupantMap.set(item.occupancy, purchasableOptionsWithPriceBreakDownByOption)
    })
    return selectablePurchasableOptionsOccupantMap
  },
)

export const getTourV2PackageOptionsMissingRoomType = createSelector(
  getTourPurchasableAndPriceOptionsByPackage,
  (purchasableOptionsByPackage: Map<string, Array<[Tours.TourV2OfferPurchasableOption, Tours.V2OfferDeparturePriceBreakdown]>>): Map<string, Tours.V2OfferRoomType | null> => {
    const packageOptionsMissingRoomType = new Map()

    const packageOptionsAvailableRoomsTypes = new Map()
    purchasableOptionsByPackage.forEach((purchasableOptions, packageOption) => {
      const availableRooms = purchasableOptions.map(([purchasableOption, _]) => formatRoomType(purchasableOption.roomType!))
      packageOptionsAvailableRoomsTypes.set(packageOption, availableRooms)
    })

    const availablePackageOptions = [...purchasableOptionsByPackage.keys()]
    packageOptionsAvailableRoomsTypes.forEach((availableRooms, packageOption) => {
      availablePackageOptions.forEach(packageOptionToCheck => {
        if (packageOptionToCheck !== packageOption) {
          const availableRoomsToCheck = packageOptionsAvailableRoomsTypes.get(packageOptionToCheck)
          const missingRooms = availableRoomsToCheck.filter(room => !availableRooms.includes(room))
          packageOptionsMissingRoomType.set(packageOption, missingRooms.length > 0 ? missingRooms[0] : null)
        }
      })
    })
    return packageOptionsMissingRoomType
  },
)

export const selectablePurchasableOptionsHasRoomTypeUpgrade = createSelector(
  getTourSelectablePurchasableOptionsByOccupant,
  (selectablePurchasableOptionsByOccupant: Map<string, Array<Tours.TourV2OfferPurchasableOption>>): boolean =>
    Array.from(selectablePurchasableOptionsByOccupant.values())
      .filter(purchasableOptions => purchasableOptions && purchasableOptions.length > 1)
      .flatMap<Tours.TourV2OfferPurchasableOption>(purchasableOption => purchasableOption)
      .some(purchasableOption => hasRoomTypeUpgrade(purchasableOption?.roomType)),
)

export const hasMultiplePurchasableOptionsWithPackageUpgradeOrRoomTypeUpgrade = createSelector(
  getTourSelectablePurchasableOptionsByOccupant,
  (selectablePurchasableOptionsByOccupant: Map<string, Array<Tours.TourV2OfferPurchasableOption>>): boolean => {
    const purchasbleOptions = Array.from(selectablePurchasableOptionsByOccupant.values())
      .filter(purchasableOptions => purchasableOptions && purchasableOptions.length > 1)
      .flatMap<Tours.TourV2OfferPurchasableOption>(purchasableOption => purchasableOption)
    const { hasPackageUpgrade, hasRoomType } = checkPurchasableOptionHasPackageUpgradeOrRoomType(purchasbleOptions)
    return hasPackageUpgrade || hasRoomType
  },
)

export const hasOptionalExtras = createSelector(
  getTourV2Items,
  (state: App.State) => state.offer.tourV2Offers,
  (items, tourOffers): boolean => {
    return items.some(item => !isEmptyObject(tourOffers?.[item.offerId]?.purchasableExperienceOptions ?? EmptyObject))
  },
)

export const isLuxPlusToursEnabled = createSelector(
  isLuxPlusEnabled,
  (state: App.State) => state.optimizely.optimizelyFeatureFlags,
  (isLuxPlusEnabled, optimizelyFeatureFlags) => !!(isLuxPlusEnabled && optimizelyFeatureFlags[OptimizelyFeatureFlags.luxPlusToursToggle]),
)

const hasMemberPrice = createSelector(
  getTourV2Items,
  (state: App.State) => state.offer.tourV2Offers,
  (items, tourOffers): boolean => {
    return items.some(item => {
      return tourOffers?.[item.offerId]?.purchasableOptions?.some(purchasableOption => !!purchasableOption?.memberPrice) ?? false
    })
  },
)

const isEarlyAccessForMembers = createSelector(
  getTourV2Offer,
  (offer): boolean => {
    return offer?.luxPlus?.access === 'earlyAccess'
  },
)

export const shouldShowLuxPlusUpsellForTour = createSelector(
  isLuxPlusToursEnabled,
  hasMemberPrice,
  isLuxPlusAvailableToAddToCart,
  isEarlyAccessForMembers,
  (isLuxPlusToursEnabled, hasMemberPrice, isLuxPlusAvailableToAddToCart, isEarlyAccessForMembers): boolean => !!(isLuxPlusToursEnabled && (hasMemberPrice || isEarlyAccessForMembers) && isLuxPlusAvailableToAddToCart),
)
