import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'
import Caption from 'components/Luxkit/Typography/Caption'
import { SVG_ICON_SIZE_CSS_VAR, StyledSvgIcon } from '../SvgIcon'

export const LABEL_HEIGHT = rem(24)

const LabelElement = styled.span`
  height: ${LABEL_HEIGHT};
  padding: 0 ${rem(8)};
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  flex-wrap: nowrap;
  border: 1px solid transparent;
  border-radius: ${props => props.theme.borderRadius.S};
  gap: ${rem(4)};

  > ${StyledSvgIcon} {
    ${SVG_ICON_SIZE_CSS_VAR}: ${rem(16)};
  }

  &.kind-primary {
    &.variant-dark {
      background-color: ${props => props.theme.palette.neutral.default.one};
      border-color: ${props => props.theme.palette.neutral.default.one};
      color: ${props => props.theme.palette.neutral.default.eight};
    }

    &.variant-urgency {
      background-color: ${props => props.theme.palette.messaging.urgency.normalBackground};
      border-color: ${props => props.theme.palette.messaging.urgency.normalForeground};
      color: ${props => props.theme.palette.messaging.urgency.normalContrast};
    }

    &.variant-critical {
      background-color: ${props => props.theme.palette.messaging.critical.normalBackground};
      border-color: ${props => props.theme.palette.messaging.critical.normalForeground};
      color: ${props => props.theme.palette.messaging.critical.normalContrast};
    }

    &.variant-highlight {
      background-color: ${props => props.theme.palette.highlight.primary.normalBackground};
      border-color: ${props => props.theme.palette.highlight.primary.normalForeground};
      color: ${props => props.theme.palette.highlight.primary.normalContrast};
    }

    &.variant-highlight-secondary {
      background-color: ${props => props.theme.palette.highlight.secondary.normalBackground};
      border-color: ${props => props.theme.palette.highlight.secondary.normalForeground};
      color: ${props => props.theme.palette.highlight.secondary.normalContrast};
    }

    &.variant-exclusive {
      background-color: ${props => props.theme.palette.product.exclusive.background};
      border-color: ${props => props.theme.palette.product.exclusive.background};
      color: ${props => props.theme.palette.product.exclusive.contrast};
    }

    &.variant-ultra {
      background-color: ${props => props.theme.palette.product.ultralux.background};
      border-color: ${props => props.theme.palette.product.ultralux.background};
      color: ${props => props.theme.palette.product.ultralux.contrast};
    }

    &.variant-velocity {
      background-color: ${props => props.theme.palette.neutral.default.eight};
      border-color: ${props => props.theme.palette.neutral.default.eight};
      color: ${props => props.theme.palette.neutral.default.one};
    }

    &.variant-loyalty {
      background-color: ${props => props.theme.palette.product.loyalty.background};
      border-color: ${props => props.theme.palette.product.loyalty.background};
      color: ${props => props.theme.palette.product.loyalty.contrast};
    }
  }

  &.kind-secondary {
    background-color: ${props => props.theme.palette.neutral.default.eight};

    &.variant-dark {
      border-color: ${props => props.theme.palette.neutral.default.two};
      color: ${props => props.theme.palette.neutral.default.two};
    }

    &.variant-ghost {
      border-color: ${props => props.theme.palette.neutral.default.six};
      color: ${props => props.theme.palette.neutral.default.one};
    }

    &.variant-grey {
      border-color: ${props => props.theme.palette.neutral.default.three};
      color: ${props => props.theme.palette.neutral.default.three};
    }

    &.variant-urgency {
      border-color: ${props => props.theme.palette.messaging.urgency.normalForeground};
      color: ${props => props.theme.palette.messaging.urgency.normalForeground};
    }

    &.variant-critical {
      border-color: ${props => props.theme.palette.messaging.critical.normalForeground};
      color: ${props => props.theme.palette.messaging.critical.normalForeground};
    }

    &.variant-highlight {
      border: none;
      background-color: ${props => props.theme.palette.highlight.primary.normalBackground};
      color: ${props => props.theme.palette.highlight.primary.normalContrast};
    }

    &.variant-velocity {
      color: ${props => props.theme.palette.neutral.default.one};
      border: none;
    }
  }

  &.kind-tertiary {
    border-color: ${props => props.theme.palette.neutral.default.eight};

    &.variant-dark {
      background-color: ${props => props.theme.palette.neutral.default.eight};
      border-color: ${props => props.theme.palette.neutral.default.eight};
      color: ${props => props.theme.palette.neutral.default.one};
    }

    &.variant-ghost {
      background-color: ${props => props.theme.palette.neutral.default.six};
      border-color: ${props => props.theme.palette.neutral.default.six};
      color: ${props => props.theme.palette.neutral.default.one};
    }

    &.variant-highlight {
      background-color: ${props => props.theme.palette.highlight.primary.lightBackground};
      border-color: ${props => props.theme.palette.highlight.primary.lightBackground};
      color: ${props => props.theme.palette.highlight.primary.lightContrast};
    }

    &.variant-success {
      background-color: ${props => props.theme.palette.messaging.success.lightBackground};
      border-color: ${props => props.theme.palette.messaging.success.lightBackground};
      color: ${props => props.theme.palette.messaging.success.lightContrast};
    }

    &.variant-urgency {
      background-color: ${props => props.theme.palette.messaging.urgency.lightBackground};
      border-color: ${props => props.theme.palette.messaging.urgency.lightBackground};
      color: ${props => props.theme.palette.messaging.urgency.lightContrast};
    }

    &.variant-critical {
      background-color: ${props => props.theme.palette.messaging.critical.lightBackground};
      border-color: ${props => props.theme.palette.messaging.critical.lightBackground};
      color: ${props => props.theme.palette.messaging.critical.lightContrast};
    }

    &.variant-fast {
      background-color: ${props => props.theme.palette.highlight.tertiary.lightBackground};
      border-color: ${props => props.theme.palette.highlight.tertiary.lightBackground};
      color: ${props => props.theme.palette.highlight.tertiary.lightContrast};
    }

    &.variant-warning {
      background-color: ${props => props.theme.palette.messaging.warning.lightBackground};
      border-color: ${props => props.theme.palette.messaging.warning.lightBackground};
      color: ${props => props.theme.palette.messaging.warning.lightContrast};
    }
  }

  &.kind-plain {
    height: unset;
    padding: 0;
    color: ${props => props.theme.palette.neutral.default.one};

    &.variant-dark {
      color: ${props => props.theme.palette.neutral.default.one};
    }

    &.variant-ghost {
      color: ${props => props.theme.palette.neutral.default.two};
    }

    &.variant-highlight {
      color: ${props => props.theme.palette.highlight.primary.normalForeground};
    }

    &.variant-highlight-secondary {
      color: ${props => props.theme.palette.highlight.secondary.normalForeground};
    }

    &.variant-success {
      color: ${props => props.theme.palette.messaging.success.normalForeground};
    }

    &.variant-urgency {
      color: ${props => props.theme.palette.messaging.urgency.normalForeground};
    }

    &.variant-critical {
      color: ${props => props.theme.palette.messaging.critical.normalForeground};
    }

    &.variant-fast {
      color: ${props => props.theme.palette.highlight.tertiary.normalForeground};
    }

    &.variant-warning {
      color: ${props => props.theme.palette.messaging.warning.normalForeground};
    }

    &.variant-exclusive {
      color: ${props => props.theme.palette.product.exclusive.foreground};
    }

    &.variant-velocity {
      color: #512698;
    }
  }
`

type InheritedCaptionProps = Pick<React.ComponentProps<typeof Caption>, 'format'>
type Kind = 'primary' | 'secondary' | 'tertiary' | 'plain'
type Variant = 'dark'
| 'ghost'
| 'grey'
| 'highlight'
| 'highlight-secondary'
| 'success'
| 'urgency'
| 'fast'
| 'warning'
| 'critical'
| 'exclusive'
| 'velocity'
| 'ultra'
| 'loyalty'

interface Props extends React.HTMLAttributes<HTMLSpanElement>, InheritedCaptionProps {
  kind: Kind;
  variant: Variant;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

/**
 * This is a base 'abstract' label that is used by all the *implementation labels*
 * This should never be used directly. Talk to your designer to discover the correct
 * label type to use in the event you are unsure.
 */
const Label = React.forwardRef<HTMLSpanElement, Props>((props, ref) => {
  const {
    kind,
    variant,
    startIcon,
    endIcon,
    children,
    className,
    format,
    ...rest
  } = props

  const isPlainChildren = typeof children === 'string' ||
  typeof children === 'number' ||
  (Array.isArray(children) && children.every(child => typeof child === 'string' || typeof child === 'number' || typeof child === 'boolean'))

  return <LabelElement
    {...rest}
    ref={ref}
    className={cn(
      className,
      `kind-${kind}`,
      `variant-${variant}`,
    )}
  >
    {startIcon}
    {isPlainChildren && <Caption variant="medium" weight="bold" format={format}>{children}</Caption>}
    {!isPlainChildren && children}
    {endIcon}
  </LabelElement>
})

Label.displayName = 'Label'

export default Label
