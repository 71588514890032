import { INTL_LONG_MONTH } from 'constants/dateFormats'
import GeoContext from 'contexts/geoContext'
import moment from 'moment'
import { useContext, useCallback } from 'react'

/**
 * Returns whether the given date is valid date or not
 * @param date The date to check
 * @returns Whether it is valid
 */
export function isValidDate(date?: Date) {
  return date instanceof Date && !isNaN(date.getTime())
}

export function dateIsAfter(date: Date, date2?: Date) {
  return date2 ? date.getTime() > date2.getTime() : false
}

export function dateIsAfterOrSame(date: Date, date2?: Date) {
  return date2 ? date.getTime() >= date2.getTime() : false
}

export function dateIsBefore(date: Date, date2?: Date) {
  return date2 ? date.getTime() < date2.getTime() : false
}

export function dateIsBeforeOrSame(date: Date, date2?: Date) {
  return date2 ? date.getTime() <= date2.getTime() : false
}

export function startOfMonth(date: Date) {
  return new Date(date.getFullYear(), date.getMonth())
}

export function startOfDay(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

export function endOfMonth(date: Date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 1, 0, 0, 0, -1)
}

export function addMonths(date: Date, toAdd: number) {
  const newDate = new Date(date)
  newDate.setMonth(newDate.getMonth() + toAdd)

  /**
   * If the date jump automatically for the next month in last day of month bug
   * set the date to the last day of previous month to prevent the double month skipping.
   */
  const isNotValidMonth = newDate.getMonth() > ((date.getMonth() + toAdd) % 12)
  if (toAdd > 0 && isNotValidMonth) newDate.setDate(0)

  return newDate
}

export function isSameDay(date: Date, date2?: Date) {
  return !!date2 &&
  date.getFullYear() === date2.getFullYear() &&
  date.getMonth() === date2.getMonth() &&
  date.getDate() === date2.getDate()
}

export function isSameMonth(date: Date, date2?: Date) {
  return !!date2 &&
  date.getFullYear() === date2.getFullYear() &&
  date.getMonth() === date2.getMonth()
}

export function getMonthName(date: Date) {
  return intlFormatter(date, INTL_LONG_MONTH)
}

export function getMonthShortName(date: Date) {
  return getMonthName(date).slice(0, 3)
}

export function convertTZ(date: Date, tzString: string) {
  return new Date(date.toLocaleString('en-US', { timeZone: tzString }))
}

export function calculateTimezoneOffset(date: Date, date2: Date) {
  const localTimezoneOffset = date.getTimezoneOffset()
  const time = Math.round(date.getTime() / 1000 / 60)
  const time2 = Math.round(date2.getTime() / 1000 / 60)
  const result = time2 - time - localTimezoneOffset
  return Math.round(result / 60)
}

export function subDays(date: Date, days: number): Date {
  return addDays(date, -days)
}

export function addDays(start: Date, days: number): Date {
  const date = new Date(start)
  const originalOffset = date.getTimezoneOffset()
  date.setDate(date.getDate() + days)
  const newOffset = date.getTimezoneOffset()
  if (originalOffset !== newOffset) {
    // Daylight savings has changed.
    // If the offset got less, the clock was wound forward (Spring).
    // If the offset got more, the clock was wound back (Autumn).
    // Either way, we assume callers of this method want to move in whole calendar days,
    // and just undo the offset change.
    const offsetChange = newOffset - originalOffset
    date.setMinutes(date.getMinutes() - offsetChange)
  }
  return date
}

export function addWeeks(start: Date, weeks: number): Date {
  return addDays(start, weeks * 7)
}

export function addYears(start: Date, years: number): Date {
  const date = new Date(start)
  date.setFullYear(date.getFullYear() + years)
  return date
}

export function addHours(start: Date, hours: number): Date {
  const date = new Date(start)
  date.setHours(date.getHours() + hours)
  return date
}

export function beginningOfToday(): Date {
  const date = new Date()
  date.setHours(0, 0, 0, 0)
  return date
}

export interface DateDifference {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}
export function getYearDiff(startDate: Date, endDate: Date): number {
  return Math.abs(endDate.getFullYear() - startDate.getFullYear())
}

type RoundingOption = 'day' | 'hour' | 'minute' | 'second'
const millisecondsPer: Record<RoundingOption, number> = {
  day: 1000 * 60 * 60 * 24,
  hour: 1000 * 60 * 60,
  minute: 1000 * 60,
  second: 1000,
}

export function dateDifference(differenceUntilDate: Date, differenceFromDate = new Date()): DateDifference {
  const diff = differenceUntilDate.getTime() - differenceFromDate.getTime()
  if (diff < 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  }
  const remainingHours = diff % millisecondsPer.day
  const days = Math.floor(diff / millisecondsPer.day)
  const remainingMinutes = remainingHours % millisecondsPer.hour
  const hours = Math.floor(remainingHours / millisecondsPer.hour)
  const remainingSeconds = remainingMinutes % millisecondsPer.minute
  const minutes = Math.floor(remainingMinutes / millisecondsPer.minute)
  const seconds = Math.floor(remainingSeconds / millisecondsPer.second)
  return {
    days,
    hours,
    minutes,
    seconds,
  }
}

export function roundedDateDifference(differenceUntilDate: Date, differenceFromDate = new Date(), precision: RoundingOption): number {
  const diff = differenceUntilDate.getTime() - differenceFromDate.getTime()
  return Math.round(diff / millisecondsPer[precision])
}

export const pad = (val: number, len = 2): string => ('0'.repeat(len) + String(val)).slice(-len)

// Format functions should be using UTC versions as the most common way we create date objects is via
// iso date strings (e.g. 2023-06-07). Javascript date objects take these dates strings and assume they are UTC times
// and then "move" the date based on the current timezone. In effect, this means that if the user is in a negative GMT time zone
// the date object will go *backwards* one day. To get around this, we use the UTC values for formatting.

export function isoDateFormat(val: Date): string {
  return `${val.getUTCFullYear()}-${pad(val.getUTCMonth() + 1)}-${pad(val.getUTCDate())}`
}

export function ymdFormatLocalDate(val: Date): string {
  return `${val.getFullYear()}-${pad(val.getMonth() + 1)}-${pad(val.getDate())}`
}

export function shortDateFormat(val: Date): string {
  return `${val.getUTCFullYear()}-${pad(val.getUTCMonth() + 1)}`
}

export function mdyDateFormat(val: Date): string {
  return `${pad(val.getUTCMonth() + 1)}/${val.getUTCDate()}/${val.getUTCFullYear()}`.replace(/(^|\D)(\d)(?!\d)/g, '$10$2')
}

export function dmyCasualDateFormat(val: Date): string {
  return `${val.getUTCDate() + 1} ${val.toLocaleDateString('en-US', { month: 'short' })} ${val.getUTCFullYear()}`
}

export function shortTimeHourFormatAmPm(val: Date, utc?: boolean): string {
  return val.toLocaleString('en-US', {
    timeZone: utc ? 'UTC' : undefined,
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).toLowerCase()
}

type SupportedDateType = Date | moment.Moment | string

function toDateObject(value: SupportedDateType) {
  if (typeof value === 'string') {
    return new Date(value)
  } else if (moment.isMoment(value)) {
    return value.toDate()
  } else {
    return value
  }
}

export const selectDateFormattingLocaleForRegion = (currentRegionCode: string) => `en-${currentRegionCode}`

const formatterCache = new Map<string, Intl.DateTimeFormat>()

export function intlFormatter(
  date: SupportedDateType,
  instructions: Intl.DateTimeFormatOptions | Array<Intl.DateTimeFormatOptions>,
  separator = ', ',
  locale = 'en-AU',
): string {
  return [...new Set(Array.isArray(instructions) ? instructions : [instructions])]
    .map(instruction => {
      const key = `${locale}.${JSON.stringify(instruction)}`
      let formatter = formatterCache.get(key)
      if (!formatter) {
        formatter = new Intl.DateTimeFormat(locale, instruction)
        formatterCache.set(key, formatter)
      }
      return formatter.format(toDateObject(date))
    })
    .join(separator)
}

export const useIntlDateFormatter = () => {
  const { currentRegionCode } = useContext(GeoContext)
  const locale = selectDateFormattingLocaleForRegion(currentRegionCode)
  return useCallback((
    date: SupportedDateType,
    instructions: Intl.DateTimeFormatOptions | Array<Intl.DateTimeFormatOptions>,
    separator = ', ',
  ) => intlFormatter(date, instructions, separator, locale), [locale])
}

export function getLastDayOfMonth(val: Date) {
  return new Date(val.getFullYear(), val.getMonth() + 1, 0).getDate()
}

export function diffInDays(date1: Date, date2: Date) {
  const t2 = date2.getTime()
  const t1 = date1.getTime()

  return Math.floor((t2 - t1) / (24 * 3600 * 1000))
}

const now = new Date()
/**
* Returns the numeric value of the age in a specific date
* @param {string} dobString - The date of birth in string
* @param {Date} date - The date that we want to know what is the age, optional - will go from 'now' otherwise
*/
export function getAge(dobString: string, date: Date = now): number {
  const birthDate: Date = new Date(dobString)
  const age: number = date.getFullYear() - birthDate.getFullYear()
  const isDateBeforeBirthDate = date.getMonth() < birthDate.getMonth() ||
  (date.getMonth() === birthDate.getMonth() && date.getDate() < birthDate.getDate())

  return age - (isDateBeforeBirthDate ? 1 : 0)
}

/**
 * Converts a date string to a UTC date
 * @param {string} dateStr - The date string
 * @returns {Date} The UTC date
 */

/**
 * Returns whether the booking is within the promotion date range
 * @param {string} bookingStartDate - The booking start date
 * @param {string} bookingEndDate - The booking end date
 * @param {string} promotionStartDate - The promotion start date
 * @param {string} promotionEndDate - The promotion end date
 * @returns {boolean} Whether the booking is within the promotion date range
 */
export function isBookingWithinDateRange(
  bookingStartDate: string,
  bookingEndDate: string,
  promotionStartDate: string,
  promotionEndDate: string,
) {
  const bookingStart = new Date(bookingStartDate)
  const bookingEnd = new Date(bookingEndDate)
  const promotionStart = new Date(promotionStartDate)
  const promotionEnd = new Date(promotionEndDate)

  return bookingStart >= promotionStart && bookingEnd <= promotionEnd
}

/**
 * @param {Date} startDate start of date range
 * @param {Date} endDate end of date range
 * @param {Date} date date to check
 * @returns {boolean} whether the date is between the start and end date
 */
export function isDateBetween(startDate: Date, endDate: Date, date = new Date()) {
  // take the time component out of the equation
  startDate.setHours(0, 0, 0, 0)
  endDate.setHours(23, 0, 0, 0)
  date.setHours(12, 0, 0, 0)
  return date >= startDate && date <= endDate
}

/**
 * @param {string} startDate start of date range
 * @param {string} endDate end of date range
 * @param {string} date date to check
 * @returns {boolean} whether the date is between or equal to the start and end date
 */
export function isDateBetweenOrEqual(startDate: string, endDate: string, date?: string) {
  const dateObj = date ? new Date(date) : new Date()
  const startDateObj = new Date(startDate)
  const endDateObj = new Date(endDate)
  return (isValidDate(dateObj) && isValidDate(startDateObj) && isValidDate(endDateObj)) &&
  (isDateBetween(startDateObj, endDateObj, dateObj) || isSameDay(dateObj, startDateObj) || isSameDay(dateObj, endDateObj))
}

const weekdayKeys = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'] as const
type WeekdayKey = typeof weekdayKeys[number]

const weekdayNameCacheByLocale: {
  [locale: string]: {
    [key in WeekdayKey]: {
      long: string;
      short: string;
    }
  },
} = {}

export function getWeekdayNames(locale: string) {
  if (!weekdayNameCacheByLocale[locale]) {
    const longFormatter = Intl.DateTimeFormat(locale, { weekday: 'long' })
    const shortFormatter = Intl.DateTimeFormat(locale, { weekday: 'short' })
    weekdayNameCacheByLocale[locale] = weekdayKeys.reduce((acc, key, i) => {
      // Intl currently doesn't have a way to just get the weekday names, so we have to use actual dates.
      // Oct 2023 started on a Sunday, and weekdayKeys starts from Sunday, so we can just use
      // dates 1-7 Oct to get the weekday names.
      const date = new Date(2023, 9, i + 1)
      acc[key] = {
        long: longFormatter.format(date),
        short: shortFormatter.format(date),
      }
      return acc
    }, {} as typeof weekdayNameCacheByLocale[string])
  }

  return weekdayNameCacheByLocale[locale]
}

export function daysInPast(date: Date): number {
  const currentDate = new Date()
  const timeDifference = currentDate.getTime() - date.getTime()
  const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24))
  return daysDifference
}

export function daysUntilDate(date: Date): number {
  const currentDate = new Date()
  const timeDifference = date.getTime() - currentDate.getTime()
  const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24))
  return daysDifference
}
