import { Bedbank } from '@luxuryescapes/contract-svc-bedbank'

import request from 'api/requestUtils'
import generateOccupancyStringByRoom from 'lib/offer/generateOccupancyStringByRoom'

import { templates } from '@luxuryescapes/lib-uri-templates'

interface CreateReservationEnquiryProps {
  propertyId: string;
  roomTypeId: string;
  roomRateId: string;
  bedGroupId?: string;
  rateGroupId?: string | null;
  region: string;
  rooms: Array<App.Occupants>;
  checkIn: string;
  checkOut: string;
  price: number;
  csBooking: boolean;
  isFlightBundle: boolean;
}

const enquiryMap = (data: Bedbank.CreateReservationEnquiryResponse) => ({
  sessionId: data.sessionId,
  price: data.price,
})

export function enquiry(payload: CreateReservationEnquiryProps) {
  return request.post<
    App.ApiResponse<Bedbank.CreateReservationEnquiryResponse>,
    Omit<Bedbank.CreateReservationEnquiryRequest, 'brand'>
  >(templates.bedbank.bedbankEnquiry.expand(), {
    propertyId: payload.propertyId,
    roomTypeId: payload.roomTypeId,
    roomRateId: payload.roomRateId,
    bedGroupId: payload.bedGroupId,
    rateGroupId: payload.rateGroupId ?? undefined,
    isFlightBundle: payload.isFlightBundle,
    region: payload.region,
    occupancy: payload.rooms.map(generateOccupancyStringByRoom),
    checkIn: payload.checkIn,
    checkOut: payload.checkOut,
    price: payload.price,
    csBooking: payload.csBooking,
  }, { credentials: 'include' })
    .then(data => enquiryMap(data.result))
}

interface CalendarProps {
  propertyId: string;
  region: string;
  nights: number;
  roomId?: string;
}

export function getCalendar(payload: CalendarProps) {
  return request.get<App.ApiResponse<Bedbank.CalendarResponse>>(templates.bedbank.bedbankCalendar.expand(payload), { credentials: 'include' })
    .then(data => data.result)
}
