import createSelector from 'lib/web/createSelector'
import { OptimizelyFeatureFlags } from 'constants/optimizely'
import config from 'constants/config'
import { matchPath } from 'react-router'
import { CHECKOUT_HEADER_PATHS } from 'components/App/Routes/constants'
import { LUXURY_PLUS_PATHS } from 'luxPlus/constants/base'

export const isLuxPlusEnabled = createSelector(
  (state: App.State) => state.optimizely.optimizelyFeatureFlags,
  (optimizelyFeatureFlags): boolean => {
    return !!(config.LUXURY_PLUS_ENABLED && optimizelyFeatureFlags[OptimizelyFeatureFlags.luxPlusFeatureToggle])
  },
)

/**
 * Check if the user has LuxPlus+ membership in the current region.
 * Members are only members in the region they bought the subscription in
 */
const isEnabledInCurrentRegionLuxPlusMember = createSelector(
  isLuxPlusEnabled,
  (state: App.State) => state.luxPlus.member.subscription.item,
  (state: App.State) => state.geo.currentRegionCode,
  (isLuxPlusEnabled, memberSubscriptionItem, currentRegion): boolean => {
    return isLuxPlusEnabled && currentRegion === memberSubscriptionItem?.region
  },
)

/**
 * if you think you need this selector, you probably don't. Use checkCanRedeemLuxPlusBenefits instead
 */
export const _isActiveMemberInCurrentRegion = createSelector(
  isEnabledInCurrentRegionLuxPlusMember,
  (state: App.State) => state.luxPlus.member.subscription.item,
  (isEnabledInCurrentRegionLuxPlusMember, memberSubscriptionItem): boolean => {
    return isEnabledInCurrentRegionLuxPlusMember && memberSubscriptionItem?.status === 'ACTIVE'
  },
)

export const isPendingRenewalLuxPlusMember = createSelector(
  isEnabledInCurrentRegionLuxPlusMember,
  (state: App.State) => state.luxPlus.member.subscription.item,
  (isEnabledInCurrentRegionLuxPlusMember, memberSubscriptionItem): boolean => {
    return isEnabledInCurrentRegionLuxPlusMember && memberSubscriptionItem?.status === 'AWAITING_RENEWAL'
  },
)

/**
 * Check if the user is a LuxPlus+ member either subscribed e.g. 'base' tier. awaiting renewal or in free preview.
 * This is used to determine if the user can view but not redeem the benefits of LuxPlus+.
 */
export const checkCanViewLuxPlusBenefits = createSelector(
  _isActiveMemberInCurrentRegion,
  isPendingRenewalLuxPlusMember,
  (state: App.State) => state.luxPlus.member.disableMembership,
  (isActiveMemberInCurrentRegion, isAwaitingRenewalInCurrentRegion, disableMembership): boolean => {
    return (isActiveMemberInCurrentRegion || isAwaitingRenewalInCurrentRegion) && !disableMembership
  },
)

export const isFreePreviewLuxPlusMember = createSelector(
  isEnabledInCurrentRegionLuxPlusMember,
  (state: App.State) => state.luxPlus.member.subscription.item,
  (isEnabledInCurrentRegionLuxPlusMember, memberSubscriptionItem): boolean => {
    return isEnabledInCurrentRegionLuxPlusMember && memberSubscriptionItem?.tier === 'free_preview'
  },
)

/** Requirement to make any LuxPlus+ purchases */
export const checkCanRedeemLuxPlusBenefits = createSelector(
  checkCanViewLuxPlusBenefits,
  isFreePreviewLuxPlusMember,
  isPendingRenewalLuxPlusMember,
  (checkCanViewLuxPlusBenefits, isFreePreviewLuxPlusMember, isPendingRenewalLuxPlusMember): boolean => {
    return checkCanViewLuxPlusBenefits && !isFreePreviewLuxPlusMember && !isPendingRenewalLuxPlusMember
  },
)

export const isEligibleLuxPlusFreePreview = createSelector(
  isLuxPlusEnabled,
  (state: App.State) => state.luxPlus.products.freePreview?.offer?.isEligible,
  (isLuxPlusEnabled, luxPlusFreePreviewAvailable): boolean => {
    return isLuxPlusEnabled && !!luxPlusFreePreviewAvailable
  },
)

export const isEnabledLuxPlusDiscountedInsurance = createSelector(
  isLuxPlusEnabled,
  (state: App.State) => state.optimizely.optimizelyFeatureFlags,
  (isLuxPlusEnabled, optimizelyFeatureFlags): boolean => {
    return isLuxPlusEnabled && optimizelyFeatureFlags[OptimizelyFeatureFlags.luxPlusDiscountedInsuranceBenefit]
  },
)

export const isEnabledLuxPlusDiscountedBookingProtection = createSelector(
  isLuxPlusEnabled,
  (state: App.State) => state.optimizely.optimizelyFeatureFlags,
  (isLuxPlusEnabled, optimizelyFeatureFlags): boolean => {
    return isLuxPlusEnabled && optimizelyFeatureFlags[OptimizelyFeatureFlags.luxPlusDiscountedBookingProtectionBenefit]
  },
)

export const isInsuranceAndRefundProtectionEnabled = createSelector(
  isEnabledLuxPlusDiscountedInsurance,
  isEnabledLuxPlusDiscountedBookingProtection,
  (isEnabledLuxPlusDiscountedInsurance, isEnabledLuxPlusDiscountedBookingProtection): boolean => {
    return isEnabledLuxPlusDiscountedInsurance && isEnabledLuxPlusDiscountedBookingProtection
  },
)

export const selectHasLuxPlusFreePreviewBanner = createSelector(
  isLuxPlusEnabled,
  checkCanViewLuxPlusBenefits,
  (state: App.State) => state.config.luxPlusFreePreviewBannerDismissed,
  (state: App.State) => state.router.location.pathname,
  (
    isLuxPlusEnabled,
    canViewLuxPlusBenefits,
    luxPlusFreePreviewBannerDismissed,
    path,
  ): boolean => {
    const mappedCheckoutHeaderPaths = CHECKOUT_HEADER_PATHS.map((path) => '/:region' + path)
    const pathsBlacklist = [
      ...mappedCheckoutHeaderPaths,
      '/:region/booking/confirmation',
      `/:region/${LUXURY_PLUS_PATHS.ACCOUNT_MANAGEMENT_PAGE}`,
      '/:regionCode/campaign/early-bird-2025',
    ]

    const isBlacklistedPath = !!matchPath(path, {
      path: pathsBlacklist,
      exact: true,
    })

    return isLuxPlusEnabled && !canViewLuxPlusBenefits && !luxPlusFreePreviewBannerDismissed && !isBlacklistedPath
  },
)
