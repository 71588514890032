import { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch } from 'hooks/reduxHooks'
import { addFlashCruiseFacets } from 'actions/CruiseActions'
import GlobalSearchState from 'contexts/GlobalSearch/GlobalSearchState'
import useCruiseSearchFacets from 'hooks/Cruise/useCruiseSearchFacets'
import useOffers from 'hooks/Offers/useOffers'
import useOfferList from 'hooks/Offers/useOfferList'
import { CRUISE_FLASH_OFFERS_FILTERS } from 'constants/cruise'
import useCruisePriceByNight from 'hooks/useCruisePriceByNight'
import { cruiseFilterFlashOffers, splitStringToArray } from 'lib/cruises/cruiseFlashUtils'

interface Props {
  filters: App.OfferListFilters;
  globalFilters: GlobalSearchState;
}

function useCruiseSearchFlashOffers({ filters, globalFilters }: Props): App.OfferList {
  const dispatch = useAppDispatch()

  const offerList = useOfferList(CRUISE_FLASH_OFFERS_FILTERS)
  const [offers] = useOffers<App.Offer>(offerList.offerIds)

  const sidebarFacetParams = useMemo(() => ({
    facetTypes: ['ship_names', 'cabin_types', 'special_offers', 'lux_exclusive', 'cruise_prices'],
    cruiseLines: filters.cruiseLines,
    departurePlaceId: filters.departurePlaceId,
    departureIds: filters.departureIds,
    rateCodes: filters.rateCodes,
    destinationId: filters.destinationId,
    destinationIds: filters.destinationIds,
    departureMonths: filters.departureMonths,
    durationMin: filters.durationMin,
    durationMax: filters.durationMax,
    departureStartDate: filters.departureStartDate,
    departureEndDate: filters.departureEndDate,
    durationRange: filters.durationRange,
    cruiseShips: filters.cruiseShips,
  }), [filters]) as unknown as App.CruiseSearchFacetParams

  const [, fetchingFacets] = useCruiseSearchFacets(sidebarFacetParams)
  const [facetCruiseLines, fetchingCruiseLineFacets] = useCruiseSearchFacets({ facetTypes: ['cruise_lines'] })
  const [, fetchingDepartureFacets] = useCruiseSearchFacets({ facetTypes: ['departure_ports'] })
  const [, fetchingDestinationFacets] = useCruiseSearchFacets({ facetTypes: ['destination_ports'] })
  const cruisePriceByNight = useCruisePriceByNight()

  useEffect(() => {
    const startLocations = [...new Set(offers.map(({ startLocation }) => startLocation))].filter(Boolean)
    const endLocations = [...new Set(offers.map(({ endLocation }) => endLocation))].filter(Boolean)

    // Add departure, destination ports and cruise line facets for flash offers
    if (!fetchingDepartureFacets) {
      dispatch(addFlashCruiseFacets(
        { facetTypes: ['departure_ports'] },
        startLocations.map((location) => ({
          name: location,
          value: location,
          category: 'departure_ports',
          flashOfferCount: 1,
        })) as Array<App.CruiseSearchFacet>,
      ))
    }

    if (!fetchingDestinationFacets) {
      dispatch(addFlashCruiseFacets(
        { facetTypes: ['destination_ports'] },
        endLocations.map((location) => ({
          name: location,
          value: location,
          category: 'destination_ports',
          flashOfferCount: 1,
        })) as Array<App.CruiseSearchFacet>,
      ))
    }

    if (!fetchingCruiseLineFacets) {
      const cruiseLines = offers
        .filter((offer) => (
          offer.packages?.[0]?.tour?.logoImageId &&
          offer.vendorName &&
          // Ignore cruise line logos that are combos, like Riverside/Silversea
          !offer.vendorName.includes('/')
        ))
        .map((offer) => {
          const imageId = offer.packages[0]?.tour?.logoImageId
          const name = offer.vendorName as string
          return {
            name,
            value: name,
            imageId,
            category: 'cruise_lines',
          }
        })

      dispatch(addFlashCruiseFacets(
        { facetTypes: ['cruise_lines'] },
        cruiseLines,
      ))
    }
  }, [
    offers,
    dispatch,
    fetchingDepartureFacets,
    fetchingDestinationFacets,
    fetchingCruiseLineFacets,
  ])

  const hasFilters = useMemo(() => {
    const cabinTypes = filters.cabinTypes || []
    const cruiseShips = filters.cruiseShips || []
    const cruiseLines = filters.cruiseLines?.length ? filters.cruiseLines : globalFilters.cruiseLines || []
    const destinationName = filters.destinationName || ''
    const destinationNames = filters.destinationNames?.length ? filters.destinationNames : globalFilters.searchItems.map(({ format }) => format.mainText) || []
    const departureName = filters.departureName || ''
    const departureNames = filters.departureNames?.length ? filters.departureNames : globalFilters.secondarySearchItems.map(({ format }) => format.mainText) || []

    const hasFilters = (
      cabinTypes.length ||
      cruiseLines.length ||
      cruiseShips.length ||
      destinationName ||
      departureName ||
      departureNames?.length ||
      destinationNames?.length
    )
    return hasFilters ? 5 : 10
  }, [
    filters.cabinTypes,
    filters.cruiseLines,
    filters.cruiseShips,
    filters.departureName,
    filters.departureNames,
    filters.destinationName,
    filters.destinationNames,
    globalFilters.cruiseLines,
    globalFilters.searchItems,
    globalFilters.secondarySearchItems,
  ])

  // TODO: Change this so that flash offers are fetched from the search service in the same flow as cruise offers
  // Filter offers
  const unfilteredOffers = useMemo(() => {
    // unavailable filters for flash offers
    if (
      (filters.sortBy && filters.sortBy !== 'recommended') ||
      globalFilters.checkinDate ||
      globalFilters.checkoutDate
    ) {
      return []
    }

    const minPrice = filters.minPrice
    const maxPrice = filters.maxPrice
    const cabinTypes = filters.cabinTypes || []
    const cruiseShips = filters.cruiseShips || []
    const cruiseLines = filters.cruiseLines?.length ? filters.cruiseLines : globalFilters.cruiseLines || []
    const destinationName = filters.destinationName || ''
    const destinationNames = filters.destinationNames?.length ? filters.destinationNames : globalFilters.searchItems.map(({ format }) => format.mainText) || []
    const departureName = filters.departureName || ''
    const departureNames = filters.departureNames?.length ? filters.departureNames : globalFilters.secondarySearchItems.map(({ format }) => format.mainText) || []
    const departureMonths = filters.departureMonths?.length ? filters.departureMonths?.filter(Boolean) : globalFilters.flexibleMonths?.split(',')?.filter(Boolean) || []
    const durationMax = filters.durationMax || 0
    const durationMin = filters.durationMin || 0
    const durationRange = filters.durationRange?.map((range) => {
      if (!range) return { min: 0, max: 0 }

      const [min, max] = range.split('-')
      return { min: parseInt(min, 10), max: parseInt(max, 10) }
    }) || []

    const cruiseLineNames = facetCruiseLines
      .filter(({ value }) => cruiseLines.includes(value as string))
      .map(({ originalName }) => originalName as string)

    return cruiseFilterFlashOffers({
      offers,
      cruiseLineNames,
      cabinTypes,
      cruiseShips,
      departureName,
      destinationName,
      departureMonths,
      destinationNames,
      departureNames,
      minPrice,
      maxPrice,
      durationMin,
      durationMax,
      durationRange,
      cruisePriceByNight,
    })
  }, [
    filters.sortBy,
    filters.minPrice,
    filters.maxPrice,
    filters.cabinTypes,
    filters.cruiseShips,
    filters.cruiseLines,
    filters.destinationName,
    filters.destinationNames,
    filters.departureName,
    filters.departureNames,
    filters.departureMonths,
    filters.durationMax,
    filters.durationMin,
    filters.durationRange,
    globalFilters.checkinDate,
    globalFilters.checkoutDate,
    globalFilters.cruiseLines,
    globalFilters.searchItems,
    globalFilters.secondarySearchItems,
    globalFilters.flexibleMonths,
    facetCruiseLines,
    offers,
    cruisePriceByNight,
  ])

  const filteredOffers = useMemo(() => {
    const limit = hasFilters > 0 ? 10 : 5
    return unfilteredOffers.slice(0, limit)
  }, [hasFilters, unfilteredOffers])

  const sumDuration = useCallback((min: number, max?: number) => {
    return filteredOffers.reduce((acc, offer) => {
      const duration = offer.minDuration || 0
      if (duration >= min || (max && duration >= min && duration <= max)) {
        return acc + 1
      }
      return acc
    }, 0)
  }, [filteredOffers])

  // add facets for flash offers
  useEffect(() => {
    if (!fetchingFacets && unfilteredOffers.length) {
      const countByCabinType = unfilteredOffers.reduce((acc, offer) => {
        const cabinTypes = splitStringToArray(',', offer.cabinTypes)
        cabinTypes.forEach((type) => {
          acc[type] = (acc[type] ?? 0) + 1
        })

        return acc
      }, {} as Record<string, number>)

      const countByShipName = unfilteredOffers.reduce((acc, offer) => {
        const cruiseLine = facetCruiseLines.find((cruiseLine) => cruiseLine.name === offer.vendorName)
        const shipName = offer.vendorVehicle
        if (shipName && offer.vendorVehicle) {
          acc[shipName] = {
            code: cruiseLine?.value || offer.vendorName || '',
            vendorName: offer.vendorName || '',
            count: (acc[shipName]?.count || 0) + 1,
          }
        }

        return acc
      }, {} as Record<string, { code: string, vendorName: string, count: number }>)

      const shipNameFacets = Object.entries(countByShipName).map(([name, { count, vendorName, code }]) => ({
        name,
        flashOfferCount: count,
        category: 'ship_names',
        vendorName,
        code,
      })) as Array<App.CruiseSearchFacet>

      const cabinTypeFacets = Object.entries(countByCabinType).map(([name, count]) => ({
        name: name.trim(),
        flashOfferCount: count,
        category: 'cabin_types',
      })) as Array<App.CruiseSearchFacet>

      const flashFacets = [...cabinTypeFacets, ...shipNameFacets]
      const offerWithMinPrice = unfilteredOffers.reduce((minOffer, offer) => (
        offer.lowestPricePackage?.price! < minOffer.lowestPricePackage?.price! ? offer : minOffer
      ), unfilteredOffers[0])
      const offerWithMaxPrice = unfilteredOffers.reduce((maxOffer, offer) => (
        offer.lowestPricePackage?.price! > maxOffer.lowestPricePackage?.price! ? offer : maxOffer
      ), unfilteredOffers[0])

      flashFacets.push({
        category: 'cruise_prices',
        name: 'cruise_prices',
        min: offerWithMinPrice?.lowestPricePackage?.price || 0,
        max: offerWithMaxPrice?.lowestPricePackage?.price || 0,
        minPerNight: Math.round((offerWithMinPrice?.lowestPricePackage?.price! || 0) / ((offerWithMinPrice?.minDuration || 0) - 1)) || 0,
        maxPerNight: Math.round((offerWithMaxPrice?.lowestPricePackage?.price! || 0) / ((offerWithMaxPrice?.minDuration || 0) - 1)) || 0,
      })

      flashFacets.push({
        name: 'cruise_durations',
        category: 'cruise_durations',
        shortDuration: sumDuration(1, 4),
        mediumDuration: sumDuration(5, 8),
        longDuration: sumDuration(9, 14),
        veryLongDuration: sumDuration(15),
      })

      flashFacets.push({
        name: 'Lux Exclusive',
        category: 'lux_exclusive',
        flashOfferCount: unfilteredOffers.length,
      })

      flashFacets.push({
        name: 'Special offers',
        category: 'special_offers',
        flashOfferCount: unfilteredOffers.length,
      })

      if (flashFacets.length) {
        dispatch(addFlashCruiseFacets(sidebarFacetParams, flashFacets))
      }
    }
  }, [filteredOffers, sidebarFacetParams, dispatch, fetchingFacets, sumDuration, facetCruiseLines, unfilteredOffers])

  return {
    offerIds: filteredOffers.map(({ id }) => id),
    error: null,
    key: offerList.key,
    fetching: false,
    offerCount: filteredOffers.length,
  } as App.OfferList
}

export default useCruiseSearchFlashOffers
