import { convertMarkdownInclusionsToArray } from 'lib/string/markdownUtils'
import { inclusionTravelPeriodFilter } from './inclusionsTravelPeriodFilter'
import luxPlusInclusionFilter from './luxPlusInclusionFilter'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'

interface GetInclusionsOptions {
  checkIn?: moment.Moment;
  checkOut?: moment.Moment;
  rate?: App.PackageRoomRate;
  luxPlusTier?: App.MembershipSubscriptionTier;
}

export function extractPkgInclusions(pkg: App.Package | undefined, options: GetInclusionsOptions = {}) {
  const { checkIn, checkOut, luxPlusTier } = options

  if (!pkg) {
    return [[], [], [], []]
  }
  const inclusions = inclusionTravelPeriodFilter(pkg.inclusions ?? [], checkIn?.format(ISO_DATE_FORMAT), checkOut?.format(ISO_DATE_FORMAT))
  const bonusInclusions = inclusionTravelPeriodFilter(pkg.bonusInclusions ?? [], checkIn?.format(ISO_DATE_FORMAT), checkOut?.format(ISO_DATE_FORMAT))
  const mainInclusions = [...inclusions, ...bonusInclusions]
  const luxPlusInclusions = luxPlusInclusionFilter(pkg.luxPlusInclusionsByTier?.[luxPlusTier ?? ''] ?? [], mainInclusions)
  const tacticalInclusions = mainInclusions.filter(incl => incl.hasTactical)

  return [
    inclusions,
    bonusInclusions,
    luxPlusInclusions,
    tacticalInclusions,
  ]
}

export function getPkgInclusionsCount(pkg: App.Package, options: GetInclusionsOptions = {}) {
  const [inclusions, bonusInclusions, luxPlusInclusions] = extractPkgInclusions(pkg, options)
  const { inclusionsV2 = [] } = pkg

  return (inclusions.length ? inclusions.length : inclusionsV2.length) + bonusInclusions.length + luxPlusInclusions.length
}

export function mapInclusionsToTileInclusions(inclusions: Array<App.PackageInclusion>) : Array<App.TileInclusion> {
  return inclusions.flatMap<App.TileInclusion>((bonusInclusion) => {
    if (bonusInclusion.descriptionV2List && bonusInclusion.descriptionV2List.length > 0) {
      return bonusInclusion.descriptionV2List.map((inclusionV2) => ({
        text: inclusionV2.text,
        isHighlighted: inclusionV2.isHighlighted,
        icon: inclusionV2?.icon,
      }))
    } else {
      const description = bonusInclusion?.description?.startsWith('-') ?
        bonusInclusion?.description?.slice(1).trim() :
        bonusInclusion.description

      const descriptionLines = description ? convertMarkdownInclusionsToArray(description) : []
      return descriptionLines.map((line) => ({
        text: line,
        isHighlighted: false,
        icon: bonusInclusion?.categoryIcon || '',
        minDuration: bonusInclusion?.minDuration,
        maxDuration: bonusInclusion?.maxDuration,
      }))
    }
  })
}
