import config from 'constants/config'
import { OptimizelyFeatureFlags } from 'constants/optimizely'
import { createSelector } from 'reselect'

export const isLuxLoyaltyEnabled = createSelector(
  (state: App.State) => state.optimizely.optimizelyFeatureFlags,
  (optimizelyFeatureFlags): boolean => {
    return !!(config.LUX_LOYALTY_ENABLED && optimizelyFeatureFlags[OptimizelyFeatureFlags.luxLoyaltyFeatureToggle])
  },
)
