import config from 'constants/config'
import {
  KLARNA_PAYMENT_TYPE,
  KLARNA_BP_PAYMENT_TYPE,
  MIN_KLARNA_PAYMENT,
  MAX_KLARNA_PAYMENT,
  MIN_KLARNA_BP_GBP_PAYMENT,
  MIN_KLARNA_BP_NZD_PAYMENT,
  MIN_KLARNA_BP_USD_PAYMENT,
  MAX_KLARNA_BP_GBP_PAYMENT,
  MAX_KLARNA_BP_NZD_PAYMENT,
  MAX_KLARNA_BP_USD_PAYMENT,
  BRAINTREE_PAYMENT_TYPE,
  paymentMethodLabels,
  APPLE_PAY_PAYMENT_TYPE,
  BASE_MERCHANT_FEE,
  GOOGLE_PAY_PAYMENT_TYPE,
  GO_CARDLESS_PAYMENT_TYPE,
  STRIPE_3DS_PAYMENT_TYPE,
  STRIPE_3DS_V2_PAYMENT_TYPE,
  STRIPE_PAYMENT_TYPE,
  STRIPE_CUSTOM_PAYTO_TYPE,
  STRIPE_PAYMENT_ELEMENT_CARD_TYPE,
  STRIPE_PAYMENT_ELEMENT_KLARNA_TYPE,
  SECURE_PAYMENT_TYPE,
} from 'constants/payment'
import getPaymentMethods from 'lib/payment/getPaymentMethods'
import { isAfterPayBpEnabled, isWithinAfterPayBpAvailabilityRange } from 'lib/payment/afterPayBpUtils'
import { isHoolahBpEnabled, isWithinHoolahBpAvailabilityRange } from 'lib/payment/hoolahBpUtils'
import { isAtomeBpEnabled, isWithinAtomeBpAvailabilityRange } from 'lib/payment/atomeBpUtils'
import { isWaaveBpEnabled, isWithinWaaveBpAvailabilityRange } from 'lib/payment/waaveBpUtils'
import { isEnabledForFeature } from 'lib/config/featureFlagUtils'
import { PaymentMethodRowData } from 'checkout/Components/CheckoutSteps/Payment/PurchaseBox/PaymentOptions/common'

export const isKlarnaEnabled = (currency: string): boolean =>
  !!(config.KLARNA_ENABLED && getPaymentMethods(currency).includes(KLARNA_PAYMENT_TYPE))

export const isWithinKlarnaAvailabilityRange = (amount: number): boolean =>
  amount >= MIN_KLARNA_PAYMENT && amount <= MAX_KLARNA_PAYMENT

export const isKlarnaBpEnabled = (currency: string): boolean => {
  return config.KLARNA_BP_CURRENCIES ? isEnabledForFeature(config.KLARNA_BP_CURRENCIES, currency) && getPaymentMethods(currency).includes(KLARNA_BP_PAYMENT_TYPE) : false
}

export const isWithinKlarnaBpAvailabilityRange = (amount: number, currency:string): boolean => {
  switch (currency) {
    case 'NZD':
      return (amount >= MIN_KLARNA_BP_NZD_PAYMENT && amount <= MAX_KLARNA_BP_NZD_PAYMENT)
    case 'USD':
      return (amount >= MIN_KLARNA_BP_USD_PAYMENT && amount <= MAX_KLARNA_BP_USD_PAYMENT)
    case 'GBP':
      return (amount >= MIN_KLARNA_BP_GBP_PAYMENT && amount <= MAX_KLARNA_BP_GBP_PAYMENT)
    default:
      return false
  }
}

export const getKlarnaBpMaxLimit = (currency:string) :number => {
  switch (currency) {
    case 'NZD':
      return MAX_KLARNA_BP_NZD_PAYMENT
    case 'USD':
      return MAX_KLARNA_BP_USD_PAYMENT
    case 'GBP':
      return MAX_KLARNA_BP_GBP_PAYMENT
    default:
      return 0
  }
}

export function calculateInstalments(amount: number, instalments: number): number {
  return Math.ceil(parseFloat(((amount / instalments) * 100).toFixed(3))) / 100
}

export function isPaypalEnabled(currency: string) {
  return config.PAYPAL_ENABLED && getPaymentMethods(currency).includes(BRAINTREE_PAYMENT_TYPE)
}

export function getBNPLMethodAvailability(currencyCode: string, ...prices: Array<number>) {
  return {
    isKlarnaAvailable: isKlarnaEnabled(currencyCode) && prices.some(price => isWithinKlarnaAvailabilityRange(price)),
    isKlarnaBpAvailable: isKlarnaBpEnabled(currencyCode) && prices.some(price => isWithinKlarnaBpAvailabilityRange(price, currencyCode)),
    isAfterPayBpAvailable: isAfterPayBpEnabled(currencyCode) && prices.some(price => isWithinAfterPayBpAvailabilityRange(price, currencyCode)),
    isHoolahBpAvailable: isHoolahBpEnabled(currencyCode) && prices.some(price => isWithinHoolahBpAvailabilityRange(price, currencyCode)),
    isAtomeBpAvailable: isAtomeBpEnabled(currencyCode) && prices.some(price => isWithinAtomeBpAvailabilityRange(price, currencyCode)),
    isWaaveBpAvailable: isWaaveBpEnabled(currencyCode) && prices.some(price => isWithinWaaveBpAvailabilityRange(price, currencyCode)),
  }
}

export function isWithInStartDateRange(startDate, eligibilityRangeInDays) {
  if (!startDate) return true

  const currentDate = Date.now()
  const eligibilityRange = eligibilityRangeInDays * 24 * 60 * 60 * 1000

  const startDateInIsoFormat = new Date(startDate).toISOString()
  const startDateInMiliseonds = Date.parse(startDateInIsoFormat)

  return currentDate + eligibilityRange < startDateInMiliseonds
}

export function paymentMethodsToString(paymentMethods: Array<string> = []): string {
  const s = new Set<string>()
  paymentMethods.forEach(method => s.add(paymentMethodLabels[method] || method))
  const arr = Array.from(s)
  const formatter = new Intl.ListFormat('en', { type: 'disjunction' })
  return `This promo code applies to ${formatter.format(arr)}`
}

const merchantFeePaymentTypes: Array<App.MerchantFeePaymentType> = ['visa', 'mastercard', 'amex']
export function isCardAcceptedMerchantFeePaymentType(brand: string): brand is App.MerchantFeePaymentType {
  return merchantFeePaymentTypes.includes(brand as App.MerchantFeePaymentType)
}

export function calculateMerchantFeeForOrder(merchantFeeDetails: Array<App.MerchantFeeDetails>): number {
  if (!merchantFeeDetails) return 0
  return merchantFeeDetails.reduce((total, merchantFeeItem) => {
    return total + parseFloat(merchantFeeItem.amount)
  }, 0)
}

export function getMerchantFeeByOrderItem(merchantFeeDetails: Array<App.MerchantFeeDetails>, itemId: string): App.MerchantFeeDetails | null {
  const merchantFeeItem = merchantFeeDetails?.find(item => item.fkItem === itemId)
  return merchantFeeItem || null
}

const merchantFeeCaption = (pspName) => {
  switch (pspName) {
    case STRIPE_3DS_PAYMENT_TYPE:
    case STRIPE_PAYMENT_TYPE:
    case STRIPE_3DS_V2_PAYMENT_TYPE:
      return `Payment fee from ${BASE_MERCHANT_FEE}%`
    case BRAINTREE_PAYMENT_TYPE:
    case APPLE_PAY_PAYMENT_TYPE:
    case GOOGLE_PAY_PAYMENT_TYPE:
    case STRIPE_PAYMENT_ELEMENT_KLARNA_TYPE:
      return `Payment fee of ${BASE_MERCHANT_FEE}%`
    case GO_CARDLESS_PAYMENT_TYPE:
      return 'No Payment fee; transfer directly from your bank account'
    case STRIPE_CUSTOM_PAYTO_TYPE:
      return 'No Payment fee; Pay quickly and directly from your bank account with PayTo'
    case STRIPE_PAYMENT_ELEMENT_CARD_TYPE:
      return `No debit card fee, Credit card fees from ${BASE_MERCHANT_FEE}%`
    case SECURE_PAYMENT_TYPE:
      return 'Generate a payment link to receive payment securely over the phone'
    default:
      return null
  }
}

export function editPaymentMethodCaptions(paymentMethods: Array<PaymentMethodRowData>, isMerchantFeeAvailable: boolean, isStandaloneLuxPlusSubscription: boolean) : Array<PaymentMethodRowData> {
  // No Merchant fees on LuxPLus
  if (!isMerchantFeeAvailable || isStandaloneLuxPlusSubscription) return paymentMethods

  const paymentMethodsWithUpdatedLabels = paymentMethods.map((paymentMethod) => {
    return { ...paymentMethod, subLabel: (merchantFeeCaption(paymentMethod.name) || paymentMethod.subLabel) }
  },
  )

  return paymentMethodsWithUpdatedLabels
}

export function isStripePaymentCardV2(card: App.StripePaymentCard | App.StripePaymentCardV2): card is App.StripePaymentCardV2 {
  return 'funding' in card
}
