import React, { FormEventHandler, useCallback, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useAppDispatch } from 'hooks/reduxHooks'
import { formToObject } from 'lib/forms/formToObject'
import { updateUserDetails } from 'actions/AuthActions'
import TextButton from 'components/Luxkit/Button/TextButton'
import CheckboxInput from 'components/Luxkit/Checkbox/CheckboxInput'
import PhoneInput from 'components/Common/Form/Input/PhoneInput'
import LegalText from './AccountAccessLegalText'
import Group from 'components/utils/Group'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { AccountAccessModalResult } from './AccountAccessModal'
import * as Analytics from 'analytics/analytics'
import { setAccountAddMobilePrompted } from 'storage/account'

interface PhoneNum {
  phone: string;
  prefix: string;
}

interface AddPhoneForm {
  phoneNum: PhoneNum;
}

interface Props {
  error?: any;
  regionCode?: string;
  phone?: string;
  dismissable?: boolean;
}

function AccountAccessAddPhone(props: Props) {
  const {
    regionCode,
    phone,
    dismissable,
  } = props

  const termsCheckboxRef = useRef<HTMLInputElement>(null)
  const modalContext = useModalElementContext<AccountAccessModalResult>()
  const dispatch = useAppDispatch()
  const onAddPhone = useCallback<FormEventHandler<HTMLFormElement>>((event) => {
    event.preventDefault()
    const joinData = formToObject<AddPhoneForm>(event.currentTarget)
    dispatch(updateUserDetails({
      phone: joinData.phoneNum.phone,
      phonePrefix: joinData.phoneNum.prefix,
    },
    ))
  }, [dispatch])

  useEffect(() => {
    setAccountAddMobilePrompted(true)
    Analytics.trackClientEvent({
      subject: 'add_phone_number',
      action: 'impression',
      category: 'logging',
      type: 'operational',
    })
  }, [])

  useEffect(() => {
    if (phone?.length) {
      Analytics.trackClientEvent({
        subject: 'add_phone_number',
        action: 'interaction',
        category: 'logging',
        type: 'operational',
      })
      modalContext.resolve({ loggedIn: true })
    }
  }, [modalContext, phone])

  return (
    <>
      <ModalHeader
        title="Add mobile number"
        subtitle="Add your mobile number for an easier and quicker log in and checkout experience with a one-time code."
        dismissible={dismissable}
      />
      <ModalBody>
        <ModalContent>
          <form name="joinForm" onSubmit={onAddPhone}>
            <Group direction="vertical" gap={12}>
              <PhoneInput
                id="phoneNum"
                name="phoneNum"
                label="Phone number"
                prefixCountry={regionCode}
                required
              />
              <CheckboxInput
                ref={termsCheckboxRef}
                name="agreeTandC"
                data-testid="terms-and-privacy-agree-checkbox"
                required
              >
                <LegalText modalType="promptAddPhone"/>
              </CheckboxInput>
              <TextButton
                kind="primary"
                fit="flex"
                type="submit"
                size="large"
              >
                Add mobile number
              </TextButton>
            </Group>
          </form>
        </ModalContent>
      </ModalBody>
    </>
  )
}

function mapStateToProps(state: App.State) {
  return {
    error: state.auth.error,
    regionCode: state.geo.currentRegionCode,
    phone: state.auth.account.phone,
  }
}

export default connect(mapStateToProps)(AccountAccessAddPhone)
