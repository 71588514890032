export const STRIPE_PAYMENT_TYPE = 'stripe'
export const STRIPE_3DS_PAYMENT_TYPE = 'stripe_3ds'
export const STRIPE_PAYMENT_ELEMENT_TYPE = 'stripe_payment_element'
export const CREDIT_PAYMENT_TYPE = 'credit'
export const PROMO_PAYMENT_TYPE = 'promo'
export const VELOCITY_PAYMENT_TYPE = 'velocity'
export const STRIPE_CARD_PAYMENT_TYPE = 'card'
export const BRAINTREE_PAYMENT_TYPE = 'braintree'
export const RAZORPAY_PAYMENT_TYPE = 'razorpay'
export const KLARNA_PAYMENT_TYPE = 'klarna'
export const BRIDGER_PAY_PAYMENT_TYPE = 'bridgerpay'
export const AFTER_PAY_BP_PAYMENT_TYPE = 'afterpay_bp'
export const HOOLAH_BP_PAYMENT_TYPE = 'hoolah_bp'
export const ATOME_BP_PAYMENT_TYPE = 'atome_bp'
export const WAAVE_BP_PAYMENT_TYPE = 'waave_bp'
export const GO_CARDLESS_PAYMENT_TYPE = 'gocardless'
export const APPLE_PAY_PAYMENT_TYPE = 'applepay'
export const GOOGLE_PAY_PAYMENT_TYPE = 'googlepay'
export const KLARNA_BP_PAYMENT_TYPE = 'klarna_bp'
export const STRIPE_PAYMENT_ELEMENT_CARD_TYPE = 'stripe_payment_element_card'
export const STRIPE_PAYMENT_ELEMENT_GIROPAY_TYPE = 'stripe_payment_element_giropay'
export const STRIPE_PAYMENT_ELEMENT_IDEAL_TYPE = 'stripe_payment_element_ideal'
export const STRIPE_PAYMENT_ELEMENT_KLARNA_TYPE = 'stripe_payment_element_klarna'
export const STRIPE_CUSTOM_PAYTO_TYPE = 'stripe_custom_payto'
export const STRIPE_PAYMENT_ELEMENT_WALLET_TYPE = 'stripe_payment_element_wallet'
export const STRIPE_PAYMENT_ELEMENT_GOOGLE_PAY_TYPE = 'stripe_payment_element_google_pay'
export const STRIPE_PAYMENT_ELEMENT_APPLE_PAY_TYPE = 'stripe_payment_element_apple_pay'
export const STRIPE_PAYMENT_ELEMENT_AFTERPAY_TYPE = 'stripe_payment_element_afterpay'
export const STRIPE_3DS_V2_PAYMENT_TYPE = 'stripe_3ds_v2'
export const CAR_HIRE_PAY_ON_ARRIVAL_TYPE = 'car_hire_pay_on_arrival'
export const SECURE_PAYMENT_TYPE = 'secure_payment'
export const FLYING_BLUE_PAYMENT_TYPE = 'flying_blue'

// All payment methods which are subtypes of the Stripe Payment Element
export const STRIPE_PAYMENT_ELEMENT_METHODS = [
  STRIPE_PAYMENT_ELEMENT_CARD_TYPE,
  STRIPE_PAYMENT_ELEMENT_GOOGLE_PAY_TYPE,
  STRIPE_PAYMENT_ELEMENT_APPLE_PAY_TYPE,
  STRIPE_PAYMENT_ELEMENT_AFTERPAY_TYPE,
  STRIPE_PAYMENT_ELEMENT_GIROPAY_TYPE,
  STRIPE_PAYMENT_ELEMENT_IDEAL_TYPE,
  STRIPE_PAYMENT_ELEMENT_KLARNA_TYPE,
]

export const STRIPE_PAYMENT_ELEMENT_PARTIAL_PAYMENT_METHODS = [
  STRIPE_PAYMENT_ELEMENT_CARD_TYPE,
  STRIPE_PAYMENT_ELEMENT_GOOGLE_PAY_TYPE,
  STRIPE_PAYMENT_ELEMENT_APPLE_PAY_TYPE,
]

export const paymentMethodLabels = {
  [APPLE_PAY_PAYMENT_TYPE]: 'Apple Pay',
  [GOOGLE_PAY_PAYMENT_TYPE]: 'Google Pay',
  [STRIPE_PAYMENT_ELEMENT_TYPE]: 'Stripe Payment Element',
  [STRIPE_3DS_PAYMENT_TYPE]: 'Credit or debit card',
  [STRIPE_3DS_V2_PAYMENT_TYPE]: 'Credit or debit card',
  [STRIPE_PAYMENT_TYPE]: 'Credit or debit card',
  [STRIPE_PAYMENT_ELEMENT_CARD_TYPE]: 'Credit or debit card',
  [BRAINTREE_PAYMENT_TYPE]: 'PayPal',
  [VELOCITY_PAYMENT_TYPE]: 'Velocity Points',
  [KLARNA_PAYMENT_TYPE]: 'Klarna',
  [KLARNA_BP_PAYMENT_TYPE]: 'Klarna',
  [AFTER_PAY_BP_PAYMENT_TYPE]: 'Afterpay',
  [HOOLAH_BP_PAYMENT_TYPE]: 'Hoolah',
  [ATOME_BP_PAYMENT_TYPE]: 'Atome',
  [WAAVE_BP_PAYMENT_TYPE]: 'Waave',
  [RAZORPAY_PAYMENT_TYPE]: 'Razorpay',
  [GO_CARDLESS_PAYMENT_TYPE]: 'PayTo',
  [FLYING_BLUE_PAYMENT_TYPE]: 'Flyingblue',
}

export type PaymentMethod =
  typeof BRAINTREE_PAYMENT_TYPE |
  typeof STRIPE_PAYMENT_TYPE |
  typeof STRIPE_3DS_PAYMENT_TYPE |
  typeof STRIPE_3DS_V2_PAYMENT_TYPE |
  typeof STRIPE_PAYMENT_ELEMENT_APPLE_PAY_TYPE |
  typeof STRIPE_PAYMENT_ELEMENT_GOOGLE_PAY_TYPE |
  typeof STRIPE_PAYMENT_ELEMENT_CARD_TYPE |
  typeof STRIPE_PAYMENT_ELEMENT_GIROPAY_TYPE |
  typeof STRIPE_PAYMENT_ELEMENT_IDEAL_TYPE |
  typeof STRIPE_PAYMENT_ELEMENT_KLARNA_TYPE |
  typeof STRIPE_CUSTOM_PAYTO_TYPE |
  typeof FLYING_BLUE_PAYMENT_TYPE |
  typeof RAZORPAY_PAYMENT_TYPE |
  typeof KLARNA_PAYMENT_TYPE |
  typeof AFTER_PAY_BP_PAYMENT_TYPE |
  typeof HOOLAH_BP_PAYMENT_TYPE |
  typeof ATOME_BP_PAYMENT_TYPE |
  typeof VELOCITY_PAYMENT_TYPE |
  typeof APPLE_PAY_PAYMENT_TYPE |
  typeof GOOGLE_PAY_PAYMENT_TYPE |
  typeof KLARNA_BP_PAYMENT_TYPE |
  typeof WAAVE_BP_PAYMENT_TYPE |
  typeof GO_CARDLESS_PAYMENT_TYPE |
  typeof SECURE_PAYMENT_TYPE

export type BriderPayPaymentMethod =
  typeof AFTER_PAY_BP_PAYMENT_TYPE |
  typeof HOOLAH_BP_PAYMENT_TYPE |
  typeof ATOME_BP_PAYMENT_TYPE |
  typeof KLARNA_BP_PAYMENT_TYPE |
  typeof WAAVE_BP_PAYMENT_TYPE

export type StripePaymentElementMethod =
  typeof STRIPE_PAYMENT_ELEMENT_CARD_TYPE |
  typeof STRIPE_PAYMENT_ELEMENT_WALLET_TYPE |
  typeof STRIPE_PAYMENT_ELEMENT_AFTERPAY_TYPE

// The payment methods used for the final payment,
// after credits, promo have been applied
export const PAYMENT_TYPES_NOPROMO: Array<PaymentMethod> = [
  STRIPE_PAYMENT_TYPE,
  STRIPE_3DS_PAYMENT_TYPE,
  STRIPE_3DS_V2_PAYMENT_TYPE,
  BRAINTREE_PAYMENT_TYPE,
  RAZORPAY_PAYMENT_TYPE,
  KLARNA_PAYMENT_TYPE,
  AFTER_PAY_BP_PAYMENT_TYPE,
  HOOLAH_BP_PAYMENT_TYPE,
  ATOME_BP_PAYMENT_TYPE,
  APPLE_PAY_PAYMENT_TYPE,
  GOOGLE_PAY_PAYMENT_TYPE,
  KLARNA_BP_PAYMENT_TYPE,
  WAAVE_BP_PAYMENT_TYPE,
  GO_CARDLESS_PAYMENT_TYPE,
  FLYING_BLUE_PAYMENT_TYPE,
]

export const PAYMENT_TYPES_SIMPLE: Array<PaymentMethod> = [
  RAZORPAY_PAYMENT_TYPE,
  KLARNA_PAYMENT_TYPE,
  GO_CARDLESS_PAYMENT_TYPE,
  STRIPE_CUSTOM_PAYTO_TYPE,
  FLYING_BLUE_PAYMENT_TYPE,
]
export const PAYMENT_TYPES_BRIDGER: Array<PaymentMethod> = [
  KLARNA_BP_PAYMENT_TYPE,
  AFTER_PAY_BP_PAYMENT_TYPE,
  HOOLAH_BP_PAYMENT_TYPE,
  ATOME_BP_PAYMENT_TYPE,
  WAAVE_BP_PAYMENT_TYPE,
]
export const PAYMENT_TYPES_BRAINTREE : Array<PaymentMethod> = [
  BRAINTREE_PAYMENT_TYPE,
]
export const PAYMENT_TYPES_STRIPE : Array<PaymentMethod> = [
  STRIPE_PAYMENT_TYPE,
  STRIPE_3DS_PAYMENT_TYPE,
  STRIPE_3DS_V2_PAYMENT_TYPE,
  APPLE_PAY_PAYMENT_TYPE,
  GOOGLE_PAY_PAYMENT_TYPE,
]

export const STRIPE_PAYMENT_ELEMENT_METHODS_MAP = {
  [STRIPE_PAYMENT_ELEMENT_CARD_TYPE]: 'card',
  [STRIPE_PAYMENT_ELEMENT_GOOGLE_PAY_TYPE]: 'google_pay',
  [STRIPE_PAYMENT_ELEMENT_APPLE_PAY_TYPE]: 'apple_pay',
  [STRIPE_PAYMENT_ELEMENT_GIROPAY_TYPE]: 'giropay',
  [STRIPE_PAYMENT_ELEMENT_IDEAL_TYPE]: 'ideal',
  [STRIPE_PAYMENT_ELEMENT_KLARNA_TYPE]: 'klarna',
}

export type StripePaymentElementPaymentMethodType = 'card' | 'google_pay' | 'apple_pay' | 'payto' | 'ideal' | 'klarna'

export const PROMO_TYPE_PERCENTAGE = 'percentage'
export const PROMO_TYPE_FIXED = 'fixed_amount'

export const HIGH_VALUE_ORDER_AUD = 2999

export const MIN_KLARNA_PAYMENT = 35
export const MAX_KLARNA_PAYMENT = 2500

export const MAX_AFTER_PAY_BP_AUD_PAYMENT = 2000
export const MAX_AFTER_PAY_BP_USD_PAYMENT = 3000
export const MAX_AFTER_PAY_BP_NZD_PAYMENT = 3000

export const MIN_KLARNA_BP_USD_PAYMENT = 35
export const MIN_KLARNA_BP_NZD_PAYMENT = 35
export const MIN_KLARNA_BP_GBP_PAYMENT = 35

export const MAX_KLARNA_BP_USD_PAYMENT = 4000
export const MAX_KLARNA_BP_NZD_PAYMENT = 4000
export const MAX_KLARNA_BP_GBP_PAYMENT = 1000

export const MIN_HOOLAH_BP_SGD_PAYMENT = 30
export const MIN_HOOLAH_BP_MYR_PAYMENT = 100
export const MIN_HOOLAH_BP_HKD_PAYMENT = 100

export const MAX_HOOLAH_BP_SGD_PAYMENT = 2000
export const MAX_HOOLAH_BP_MYR_PAYMENT = 5000
export const MAX_HOOLAH_BP_HKD_PAYMENT = 10000

export const MIN_ATOME_BP_SGD_PAYMENT = 30
export const MIN_ATOME_BP_MYR_PAYMENT = 100
export const MIN_ATOME_BP_HKD_PAYMENT = 100

export const MAX_ATOME_BP_SGD_PAYMENT = 4000
export const MAX_ATOME_BP_MYR_PAYMENT = 6000
export const MAX_ATOME_BP_HKD_PAYMENT = 23000

export const MAX_STRIPE_CUSTOM_PAYTO_PAYMENT = 20000

export const INELEGIBLE_SERVICE_FEE_OFFER_TYPES: Array<App.OfferType> = ['connection_tour', 'cruise']
export const DEPOSIT_ENABLED_CURRENCIES = ['AUD', 'USD', 'NZD', 'GBP', 'SGD', 'CAD', 'CNY', 'EUR', 'HKD', 'IDR', 'ILS', 'JPY', 'KRW', 'MOP', 'MYR', 'PHP', 'QAR', 'RUB', 'SAR', 'TWD', 'THB', 'ZAR', 'AED', 'VND']
export const MIN_DEPOSITS_AUD_PAYMENT = 0
export const MIN_DEPOSITS_USD_PAYMENT = 0
export const MIN_DEPOSITS_NZD_PAYMENT = 0
export const MIN_DEPOSITS_GBP_PAYMENT = 0
export const MIN_DEPOSITS_SGD_PAYMENT = 0
export const MIN_DEPOSITS_CAD_PAYMENT = 0
export const MIN_DEPOSITS_CNY_PAYMENT = 0
export const MIN_DEPOSITS_EUR_PAYMENT = 0
export const MIN_DEPOSITS_HKD_PAYMENT = 0
export const MIN_DEPOSITS_IDR_PAYMENT = 0
export const MIN_DEPOSITS_ILS_PAYMENT = 0
export const MIN_DEPOSITS_JPY_PAYMENT = 0
export const MIN_DEPOSITS_KRW_PAYMENT = 0
export const MIN_DEPOSITS_MOP_PAYMENT = 0
export const MIN_DEPOSITS_MYR_PAYMENT = 0
export const MIN_DEPOSITS_PHP_PAYMENT = 0
export const MIN_DEPOSITS_QAR_PAYMENT = 0
export const MIN_DEPOSITS_RUB_PAYMENT = 0
export const MIN_DEPOSITS_SAR_PAYMENT = 0
export const MIN_DEPOSITS_TWD_PAYMENT = 0
export const MIN_DEPOSITS_THB_PAYMENT = 0
export const MIN_DEPOSITS_ZAR_PAYMENT = 0
export const MIN_DEPOSITS_AED_PAYMENT = 0
export const MIN_DEPOSITS_VND_PAYMENT = 0

export const REFUND_TO_CREDIT_REASON = 'Covid 19'
export const REFUND_DUE_TO_CHANGE_OF_MIND_REASON = 'Change of Mind Within Refund Guarantee Period'
export const REFUND_TO_CREDIT_COMMENT = 'Self-serve by customer'

export const REFUND_BACK_TO_ORIGIN_COMMENT = 'Self-serve by customer'

export const SHADOW_BAN_USER_ERROR_MESSAGE = 'Something went wrong with the payment'

export const REFUND_TYPE_BACK_TO_ORIGIN = 'back_to_origin'
export const REFUND_TYPE_CREDIT = 'credit'

export const CURRENCY_MIN_DEPOSIT_AMOUNT_MAP = {
  AUD: MIN_DEPOSITS_AUD_PAYMENT,
  USD: MIN_DEPOSITS_USD_PAYMENT,
  NZD: MIN_DEPOSITS_NZD_PAYMENT,
  GBP: MIN_DEPOSITS_GBP_PAYMENT,
  SGD: MIN_DEPOSITS_SGD_PAYMENT,
  CAD: MIN_DEPOSITS_CAD_PAYMENT,
  CNY: MIN_DEPOSITS_CNY_PAYMENT,
  EUR: MIN_DEPOSITS_EUR_PAYMENT,
  HKD: MIN_DEPOSITS_HKD_PAYMENT,
  IDR: MIN_DEPOSITS_IDR_PAYMENT,
  ILS: MIN_DEPOSITS_ILS_PAYMENT,
  JPY: MIN_DEPOSITS_JPY_PAYMENT,
  KRW: MIN_DEPOSITS_KRW_PAYMENT,
  MOP: MIN_DEPOSITS_MOP_PAYMENT,
  MYR: MIN_DEPOSITS_MYR_PAYMENT,
  PHP: MIN_DEPOSITS_PHP_PAYMENT,
  QAR: MIN_DEPOSITS_QAR_PAYMENT,
  RUB: MIN_DEPOSITS_RUB_PAYMENT,
  SAR: MIN_DEPOSITS_SAR_PAYMENT,
  TWD: MIN_DEPOSITS_TWD_PAYMENT,
  THB: MIN_DEPOSITS_THB_PAYMENT,
  ZAR: MIN_DEPOSITS_ZAR_PAYMENT,
  AED: MIN_DEPOSITS_AED_PAYMENT,
  VND: MIN_DEPOSITS_VND_PAYMENT,
}

export const DEPOSIT_STATUS = {
  DEPOSIT_TAKEN: 'deposit_taken',
  DEPOSIT_FAILED: 'deposit_failed',
  DEPOSIT_DETAILS_CREATED: 'deposit_details_created',
  DUE_BALANCE_MANUAL_DEBIT_TAKEN: 'due_balance_manual_debit_taken',
  DUE_BALANCE_AUTO_DEBIT_TAKEN: 'due_balance_auto_debit_taken',
  DUE_BALANCE_AUTO_DEBIT_FAILED: 'due_balance_auto_debit_failed',
  DUE_BALANCE_MANUAL_DEBIT_FAILED: 'due_balance_manual_debit_failed',
  FULL_BALANCE_REFUNDED: 'full_balance_refunded',
}

export const PAYMENT_METHODS_NOT_ALLOWED_REFUND: Array<string> = []

export const ALLOWED_GIFT_CARD_PAYMENT_METHODS: Array<PaymentMethod> = [
  STRIPE_PAYMENT_TYPE,
  STRIPE_3DS_PAYMENT_TYPE,
  STRIPE_3DS_V2_PAYMENT_TYPE,
  RAZORPAY_PAYMENT_TYPE,
  APPLE_PAY_PAYMENT_TYPE,
  GOOGLE_PAY_PAYMENT_TYPE,
]

export const ALLOWED_DEPOSIT_BALANCE_PAYMENT_METHODS: Array<PaymentMethod> = [
  STRIPE_3DS_PAYMENT_TYPE,
  STRIPE_3DS_V2_PAYMENT_TYPE,
  APPLE_PAY_PAYMENT_TYPE,
  GOOGLE_PAY_PAYMENT_TYPE,
]

export interface PaypalSupplementaryData {
  ota_service_start_date?: string
  ota_service_end_date?: string
  ota_start_country?: string
}

export enum PAYMENT_OPTIONS {
  FULL = 'full',
  DEPOSIT = 'deposit',
  RESERVE_FOR_FREE = 'reserve_for_free',
}

export enum PAYTO_PAYMENT_OPTIONS {
  PAY_VIA_BANK='payto-bank',
  PAY_VIA_PAYID='payto-payid'
}

export enum INSTALMENT_PLANS {
  SINGLE = 'single',
  MONTHLY = 'monthly',
  FORTNIGHTLY = 'fortnightly',
  WEEKLY = 'weekly',
}

export enum INSTALMENT_PERIODS {
  WEEKLY = 7,
  FORTNIGHTLY = 14,
  MONTHLY = 30,
}

export const DEPOSIT_PENDING_BALANCE_STATUSES: Set<string> = new Set([
  DEPOSIT_STATUS.DEPOSIT_TAKEN,
  DEPOSIT_STATUS.DUE_BALANCE_AUTO_DEBIT_FAILED,
  DEPOSIT_STATUS.DUE_BALANCE_MANUAL_DEBIT_FAILED,
])

export const DEPOSIT_BALANCE_PAYMENT_STATUSES: Set<string> = new Set([
  DEPOSIT_STATUS.DEPOSIT_TAKEN,
  DEPOSIT_STATUS.DUE_BALANCE_AUTO_DEBIT_FAILED,
  DEPOSIT_STATUS.DUE_BALANCE_MANUAL_DEBIT_FAILED,
  DEPOSIT_STATUS.DUE_BALANCE_MANUAL_DEBIT_TAKEN,
])

export const DEPOSIT_PAID_BALANCE_STATUSES: Set<string> = new Set([
  DEPOSIT_STATUS.DUE_BALANCE_AUTO_DEBIT_TAKEN,
  DEPOSIT_STATUS.DUE_BALANCE_MANUAL_DEBIT_TAKEN,
  DEPOSIT_STATUS.FULL_BALANCE_REFUNDED,
])

export enum INSTALMENT_STATUS {
  INITIAL_PAYMENT_TAKEN = 'initial_payment_taken', // Initial payment paid by user
  PAYMENT_FAILED = 'payment_failed', // FAILURE: Initial payment failed
  INSTALMENT_DUE_BALANCE_MANUAL_DEBIT_FAILED = 'payment_due_balance_manual_debit_failed', // FAILURE: Manual payment of due balance amount failed
  PAYMENT_REFUNDED = 'payment_refunded',
  PAYMENT_FULL_BALANCE_REFUNDED = 'payment_full_balance_refunded',
  PAYMENT_MANUAL_DEBIT_TAKEN = 'payment_manual_debit_taken',
  PAYMENT_MANUAL_DEBIT_FAILED = 'payment_manual_debit_failed',
  PAYMENT_TAKEN_COMPLETE = 'payment_taken_complete',
  PAYMENT_MANUAL_DEBIT_TAKEN_COMPLETE = 'payment_manual_debit_taken_complete',
  PAYMENT_AUTO_DEBIT_TAKEN = 'payment_auto_debit_taken',
  PAYMENT_AUTO_DEBIT_FAILED = 'payment_auto_debit_failed',
  PAYMENT_UPCOMING = 'payment_upcoming', // independent status for showing unpaid instalments
  CREDIT_APPLIED = 'credit_applied', // independent status for showing initial credit applied
}

export const INSTALMENT_PENDING_BALANCE_STATUSES: Set<string> = new Set([
  INSTALMENT_STATUS.INITIAL_PAYMENT_TAKEN,
  INSTALMENT_STATUS.PAYMENT_AUTO_DEBIT_TAKEN,
  INSTALMENT_STATUS.PAYMENT_AUTO_DEBIT_FAILED,
  INSTALMENT_STATUS.INSTALMENT_DUE_BALANCE_MANUAL_DEBIT_FAILED,
  INSTALMENT_STATUS.PAYMENT_MANUAL_DEBIT_TAKEN,
  INSTALMENT_STATUS.PAYMENT_MANUAL_DEBIT_FAILED,
])

export const INSTALMENT_PAYMENT_STATUSES: Set<string> = new Set([
  INSTALMENT_STATUS.PAYMENT_TAKEN_COMPLETE,
  INSTALMENT_STATUS.PAYMENT_MANUAL_DEBIT_TAKEN,
  INSTALMENT_STATUS.PAYMENT_MANUAL_DEBIT_FAILED,
  INSTALMENT_STATUS.PAYMENT_MANUAL_DEBIT_TAKEN_COMPLETE,
  INSTALMENT_STATUS.INSTALMENT_DUE_BALANCE_MANUAL_DEBIT_FAILED,
  INSTALMENT_STATUS.PAYMENT_AUTO_DEBIT_FAILED,
])

export const INSTALMENT_MANUAL_PAYMENT_SUCCESS_STATUSES: Set<string> = new Set([
  INSTALMENT_STATUS.PAYMENT_TAKEN_COMPLETE,
  INSTALMENT_STATUS.PAYMENT_MANUAL_DEBIT_TAKEN,
])

export const INSTALMENT_PAYMENT_SUCCESS_STATUSES: Set<string> = new Set([
  INSTALMENT_STATUS.INITIAL_PAYMENT_TAKEN,
  INSTALMENT_STATUS.PAYMENT_TAKEN_COMPLETE,
  INSTALMENT_STATUS.PAYMENT_AUTO_DEBIT_TAKEN,
  INSTALMENT_STATUS.PAYMENT_MANUAL_DEBIT_TAKEN_COMPLETE,
  INSTALMENT_STATUS.PAYMENT_MANUAL_DEBIT_TAKEN,
])

export const INSTALMENT_PAYMENT_FAILURE_STATUSES: Set<string> = new Set([
  INSTALMENT_STATUS.PAYMENT_FAILED,
  INSTALMENT_STATUS.PAYMENT_MANUAL_DEBIT_FAILED,
  INSTALMENT_STATUS.PAYMENT_AUTO_DEBIT_FAILED,
  INSTALMENT_STATUS.INSTALMENT_DUE_BALANCE_MANUAL_DEBIT_FAILED,
])

export const INSTALMENT_PAYMENT_REFUND_STATUSES: Set<string> = new Set([
  INSTALMENT_STATUS.PAYMENT_REFUNDED,
  INSTALMENT_STATUS.PAYMENT_FULL_BALANCE_REFUNDED,
])

export enum INSTALMENT_PAYMENT_TYPE {
  INSTALMENT_PAYMENT = 'instalment_payment',
  INSTALMENT_BALANCE = 'instalment_balance',
}

export const ZERO_DECIMAL_CURRENCIES = new Set([
  'BIF', 'CLP', 'DJF', 'GNF', 'JPY', 'KMF', 'KRW', 'MGA',
  'PYG', 'RWF', 'VND', 'VUV', 'XAF', 'XOF', 'XPF',
])

export const COMP_PROMO_CODE = 'LEPRIZE'
export const FRIENDS_AND_FAMILY_PROMOS = ['FAMILYANDFRIENDS20', 'FAMILYANDFRIENDS10']

export enum RESERVE_FOR_ZERO_STATUS {
  BOOKING_DETAILS_CREATED = 'booking_details_created',
  PAYMENT_PENDING = 'payment_pending',
  PAYMENT_TAKEN = 'payment_taken',
  BOOKING_CANCELLED = 'booking_cancelled',
  SUBSCRIPTION_CREATION_ERRORED = 'subscription_creation_errored',
  SETUP_INTENT_ERRORED = 'setup_intent_errored',
  PAYMENT_FAILED = 'payment_failed',
  PAYMENT_AUTO_DEBIT_TAKEN = 'payment_auto_debit_taken',
  PAYMENT_AUTO_DEBIT_FAILED = 'payment_auto_debit_failed',
  PAYMENT_MANUAL_DEBIT_TAKEN = 'payment_manual_debit_taken',
  PAYMENT_MANUAL_DEBIT_FAILED = 'payment_manual_debit_failed',
}

export const RESERVE_FOR_ZERO_PENDING_BALANCE_STATUSES: Set<string> = new Set([
  RESERVE_FOR_ZERO_STATUS.PAYMENT_PENDING,
  RESERVE_FOR_ZERO_STATUS.PAYMENT_MANUAL_DEBIT_FAILED,
  RESERVE_FOR_ZERO_STATUS.PAYMENT_MANUAL_DEBIT_TAKEN,
])

export const BASE_MERCHANT_FEE = 0.95
